import React from 'react';
import ray from "../../../assets/img/ray-fix.png"
import peyman from "../../../assets/img/peyman-2.png"
import our_story from "../../../assets/img/our_story.png"

export default function Story(props) {
    return (
        <div className="story">
            <div className="contain">
                {/* <div className="bubble">
                    Our Story
                </div> */}
                <img className="bubble" src={our_story} alt="Our Story" />

                <div className="images">
                    <div className="image">
                        <div>
                            <img style={{ filter: "brightness(1.12)" }} src={peyman} alt="Peyman Ramezani" />
                        </div>
                        <div className="caption">
                            Peyman Ramezani
                        </div>
                    </div>
                    <div className="image">
                        <div>
                            <img src={ray} alt="Ray Rafiq" />
                        </div>
                        <div className="caption">
                            Ray Rafiq
                        </div>
                    </div>
                </div>

                <div className="about">
                    You want to enjoy your home - so you should also enjoy the process of buying a home.
                    <br /><br />
                    Which today, no-one can say.
                    <br /><br />
                    Let us introduce you to Peyman and Ray.
                    <br /><br />
                    Both passionate about helping those that no-one else is helping.
                    <br /><br />
                    Specifically people who have done everything right in life, but are still locked out of homeownership.
                    <br /><br />
                    We’re for those that are securely employed, who have the income but can’t understand why the computer says no.
                    <br /><br />
                    And for young families who wonder why they have to pay more in rent when a mortgage would cost them less.
                    <br /><br />
                    Most importantly, we understand not everyone can go to bank of Mum and Dad, so we’re for you with the best rates on high loan-to-value mortgages.
                    <br /><br />
                    We care deeply about helping hard working folk get into homeownership.
                    <br /><br />
                    Sprung works because we keep things simple - our process looks at your property, the place it’s in and its price. So we only lend when the property is as safe as the people who want to live in it.
                    <br /><br />
                    Our modern systems will look to give you confidence when buying a home, by giving you a clear yes if you’ve got a clean credit file.
                    <br /><br />
                    And we also say yes to secure self employed people. And young families who haven’t had the time to save a huge deposit yet.
                    <br /><br />
                    We know the biggest barrier to getting into homeownership is saving for a big enough deposit.
                    <br /><br />
                    That’s why we’re speeding up the process: instant decisions, the lowest rates for high loan-to-value mortgages and we’re even automating the conveyancing process to save you from the pain so many others suffer when buying a home.
                    <br /><br />
                    We start as we mean to go on.
                    <br /><br />
                    If there’s one thing to remember about Sprung: our computer says yes.
                </div>
            </div>
        </div>
    );
}