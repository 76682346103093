import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

export default function NumberApplicants(props) {
    const [data, setData] = useState(props.data)
    const [dataset, setDataset] = useState([])

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        let obj = {
            "1": 0,
            "2": 0,
            "3": 0
        }
        for (let i = 0, size = data.length; i < size; i++) {
            obj[data[i].data.number_of_applicants] += 1
        }

        let arr = []
        Object.entries(obj).map(e => {
            arr.push({ x: e[0], y: e[1] })
        })

        setDataset(arr)
    }, [data])

    return (
        <Bar data={{
            labels: ["1", "2", "3"],
            datasets: [
                {
                    label: 'Number of applicants on application',
                    data: [...dataset],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }} />
    );
}