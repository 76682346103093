require("datejs")

export default function create_date_set(min, max, bucket, type) {
    if (bucket === "day") {
        if (type === "label") {
            let arr = []
            for (let i = 0, size = Math.floor((new Date.parse(max) - new Date.parse(min)) / 86400000); i < size; i++) {
                arr.push(new Date(min).addDays(i).toDateString())
            }
            return arr
        } else if (type === "object") {
            let obj = {}
            for (let i = 0, size = Math.floor((new Date.parse(max) - new Date.parse(min)) / 86400000); i < size; i++) {
                obj[new Date(min).addDays(i).toDateString()] = 0
            }
            return obj
        }
    } else if (bucket === "week") {
        if (type === "label") {
            let arr = []
            for (let i = 0, size = Math.floor((new Date.parse(max) - new Date.parse(min)) / 86400000); i < size; i += 7) {
                let date = new Date(min).addDays(i)
                date.setDate(date.getDate() - (date.getDay() + 6) % 7);
                date = date.toDateString()
                if(!arr.includes(date)) arr.push(date)
            }
            return arr
        } else if (type === "object") {
            let obj = {}
            for (let i = 0, size = Math.floor((new Date.parse(max) - new Date.parse(min)) / 86400000); i < size; i += 7) {
                let date = new Date(min).addDays(i)
                date.setDate(date.getDate() - (date.getDay() + 6) % 7);
                date = date.toDateString()
                obj[date] = 0
            }
            return obj
        }
    } else if (bucket === "month") {
        if (type === "label") {
            let arr = []
            // for (let i = 0, size = Math.floor((new Date.parse(max) - new Date.parse(min)) / 86400000); i < size; i++) {
            //     let date = new Date(min).addDays(i)
            //     date.setDate(1);
            //     date = date.toDateString()
            //     if(!arr.includes(date)) arr.push(date)
            // }
            return arr
        } else if (type === "object") {
            let obj = {}
            for (let i = 0, size = Math.floor((new Date.parse(max) - new Date.parse(min)) / 86400000); i < size; i++) {
                let date = new Date(min).addDays(i)
                date.setDate(1);
                date = date.toString("MMMM yyyy")
                obj[date] = 0
            }
            return obj
        }
    } else if (bucket === "year") {
        if (type === "label") {
            let arr = []
            // for (let i = 0, size = Math.floor((new Date.parse(max) - new Date.parse(min)) / 86400000); i < size; i++) {
            //     let date = new Date(min).addDays(i)
            //     date.setDate(1);
            //     date = date.toDateString()
            //     if(!arr.includes(date)) arr.push(date)
            // }
            return arr
        } else if (type === "object") {
            let obj = {}
            for (let i = 0, size = Math.floor((new Date.parse(max) - new Date.parse(min)) / 86400000); i < size; i++) {
                let date = new Date(min).addDays(i)
                date = date.toString("yyyy")
                obj[date] = 0
            }
            return obj
        }
    }
}