import React from 'react';
import logo from "../../../assets/img/logo.png"
import history from "../../../history"
import cta from "../../../assets/img/cta.png"

export default function CTA(props) {
    return (
        <div className="cta">
            <div className="contain">
                <div className="heading">
                    <img className="logo" src={logo} alt="Sprung" />
                </div>

                {/* <div className="call">
                    Changing <span className="underline">Everything</span> for<br />First Time Buyers
                </div> */}
                <img className="bubble" src={cta} alt="Call to action" />
                <br />
                <div className="want_in" onClick={() => history.push("/apply")}>
                    I Want In
                </div>
                <br />
                <div className="small_text">
                    Sign up with your email address to receive news and register for our products.
                </div>
            </div>
        </div>
    );
}