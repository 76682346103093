import commaNumber from 'comma-number';
import React, { useEffect, useState } from 'react';
import britain from "../../assets/img/britain.png"
import GoogleMapReact from 'google-map-react';
import marker from "../../assets/img/map_marker.png"

export default function Input(props) {
    const [center, setCenter] = useState(null)
    const [zoom, setZoom] = useState(15)
    const [data, setData] = useState(props.data)
    let count = 0

    useEffect(() => {
        if (props.data) {
            setData(props.data)
        }
    }, [props.data])

    useEffect(() => {
        let arr = []
        for (let i = 0; i < data.length; i++) {
            console.log(data[i])
            arr.push([data[i].coordinates.lat, data[i].coordinates.long])
        }
        let middle = getLatLngCenter(arr)
        setCenter({
            lat: middle[0],
            lng: middle[1]
        })
        setZoom(middle[2])
    }, [])

    function rad2degr(rad) { return rad * 180 / Math.PI; }
    function degr2rad(degr) { return degr * Math.PI / 180; }
    function getLatLngCenter(latLngInDegr) {
        let LATIDX = 0;
        let LNGIDX = 1;
        let sumX = 0;
        let sumY = 0;
        let sumZ = 0;
        let meters = 0;

        for (var i = 0; i < latLngInDegr.length; i++) {
            let lat = degr2rad(latLngInDegr[i][LATIDX]);
            let lng = degr2rad(latLngInDegr[i][LNGIDX]);
            // sum of cartesian coordinates
            sumX += Math.cos(lat) * Math.cos(lng);
            sumY += Math.cos(lat) * Math.sin(lng);
            sumZ += Math.sin(lat);
            if (i < latLngInDegr.length - 1) {
                let dist = distance(latLngInDegr[i][LATIDX], latLngInDegr[i][LNGIDX], latLngInDegr[i + 1][LATIDX], latLngInDegr[i + 1][LNGIDX])
                if (dist > meters) meters = dist
            }
        }

        meters *= 1000
        var zoomfactor = 1;
        if (meters < 1128) zoomfactor = 15
        else if ((meters > 1128) && (meters <= 2256)) zoomfactor = 14
        else if ((meters > 2256) && (meters <= 4513)) zoomfactor = 13
        else if ((meters > 4513) && (meters <= 9027)) zoomfactor = 12
        else if ((meters > 9027) && (meters <= 18055)) zoomfactor = 11
        else if ((meters > 18055) && (meters <= 36111)) zoomfactor = 10
        else if ((meters > 36111) && (meters <= 72223)) zoomfactor = 9
        else if ((meters > 72223) && (meters <= 144447)) zoomfactor = 8
        else if ((meters > 144447) && (meters <= 288895)) zoomfactor = 7
        else if ((meters > 288895) && (meters <= 577790)) zoomfactor = 6
        else if ((meters > 577790) && (meters <= 1155581)) zoomfactor = 5
        else if ((meters > 1155581) && (meters <= 2311162)) zoomfactor = 4
        else if ((meters > 2311162) && (meters <= 4622324)) zoomfactor = 3
        else if ((meters > 4622324) && (meters <= 9244649)) zoomfactor = 2
        else if (meters > 9244649) zoomfactor = 1

        console.log(meters, zoomfactor)
        let avgX = sumX / latLngInDegr.length;
        let avgY = sumY / latLngInDegr.length;
        let avgZ = sumZ / latLngInDegr.length;

        // convert average x, y, z coordinate to latitude and longtitude
        let lng = Math.atan2(avgY, avgX);
        let hyp = Math.sqrt(avgX * avgX + avgY * avgY);
        let lat = Math.atan2(avgZ, hyp);

        return ([rad2degr(lat), rad2degr(lng), zoomfactor]);
    }

    function distance(lat1, lon1, lat2, lon2) {
        var p = 0.017453292519943295;    // Math.PI / 180
        var c = Math.cos;
        var a = 0.5 - c((lat2 - lat1) * p) / 2 +
            c(lat1 * p) * c(lat2 * p) *
            (1 - c((lon2 - lon1) * p)) / 2;

        return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    }

    return (
        <div className='input'>
            <div className='column entry'>
                <div className='top'>
                    <div>
                        £<input type="number" max="999" value={props.input} onChange={e => props.setInput(e.currentTarget.value)} />million
                    </div>
                    <div>
                        <img src={britain} alt="Britain" />
                    </div>
                </div>
                <div className='middle'>
                    <div className='row'>
                        <div className='data1'>Portfolio</div>
                        <div className='data2'>£{commaNumber(data.reduce((r, c) => r + c.price[0].value, 0))}</div>
                    </div>
                    <div className='row'>
                        <div className='data1'>Total</div>
                        <div className='data2'>{data.length}</div>
                    </div>
                    <div className='row'>
                        <div className='data1'>Average Price</div>
                        <div className='data2'>£{commaNumber(Math.round(data.reduce((r, c) => r + c.price[0].value, 0) / data.length))}</div>
                    </div>
                    {/* <div className='row'>
                        <div className='data1'>5yr Historic Growth</div>
                        <div className='data2'>%</div>
                    </div>
                    <div className='row'>
                        <div className='data1'>Annual Rental Income</div>
                        <div className='data2'>£1,900,000</div>
                    </div>
                    <div className='row'>
                        <div className='data1'>Average Yield</div>
                        <div className='data2'>2.21%</div>
                    </div>
                    <div className='row'>
                        <div className='data1'>Total Return</div>
                        <div className='data2'>%</div>
                    </div>
                    <div className='row'>
                        <div className='data1'>Fee</div>
                        <div className='data2'>0.8%</div>
                    </div>
                    <div className='row'>
                        <div className='data1'>Total Net Return</div>
                        <div className='data2'>%</div>
                    </div> */}
                </div>
                <div className='bottom'>
                    <button>
                        Customise your own strategy
                    </button>
                </div>
            </div>
            <div className='column map'>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyAxj00b1JDDjMKL4I-QQrUz-OcGFOJ7N2Y" }}
                    // defaultCenter={center}
                    defaultCenter={{
                        lat: 55.747268,
                        lng: -4.375876
                    }}
                    defaultZoom={zoom}
                    yesIWantToUseGoogleMapApiInternals
                >
                    {data.sort((a, b) => {
                        return b._price - a._price
                    }).map(e => {
                        count += e.price[0].value
                        return <div
                            lat={e.coordinates.lat}
                            lng={e.coordinates.long}
                            style={{position: "relative", filter: count > props.input * 1000000 ? "grayscale(1)brightness(1.6)" : "", zIndex: count > props.input * 1000000 ? "1" : "4"}}>
                            <img className='marker' src={marker} alt="Marker" />
                        </div>
                    })}
                </GoogleMapReact>
            </div>
        </div>
    );
}