import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';

export default function Adverse(props) {
    const [data, setData] = useState(props.data)
    const [dataset, setDataset] = useState([])

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        let obj = {
            "Yes": 0,
            "No": 0
        }
        data.forEach(e => {
            if(e.data.adverse_credit === "YES") obj.Yes += 1
            else if(e.data.adverse_credit === "NO") obj.No += 1
        })
        setDataset([obj.Yes, obj.No])
    }, [data])
    
    return (
        <Doughnut data={{
            labels: ["Yes", "No"],
            datasets: [
                {
                    label: 'Adverse Credit',
                    data: dataset,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }} />
    );
}