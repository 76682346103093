import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

export default function EmploymentType(props) {
    const [data, setData] = useState(props.data)
    const [dataset, setDataset] = useState([])

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        let obj = {
            "Employed": 0,
            "Self Employed": 0,
            "Company Director": 0,
            "Contractor": 0,
            "Retired": 0,
            "Homemaker": 0,
            "Student": 0,
            "Unemployed": 0
        }
        for (let i = 0, size = data.length; i < size; i++) {
            if(data[i].data.employment) {
                let type = data[i].data.employment.split("_")
                let string = ""
                for (let j = 0, size2 = type.length; j < size2; j++) {
                    if(j > 0) string += " "
                    string += type[j][0] + type[j].substring(1, type[j].length).toLowerCase()
                }
                obj[string] += 1
            }
        }

        let arr = []
        Object.entries(obj).map(e => {
            arr.push({ x: e[0], y: e[1] })
        })

        setDataset(arr)
    }, [data])

    return (
        <Bar data={{
            labels: ["Employed", "Self Employed", "Company Director", "Contractor", "Retired", "Homemaker", "Student", "Unemployed"],
            datasets: [
                {
                    label: 'Type of employment',
                    data: [...dataset],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }} />
    );
}