import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';

export default function UkNationals(props) {
    const [data, setData] = useState(props.data)
    const [dataset, setDataset] = useState([])

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        let obj = {
            "UK": 0,
            "EU": 0,
            "Other": 0
        }
        data.forEach(e => {
            if(e.data.uk_nationals === "YES") obj.UK += 1
            else if(e.data.eu_nationals === "YES") obj.EU += 1
            else obj.Other += 1
        })
        setDataset([obj.UK, obj.EU, obj.Other])
    }, [data])

    return (
        <Doughnut data={{
            labels: ["UK", "EU", "Other"],
            datasets: [
                {
                    label: 'Nationality',
                    data: dataset,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }} />
    );
}