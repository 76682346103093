import React, { useEffect, useState } from 'react';
import Footer from '../footer/footer';
import logo from "../../assets/img/logo.png"

export default function Mortgage(props) {
    const [height, setHeight] = useState(200)

    useEffect(() => {
        // Setup event listener
        var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

        // Listen for event
        eventer(messageEvent, function (e) {
            // Check that message being passed is the documentHeight
            if ((typeof e.data === 'string') && (e.data.indexOf('freeWindowHeight:') > -1)) {

                // Split string from identifier
                var height = e.data.split('freeWindowHeight:')[1];

                // do stuff with the height
                setHeight(height - 115)
            }
        }, false);
    }, [])

    return (
        <div className="mortgage">
            <div className="contain">
                <div className="heading">
                    <img className="logo" src={logo} alt="Sprung" />
                </div>

                <iframe style={{height: height + "px"}} title="Fact Find" className="iframe" src={"https://moresimple.money/adverse_credit_full/sprung"} />
            </div>

            <Footer />
        </div>
    );
}