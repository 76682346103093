import React, { useState } from 'react';
import logo from "../../assets/img/logo.png"
import Input from './input';
import data from "./example_data"
import Properties from './properties';
import Footer from '../footer/footer';

export default function Pipeline(props) {
    const [input, setInput] = useState(90)

    return (
        <div className="pipeline">
            <div className="contain">
                <div className="heading">
                    <img className="logo" src={logo} alt="Sprung" />
                </div>

                <Input data={data} input={input} setInput={setInput}/>
                <Properties data={data} input={input} />
            </div>

            <Footer />
        </div>
    );
}