import property_1 from "../../assets/img/maps/property_1.png"
import property_2 from "../../assets/img/maps/property_2.png"
import property_3 from "../../assets/img/maps/property_3.png"
import property_4 from "../../assets/img/maps/property_4.png"
import property_5 from "../../assets/img/maps/property_5.png"
import property_6 from "../../assets/img/maps/property_6.png"
import property_7 from "../../assets/img/maps/property_7.png"
import property_8 from "../../assets/img/maps/property_8.png"
import property_9 from "../../assets/img/maps/property_9.png"
import property_10 from "../../assets/img/maps/property_10.png"
import property_11 from "../../assets/img/maps/property_11.png"
import property_12 from "../../assets/img/maps/property_12.png"
import property_13 from "../../assets/img/maps/property_13.png"
import property_14 from "../../assets/img/maps/property_14.png"
import property_15 from "../../assets/img/maps/property_15.png"
import property_16 from "../../assets/img/maps/property_16.png"
import property_17 from "../../assets/img/maps/property_17.png"
import property_18 from "../../assets/img/maps/property_18.png"
import property_19 from "../../assets/img/maps/property_19.png"
import property_20 from "../../assets/img/maps/property_20.png"
import property_21 from "../../assets/img/maps/property_21.png"
import property_22 from "../../assets/img/maps/property_22.png"
import property_23 from "../../assets/img/maps/property_23.png"
import property_24 from "../../assets/img/maps/property_24.png"
import property_25 from "../../assets/img/maps/property_25.png"
import property_26 from "../../assets/img/maps/property_26.png"
import property_27 from "../../assets/img/maps/property_27.png"
import property_28 from "../../assets/img/maps/property_28.png"
import property_29 from "../../assets/img/maps/property_29.png"
import property_30 from "../../assets/img/maps/property_30.png"
import property_31 from "../../assets/img/maps/property_31.png"
import property_32 from "../../assets/img/maps/property_32.png"
import property_33 from "../../assets/img/maps/property_33.png"
import property_34 from "../../assets/img/maps/property_34.png"
import property_35 from "../../assets/img/maps/property_35.png"
import property_36 from "../../assets/img/maps/property_36.png"
import property_37 from "../../assets/img/maps/property_37.png"
import property_38 from "../../assets/img/maps/property_38.png"
import property_39 from "../../assets/img/maps/property_39.png"
import property_40 from "../../assets/img/maps/property_40.png"
import property_41 from "../../assets/img/maps/property_41.png"
import property_42 from "../../assets/img/maps/property_42.png"
import property_43 from "../../assets/img/maps/property_43.png"
import property_44 from "../../assets/img/maps/property_44.png"
import property_45 from "../../assets/img/maps/property_45.png"
import property_46 from "../../assets/img/maps/property_46.png"
import property_47 from "../../assets/img/maps/property_47.png"
import property_48 from "../../assets/img/maps/property_48.png"
import property_49 from "../../assets/img/maps/property_49.png"
import property_50 from "../../assets/img/maps/property_50.png"
import property_51 from "../../assets/img/maps/property_51.png"
import property_52 from "../../assets/img/maps/property_52.png"
import property_53 from "../../assets/img/maps/property_53.png"
import property_54 from "../../assets/img/maps/property_54.png"
import property_55 from "../../assets/img/maps/property_55.png"
import property_56 from "../../assets/img/maps/property_56.png"
import property_57 from "../../assets/img/maps/property_57.png"
import property_58 from "../../assets/img/maps/property_58.png"
import property_59 from "../../assets/img/maps/property_59.png"
import property_60 from "../../assets/img/maps/property_60.png"
import property_61 from "../../assets/img/maps/property_61.png"
import property_62 from "../../assets/img/maps/property_62.png"
import property_63 from "../../assets/img/maps/property_63.png"
import property_64 from "../../assets/img/maps/property_64.png"
import property_65 from "../../assets/img/maps/property_65.png"
import property_66 from "../../assets/img/maps/property_66.png"
import property_67 from "../../assets/img/maps/property_67.png"
import property_68 from "../../assets/img/maps/property_68.png"
import property_69 from "../../assets/img/maps/property_69.png"
import property_70 from "../../assets/img/maps/property_70.png"
import property_71 from "../../assets/img/maps/property_71.png"
import property_72 from "../../assets/img/maps/property_72.png"
import property_73 from "../../assets/img/maps/property_73.png"
import property_74 from "../../assets/img/maps/property_74.png"
import property_75 from "../../assets/img/maps/property_75.png"
import property_76 from "../../assets/img/maps/property_76.png"
import property_77 from "../../assets/img/maps/property_77.png"
import property_78 from "../../assets/img/maps/property_78.png"
import property_79 from "../../assets/img/maps/property_79.png"
import property_80 from "../../assets/img/maps/property_80.png"
import property_81 from "../../assets/img/maps/property_81.png"
import property_82 from "../../assets/img/maps/property_82.png"
import property_83 from "../../assets/img/maps/property_83.png"
import property_84 from "../../assets/img/maps/property_84.png"
import property_85 from "../../assets/img/maps/property_85.png"
import property_86 from "../../assets/img/maps/property_86.png"
import property_87 from "../../assets/img/maps/property_87.png"
import property_88 from "../../assets/img/maps/property_88.png"
import property_89 from "../../assets/img/maps/property_89.png"
import property_90 from "../../assets/img/maps/property_90.png"
import property_91 from "../../assets/img/maps/property_91.png"
import property_92 from "../../assets/img/maps/property_92.png"
import property_93 from "../../assets/img/maps/property_93.png"
import property_94 from "../../assets/img/maps/property_94.png"
import property_95 from "../../assets/img/maps/property_95.png"
import property_96 from "../../assets/img/maps/property_96.png"
import property_97 from "../../assets/img/maps/property_97.png"
import property_98 from "../../assets/img/maps/property_98.png"
import property_99 from "../../assets/img/maps/property_99.png"
import property_100 from "../../assets/img/maps/property_100.png"
import property_101 from "../../assets/img/maps/property_101.png"
import property_102 from "../../assets/img/maps/property_102.png"
import property_103 from "../../assets/img/maps/property_103.png"
import property_104 from "../../assets/img/maps/property_104.png"
import property_105 from "../../assets/img/maps/property_105.png"
import property_106 from "../../assets/img/maps/property_106.png"
import property_107 from "../../assets/img/maps/property_107.png"
import property_108 from "../../assets/img/maps/property_108.png"
import property_109 from "../../assets/img/maps/property_109.png"
import property_110 from "../../assets/img/maps/property_110.png"
import property_111 from "../../assets/img/maps/property_111.png"
import property_112 from "../../assets/img/maps/property_112.png"
import property_113 from "../../assets/img/maps/property_113.png"
import property_114 from "../../assets/img/maps/property_114.png"
import property_115 from "../../assets/img/maps/property_115.png"
import property_116 from "../../assets/img/maps/property_116.png"
import property_117 from "../../assets/img/maps/property_117.png"
import property_118 from "../../assets/img/maps/property_118.png"
import property_119 from "../../assets/img/maps/property_119.png"
import property_120 from "../../assets/img/maps/property_120.png"
import property_121 from "../../assets/img/maps/property_121.png"
import property_122 from "../../assets/img/maps/property_122.png"
import property_123 from "../../assets/img/maps/property_123.png"
import property_124 from "../../assets/img/maps/property_124.png"
import property_125 from "../../assets/img/maps/property_125.png"
import property_126 from "../../assets/img/maps/property_126.png"
import property_127 from "../../assets/img/maps/property_127.png"
import property_128 from "../../assets/img/maps/property_128.png"
import property_129 from "../../assets/img/maps/property_129.png"
import property_130 from "../../assets/img/maps/property_130.png"
import property_131 from "../../assets/img/maps/property_131.png"
import property_132 from "../../assets/img/maps/property_132.png"
import property_133 from "../../assets/img/maps/property_133.png"
import property_134 from "../../assets/img/maps/property_134.png"
import property_135 from "../../assets/img/maps/property_135.png"
import property_136 from "../../assets/img/maps/property_136.png"
import property_137 from "../../assets/img/maps/property_137.png"
import property_138 from "../../assets/img/maps/property_138.png"
import property_139 from "../../assets/img/maps/property_139.png"
import property_140 from "../../assets/img/maps/property_140.png"
import property_141 from "../../assets/img/maps/property_141.png"
import property_142 from "../../assets/img/maps/property_142.png"
import property_143 from "../../assets/img/maps/property_143.png"
import property_144 from "../../assets/img/maps/property_144.png"
import property_145 from "../../assets/img/maps/property_145.png"
import property_146 from "../../assets/img/maps/property_146.png"
import property_147 from "../../assets/img/maps/property_147.png"
import property_148 from "../../assets/img/maps/property_148.png"
import property_149 from "../../assets/img/maps/property_149.png"
import property_150 from "../../assets/img/maps/property_150.png"
import property_151 from "../../assets/img/maps/property_151.png"
import property_152 from "../../assets/img/maps/property_152.png"
import property_153 from "../../assets/img/maps/property_153.png"
import property_154 from "../../assets/img/maps/property_154.png"
import property_155 from "../../assets/img/maps/property_155.png"
import property_156 from "../../assets/img/maps/property_156.png"
import property_157 from "../../assets/img/maps/property_157.png"
import property_158 from "../../assets/img/maps/property_158.png"
import property_159 from "../../assets/img/maps/property_159.png"
import property_160 from "../../assets/img/maps/property_160.png"
import property_161 from "../../assets/img/maps/property_161.png"
import property_162 from "../../assets/img/maps/property_162.png"
import property_163 from "../../assets/img/maps/property_163.png"
import property_164 from "../../assets/img/maps/property_164.png"
import property_165 from "../../assets/img/maps/property_165.png"
import property_166 from "../../assets/img/maps/property_166.png"
import property_167 from "../../assets/img/maps/property_167.png"
import property_168 from "../../assets/img/maps/property_168.png"
import property_169 from "../../assets/img/maps/property_169.png"
import property_170 from "../../assets/img/maps/property_170.png"
import property_171 from "../../assets/img/maps/property_171.png"
import property_172 from "../../assets/img/maps/property_172.png"
import property_173 from "../../assets/img/maps/property_173.png"
import property_174 from "../../assets/img/maps/property_174.png"
import property_175 from "../../assets/img/maps/property_175.png"
import property_176 from "../../assets/img/maps/property_176.png"
import property_177 from "../../assets/img/maps/property_177.png"
import property_178 from "../../assets/img/maps/property_178.png"
import property_179 from "../../assets/img/maps/property_179.png"
import property_180 from "../../assets/img/maps/property_180.png"
import property_181 from "../../assets/img/maps/property_181.png"
import property_182 from "../../assets/img/maps/property_182.png"
import property_183 from "../../assets/img/maps/property_183.png"
import property_184 from "../../assets/img/maps/property_184.png"
import property_185 from "../../assets/img/maps/property_185.png"
import property_186 from "../../assets/img/maps/property_186.png"
import property_187 from "../../assets/img/maps/property_187.png"
import property_188 from "../../assets/img/maps/property_188.png"
import property_189 from "../../assets/img/maps/property_189.png"
import property_190 from "../../assets/img/maps/property_190.png"
import property_191 from "../../assets/img/maps/property_191.png"
import property_192 from "../../assets/img/maps/property_192.png"
import property_193 from "../../assets/img/maps/property_193.png"
import property_194 from "../../assets/img/maps/property_194.png"
import property_195 from "../../assets/img/maps/property_195.png"
import property_196 from "../../assets/img/maps/property_196.png"
import property_197 from "../../assets/img/maps/property_197.png"
import property_198 from "../../assets/img/maps/property_198.png"
import property_199 from "../../assets/img/maps/property_199.png"
import property_200 from "../../assets/img/maps/property_200.png"
import property_201 from "../../assets/img/maps/property_201.png"
import property_202 from "../../assets/img/maps/property_202.png"
import property_203 from "../../assets/img/maps/property_203.png"
import property_204 from "../../assets/img/maps/property_204.png"
import property_205 from "../../assets/img/maps/property_205.png"
import property_206 from "../../assets/img/maps/property_206.png"
import property_207 from "../../assets/img/maps/property_207.png"
import property_208 from "../../assets/img/maps/property_208.png"
import property_209 from "../../assets/img/maps/property_209.png"
import property_210 from "../../assets/img/maps/property_210.png"
import property_211 from "../../assets/img/maps/property_211.png"
import property_212 from "../../assets/img/maps/property_212.png"
import property_213 from "../../assets/img/maps/property_213.png"
import property_214 from "../../assets/img/maps/property_214.png"
import property_215 from "../../assets/img/maps/property_215.png"
import property_216 from "../../assets/img/maps/property_216.png"
import property_217 from "../../assets/img/maps/property_217.png"
import property_218 from "../../assets/img/maps/property_218.png"
import property_219 from "../../assets/img/maps/property_219.png"
import property_220 from "../../assets/img/maps/property_220.png"
import property_221 from "../../assets/img/maps/property_221.png"
import property_222 from "../../assets/img/maps/property_222.png"
import property_223 from "../../assets/img/maps/property_223.png"
import property_224 from "../../assets/img/maps/property_224.png"
import property_225 from "../../assets/img/maps/property_225.png"
import property_226 from "../../assets/img/maps/property_226.png"
import property_227 from "../../assets/img/maps/property_227.png"
import property_228 from "../../assets/img/maps/property_228.png"
import property_229 from "../../assets/img/maps/property_229.png"
import property_230 from "../../assets/img/maps/property_230.png"
import property_231 from "../../assets/img/maps/property_231.png"
import property_232 from "../../assets/img/maps/property_232.png"
import property_233 from "../../assets/img/maps/property_233.png"
import property_234 from "../../assets/img/maps/property_234.png"
import property_235 from "../../assets/img/maps/property_235.png"
import property_236 from "../../assets/img/maps/property_236.png"
import property_237 from "../../assets/img/maps/property_237.png"
import property_238 from "../../assets/img/maps/property_238.png"
import property_239 from "../../assets/img/maps/property_239.png"
import property_240 from "../../assets/img/maps/property_240.png"
import property_241 from "../../assets/img/maps/property_241.png"
import property_242 from "../../assets/img/maps/property_242.png"
import property_243 from "../../assets/img/maps/property_243.png"
import property_244 from "../../assets/img/maps/property_244.png"
import property_245 from "../../assets/img/maps/property_245.png"
import property_246 from "../../assets/img/maps/property_246.png"
import property_247 from "../../assets/img/maps/property_247.png"
import property_248 from "../../assets/img/maps/property_248.png"
import property_249 from "../../assets/img/maps/property_249.png"
import property_250 from "../../assets/img/maps/property_250.png"
import property_251 from "../../assets/img/maps/property_251.png"
import property_252 from "../../assets/img/maps/property_252.png"
import property_253 from "../../assets/img/maps/property_253.png"
import property_254 from "../../assets/img/maps/property_254.png"
import property_255 from "../../assets/img/maps/property_255.png"
import property_256 from "../../assets/img/maps/property_256.png"
import property_257 from "../../assets/img/maps/property_257.png"
import property_258 from "../../assets/img/maps/property_258.png"
import property_259 from "../../assets/img/maps/property_259.png"
import property_260 from "../../assets/img/maps/property_260.png"
import property_261 from "../../assets/img/maps/property_261.png"
import property_262 from "../../assets/img/maps/property_262.png"
import property_263 from "../../assets/img/maps/property_263.png"
import property_264 from "../../assets/img/maps/property_264.png"
import property_265 from "../../assets/img/maps/property_265.png"
import property_266 from "../../assets/img/maps/property_266.png"
import property_267 from "../../assets/img/maps/property_267.png"
import property_268 from "../../assets/img/maps/property_268.png"
import property_269 from "../../assets/img/maps/property_269.png"
import property_270 from "../../assets/img/maps/property_270.png"
import property_271 from "../../assets/img/maps/property_271.png"
import property_272 from "../../assets/img/maps/property_272.png"
import property_273 from "../../assets/img/maps/property_273.png"
import property_274 from "../../assets/img/maps/property_274.png"
import property_275 from "../../assets/img/maps/property_275.png"
import property_276 from "../../assets/img/maps/property_276.png"
import property_277 from "../../assets/img/maps/property_277.png"
import property_278 from "../../assets/img/maps/property_278.png"
import property_279 from "../../assets/img/maps/property_279.png"
import property_280 from "../../assets/img/maps/property_280.png"
import property_281 from "../../assets/img/maps/property_281.png"
import property_282 from "../../assets/img/maps/property_282.png"
import property_283 from "../../assets/img/maps/property_283.png"
import property_284 from "../../assets/img/maps/property_284.png"
import property_285 from "../../assets/img/maps/property_285.png"
import property_286 from "../../assets/img/maps/property_286.png"
import property_287 from "../../assets/img/maps/property_287.png"
import property_288 from "../../assets/img/maps/property_288.png"
import property_289 from "../../assets/img/maps/property_289.png"
import property_290 from "../../assets/img/maps/property_290.png"
import property_291 from "../../assets/img/maps/property_291.png"
import property_292 from "../../assets/img/maps/property_292.png"
import property_293 from "../../assets/img/maps/property_293.png"
import property_294 from "../../assets/img/maps/property_294.png"
import property_295 from "../../assets/img/maps/property_295.png"
import property_296 from "../../assets/img/maps/property_296.png"
import property_297 from "../../assets/img/maps/property_297.png"
import property_298 from "../../assets/img/maps/property_298.png"
import property_299 from "../../assets/img/maps/property_299.png"
import property_300 from "../../assets/img/maps/property_300.png"
import property_301 from "../../assets/img/maps/property_301.png"
import property_302 from "../../assets/img/maps/property_302.png"
import property_303 from "../../assets/img/maps/property_303.png"
import property_304 from "../../assets/img/maps/property_304.png"
import property_305 from "../../assets/img/maps/property_305.png"
import property_306 from "../../assets/img/maps/property_306.png"
import property_307 from "../../assets/img/maps/property_307.png"
import property_308 from "../../assets/img/maps/property_308.png"
import property_309 from "../../assets/img/maps/property_309.png"
import property_310 from "../../assets/img/maps/property_310.png"
import property_311 from "../../assets/img/maps/property_311.png"
import property_312 from "../../assets/img/maps/property_312.png"
import property_313 from "../../assets/img/maps/property_313.png"
import property_314 from "../../assets/img/maps/property_314.png"
import property_315 from "../../assets/img/maps/property_315.png"
import property_316 from "../../assets/img/maps/property_316.png"
import property_317 from "../../assets/img/maps/property_317.png"
import property_318 from "../../assets/img/maps/property_318.png"
import property_319 from "../../assets/img/maps/property_319.png"
import property_320 from "../../assets/img/maps/property_320.png"
import property_321 from "../../assets/img/maps/property_321.png"
import property_322 from "../../assets/img/maps/property_322.png"
import property_323 from "../../assets/img/maps/property_323.png"
import property_324 from "../../assets/img/maps/property_324.png"
import property_325 from "../../assets/img/maps/property_325.png"
import property_326 from "../../assets/img/maps/property_326.png"
import property_327 from "../../assets/img/maps/property_327.png"
import property_328 from "../../assets/img/maps/property_328.png"
import property_329 from "../../assets/img/maps/property_329.png"
import property_330 from "../../assets/img/maps/property_330.png"
import property_331 from "../../assets/img/maps/property_331.png"
import property_332 from "../../assets/img/maps/property_332.png"
import property_333 from "../../assets/img/maps/property_333.png"
import property_334 from "../../assets/img/maps/property_334.png"
import property_335 from "../../assets/img/maps/property_335.png"
import property_336 from "../../assets/img/maps/property_336.png"
import property_337 from "../../assets/img/maps/property_337.png"
import property_338 from "../../assets/img/maps/property_338.png"
import property_339 from "../../assets/img/maps/property_339.png"
import property_340 from "../../assets/img/maps/property_340.png"
import property_341 from "../../assets/img/maps/property_341.png"
import property_342 from "../../assets/img/maps/property_342.png"
import property_343 from "../../assets/img/maps/property_343.png"
import property_344 from "../../assets/img/maps/property_344.png"
import property_345 from "../../assets/img/maps/property_345.png"
import property_346 from "../../assets/img/maps/property_346.png"
import property_347 from "../../assets/img/maps/property_347.png"
import property_348 from "../../assets/img/maps/property_348.png"
import property_349 from "../../assets/img/maps/property_349.png"
import property_350 from "../../assets/img/maps/property_350.png"
import property_351 from "../../assets/img/maps/property_351.png"
import property_352 from "../../assets/img/maps/property_352.png"
import property_353 from "../../assets/img/maps/property_353.png"
import property_354 from "../../assets/img/maps/property_354.png"
import property_355 from "../../assets/img/maps/property_355.png"
import property_356 from "../../assets/img/maps/property_356.png"
import property_357 from "../../assets/img/maps/property_357.png"
import property_358 from "../../assets/img/maps/property_358.png"
import property_359 from "../../assets/img/maps/property_359.png"
import property_360 from "../../assets/img/maps/property_360.png"
import property_361 from "../../assets/img/maps/property_361.png"
import property_362 from "../../assets/img/maps/property_362.png"
import property_363 from "../../assets/img/maps/property_363.png"
import property_364 from "../../assets/img/maps/property_364.png"
import property_365 from "../../assets/img/maps/property_365.png"
import property_366 from "../../assets/img/maps/property_366.png"
import property_367 from "../../assets/img/maps/property_367.png"
import property_368 from "../../assets/img/maps/property_368.png"
import property_369 from "../../assets/img/maps/property_369.png"
import property_370 from "../../assets/img/maps/property_370.png"
import property_371 from "../../assets/img/maps/property_371.png"
import property_372 from "../../assets/img/maps/property_372.png"
import property_373 from "../../assets/img/maps/property_373.png"
import property_374 from "../../assets/img/maps/property_374.png"
import property_375 from "../../assets/img/maps/property_375.png"
import property_376 from "../../assets/img/maps/property_376.png"
import property_377 from "../../assets/img/maps/property_377.png"
import property_378 from "../../assets/img/maps/property_378.png"
import property_379 from "../../assets/img/maps/property_379.png"
import property_380 from "../../assets/img/maps/property_380.png"
import property_381 from "../../assets/img/maps/property_381.png"
import property_382 from "../../assets/img/maps/property_382.png"
import property_383 from "../../assets/img/maps/property_383.png"
import property_384 from "../../assets/img/maps/property_384.png"
import property_385 from "../../assets/img/maps/property_385.png"
import property_386 from "../../assets/img/maps/property_386.png"
import property_387 from "../../assets/img/maps/property_387.png"
import property_388 from "../../assets/img/maps/property_388.png"
import property_389 from "../../assets/img/maps/property_389.png"
import property_390 from "../../assets/img/maps/property_390.png"
import property_391 from "../../assets/img/maps/property_391.png"
import property_392 from "../../assets/img/maps/property_392.png"
import property_393 from "../../assets/img/maps/property_393.png"
import property_394 from "../../assets/img/maps/property_394.png"
import property_395 from "../../assets/img/maps/property_395.png"
import property_396 from "../../assets/img/maps/property_396.png"
import property_397 from "../../assets/img/maps/property_397.png"
import property_398 from "../../assets/img/maps/property_398.png"
import property_399 from "../../assets/img/maps/property_399.png"
import property_400 from "../../assets/img/maps/property_400.png"
import property_401 from "../../assets/img/maps/property_401.png"
import property_402 from "../../assets/img/maps/property_402.png"
import property_403 from "../../assets/img/maps/property_403.png"
import property_404 from "../../assets/img/maps/property_404.png"
import property_405 from "../../assets/img/maps/property_405.png"
import property_406 from "../../assets/img/maps/property_406.png"
import property_407 from "../../assets/img/maps/property_407.png"
import property_408 from "../../assets/img/maps/property_408.png"
import property_409 from "../../assets/img/maps/property_409.png"
import property_410 from "../../assets/img/maps/property_410.png"
import property_411 from "../../assets/img/maps/property_411.png"
import property_412 from "../../assets/img/maps/property_412.png"
import property_413 from "../../assets/img/maps/property_413.png"
import property_414 from "../../assets/img/maps/property_414.png"
import property_415 from "../../assets/img/maps/property_415.png"
import property_416 from "../../assets/img/maps/property_416.png"
import property_417 from "../../assets/img/maps/property_417.png"
import property_418 from "../../assets/img/maps/property_418.png"
import property_419 from "../../assets/img/maps/property_419.png"
import property_420 from "../../assets/img/maps/property_420.png"
import property_421 from "../../assets/img/maps/property_421.png"
import property_422 from "../../assets/img/maps/property_422.png"
import property_423 from "../../assets/img/maps/property_423.png"
import property_424 from "../../assets/img/maps/property_424.png"
import property_425 from "../../assets/img/maps/property_425.png"
import property_426 from "../../assets/img/maps/property_426.png"
import property_427 from "../../assets/img/maps/property_427.png"
import property_428 from "../../assets/img/maps/property_428.png"
import property_429 from "../../assets/img/maps/property_429.png"
import property_430 from "../../assets/img/maps/property_430.png"
import property_431 from "../../assets/img/maps/property_431.png"
import property_432 from "../../assets/img/maps/property_432.png"
import property_433 from "../../assets/img/maps/property_433.png"
import property_434 from "../../assets/img/maps/property_434.png"
import property_435 from "../../assets/img/maps/property_435.png"
import property_436 from "../../assets/img/maps/property_436.png"
import property_437 from "../../assets/img/maps/property_437.png"
import property_438 from "../../assets/img/maps/property_438.png"
import property_439 from "../../assets/img/maps/property_439.png"
import property_440 from "../../assets/img/maps/property_440.png"
import property_441 from "../../assets/img/maps/property_441.png"
import property_442 from "../../assets/img/maps/property_442.png"
import property_443 from "../../assets/img/maps/property_443.png"
import property_444 from "../../assets/img/maps/property_444.png"
import property_445 from "../../assets/img/maps/property_445.png"
import property_446 from "../../assets/img/maps/property_446.png"
import property_447 from "../../assets/img/maps/property_447.png"
import property_448 from "../../assets/img/maps/property_448.png"
import property_449 from "../../assets/img/maps/property_449.png"
import property_450 from "../../assets/img/maps/property_450.png"
import property_451 from "../../assets/img/maps/property_451.png"
import property_452 from "../../assets/img/maps/property_452.png"
import property_453 from "../../assets/img/maps/property_453.png"
import property_454 from "../../assets/img/maps/property_454.png"
import property_455 from "../../assets/img/maps/property_455.png"
import property_456 from "../../assets/img/maps/property_456.png"
import property_457 from "../../assets/img/maps/property_457.png"
import property_458 from "../../assets/img/maps/property_458.png"
import property_459 from "../../assets/img/maps/property_459.png"
import property_460 from "../../assets/img/maps/property_460.png"
import property_461 from "../../assets/img/maps/property_461.png"
import property_462 from "../../assets/img/maps/property_462.png"
import property_463 from "../../assets/img/maps/property_463.png"
import property_464 from "../../assets/img/maps/property_464.png"
import property_465 from "../../assets/img/maps/property_465.png"
import property_466 from "../../assets/img/maps/property_466.png"
import property_467 from "../../assets/img/maps/property_467.png"
import property_468 from "../../assets/img/maps/property_468.png"
import property_469 from "../../assets/img/maps/property_469.png"
import property_470 from "../../assets/img/maps/property_470.png"
import property_471 from "../../assets/img/maps/property_471.png"
import property_472 from "../../assets/img/maps/property_472.png"
import property_473 from "../../assets/img/maps/property_473.png"
import property_474 from "../../assets/img/maps/property_474.png"
import property_475 from "../../assets/img/maps/property_475.png"
import property_476 from "../../assets/img/maps/property_476.png"
import property_477 from "../../assets/img/maps/property_477.png"
import property_478 from "../../assets/img/maps/property_478.png"
import property_479 from "../../assets/img/maps/property_479.png"
import property_480 from "../../assets/img/maps/property_480.png"
import property_481 from "../../assets/img/maps/property_481.png"
import property_482 from "../../assets/img/maps/property_482.png"
import property_483 from "../../assets/img/maps/property_483.png"
import property_484 from "../../assets/img/maps/property_484.png"
import property_485 from "../../assets/img/maps/property_485.png"
import property_486 from "../../assets/img/maps/property_486.png"
import property_487 from "../../assets/img/maps/property_487.png"
import property_488 from "../../assets/img/maps/property_488.png"
import property_489 from "../../assets/img/maps/property_489.png"
import property_490 from "../../assets/img/maps/property_490.png"
import property_491 from "../../assets/img/maps/property_491.png"
import property_492 from "../../assets/img/maps/property_492.png"
import property_493 from "../../assets/img/maps/property_493.png"
import property_494 from "../../assets/img/maps/property_494.png"
import property_495 from "../../assets/img/maps/property_495.png"
import property_496 from "../../assets/img/maps/property_496.png"
import property_497 from "../../assets/img/maps/property_497.png"
import property_498 from "../../assets/img/maps/property_498.png"
import property_499 from "../../assets/img/maps/property_499.png"
import property_500 from "../../assets/img/maps/property_500.png"
import property_501 from "../../assets/img/maps/property_501.png"
import property_502 from "../../assets/img/maps/property_502.png"
import property_503 from "../../assets/img/maps/property_503.png"
import property_504 from "../../assets/img/maps/property_504.png"
import property_505 from "../../assets/img/maps/property_505.png"
import property_506 from "../../assets/img/maps/property_506.png"
import property_507 from "../../assets/img/maps/property_507.png"
import property_508 from "../../assets/img/maps/property_508.png"
import property_509 from "../../assets/img/maps/property_509.png"
import property_510 from "../../assets/img/maps/property_510.png"
import property_511 from "../../assets/img/maps/property_511.png"
import property_512 from "../../assets/img/maps/property_512.png"
import property_513 from "../../assets/img/maps/property_513.png"
import property_514 from "../../assets/img/maps/property_514.png"
import property_515 from "../../assets/img/maps/property_515.png"
import property_516 from "../../assets/img/maps/property_516.png"
import property_517 from "../../assets/img/maps/property_517.png"
import property_518 from "../../assets/img/maps/property_518.png"
import property_519 from "../../assets/img/maps/property_519.png"
import property_520 from "../../assets/img/maps/property_520.png"
import property_521 from "../../assets/img/maps/property_521.png"
import property_522 from "../../assets/img/maps/property_522.png"
import property_523 from "../../assets/img/maps/property_523.png"
import property_524 from "../../assets/img/maps/property_524.png"
import property_525 from "../../assets/img/maps/property_525.png"
import property_526 from "../../assets/img/maps/property_526.png"
import property_527 from "../../assets/img/maps/property_527.png"
import property_528 from "../../assets/img/maps/property_528.png"
import property_529 from "../../assets/img/maps/property_529.png"
import property_530 from "../../assets/img/maps/property_530.png"
import property_531 from "../../assets/img/maps/property_531.png"
import property_532 from "../../assets/img/maps/property_532.png"
import property_533 from "../../assets/img/maps/property_533.png"
import property_534 from "../../assets/img/maps/property_534.png"
import property_535 from "../../assets/img/maps/property_535.png"
import property_536 from "../../assets/img/maps/property_536.png"
import property_537 from "../../assets/img/maps/property_537.png"
import property_538 from "../../assets/img/maps/property_538.png"
import property_539 from "../../assets/img/maps/property_539.png"
import property_540 from "../../assets/img/maps/property_540.png"
import property_541 from "../../assets/img/maps/property_541.png"
import property_542 from "../../assets/img/maps/property_542.png"
import property_543 from "../../assets/img/maps/property_543.png"
import property_544 from "../../assets/img/maps/property_544.png"
import property_545 from "../../assets/img/maps/property_545.png"
import property_546 from "../../assets/img/maps/property_546.png"
import property_547 from "../../assets/img/maps/property_547.png"
import property_548 from "../../assets/img/maps/property_548.png"
import property_549 from "../../assets/img/maps/property_549.png"
import property_550 from "../../assets/img/maps/property_550.png"
import property_551 from "../../assets/img/maps/property_551.png"
import property_552 from "../../assets/img/maps/property_552.png"
import property_553 from "../../assets/img/maps/property_553.png"
import property_554 from "../../assets/img/maps/property_554.png"
import property_555 from "../../assets/img/maps/property_555.png"
import property_556 from "../../assets/img/maps/property_556.png"
import property_557 from "../../assets/img/maps/property_557.png"
import property_558 from "../../assets/img/maps/property_558.png"
import property_559 from "../../assets/img/maps/property_559.png"
import property_560 from "../../assets/img/maps/property_560.png"
import property_561 from "../../assets/img/maps/property_561.png"
import property_562 from "../../assets/img/maps/property_562.png"
import property_563 from "../../assets/img/maps/property_563.png"
import property_564 from "../../assets/img/maps/property_564.png"
import property_565 from "../../assets/img/maps/property_565.png"
import property_566 from "../../assets/img/maps/property_566.png"
import property_567 from "../../assets/img/maps/property_567.png"
import property_568 from "../../assets/img/maps/property_568.png"
import property_569 from "../../assets/img/maps/property_569.png"
import property_570 from "../../assets/img/maps/property_570.png"
import property_571 from "../../assets/img/maps/property_571.png"
import property_572 from "../../assets/img/maps/property_572.png"
import property_573 from "../../assets/img/maps/property_573.png"
import property_574 from "../../assets/img/maps/property_574.png"
import property_575 from "../../assets/img/maps/property_575.png"
import property_576 from "../../assets/img/maps/property_576.png"
import property_577 from "../../assets/img/maps/property_577.png"
import property_578 from "../../assets/img/maps/property_578.png"
import property_579 from "../../assets/img/maps/property_579.png"
import property_580 from "../../assets/img/maps/property_580.png"
import property_581 from "../../assets/img/maps/property_581.png"
import property_582 from "../../assets/img/maps/property_582.png"
import property_583 from "../../assets/img/maps/property_583.png"
import property_584 from "../../assets/img/maps/property_584.png"
import property_585 from "../../assets/img/maps/property_585.png"
import property_586 from "../../assets/img/maps/property_586.png"
import property_587 from "../../assets/img/maps/property_587.png"
import property_588 from "../../assets/img/maps/property_588.png"
import property_589 from "../../assets/img/maps/property_589.png"
import property_590 from "../../assets/img/maps/property_590.png"
import property_591 from "../../assets/img/maps/property_591.png"
import property_592 from "../../assets/img/maps/property_592.png"
import property_593 from "../../assets/img/maps/property_593.png"
import property_594 from "../../assets/img/maps/property_594.png"
import property_595 from "../../assets/img/maps/property_595.png"
import property_596 from "../../assets/img/maps/property_596.png"
import property_597 from "../../assets/img/maps/property_597.png"
import property_598 from "../../assets/img/maps/property_598.png"
import property_599 from "../../assets/img/maps/property_599.png"
import property_600 from "../../assets/img/maps/property_600.png"
import property_601 from "../../assets/img/maps/property_601.png"
import property_602 from "../../assets/img/maps/property_602.png"
import property_603 from "../../assets/img/maps/property_603.png"
import property_604 from "../../assets/img/maps/property_604.png"
import property_605 from "../../assets/img/maps/property_605.png"
import property_606 from "../../assets/img/maps/property_606.png"
import property_607 from "../../assets/img/maps/property_607.png"
import property_608 from "../../assets/img/maps/property_608.png"
import property_609 from "../../assets/img/maps/property_609.png"
import property_610 from "../../assets/img/maps/property_610.png"
import property_611 from "../../assets/img/maps/property_611.png"
import property_612 from "../../assets/img/maps/property_612.png"
import property_613 from "../../assets/img/maps/property_613.png"
import property_614 from "../../assets/img/maps/property_614.png"
import property_615 from "../../assets/img/maps/property_615.png"
import property_616 from "../../assets/img/maps/property_616.png"
import property_617 from "../../assets/img/maps/property_617.png"
import property_618 from "../../assets/img/maps/property_618.png"
import property_619 from "../../assets/img/maps/property_619.png"
import property_620 from "../../assets/img/maps/property_620.png"
import property_621 from "../../assets/img/maps/property_621.png"
import property_622 from "../../assets/img/maps/property_622.png"
import property_623 from "../../assets/img/maps/property_623.png"
import property_624 from "../../assets/img/maps/property_624.png"
import property_625 from "../../assets/img/maps/property_625.png"
import property_626 from "../../assets/img/maps/property_626.png"
import property_627 from "../../assets/img/maps/property_627.png"
import property_628 from "../../assets/img/maps/property_628.png"
import property_629 from "../../assets/img/maps/property_629.png"
import property_630 from "../../assets/img/maps/property_630.png"
import property_631 from "../../assets/img/maps/property_631.png"
import property_632 from "../../assets/img/maps/property_632.png"
import property_633 from "../../assets/img/maps/property_633.png"
import property_634 from "../../assets/img/maps/property_634.png"
import property_635 from "../../assets/img/maps/property_635.png"
import property_636 from "../../assets/img/maps/property_636.png"
import property_637 from "../../assets/img/maps/property_637.png"
import property_638 from "../../assets/img/maps/property_638.png"
import property_639 from "../../assets/img/maps/property_639.png"
import property_640 from "../../assets/img/maps/property_640.png"
import property_641 from "../../assets/img/maps/property_641.png"
import property_642 from "../../assets/img/maps/property_642.png"
import property_643 from "../../assets/img/maps/property_643.png"
import property_644 from "../../assets/img/maps/property_644.png"
import property_645 from "../../assets/img/maps/property_645.png"
import property_646 from "../../assets/img/maps/property_646.png"
import property_647 from "../../assets/img/maps/property_647.png"
import property_648 from "../../assets/img/maps/property_648.png"
import property_649 from "../../assets/img/maps/property_649.png"
import property_650 from "../../assets/img/maps/property_650.png"
import property_651 from "../../assets/img/maps/property_651.png"
import property_652 from "../../assets/img/maps/property_652.png"
import property_653 from "../../assets/img/maps/property_653.png"
import property_654 from "../../assets/img/maps/property_654.png"
import property_655 from "../../assets/img/maps/property_655.png"
import property_656 from "../../assets/img/maps/property_656.png"
import property_657 from "../../assets/img/maps/property_657.png"
import property_658 from "../../assets/img/maps/property_658.png"
import property_659 from "../../assets/img/maps/property_659.png"
import property_660 from "../../assets/img/maps/property_660.png"
import property_661 from "../../assets/img/maps/property_661.png"
import property_662 from "../../assets/img/maps/property_662.png"
import property_663 from "../../assets/img/maps/property_663.png"
import property_664 from "../../assets/img/maps/property_664.png"
import property_665 from "../../assets/img/maps/property_665.png"
import property_666 from "../../assets/img/maps/property_666.png"
import property_667 from "../../assets/img/maps/property_667.png"
import property_668 from "../../assets/img/maps/property_668.png"
import property_669 from "../../assets/img/maps/property_669.png"
import property_670 from "../../assets/img/maps/property_670.png"
import property_671 from "../../assets/img/maps/property_671.png"
import property_672 from "../../assets/img/maps/property_672.png"
import property_673 from "../../assets/img/maps/property_673.png"
import property_674 from "../../assets/img/maps/property_674.png"
import property_675 from "../../assets/img/maps/property_675.png"
import property_676 from "../../assets/img/maps/property_676.png"
import property_677 from "../../assets/img/maps/property_677.png"
import property_678 from "../../assets/img/maps/property_678.png"
import property_679 from "../../assets/img/maps/property_679.png"
import property_680 from "../../assets/img/maps/property_680.png"
import property_681 from "../../assets/img/maps/property_681.png"
import property_682 from "../../assets/img/maps/property_682.png"
import property_683 from "../../assets/img/maps/property_683.png"
import property_684 from "../../assets/img/maps/property_684.png"
import property_685 from "../../assets/img/maps/property_685.png"
import property_686 from "../../assets/img/maps/property_686.png"
import property_687 from "../../assets/img/maps/property_687.png"
import property_688 from "../../assets/img/maps/property_688.png"
import property_689 from "../../assets/img/maps/property_689.png"
import property_690 from "../../assets/img/maps/property_690.png"
import property_691 from "../../assets/img/maps/property_691.png"
import property_692 from "../../assets/img/maps/property_692.png"
import property_693 from "../../assets/img/maps/property_693.png"
import property_694 from "../../assets/img/maps/property_694.png"
import property_695 from "../../assets/img/maps/property_695.png"
import property_696 from "../../assets/img/maps/property_696.png"
import property_697 from "../../assets/img/maps/property_697.png"
import property_698 from "../../assets/img/maps/property_698.png"
import property_699 from "../../assets/img/maps/property_699.png"
import property_700 from "../../assets/img/maps/property_700.png"
import property_701 from "../../assets/img/maps/property_701.png"
import property_702 from "../../assets/img/maps/property_702.png"
import property_703 from "../../assets/img/maps/property_703.png"
import property_704 from "../../assets/img/maps/property_704.png"
import property_705 from "../../assets/img/maps/property_705.png"
import property_706 from "../../assets/img/maps/property_706.png"
import property_707 from "../../assets/img/maps/property_707.png"
import property_708 from "../../assets/img/maps/property_708.png"
import property_709 from "../../assets/img/maps/property_709.png"
import property_710 from "../../assets/img/maps/property_710.png"
import property_711 from "../../assets/img/maps/property_711.png"
import property_712 from "../../assets/img/maps/property_712.png"
import property_713 from "../../assets/img/maps/property_713.png"
import property_714 from "../../assets/img/maps/property_714.png"
import property_715 from "../../assets/img/maps/property_715.png"
import property_716 from "../../assets/img/maps/property_716.png"
import property_717 from "../../assets/img/maps/property_717.png"
import property_718 from "../../assets/img/maps/property_718.png"
import property_719 from "../../assets/img/maps/property_719.png"
import property_720 from "../../assets/img/maps/property_720.png"
import property_721 from "../../assets/img/maps/property_721.png"
import property_722 from "../../assets/img/maps/property_722.png"
import property_723 from "../../assets/img/maps/property_723.png"
import property_724 from "../../assets/img/maps/property_724.png"
import property_725 from "../../assets/img/maps/property_725.png"
import property_726 from "../../assets/img/maps/property_726.png"
import property_727 from "../../assets/img/maps/property_727.png"
import property_728 from "../../assets/img/maps/property_728.png"
import property_729 from "../../assets/img/maps/property_729.png"
import property_730 from "../../assets/img/maps/property_730.png"
import property_731 from "../../assets/img/maps/property_731.png"
import property_732 from "../../assets/img/maps/property_732.png"
import property_733 from "../../assets/img/maps/property_733.png"
import property_734 from "../../assets/img/maps/property_734.png"
import property_735 from "../../assets/img/maps/property_735.png"
import property_736 from "../../assets/img/maps/property_736.png"
import property_737 from "../../assets/img/maps/property_737.png"
import property_738 from "../../assets/img/maps/property_738.png"
import property_739 from "../../assets/img/maps/property_739.png"
import property_740 from "../../assets/img/maps/property_740.png"
import property_741 from "../../assets/img/maps/property_741.png"
import property_742 from "../../assets/img/maps/property_742.png"
import property_743 from "../../assets/img/maps/property_743.png"
import property_744 from "../../assets/img/maps/property_744.png"
import property_745 from "../../assets/img/maps/property_745.png"
import property_746 from "../../assets/img/maps/property_746.png"
import property_747 from "../../assets/img/maps/property_747.png"
import property_748 from "../../assets/img/maps/property_748.png"
import property_749 from "../../assets/img/maps/property_749.png"
import property_750 from "../../assets/img/maps/property_750.png"
import property_751 from "../../assets/img/maps/property_751.png"
import property_752 from "../../assets/img/maps/property_752.png"
import property_753 from "../../assets/img/maps/property_753.png"
import property_754 from "../../assets/img/maps/property_754.png"
import property_755 from "../../assets/img/maps/property_755.png"
import property_756 from "../../assets/img/maps/property_756.png"
import property_757 from "../../assets/img/maps/property_757.png"
import property_758 from "../../assets/img/maps/property_758.png"
import property_759 from "../../assets/img/maps/property_759.png"
import property_760 from "../../assets/img/maps/property_760.png"
import property_761 from "../../assets/img/maps/property_761.png"
import property_762 from "../../assets/img/maps/property_762.png"
import property_763 from "../../assets/img/maps/property_763.png"
import property_764 from "../../assets/img/maps/property_764.png"
import property_765 from "../../assets/img/maps/property_765.png"
import property_766 from "../../assets/img/maps/property_766.png"
import property_767 from "../../assets/img/maps/property_767.png"
import property_768 from "../../assets/img/maps/property_768.png"
import property_769 from "../../assets/img/maps/property_769.png"
import property_770 from "../../assets/img/maps/property_770.png"
import property_771 from "../../assets/img/maps/property_771.png"
import property_772 from "../../assets/img/maps/property_772.png"
import property_773 from "../../assets/img/maps/property_773.png"
import property_774 from "../../assets/img/maps/property_774.png"
import property_775 from "../../assets/img/maps/property_775.png"
import property_776 from "../../assets/img/maps/property_776.png"
import property_777 from "../../assets/img/maps/property_777.png"
import property_778 from "../../assets/img/maps/property_778.png"
import property_779 from "../../assets/img/maps/property_779.png"
import property_780 from "../../assets/img/maps/property_780.png"
import property_781 from "../../assets/img/maps/property_781.png"
import property_782 from "../../assets/img/maps/property_782.png"
import property_783 from "../../assets/img/maps/property_783.png"
import property_784 from "../../assets/img/maps/property_784.png"
import property_785 from "../../assets/img/maps/property_785.png"
import property_786 from "../../assets/img/maps/property_786.png"
import property_787 from "../../assets/img/maps/property_787.png"
import property_788 from "../../assets/img/maps/property_788.png"
import property_789 from "../../assets/img/maps/property_789.png"
import property_790 from "../../assets/img/maps/property_790.png"
import property_791 from "../../assets/img/maps/property_791.png"
import property_792 from "../../assets/img/maps/property_792.png"
import property_793 from "../../assets/img/maps/property_793.png"
import property_794 from "../../assets/img/maps/property_794.png"
import property_795 from "../../assets/img/maps/property_795.png"
import property_796 from "../../assets/img/maps/property_796.png"
import property_797 from "../../assets/img/maps/property_797.png"
import property_798 from "../../assets/img/maps/property_798.png"
import property_799 from "../../assets/img/maps/property_799.png"
import property_800 from "../../assets/img/maps/property_800.png"
import property_801 from "../../assets/img/maps/property_801.png"
import property_802 from "../../assets/img/maps/property_802.png"
import property_803 from "../../assets/img/maps/property_803.png"
import property_804 from "../../assets/img/maps/property_804.png"
import property_805 from "../../assets/img/maps/property_805.png"
import property_806 from "../../assets/img/maps/property_806.png"
import property_807 from "../../assets/img/maps/property_807.png"
import property_808 from "../../assets/img/maps/property_808.png"
import property_809 from "../../assets/img/maps/property_809.png"
import property_810 from "../../assets/img/maps/property_810.png"
import property_811 from "../../assets/img/maps/property_811.png"
import property_812 from "../../assets/img/maps/property_812.png"
import property_813 from "../../assets/img/maps/property_813.png"
import property_814 from "../../assets/img/maps/property_814.png"
import property_815 from "../../assets/img/maps/property_815.png"
import property_816 from "../../assets/img/maps/property_816.png"
import property_817 from "../../assets/img/maps/property_817.png"
import property_818 from "../../assets/img/maps/property_818.png"
import property_819 from "../../assets/img/maps/property_819.png"
import property_820 from "../../assets/img/maps/property_820.png"
import property_821 from "../../assets/img/maps/property_821.png"
import property_822 from "../../assets/img/maps/property_822.png"
import property_823 from "../../assets/img/maps/property_823.png"
import property_824 from "../../assets/img/maps/property_824.png"
import property_825 from "../../assets/img/maps/property_825.png"
import property_826 from "../../assets/img/maps/property_826.png"
import property_827 from "../../assets/img/maps/property_827.png"
import property_828 from "../../assets/img/maps/property_828.png"
import property_829 from "../../assets/img/maps/property_829.png"
import property_830 from "../../assets/img/maps/property_830.png"
import property_831 from "../../assets/img/maps/property_831.png"
import property_832 from "../../assets/img/maps/property_832.png"
import property_833 from "../../assets/img/maps/property_833.png"
import property_834 from "../../assets/img/maps/property_834.png"
import property_835 from "../../assets/img/maps/property_835.png"
import property_836 from "../../assets/img/maps/property_836.png"
import property_837 from "../../assets/img/maps/property_837.png"
import property_838 from "../../assets/img/maps/property_838.png"
import property_839 from "../../assets/img/maps/property_839.png"
import property_840 from "../../assets/img/maps/property_840.png"
import property_841 from "../../assets/img/maps/property_841.png"
import property_842 from "../../assets/img/maps/property_842.png"
import property_843 from "../../assets/img/maps/property_843.png"
import property_844 from "../../assets/img/maps/property_844.png"
import property_845 from "../../assets/img/maps/property_845.png"
import property_846 from "../../assets/img/maps/property_846.png"
import property_847 from "../../assets/img/maps/property_847.png"
import property_848 from "../../assets/img/maps/property_848.png"
import property_849 from "../../assets/img/maps/property_849.png"
import property_850 from "../../assets/img/maps/property_850.png"
import property_851 from "../../assets/img/maps/property_851.png"
import property_852 from "../../assets/img/maps/property_852.png"
import property_853 from "../../assets/img/maps/property_853.png"
import property_854 from "../../assets/img/maps/property_854.png"
import property_855 from "../../assets/img/maps/property_855.png"
import property_856 from "../../assets/img/maps/property_856.png"
import property_857 from "../../assets/img/maps/property_857.png"
import property_858 from "../../assets/img/maps/property_858.png"
import property_859 from "../../assets/img/maps/property_859.png"
import property_860 from "../../assets/img/maps/property_860.png"
import property_861 from "../../assets/img/maps/property_861.png"
import property_862 from "../../assets/img/maps/property_862.png"
import property_863 from "../../assets/img/maps/property_863.png"
import property_864 from "../../assets/img/maps/property_864.png"
import property_865 from "../../assets/img/maps/property_865.png"
import property_866 from "../../assets/img/maps/property_866.png"
import property_867 from "../../assets/img/maps/property_867.png"
import property_868 from "../../assets/img/maps/property_868.png"
import property_869 from "../../assets/img/maps/property_869.png"
import property_870 from "../../assets/img/maps/property_870.png"
import property_871 from "../../assets/img/maps/property_871.png"
import property_872 from "../../assets/img/maps/property_872.png"
import property_873 from "../../assets/img/maps/property_873.png"
import property_874 from "../../assets/img/maps/property_874.png"
import property_875 from "../../assets/img/maps/property_875.png"
import property_876 from "../../assets/img/maps/property_876.png"
import property_877 from "../../assets/img/maps/property_877.png"
import property_878 from "../../assets/img/maps/property_878.png"
import property_879 from "../../assets/img/maps/property_879.png"
import property_880 from "../../assets/img/maps/property_880.png"
import property_881 from "../../assets/img/maps/property_881.png"
import property_882 from "../../assets/img/maps/property_882.png"
import property_883 from "../../assets/img/maps/property_883.png"
import property_884 from "../../assets/img/maps/property_884.png"
import property_885 from "../../assets/img/maps/property_885.png"
import property_886 from "../../assets/img/maps/property_886.png"
import property_887 from "../../assets/img/maps/property_887.png"
import property_888 from "../../assets/img/maps/property_888.png"
import property_889 from "../../assets/img/maps/property_889.png"
import property_890 from "../../assets/img/maps/property_890.png"
import property_891 from "../../assets/img/maps/property_891.png"
import property_892 from "../../assets/img/maps/property_892.png"
import property_893 from "../../assets/img/maps/property_893.png"
import property_894 from "../../assets/img/maps/property_894.png"
import property_895 from "../../assets/img/maps/property_895.png"
import property_896 from "../../assets/img/maps/property_896.png"
import property_897 from "../../assets/img/maps/property_897.png"
import property_898 from "../../assets/img/maps/property_898.png"
import property_899 from "../../assets/img/maps/property_899.png"
import property_900 from "../../assets/img/maps/property_900.png"
import property_901 from "../../assets/img/maps/property_901.png"
import property_902 from "../../assets/img/maps/property_902.png"
import property_903 from "../../assets/img/maps/property_903.png"
import property_904 from "../../assets/img/maps/property_904.png"
import property_905 from "../../assets/img/maps/property_905.png"
import property_906 from "../../assets/img/maps/property_906.png"
import property_907 from "../../assets/img/maps/property_907.png"
import property_908 from "../../assets/img/maps/property_908.png"
import property_909 from "../../assets/img/maps/property_909.png"
import property_910 from "../../assets/img/maps/property_910.png"
import property_911 from "../../assets/img/maps/property_911.png"
import property_912 from "../../assets/img/maps/property_912.png"
import property_913 from "../../assets/img/maps/property_913.png"
import property_914 from "../../assets/img/maps/property_914.png"
import property_915 from "../../assets/img/maps/property_915.png"
import property_916 from "../../assets/img/maps/property_916.png"
import property_917 from "../../assets/img/maps/property_917.png"
import property_918 from "../../assets/img/maps/property_918.png"
import property_919 from "../../assets/img/maps/property_919.png"
import property_920 from "../../assets/img/maps/property_920.png"
import property_921 from "../../assets/img/maps/property_921.png"
import property_922 from "../../assets/img/maps/property_922.png"
import property_923 from "../../assets/img/maps/property_923.png"
import property_924 from "../../assets/img/maps/property_924.png"
import property_925 from "../../assets/img/maps/property_925.png"
import property_926 from "../../assets/img/maps/property_926.png"
import property_927 from "../../assets/img/maps/property_927.png"
import property_928 from "../../assets/img/maps/property_928.png"
import property_929 from "../../assets/img/maps/property_929.png"
import property_930 from "../../assets/img/maps/property_930.png"
import property_931 from "../../assets/img/maps/property_931.png"
import property_932 from "../../assets/img/maps/property_932.png"
import property_933 from "../../assets/img/maps/property_933.png"
import property_934 from "../../assets/img/maps/property_934.png"
import property_935 from "../../assets/img/maps/property_935.png"
import property_936 from "../../assets/img/maps/property_936.png"
import property_937 from "../../assets/img/maps/property_937.png"
import property_938 from "../../assets/img/maps/property_938.png"
import property_939 from "../../assets/img/maps/property_939.png"
import property_940 from "../../assets/img/maps/property_940.png"
import property_941 from "../../assets/img/maps/property_941.png"
import property_942 from "../../assets/img/maps/property_942.png"
import property_943 from "../../assets/img/maps/property_943.png"
import property_944 from "../../assets/img/maps/property_944.png"
import property_945 from "../../assets/img/maps/property_945.png"
import property_946 from "../../assets/img/maps/property_946.png"
import property_947 from "../../assets/img/maps/property_947.png"
import property_948 from "../../assets/img/maps/property_948.png"
import property_949 from "../../assets/img/maps/property_949.png"
import property_950 from "../../assets/img/maps/property_950.png"
import property_951 from "../../assets/img/maps/property_951.png"
import property_952 from "../../assets/img/maps/property_952.png"
import property_953 from "../../assets/img/maps/property_953.png"
import property_954 from "../../assets/img/maps/property_954.png"
import property_955 from "../../assets/img/maps/property_955.png"
import property_956 from "../../assets/img/maps/property_956.png"
import property_957 from "../../assets/img/maps/property_957.png"
import property_958 from "../../assets/img/maps/property_958.png"
import property_959 from "../../assets/img/maps/property_959.png"
import property_960 from "../../assets/img/maps/property_960.png"
import property_961 from "../../assets/img/maps/property_961.png"
import property_962 from "../../assets/img/maps/property_962.png"
import property_963 from "../../assets/img/maps/property_963.png"
import property_964 from "../../assets/img/maps/property_964.png"
import property_965 from "../../assets/img/maps/property_965.png"
import property_966 from "../../assets/img/maps/property_966.png"
import property_967 from "../../assets/img/maps/property_967.png"
import property_968 from "../../assets/img/maps/property_968.png"
import property_969 from "../../assets/img/maps/property_969.png"
import property_970 from "../../assets/img/maps/property_970.png"
import property_971 from "../../assets/img/maps/property_971.png"
import property_972 from "../../assets/img/maps/property_972.png"
import property_973 from "../../assets/img/maps/property_973.png"
import property_974 from "../../assets/img/maps/property_974.png"
import property_975 from "../../assets/img/maps/property_975.png"
import property_976 from "../../assets/img/maps/property_976.png"
import property_977 from "../../assets/img/maps/property_977.png"
import property_978 from "../../assets/img/maps/property_978.png"
import property_979 from "../../assets/img/maps/property_979.png"
import property_980 from "../../assets/img/maps/property_980.png"
import property_981 from "../../assets/img/maps/property_981.png"
import property_982 from "../../assets/img/maps/property_982.png"
import property_983 from "../../assets/img/maps/property_983.png"
import property_984 from "../../assets/img/maps/property_984.png"
import property_985 from "../../assets/img/maps/property_985.png"
import property_986 from "../../assets/img/maps/property_986.png"
import property_987 from "../../assets/img/maps/property_987.png"
import property_988 from "../../assets/img/maps/property_988.png"
import property_989 from "../../assets/img/maps/property_989.png"
import property_990 from "../../assets/img/maps/property_990.png"
import property_991 from "../../assets/img/maps/property_991.png"
import property_992 from "../../assets/img/maps/property_992.png"
import property_993 from "../../assets/img/maps/property_993.png"
import property_994 from "../../assets/img/maps/property_994.png"
import property_995 from "../../assets/img/maps/property_995.png"
import property_996 from "../../assets/img/maps/property_996.png"
import property_997 from "../../assets/img/maps/property_997.png"
import property_998 from "../../assets/img/maps/property_998.png"
import property_999 from "../../assets/img/maps/property_999.png"
import property_1000 from "../../assets/img/maps/property_1000.png"
import property_1001 from "../../assets/img/maps/property_1001.png"
import property_1002 from "../../assets/img/maps/property_1002.png"
import property_1003 from "../../assets/img/maps/property_1003.png"
import property_1004 from "../../assets/img/maps/property_1004.png"
import property_1005 from "../../assets/img/maps/property_1005.png"
import property_1006 from "../../assets/img/maps/property_1006.png"
import property_1007 from "../../assets/img/maps/property_1007.png"
import property_1008 from "../../assets/img/maps/property_1008.png"
import property_1009 from "../../assets/img/maps/property_1009.png"
import property_1010 from "../../assets/img/maps/property_1010.png"
import property_1011 from "../../assets/img/maps/property_1011.png"
import property_1012 from "../../assets/img/maps/property_1012.png"
import property_1013 from "../../assets/img/maps/property_1013.png"
import property_1014 from "../../assets/img/maps/property_1014.png"
import property_1015 from "../../assets/img/maps/property_1015.png"
import property_1016 from "../../assets/img/maps/property_1016.png"
import property_1017 from "../../assets/img/maps/property_1017.png"
import property_1018 from "../../assets/img/maps/property_1018.png"
import property_1019 from "../../assets/img/maps/property_1019.png"
import property_1020 from "../../assets/img/maps/property_1020.png"
import property_1021 from "../../assets/img/maps/property_1021.png"
import property_1022 from "../../assets/img/maps/property_1022.png"

const data = [
    {
        "url": "https://www.rightmove.co.uk/properties/110742359#",
        "title": "2 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/12k/11612/110742359/11612_BUR210143_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11612/110742359/11612_BUR210143_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11612/110742359/11612_BUR210143_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11612/110742359/11612_BUR210143_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11612/110742359/11612_BUR210143_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11612/110742359/11612_BUR210143_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11612/110742359/11612_BUR210143_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11612/110742359/11612_BUR210143_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11612/110742359/11612_BUR210143_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11612/110742359/11612_BUR210143_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11612/110742359/11612_BUR210143_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 325000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Merrow Park, Guildford, Surrey, GU4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.533643,
            "lat": 51.251113
        },
        "_place": 11.79,
        "_people": 25.93,
        "_property": 3,
        "_price": 108.82,
        "map": property_1
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116682551#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242753/116682551/242753_31017541_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "ATLANTIC ARTHOUSE, Scarinish, Isle Of Tiree",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -6.805688,
            "lat": 56.502353
        },
        "_place": 9.4,
        "_people": 23.79,
        "_property": 1,
        "_price": 194.22,
        "map": property_2
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114381923#",
        "title": "Equestrian facility",
        "images": [
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114013/114381923/114013_102051001862_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 470000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Booth Hall Farm, Clamgoose Lane, Kingsley, ST10 2EG ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.99832,
            "lat": 53.00628
        },
        "_place": 5.02,
        "_people": 21.64,
        "_property": 5,
        "_price": 184.28,
        "map": property_3
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113045033#",
        "title": "2 bedroom lodge",
        "images": [
            "https://media.rightmove.co.uk/174k/173879/113045033/173879_SMWINDELT_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/174k/173879/113045033/173879_SMWINDELT_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/174k/173879/113045033/173879_SMWINDELT_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/174k/173879/113045033/173879_SMWINDELT_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/174k/173879/113045033/173879_SMWINDELT_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/174k/173879/113045033/173879_SMWINDELT_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/174k/173879/113045033/173879_SMWINDELT_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/174k/173879/113045033/173879_SMWINDELT_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/174k/173879/113045033/173879_SMWINDELT_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/174k/173879/113045033/173879_SMWINDELT_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/174k/173879/113045033/173879_SMWINDELT_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/174k/173879/113045033/173879_SMWINDELT_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/174k/173879/113045033/173879_SMWINDELT_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/174k/173879/113045033/173879_SMWINDELT_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 114995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Winchelsea Beach, TN36 4NB",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": 0.7229,
            "lat": 50.9152
        },
        "_place": 5.25,
        "_people": 19.61,
        "_property": 4,
        "_price": 131.59,
        "map": property_4
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117670727#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/8k/7732/117670727/7732_31165550_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7732/117670727/7732_31165550_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7732/117670727/7732_31165550_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7732/117670727/7732_31165550_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7732/117670727/7732_31165550_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7732/117670727/7732_31165550_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7732/117670727/7732_31165550_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7732/117670727/7732_31165550_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7732/117670727/7732_31165550_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7732/117670727/7732_31165550_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7732/117670727/7732_31165550_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7732/117670727/7732_31165550_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7732/117670727/7732_31165550_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7732/117670727/7732_31165550_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7732/117670727/7732_31165550_IMG_14_0001.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Main Street, Kelfield, York",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.102185,
            "lat": 53.838539
        },
        "_place": 4.85,
        "_people": 20.05,
        "_property": 5,
        "_price": 105.47,
        "map": property_5
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113915864#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50428/113915864/50428_FCY200107_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 800000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Hamerton Road, Alconbury Weston, Huntingdon, PE28",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.272684,
            "lat": 52.378637
        },
        "_place": 9.71,
        "_people": 25.45,
        "_property": 5,
        "_price": 115.93,
        "map": property_6
    },
    {
        "url": "https://www.rightmove.co.uk/properties/79035516#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/80k/79577/79035516/79577_30504043_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79577/79035516/79577_30504043_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79577/79035516/79577_30504043_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79577/79035516/79577_30504043_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79577/79035516/79577_30504043_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79577/79035516/79577_30504043_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79577/79035516/79577_30504043_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79577/79035516/79577_30504043_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 695000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Farnham Lane, Knaresborough, North Yorkshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.463444,
            "lat": 54.035948
        },
        "_place": 1.31,
        "_people": 34.35,
        "_property": 0,
        "_price": 154.13,
        "map": property_7
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114830468#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203009/114830468/203009_1213380-1_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 4,
        "address": "Ashgate Road, Chesterfield, S40",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.443081,
            "lat": 53.239569
        },
        "_place": 1.8,
        "_people": 32.69,
        "_property": 1,
        "_price": 199.5,
        "map": property_8
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108187316#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15529/108187316/15529_9793877_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 410000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Bucklesham Road ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.201096,
            "lat": 52.041134
        },
        "_place": 0.49,
        "_people": 27.21,
        "_property": 3,
        "_price": 121,
        "map": property_9
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114673028#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/103k/102578/114673028/102578_102593001519_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/114673028/102578_102593001519_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/114673028/102578_102593001519_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/114673028/102578_102593001519_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/114673028/102578_102593001519_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/114673028/102578_102593001519_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/114673028/102578_102593001519_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/114673028/102578_102593001519_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/114673028/102578_102593001519_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/114673028/102578_102593001519_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/114673028/102578_102593001519_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Strath Appin",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -5.371499,
            "lat": 56.571081
        },
        "_place": 6.35,
        "_people": 23.53,
        "_property": 2,
        "_price": 181.27,
        "map": property_10
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116862248#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/239k/238340/116862248/238340_RUS210607_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238340/116862248/238340_RUS210607_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238340/116862248/238340_RUS210607_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238340/116862248/238340_RUS210607_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238340/116862248/238340_RUS210607_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238340/116862248/238340_RUS210607_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238340/116862248/238340_RUS210607_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238340/116862248/238340_RUS210607_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238340/116862248/238340_RUS210607_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238340/116862248/238340_RUS210607_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 965000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Greenacres Avenue, Ickenham, Middlesex, UB10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.457371,
            "lat": 51.565918
        },
        "_place": 9.15,
        "_people": 20.04,
        "_property": 3,
        "_price": 122.51,
        "map": property_11
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117402068#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/226k/225329/117402068/225329_The_Hazeldon_Dec_21_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225329/117402068/225329_The_Hazeldon_Dec_21_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225329/117402068/225329_The_Hazeldon_Dec_21_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225329/117402068/225329_The_Hazeldon_Dec_21_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225329/117402068/225329_The_Hazeldon_Dec_21_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225329/117402068/225329_The_Hazeldon_Dec_21_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225329/117402068/225329_The_Hazeldon_Dec_21_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225329/117402068/225329_The_Hazeldon_Dec_21_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225329/117402068/225329_The_Hazeldon_Dec_21_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225329/117402068/225329_The_Hazeldon_Dec_21_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225329/117402068/225329_The_Hazeldon_Dec_21_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225329/117402068/225329_The_Hazeldon_Dec_21_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225329/117402068/225329_The_Hazeldon_Dec_21_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225329/117402068/225329_The_Hazeldon_Dec_21_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225329/117402068/225329_The_Hazeldon_Dec_21_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 559995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Off of Orchard Way,\r\nKings Hill,\r\nWest Malling,\r\nME19 4ED",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.40516,
            "lat": 51.27509
        },
        "_place": 0.73,
        "_people": 20.7,
        "_property": 5,
        "_price": 135.1,
        "map": property_12
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117593669#",
        "title": "7 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/117593669/193358_157083_IMG_31_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": null,
        "address": "34 Greevegate, Hunstanton, Norfolk, PE36 6AG, and 48A, B & C and 50 & 50a Church Street, Hunstanton, Norfolk, PE36 5HD",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": 0.491258,
            "lat": 52.940536
        },
        "_place": 8.56,
        "_people": 21.6,
        "_property": 1,
        "_price": 142.31,
        "map": property_13
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113261588#",
        "title": "2 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/44k/43170/113261588/43170_EM-139854_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43170/113261588/43170_EM-139854_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43170/113261588/43170_EM-139854_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43170/113261588/43170_EM-139854_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43170/113261588/43170_EM-139854_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43170/113261588/43170_EM-139854_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43170/113261588/43170_EM-139854_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 140000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Victoria Road, Dukinfield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.09034,
            "lat": 53.4683
        },
        "_place": 9.21,
        "_people": 31.21,
        "_property": 0,
        "_price": 164.51,
        "map": property_14
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82161282#",
        "title": "2 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/18k/17076/82161282/17076_0111280_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17076/82161282/17076_0111280_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17076/82161282/17076_0111280_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17076/82161282/17076_0111280_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17076/82161282/17076_0111280_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17076/82161282/17076_0111280_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17076/82161282/17076_0111280_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17076/82161282/17076_0111280_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17076/82161282/17076_0111280_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17076/82161282/17076_0111280_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17076/82161282/17076_0111280_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17076/82161282/17076_0111280_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 465000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "The Sidings, Wheatley, OX33",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.139424,
            "lat": 51.746381
        },
        "_place": 0.63,
        "_people": 26,
        "_property": 1,
        "_price": 163.31,
        "map": property_15
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114396836#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/51k/50225/114396836/50225_NOS210202_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/51k/50225/114396836/50225_NOS210202_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/51k/50225/114396836/50225_NOS210202_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/51k/50225/114396836/50225_NOS210202_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/51k/50225/114396836/50225_NOS210202_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/51k/50225/114396836/50225_NOS210202_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/51k/50225/114396836/50225_NOS210202_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/51k/50225/114396836/50225_NOS210202_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/51k/50225/114396836/50225_NOS210202_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/51k/50225/114396836/50225_NOS210202_IMG_09_0001.jpeg"
        ],
        "price": [
            {
                "value": 775000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Eastcote Road, Pinner, Middlesex, HA5",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.383202,
            "lat": 51.588902
        },
        "_place": 6.9,
        "_people": 19.48,
        "_property": 5,
        "_price": 190.11,
        "map": property_16
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112417586#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78849/112417586/78849_30908865_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 259950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Herbert Thomas Way, Birchgrove, Swansea",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.889424,
            "lat": 51.675514
        },
        "_place": 11.21,
        "_people": 22.44,
        "_property": 1,
        "_price": 121.62,
        "map": property_17
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117100430#",
        "title": "2 bedroom semi-detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/43k/42368/117100430/42368_8721R_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42368/117100430/42368_8721R_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42368/117100430/42368_8721R_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42368/117100430/42368_8721R_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42368/117100430/42368_8721R_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42368/117100430/42368_8721R_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42368/117100430/42368_8721R_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42368/117100430/42368_8721R_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42368/117100430/42368_8721R_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42368/117100430/42368_8721R_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42368/117100430/42368_8721R_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42368/117100430/42368_8721R_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42368/117100430/42368_8721R_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42368/117100430/42368_8721R_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 128000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Whitletts Road, Ayr, Ayrshire, KA8",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.60889,
            "lat": 55.46921
        },
        "_place": 1.76,
        "_people": 19.75,
        "_property": 3,
        "_price": 195.11,
        "map": property_18
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117559526#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/188k/187913/117559526/187913_7397_IMG_32_0000.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Stepney Road, Scarborough, YO12 5NH",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.434576,
            "lat": 54.275485
        },
        "_place": 7.71,
        "_people": 27.45,
        "_property": 0,
        "_price": 133.05,
        "map": property_19
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117578273#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45962/117578273/45962_STN160442_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 1200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Elmgate Gardens, Edgware, HA8",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.260836,
            "lat": 51.618014
        },
        "_place": 4.81,
        "_people": 22.23,
        "_property": 1,
        "_price": 165.3,
        "map": property_20
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111299522#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63640/111299522/63640_P_63640_10241_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 210000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "OAKFIELD GARDENS, PETERLEE, OAKERSIDE, Peterlee, SR8 1BA",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.34563,
            "lat": 54.74806
        },
        "_place": 6.97,
        "_people": 29.48,
        "_property": 0,
        "_price": 179.61,
        "map": property_21
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111882818#",
        "title": "2 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/111882818/157103_21040157_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 239000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": " Upper Pendock, Malvern, WR13",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.2801,
            "lat": 52.009561
        },
        "_place": 11.04,
        "_people": 23.39,
        "_property": 1,
        "_price": 127.93,
        "map": property_22
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117742583#",
        "title": "4 bedroom character property",
        "images": [
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14453/117742583/14453_11261647_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "40 Lower Fore Street, Saltash",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.208848,
            "lat": 50.408329
        },
        "_place": 9.79,
        "_people": 28.71,
        "_property": 2,
        "_price": 108.84,
        "map": property_23
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117435080#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117435080/38879_9891629_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 349950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Cefn Y Bryn, Llandudno",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.827642,
            "lat": 53.303589
        },
        "_place": 1.77,
        "_people": 23.32,
        "_property": 3,
        "_price": 140.96,
        "map": property_24
    },
    {
        "url": "https://www.rightmove.co.uk/properties/89927767#",
        "title": "2 bedroom duplex",
        "images": [
            "https://media.rightmove.co.uk/157k/156830/89927767/156830_30495931_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/157k/156830/89927767/156830_30495931_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/157k/156830/89927767/156830_30495931_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/157k/156830/89927767/156830_30495931_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/157k/156830/89927767/156830_30495931_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/157k/156830/89927767/156830_30495931_IMG_05_0000.jpeg"
        ],
        "price": [
            {
                "value": 995000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Lipton Close, Southgate, London",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.13713,
            "lat": 51.63488
        },
        "_place": 6.96,
        "_people": 19.11,
        "_property": 3,
        "_price": 142.45,
        "map": property_25
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117447179#",
        "title": "24 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/117447179/28685_StHelensAvenueVictoria_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 24,
        "bathrooms": null,
        "address": "Avenue Victoria, Scarborough, North Yorkshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.4005,
            "lat": 54.27136
        },
        "_place": 4.95,
        "_people": 25.92,
        "_property": 0,
        "_price": 141.84,
        "map": property_26
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115851371#",
        "title": "4 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/85k/84806/115851371/84806_31081274_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84806/115851371/84806_31081274_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84806/115851371/84806_31081274_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84806/115851371/84806_31081274_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84806/115851371/84806_31081274_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84806/115851371/84806_31081274_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84806/115851371/84806_31081274_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84806/115851371/84806_31081274_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84806/115851371/84806_31081274_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84806/115851371/84806_31081274_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 1100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Hester Terrace, Kew",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.283919,
            "lat": 51.469708
        },
        "_place": 0.05,
        "_people": 25.55,
        "_property": 1,
        "_price": 121.77,
        "map": property_27
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117452801#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/204k/203498/117452801/203498_1288460-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203498/117452801/203498_1288460-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203498/117452801/203498_1288460-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203498/117452801/203498_1288460-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203498/117452801/203498_1288460-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203498/117452801/203498_1288460-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203498/117452801/203498_1288460-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203498/117452801/203498_1288460-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203498/117452801/203498_1288460-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203498/117452801/203498_1288460-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203498/117452801/203498_1288460-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203498/117452801/203498_1288460-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203498/117452801/203498_1288460-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203498/117452801/203498_1288460-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203498/117452801/203498_1288460-1_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 545000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Mantle Close, Droitwich, WR9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.14,
            "lat": 52.24
        },
        "_place": 0.99,
        "_people": 20.63,
        "_property": 0,
        "_price": 180.17,
        "map": property_28
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117563801#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63670/117563801/63670_M_63670_14580_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 239995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "WEYMOUTH DRIVE, SEAHAM, Seaham District, SR7 8DF",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.3586,
            "lat": 54.83162
        },
        "_place": 7.21,
        "_people": 27.95,
        "_property": 5,
        "_price": 175.43,
        "map": property_29
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107603093#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/56k/55534/107603093/55534_2250495_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55534/107603093/55534_2250495_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55534/107603093/55534_2250495_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55534/107603093/55534_2250495_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55534/107603093/55534_2250495_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55534/107603093/55534_2250495_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55534/107603093/55534_2250495_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55534/107603093/55534_2250495_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55534/107603093/55534_2250495_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55534/107603093/55534_2250495_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55534/107603093/55534_2250495_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55534/107603093/55534_2250495_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55534/107603093/55534_2250495_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 1100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Sutherland Avenue, Maida Vale",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.19372,
            "lat": 51.52345
        },
        "_place": 5.39,
        "_people": 18.1,
        "_property": 5,
        "_price": 120.67,
        "map": property_30
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110327372#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99647/110327372/99647_30778226_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 125000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "West View, 9, Mihol Road, Strath, Gairloch",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.702224,
            "lat": 57.732286
        },
        "_place": 8.92,
        "_people": 21.53,
        "_property": 2,
        "_price": 153.56,
        "map": property_31
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117048107#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/97k/96329/117048107/96329_GGG_GGG_LFSYCL_558_738113727_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/97k/96329/117048107/96329_GGG_GGG_LFSYCL_558_738113727_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/97k/96329/117048107/96329_GGG_GGG_LFSYCL_558_738113727_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/97k/96329/117048107/96329_GGG_GGG_LFSYCL_558_738113727_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96329/117048107/96329_GGG_GGG_LFSYCL_558_738113727_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96329/117048107/96329_GGG_GGG_LFSYCL_558_738113727_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96329/117048107/96329_GGG_GGG_LFSYCL_558_738113727_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96329/117048107/96329_GGG_GGG_LFSYCL_558_738113727_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96329/117048107/96329_GGG_GGG_LFSYCL_558_738113727_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Foren Crescent, Godmanchester, Huntingdon, Cambridgeshire, PE29",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.163684,
            "lat": 52.311159
        },
        "_place": 1.71,
        "_people": 26.58,
        "_property": 4,
        "_price": 200.78,
        "map": property_32
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116416349#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_20_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_21_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_22_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_23_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_24_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_25_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8666/116416349/8666_HOO210041_IMG_26_0001.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "High Street, Isle of Grain, Rochester, Kent, ME3",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.718141,
            "lat": 51.459575
        },
        "_place": 5.09,
        "_people": 19.01,
        "_property": 5,
        "_price": 151.85,
        "map": property_33
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113111060#",
        "title": "3 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/149k/148943/113111060/148943_RIC160154_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/149k/148943/113111060/148943_RIC160154_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/149k/148943/113111060/148943_RIC160154_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/149k/148943/113111060/148943_RIC160154_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/149k/148943/113111060/148943_RIC160154_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/149k/148943/113111060/148943_RIC160154_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/149k/148943/113111060/148943_RIC160154_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/149k/148943/113111060/148943_RIC160154_IMG_01_0000.jpeg"
        ],
        "price": [
            {
                "value": 835000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Queens Court, Queens Road, Richmond, Surrey, TW10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.292896,
            "lat": 51.455731
        },
        "_place": 7.73,
        "_people": 27.27,
        "_property": 1,
        "_price": 145.92,
        "map": property_34
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82087068#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/193k/192491/82087068/192491_4_H7651156_IMG_02_0001.jpg",
            "https://media.rightmove.co.uk/193k/192491/82087068/192491_4_H7651156_IMG_03_0001.jpg",
            "https://media.rightmove.co.uk/193k/192491/82087068/192491_4_H7651156_IMG_04_0002.jpg",
            "https://media.rightmove.co.uk/193k/192491/82087068/192491_4_H7651156_IMG_01_0002.jpg",
            "https://media.rightmove.co.uk/193k/192491/82087068/192491_4_H7651156_IMG_05_0002.jpg",
            "https://media.rightmove.co.uk/193k/192491/82087068/192491_4_H7651156_IMG_06_0002.jpg",
            "https://media.rightmove.co.uk/193k/192491/82087068/192491_4_H7651156_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/193k/192491/82087068/192491_4_H7651156_IMG_08_0002.jpg",
            "https://media.rightmove.co.uk/193k/192491/82087068/192491_4_H7651156_IMG_09_0003.jpg",
            "https://media.rightmove.co.uk/193k/192491/82087068/192491_4_H7651156_IMG_10_0002.jpg"
        ],
        "price": [
            {
                "value": 725000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Burford Road,\r\nWitney,\r\nOX28",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.49656,
            "lat": 51.79064
        },
        "_place": 10,
        "_people": 22.2,
        "_property": 5,
        "_price": 160.81,
        "map": property_35
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116338550#",
        "title": "4 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40259/116338550/40259_100523009206_IMG_26_0000.jpeg"
        ],
        "price": [
            {
                "value": 695000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "East Cowes, Isle Of Wight",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.287635,
            "lat": 50.762632
        },
        "_place": 0.2,
        "_people": 31.87,
        "_property": 5,
        "_price": 184.31,
        "map": property_36
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113905067#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237107/113905067/237107_ZH002_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Low Road, Grantham, Lincolnshire, NG31",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.63151,
            "lat": 52.92917
        },
        "_place": 7.41,
        "_people": 24.7,
        "_property": 1,
        "_price": 112.77,
        "map": property_37
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111166970#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_45_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_46_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_47_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_48_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_49_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_50_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_51_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_52_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_53_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_54_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/111166970/237629_4988_IMG_32_0000.jpeg"
        ],
        "price": [
            {
                "value": 695000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 4,
        "address": "Slieau Lewaigue House, Slieu Lewaigue, Ramsey",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.358173,
            "lat": 54.304707
        },
        "_place": 3.77,
        "_people": 20.17,
        "_property": 5,
        "_price": 91.22,
        "map": property_38
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116083217#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/116083217/34733_MOD210078_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 725000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Aveton Gifford, Kingsbridge, Devon, TQ7",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.841368,
            "lat": 50.327099
        },
        "_place": 8.41,
        "_people": 31.38,
        "_property": 4,
        "_price": 166.92,
        "map": property_39
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109531412#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68068/109531412/68068_SHA210026_IMG_05_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Dolphin Court, Riverside, Shaldon, Devon",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.508983,
            "lat": 50.542292
        },
        "_place": 11.82,
        "_people": 22.08,
        "_property": 2,
        "_price": 103.51,
        "map": property_40
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114660416#",
        "title": "1 bedroom retirement property",
        "images": [
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/114660416/4656_31017181_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 195000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Marden Court, Whitley Bay, NE26 ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.44776,
            "lat": 55.040316
        },
        "_place": 4.76,
        "_people": 31.66,
        "_property": 5,
        "_price": 191.41,
        "map": property_41
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111894728#",
        "title": "4 bedroom character property",
        "images": [
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_00_0000.png",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109048/111894728/109048_30880887_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "All Saints Street, Stamford",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.482231,
            "lat": 52.651756
        },
        "_place": 8.49,
        "_people": 31.06,
        "_property": 2,
        "_price": 198.74,
        "map": property_42
    },
    {
        "url": "https://www.rightmove.co.uk/properties/89811766#",
        "title": "3 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/235k/234419/89811766/234419_30479063_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234419/89811766/234419_30479063_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234419/89811766/234419_30479063_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234419/89811766/234419_30479063_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234419/89811766/234419_30479063_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234419/89811766/234419_30479063_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234419/89811766/234419_30479063_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234419/89811766/234419_30479063_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234419/89811766/234419_30479063_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234419/89811766/234419_30479063_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Merrick Road, Southall",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.376091,
            "lat": 51.504936
        },
        "_place": 10.89,
        "_people": 21,
        "_property": 0,
        "_price": 191.88,
        "map": property_43
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117677177#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/208k/207998/117677177/207998_102201003058_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 325000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Thirlby Road, North Walsham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.39499,
            "lat": 52.81298
        },
        "_place": 2.78,
        "_people": 32.52,
        "_property": 5,
        "_price": 120.26,
        "map": property_44
    },
    {
        "url": "https://www.rightmove.co.uk/properties/91147420#",
        "title": "8 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/10k/9555/91147420/9555_YAX202788_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9555/91147420/9555_YAX202788_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9555/91147420/9555_YAX202788_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9555/91147420/9555_YAX202788_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9555/91147420/9555_YAX202788_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9555/91147420/9555_YAX202788_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9555/91147420/9555_YAX202788_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9555/91147420/9555_YAX202788_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9555/91147420/9555_YAX202788_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9555/91147420/9555_YAX202788_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9555/91147420/9555_YAX202788_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9555/91147420/9555_YAX202788_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9555/91147420/9555_YAX202788_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9555/91147420/9555_YAX202788_IMG_05_0000.jpeg"
        ],
        "price": [
            {
                "value": 1200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 8,
        "bathrooms": 3,
        "address": "Straight Drove, Farcet, Peterborough",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.19945,
            "lat": 52.52612
        },
        "_place": 8.49,
        "_people": 27.9,
        "_property": 4,
        "_price": 99.89,
        "map": property_45
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117716024#",
        "title": "1 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/98k/97423/117716024/97423_P58389_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97423/117716024/97423_P58389_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97423/117716024/97423_P58389_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97423/117716024/97423_P58389_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97423/117716024/97423_P58389_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97423/117716024/97423_P58389_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97423/117716024/97423_P58389_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97423/117716024/97423_P58389_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97423/117716024/97423_P58389_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97423/117716024/97423_P58389_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97423/117716024/97423_P58389_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 625000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "The Belvedere, Holborn, London WC1R",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.11606,
            "lat": 51.51908
        },
        "_place": 11.89,
        "_people": 29.59,
        "_property": 3,
        "_price": 121.01,
        "map": property_46
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117549251#",
        "title": "4 bedroom cottage",
        "images": [
            "https://media.rightmove.co.uk/72k/71840/117549251/71840_31159267_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71840/117549251/71840_31159267_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71840/117549251/71840_31159267_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71840/117549251/71840_31159267_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71840/117549251/71840_31159267_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71840/117549251/71840_31159267_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71840/117549251/71840_31159267_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71840/117549251/71840_31159267_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71840/117549251/71840_31159267_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/72k/71840/117549251/71840_31159267_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/72k/71840/117549251/71840_31159267_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71840/117549251/71840_31159267_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71840/117549251/71840_31159267_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 695000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Westfield Road, Tockwith",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.290622,
            "lat": 53.965634
        },
        "_place": 2.78,
        "_people": 29.84,
        "_property": 0,
        "_price": 199.07,
        "map": property_47
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113483915#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/208k/207077/113483915/207077_A2E00DF1-58E0-4DE8-B6FA-9AD02CD7A37F_IMG_01_0002.jpeg",
            "https://media.rightmove.co.uk/208k/207077/113483915/207077_A2E00DF1-58E0-4DE8-B6FA-9AD02CD7A37F_IMG_00_0002.jpeg"
        ],
        "price": [
            {
                "value": null,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Site R2, Ordiquish Road West, Fochabers",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.094575,
            "lat": 57.60804
        },
        "_place": 2.59,
        "_people": 20.58,
        "_property": 2,
        "_price": 95.52,
        "map": property_48
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111874640#",
        "title": "2 bedroom mobile home",
        "images": [
            "https://media.rightmove.co.uk/169k/168656/111874640/168656_101177007556_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/111874640/168656_101177007556_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/111874640/168656_101177007556_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/111874640/168656_101177007556_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/111874640/168656_101177007556_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/111874640/168656_101177007556_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/111874640/168656_101177007556_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/111874640/168656_101177007556_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/111874640/168656_101177007556_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/111874640/168656_101177007556_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/111874640/168656_101177007556_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/111874640/168656_101177007556_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/111874640/168656_101177007556_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 130000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Waveney Valley Lakes, Wortwell, Harleston",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.35228,
            "lat": 52.41541
        },
        "_place": 6.02,
        "_people": 35.21,
        "_property": 2,
        "_price": 125.02,
        "map": property_49
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115508777#",
        "title": "6 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/61k/60209/115508777/60209_INV210114_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 6,
        "address": "Station Road, Carrbridge, Inverness-Shire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.827955,
            "lat": 57.28196
        },
        "_place": 5.89,
        "_people": 34.11,
        "_property": 5,
        "_price": 179.13,
        "map": property_50
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112638767#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140762/112638767/140762_MWSAL209349_IMG_30_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "Cilmery, Builth Wells, LD2 3LH",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.47971,
            "lat": 52.14395
        },
        "_place": 1.5,
        "_people": 24.36,
        "_property": 4,
        "_price": 105.49,
        "map": property_51
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117016499#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/241k/240338/117016499/240338_609522_IMG_20_0002.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Ramsey Road, Pondersbridge, PE26 2TW",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.142792,
            "lat": 52.513424
        },
        "_place": 6.18,
        "_people": 24.98,
        "_property": 3,
        "_price": 169.51,
        "map": property_52
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114989966#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47367/114989966/47367_100361011719_IMG_30_0000.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Silver Street, Besthorpe",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.03658,
            "lat": 52.52144
        },
        "_place": 2.25,
        "_people": 23.24,
        "_property": 2,
        "_price": 95,
        "map": property_53
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116937497#",
        "title": "5 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5038/116937497/5038_SOK210189_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 495000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Station Road, Stokesley, North Yorkshire, TS9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.18427,
            "lat": 54.46642
        },
        "_place": 8.76,
        "_people": 19.42,
        "_property": 3,
        "_price": 91.49,
        "map": property_54
    },
    {
        "url": "https://www.rightmove.co.uk/properties/79147812#",
        "title": "4 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_00_0003.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_01_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_02_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_03_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_04_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_05_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_06_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_07_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_08_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_09_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_10_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_11_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_12_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_13_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_14_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_15_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/79147812/46965_48638_IMG_16_0003.jpeg"
        ],
        "price": [
            {
                "value": 1000000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Talbot Crescent, Hendon, NW4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.236713,
            "lat": 51.584204
        },
        "_place": 7.92,
        "_people": 19.13,
        "_property": 4,
        "_price": 182.84,
        "map": property_55
    },
    {
        "url": "https://www.rightmove.co.uk/properties/85061983#",
        "title": "1 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/76k/75559/85061983/75559_CHS200186_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/76k/75559/85061983/75559_CHS200186_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/76k/75559/85061983/75559_CHS200186_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/76k/75559/85061983/75559_CHS200186_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/76k/75559/85061983/75559_CHS200186_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/76k/75559/85061983/75559_CHS200186_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/76k/75559/85061983/75559_CHS200186_IMG_07_0000.jpg"
        ],
        "price": [
            {
                "value": 1175000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Marshall Street, Soho, London, W1F",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.138113,
            "lat": 51.513737
        },
        "_place": 8.56,
        "_people": 32.18,
        "_property": 4,
        "_price": 109.61,
        "map": property_56
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111380480#",
        "title": "Plot",
        "images": [
            "https://media.rightmove.co.uk/75k/74403/111380480/74403_30855078_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/111380480/74403_30855078_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/111380480/74403_30855078_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/111380480/74403_30855078_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/111380480/74403_30855078_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/111380480/74403_30855078_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/111380480/74403_30855078_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/111380480/74403_30855078_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/111380480/74403_30855078_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/111380480/74403_30855078_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/111380480/74403_30855078_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/111380480/74403_30855078_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 180000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Bryn Ioan (Building Plot), Brynhenllan, Dinas Cross",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.902916,
            "lat": 52.018394
        },
        "_place": 3.07,
        "_people": 30.33,
        "_property": 3,
        "_price": 176.08,
        "map": property_57
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113914751#",
        "title": "2 bedroom cottage",
        "images": [
            "https://media.rightmove.co.uk/101k/100078/113914751/100078_RIS210154_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100078/113914751/100078_RIS210154_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100078/113914751/100078_RIS210154_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100078/113914751/100078_RIS210154_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100078/113914751/100078_RIS210154_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100078/113914751/100078_RIS210154_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100078/113914751/100078_RIS210154_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100078/113914751/100078_RIS210154_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100078/113914751/100078_RIS210154_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100078/113914751/100078_RIS210154_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100078/113914751/100078_RIS210154_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100078/113914751/100078_RIS210154_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100078/113914751/100078_RIS210154_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100078/113914751/100078_RIS210154_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Burton Lane, Monks Risborough, Princes Risborough, Buckinghamshire, HP27",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.823924,
            "lat": 51.731968
        },
        "_place": 11.46,
        "_people": 32.62,
        "_property": 3,
        "_price": 90.66,
        "map": property_58
    },
    {
        "url": "https://www.rightmove.co.uk/properties/80185488#",
        "title": "2 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_20_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_21_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_22_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_23_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_24_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_25_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_26_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_27_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_28_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_29_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_30_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_31_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12158/80185488/12158_ELM210131_IMG_32_0001.jpeg"
        ],
        "price": [
            {
                "value": 320000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Stockingate, South Kirkby, Pontefract, WF9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.32373,
            "lat": 53.58934
        },
        "_place": 6.24,
        "_people": 33.74,
        "_property": 5,
        "_price": 184.08,
        "map": property_59
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107331905#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/5k/4796/107331905/4796_AHD210105_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4796/107331905/4796_AHD210105_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4796/107331905/4796_AHD210105_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4796/107331905/4796_AHD210105_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4796/107331905/4796_AHD210105_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4796/107331905/4796_AHD210105_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4796/107331905/4796_AHD210105_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4796/107331905/4796_AHD210105_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4796/107331905/4796_AHD210105_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4796/107331905/4796_AHD210105_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4796/107331905/4796_AHD210105_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 245000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Vantage House, Victoria Street, Basingstoke, Hampshire, RG21",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.08905,
            "lat": 51.26056
        },
        "_place": 11.97,
        "_people": 28.92,
        "_property": 0,
        "_price": 144.21,
        "map": property_60
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115377854#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/4k/3058/115377854/3058_NES210431_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3058/115377854/3058_NES210431_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3058/115377854/3058_NES210431_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3058/115377854/3058_NES210431_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3058/115377854/3058_NES210431_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3058/115377854/3058_NES210431_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3058/115377854/3058_NES210431_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3058/115377854/3058_NES210431_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3058/115377854/3058_NES210431_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3058/115377854/3058_NES210431_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3058/115377854/3058_NES210431_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3058/115377854/3058_NES210431_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 675000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Riverside Lane, Newbury, Berkshire, RG14",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.308961,
            "lat": 51.408788
        },
        "_place": 4.45,
        "_people": 35.27,
        "_property": 0,
        "_price": 108.7,
        "map": property_61
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108523316#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/138k/137465/108523316/137465_100439036858_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/138k/137465/108523316/137465_100439036858_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/138k/137465/108523316/137465_100439036858_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/138k/137465/108523316/137465_100439036858_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/138k/137465/108523316/137465_100439036858_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/138k/137465/108523316/137465_100439036858_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/138k/137465/108523316/137465_100439036858_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/138k/137465/108523316/137465_100439036858_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/138k/137465/108523316/137465_100439036858_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/138k/137465/108523316/137465_100439036858_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/138k/137465/108523316/137465_100439036858_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/138k/137465/108523316/137465_100439036858_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 679950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Norwich",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.29812,
            "lat": 52.63531
        },
        "_place": 5.95,
        "_people": 21.48,
        "_property": 5,
        "_price": 151.28,
        "map": property_62
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115778339#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/234k/233675/115778339/233675_AEC210005_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/234k/233675/115778339/233675_AEC210005_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/234k/233675/115778339/233675_AEC210005_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/234k/233675/115778339/233675_AEC210005_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/234k/233675/115778339/233675_AEC210005_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/234k/233675/115778339/233675_AEC210005_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/234k/233675/115778339/233675_AEC210005_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/234k/233675/115778339/233675_AEC210005_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/234k/233675/115778339/233675_AEC210005_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/234k/233675/115778339/233675_AEC210005_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/234k/233675/115778339/233675_AEC210005_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/234k/233675/115778339/233675_AEC210005_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/234k/233675/115778339/233675_AEC210005_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/234k/233675/115778339/233675_AEC210005_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 460000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "'Four Views Lodge', Plot 2, Ordies, Alves, Forres, IV36",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.503994,
            "lat": 57.636668
        },
        "_place": 6.25,
        "_people": 34.63,
        "_property": 0,
        "_price": 176.29,
        "map": property_63
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116127833#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/2k/1862/116127833/1862_WIT104731_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1862/116127833/1862_WIT104731_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1862/116127833/1862_WIT104731_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1862/116127833/1862_WIT104731_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1862/116127833/1862_WIT104731_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1862/116127833/1862_WIT104731_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1862/116127833/1862_WIT104731_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1862/116127833/1862_WIT104731_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1862/116127833/1862_WIT104731_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1862/116127833/1862_WIT104731_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1862/116127833/1862_WIT104731_IMG_02_0000.jpeg"
        ],
        "price": [
            {
                "value": 595000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Kingfisher Meadows Burford Road, Witney",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.49656,
            "lat": 51.79064
        },
        "_place": 11.07,
        "_people": 26.06,
        "_property": 0,
        "_price": 164.34,
        "map": property_64
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113177078#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/96k/95807/113177078/95807_BAS190635_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95807/113177078/95807_BAS190635_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95807/113177078/95807_BAS190635_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95807/113177078/95807_BAS190635_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95807/113177078/95807_BAS190635_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95807/113177078/95807_BAS190635_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95807/113177078/95807_BAS190635_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95807/113177078/95807_BAS190635_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95807/113177078/95807_BAS190635_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95807/113177078/95807_BAS190635_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95807/113177078/95807_BAS190635_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 130000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Town Mill, Overton, Basingstoke, Hampshire, RG25",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.263284,
            "lat": 51.244765
        },
        "_place": 9.4,
        "_people": 19.29,
        "_property": 4,
        "_price": 186.1,
        "map": property_65
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116406392#",
        "title": "3 bedroom duplex",
        "images": [
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/43k/42027/116406392/42027_31106929_IMG_19_0001.jpeg"
        ],
        "price": [
            {
                "value": 725000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Victoria Road, Headingley, Leeds",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.568974,
            "lat": 53.81666
        },
        "_place": 1.64,
        "_people": 26.86,
        "_property": 5,
        "_price": 108.5,
        "map": property_66
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115449131#",
        "title": "6 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115449131/151052_31059201_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 4,
        "address": "Lockhill, Upper Sapey, Herefordshire, WR6 6XR",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -2.465245,
            "lat": 52.270149
        },
        "_place": 9.61,
        "_people": 24.3,
        "_property": 0,
        "_price": 115.84,
        "map": property_67
    },
    {
        "url": "https://www.rightmove.co.uk/properties/71718976#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/100k/99647/71718976/99647_28781054_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/100k/99647/71718976/99647_28781054_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/100k/99647/71718976/99647_28781054_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/100k/99647/71718976/99647_28781054_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/100k/99647/71718976/99647_28781054_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/100k/99647/71718976/99647_28781054_IMG_06_0000.jpg"
        ],
        "price": [
            {
                "value": 150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Building Plot, Moss Road, Ullapool, Ross-shire",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -5.157901,
            "lat": 57.900686
        },
        "_place": 7.69,
        "_people": 18.44,
        "_property": 3,
        "_price": 113.75,
        "map": property_68
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114565493#",
        "title": "1 bedroom terraced bungalow",
        "images": [
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 152000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Bembridge , Isle of Wight ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.079039,
            "lat": 50.688213
        },
        "_place": 7.38,
        "_people": 32.52,
        "_property": 1,
        "_price": 122.07,
        "map": property_69
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111732725#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136661/111732725/136661_102631017812_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Fleminghouse Lane, Huddersfield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.73573,
            "lat": 53.63915
        },
        "_place": 2.18,
        "_people": 28.2,
        "_property": 5,
        "_price": 161.63,
        "map": property_70
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114990470#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/236k/235622/114990470/235622_TBE210051_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235622/114990470/235622_TBE210051_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235622/114990470/235622_TBE210051_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235622/114990470/235622_TBE210051_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235622/114990470/235622_TBE210051_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235622/114990470/235622_TBE210051_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235622/114990470/235622_TBE210051_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235622/114990470/235622_TBE210051_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Withycross Barn, Bowerland Lane, Lingfield, Surrey, RH7",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.0101,
            "lat": 51.191391
        },
        "_place": 2.82,
        "_people": 22.31,
        "_property": 2,
        "_price": 99.96,
        "map": property_71
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117059267#",
        "title": "2 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/10k/9883/117059267/9883_22220219_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117059267/9883_22220219_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117059267/9883_22220219_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117059267/9883_22220219_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117059267/9883_22220219_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117059267/9883_22220219_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117059267/9883_22220219_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117059267/9883_22220219_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117059267/9883_22220219_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117059267/9883_22220219_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117059267/9883_22220219_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117059267/9883_22220219_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117059267/9883_22220219_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117059267/9883_22220219_IMG_14_0001.png"
        ],
        "price": [
            {
                "value": 519950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Pemberton Road, Lyndhurst, SO43",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.574056,
            "lat": 50.875393
        },
        "_place": 1.5,
        "_people": 23.75,
        "_property": 5,
        "_price": 187.47,
        "map": property_72
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81875826#",
        "title": "4 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/228k/227948/81875826/227948_4_H761756_IMG_01_0004.jpg",
            "https://media.rightmove.co.uk/228k/227948/81875826/227948_4_H761756_IMG_02_0004.jpg",
            "https://media.rightmove.co.uk/228k/227948/81875826/227948_4_H761756_IMG_03_0004.jpg",
            "https://media.rightmove.co.uk/228k/227948/81875826/227948_4_H761756_IMG_04_0004.jpg",
            "https://media.rightmove.co.uk/228k/227948/81875826/227948_4_H761756_IMG_05_0005.jpg",
            "https://media.rightmove.co.uk/228k/227948/81875826/227948_4_H761756_IMG_06_0003.jpg",
            "https://media.rightmove.co.uk/228k/227948/81875826/227948_4_H761756_IMG_07_0005.jpg",
            "https://media.rightmove.co.uk/228k/227948/81875826/227948_4_H761756_IMG_08_0005.jpg"
        ],
        "price": [
            {
                "value": 262995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Holehouse Road,\r\nKilmarnock,\r\nKA3 7AR",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.48816,
            "lat": 55.6096
        },
        "_place": 4.3,
        "_people": 20.13,
        "_property": 3,
        "_price": 152.59,
        "map": property_73
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117206801#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33716/117206801/33716_31145127_IMG_26_0000.jpeg"
        ],
        "price": [
            {
                "value": 495000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Penygarreg Lane, Pant",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.068122,
            "lat": 52.798363
        },
        "_place": 4.49,
        "_people": 30.83,
        "_property": 0,
        "_price": 165.46,
        "map": property_74
    },
    {
        "url": "https://www.rightmove.co.uk/properties/83003754#",
        "title": "1 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 230000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Craigmore Cottage, Gruline, Isle of Mull, PA71",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.983463,
            "lat": 56.487746
        },
        "_place": 6.83,
        "_people": 20.92,
        "_property": 2,
        "_price": 169.95,
        "map": property_75
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114298451#",
        "title": "3 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/8k/7460/114298451/7460_KIM1SK6599_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7460/114298451/7460_KIM1SK6599_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7460/114298451/7460_KIM1SK6599_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7460/114298451/7460_KIM1SK6599_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7460/114298451/7460_KIM1SK6599_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7460/114298451/7460_KIM1SK6599_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7460/114298451/7460_KIM1SK6599_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7460/114298451/7460_KIM1SK6599_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7460/114298451/7460_KIM1SK6599_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7460/114298451/7460_KIM1SK6599_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7460/114298451/7460_KIM1SK6599_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 395000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Mabe",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.130036,
            "lat": 50.164584
        },
        "_place": 5.12,
        "_people": 25.44,
        "_property": 5,
        "_price": 175.53,
        "map": property_76
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110483309#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/4k/3937/110483309/3937_PFM403743_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3937/110483309/3937_PFM403743_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3937/110483309/3937_PFM403743_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3937/110483309/3937_PFM403743_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3937/110483309/3937_PFM403743_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3937/110483309/3937_PFM403743_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3937/110483309/3937_PFM403743_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3937/110483309/3937_PFM403743_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3937/110483309/3937_PFM403743_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3937/110483309/3937_PFM403743_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3937/110483309/3937_PFM403743_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3937/110483309/3937_PFM403743_IMG_03_0000.jpeg"
        ],
        "price": [
            {
                "value": 485000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Conningbrook Lakes, Kennington, Ashford",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.90039,
            "lat": 51.15602
        },
        "_place": 11.57,
        "_people": 31.13,
        "_property": 5,
        "_price": 159.75,
        "map": property_77
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112155965#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54817/112155965/54817_SVN210115_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 1095000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Canville Rise, Westerham, Kent",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.072654,
            "lat": 51.271487
        },
        "_place": 9.64,
        "_people": 32.86,
        "_property": 1,
        "_price": 184.31,
        "map": property_78
    },
    {
        "url": "https://www.rightmove.co.uk/properties/78094254#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/9k/8467/78094254/8467_DUF210044_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8467/78094254/8467_DUF210044_IMG_01_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8467/78094254/8467_DUF210044_IMG_02_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8467/78094254/8467_DUF210044_IMG_03_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8467/78094254/8467_DUF210044_IMG_04_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8467/78094254/8467_DUF210044_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8467/78094254/8467_DUF210044_IMG_06_0001.jpeg"
        ],
        "price": [
            {
                "value": 235000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Linen Quarter, Winterthur Lane, Dunfermline, Fife, KY12",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.461996,
            "lat": 56.073648
        },
        "_place": 10.51,
        "_people": 20.94,
        "_property": 1,
        "_price": 185.62,
        "map": property_79
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109471811#",
        "title": "8 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_18_0001.jpeg"
        ],
        "price": [
            {
                "value": 449000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 8,
        "bathrooms": 2,
        "address": "Rhosgoch, Anglesey, LL66",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.400525,
            "lat": 53.373844
        },
        "_place": 0.42,
        "_people": 18.34,
        "_property": 0,
        "_price": 129.86,
        "map": property_80
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82150524#",
        "title": "2 bedroom ground floor flat",
        "images": [
            "https://media.rightmove.co.uk/115k/114586/82150524/114586_440942_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114586/82150524/114586_440942_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114586/82150524/114586_440942_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114586/82150524/114586_440942_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114586/82150524/114586_440942_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114586/82150524/114586_440942_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114586/82150524/114586_440942_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114586/82150524/114586_440942_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114586/82150524/114586_440942_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/115k/114586/82150524/114586_440942_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 115000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "38 Huttonpark Crescent, LARGS, KA30 8QR",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.869538,
            "lat": 55.801991
        },
        "_place": 1.11,
        "_people": 30.91,
        "_property": 2,
        "_price": 172.21,
        "map": property_81
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116465468#",
        "title": "5 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/116465468/75383_11195841_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Heath Park, Brixham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.506255,
            "lat": 50.39639
        },
        "_place": 8.37,
        "_people": 25.35,
        "_property": 0,
        "_price": 116.2,
        "map": property_82
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114941990#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/235k/234635/114941990/234635_OneBalticSquare-214950-Liverpool-OBS8330_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234635/114941990/234635_OneBalticSquare-214950-Liverpool-OBS8330_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234635/114941990/234635_OneBalticSquare-214950-Liverpool-OBS8330_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234635/114941990/234635_OneBalticSquare-214950-Liverpool-OBS8330_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234635/114941990/234635_OneBalticSquare-214950-Liverpool-OBS8330_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234635/114941990/234635_OneBalticSquare-214950-Liverpool-OBS8330_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234635/114941990/234635_OneBalticSquare-214950-Liverpool-OBS8330_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234635/114941990/234635_OneBalticSquare-214950-Liverpool-OBS8330_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234635/114941990/234635_OneBalticSquare-214950-Liverpool-OBS8330_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234635/114941990/234635_OneBalticSquare-214950-Liverpool-OBS8330_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 214950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "L8 Hands Off Investment, Grafton Street, Liverpool",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.97721,
            "lat": 53.39132
        },
        "_place": 8.87,
        "_people": 27.63,
        "_property": 1,
        "_price": 182.82,
        "map": property_83
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116732768#",
        "title": "3 bedroom character property",
        "images": [
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76394/116732768/76394_11215396_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 595000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "West Wycombe Road, High Wycombe",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.7736,
            "lat": 51.63768
        },
        "_place": 6.29,
        "_people": 23.47,
        "_property": 4,
        "_price": 195.46,
        "map": property_84
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111484040#",
        "title": "Plot",
        "images": [
            "https://media.rightmove.co.uk/130k/129328/111484040/129328_700AM_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129328/111484040/129328_700AM_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129328/111484040/129328_700AM_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129328/111484040/129328_700AM_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129328/111484040/129328_700AM_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129328/111484040/129328_700AM_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129328/111484040/129328_700AM_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129328/111484040/129328_700AM_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129328/111484040/129328_700AM_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "RESIDENTIAL DEVELOPMENT SITE, St Giles on the Heath, Devon",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.32319,
            "lat": 50.68627
        },
        "_place": 4.56,
        "_people": 32.59,
        "_property": 3,
        "_price": 112.6,
        "map": property_85
    },
    {
        "url": "https://www.rightmove.co.uk/properties/79086186#",
        "title": "5 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_20_0002.jpeg",
            "https://media.rightmove.co.uk/102k/101563/79086186/101563_6567_IMG_21_0002.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 1,
        "address": "Hazel Close, RH2",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.196072,
            "lat": 51.22817
        },
        "_place": 6.88,
        "_people": 20.87,
        "_property": 4,
        "_price": 101.54,
        "map": property_86
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115645463#",
        "title": "3 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/5k/4939/115645463/4939_LAS210317_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/115645463/4939_LAS210317_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/115645463/4939_LAS210317_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/115645463/4939_LAS210317_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/115645463/4939_LAS210317_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/115645463/4939_LAS210317_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/115645463/4939_LAS210317_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/115645463/4939_LAS210317_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/115645463/4939_LAS210317_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/115645463/4939_LAS210317_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/115645463/4939_LAS210317_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/115645463/4939_LAS210317_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/115645463/4939_LAS210317_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 235000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Courthill Street, Dalry, North Ayrshire, KA24",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.71497,
            "lat": 55.70906
        },
        "_place": 11.51,
        "_people": 35.44,
        "_property": 1,
        "_price": 197.29,
        "map": property_87
    },
    {
        "url": "https://www.rightmove.co.uk/properties/92837207#",
        "title": "2 bedroom park home",
        "images": [
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_00_0000.jpg",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_03_0000.JPG",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/34k/33366/92837207/33366_10539_IMG_17_0000.jpg"
        ],
        "price": [
            {
                "value": 165000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Pett Level Road, TN36",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.71972,
            "lat": 50.90913
        },
        "_place": 8.55,
        "_people": 19.84,
        "_property": 0,
        "_price": 162.89,
        "map": property_88
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116901407#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/116901407/62758_31131482_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 645000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "7 Lawn Drive, Whalley Range, Manchester, M16 ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.254449,
            "lat": 53.44651
        },
        "_place": 9.23,
        "_people": 28.89,
        "_property": 5,
        "_price": 155.4,
        "map": property_89
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115881212#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115881212/111235_bfffguhhih_IMG_27_0000.jpeg"
        ],
        "price": [
            {
                "value": 650000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "Griston Road, Watton, IP25",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.84215,
            "lat": 52.56687
        },
        "_place": 9.12,
        "_people": 25.81,
        "_property": 5,
        "_price": 119.46,
        "map": property_90
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107937206#",
        "title": "4 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/2k/1648/107937206/1648_STV309259_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1648/107937206/1648_STV309259_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1648/107937206/1648_STV309259_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1648/107937206/1648_STV309259_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1648/107937206/1648_STV309259_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1648/107937206/1648_STV309259_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1648/107937206/1648_STV309259_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1648/107937206/1648_STV309259_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1648/107937206/1648_STV309259_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1648/107937206/1648_STV309259_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1648/107937206/1648_STV309259_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1648/107937206/1648_STV309259_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1648/107937206/1648_STV309259_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1648/107937206/1648_STV309259_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Ramsdell, STEVENAGE",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.18794,
            "lat": 51.90058
        },
        "_place": 8.01,
        "_people": 34.8,
        "_property": 5,
        "_price": 116.78,
        "map": property_91
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116772470#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/233k/232988/116772470/232988_3_apartment_28_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/233k/232988/116772470/232988_3_apartment_28_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/233k/232988/116772470/232988_3_apartment_28_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/233k/232988/116772470/232988_3_apartment_28_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/233k/232988/116772470/232988_3_apartment_28_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/233k/232988/116772470/232988_3_apartment_28_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/233k/232988/116772470/232988_3_apartment_28_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/233k/232988/116772470/232988_3_apartment_28_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/233k/232988/116772470/232988_3_apartment_28_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/233k/232988/116772470/232988_3_apartment_28_IMG_10_0000.jpg"
        ],
        "price": [
            {
                "value": 745000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Greens Lane,\r\nLondon,\r\nN21 2SH",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.09449,
            "lat": 51.63168
        },
        "_place": 9.45,
        "_people": 19.13,
        "_property": 5,
        "_price": 127.71,
        "map": property_92
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114662927#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/44k/43113/114662927/43113_EM-47005_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43113/114662927/43113_EM-47005_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43113/114662927/43113_EM-47005_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43113/114662927/43113_EM-47005_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43113/114662927/43113_EM-47005_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43113/114662927/43113_EM-47005_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43113/114662927/43113_EM-47005_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43113/114662927/43113_EM-47005_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43113/114662927/43113_EM-47005_IMG_08_0001.jpeg"
        ],
        "price": [
            {
                "value": 145000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Fairfield Road, Droylsden",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -2.14553,
            "lat": 53.47969
        },
        "_place": 0.43,
        "_people": 28.84,
        "_property": 2,
        "_price": 162.21,
        "map": property_93
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116417726#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/116417726/61544_4931299_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Hawthorn Road, Eynsham, Witney, Oxfordshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.370775,
            "lat": 51.783842
        },
        "_place": 10.31,
        "_people": 21.52,
        "_property": 2,
        "_price": 183.33,
        "map": property_94
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114447197#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/76k/75825/114447197/75825_1250466-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75825/114447197/75825_1250466-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75825/114447197/75825_1250466-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75825/114447197/75825_1250466-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75825/114447197/75825_1250466-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75825/114447197/75825_1250466-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75825/114447197/75825_1250466-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75825/114447197/75825_1250466-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75825/114447197/75825_1250466-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75825/114447197/75825_1250466-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75825/114447197/75825_1250466-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75825/114447197/75825_1250466-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75825/114447197/75825_1250466-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75825/114447197/75825_1250466-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75825/114447197/75825_1250466-1_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 280000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Woodbank Avenue, Stockport, SK1",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.1364,
            "lat": 53.404705
        },
        "_place": 2.47,
        "_people": 32.72,
        "_property": 0,
        "_price": 148.71,
        "map": property_95
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114901100#",
        "title": "4 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144140/114901100/144140_31030091_IMG_26_0000.jpeg"
        ],
        "price": [
            {
                "value": 875000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Brockley Rise, London",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.04274,
            "lat": 51.445842
        },
        "_place": 11.96,
        "_people": 23.41,
        "_property": 3,
        "_price": 155.12,
        "map": property_96
    },
    {
        "url": "https://www.rightmove.co.uk/properties/84847465#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/167k/166331/84847465/166331_1128365_IMG_16_0000.jpg"
        ],
        "price": [
            {
                "value": 1100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Sylvester Road, Wembley, HA0",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.30777,
            "lat": 51.554429
        },
        "_place": 1.56,
        "_people": 34.26,
        "_property": 4,
        "_price": 191,
        "map": property_97
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117553076#",
        "title": "2 bedroom lodge",
        "images": [
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117553076/194636_4291_IMG_25_0000.jpeg"
        ],
        "price": [
            {
                "value": 210000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Llanidloes, Powys, SY18 6PQ",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.57086,
            "lat": 52.44928
        },
        "_place": 8.14,
        "_people": 32.73,
        "_property": 4,
        "_price": 120.52,
        "map": property_98
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114594320#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22904/114594320/22904_11150724_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 260000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Woodlands Avenue, Penwortham, Preston",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.713555,
            "lat": 53.736988
        },
        "_place": 2.54,
        "_people": 33.17,
        "_property": 0,
        "_price": 180.45,
        "map": property_99
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115503263#",
        "title": "2 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/4k/3939/115503263/3939_TWL404290_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3939/115503263/3939_TWL404290_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3939/115503263/3939_TWL404290_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3939/115503263/3939_TWL404290_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3939/115503263/3939_TWL404290_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3939/115503263/3939_TWL404290_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3939/115503263/3939_TWL404290_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3939/115503263/3939_TWL404290_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 325000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Old Orchard, Sandhurst, Cranbrook",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.55842,
            "lat": 51.02658
        },
        "_place": 1.64,
        "_people": 20.72,
        "_property": 1,
        "_price": 167.79,
        "map": property_100
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82870020#",
        "title": "2 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/12k/11448/82870020/11448_0197_HRT019707151_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11448/82870020/11448_0197_HRT019707151_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11448/82870020/11448_0197_HRT019707151_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11448/82870020/11448_0197_HRT019707151_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11448/82870020/11448_0197_HRT019707151_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11448/82870020/11448_0197_HRT019707151_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11448/82870020/11448_0197_HRT019707151_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11448/82870020/11448_0197_HRT019707151_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11448/82870020/11448_0197_HRT019707151_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11448/82870020/11448_0197_HRT019707151_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11448/82870020/11448_0197_HRT019707151_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 675000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Church Square, Shepperton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.45429,
            "lat": 51.38843
        },
        "_place": 8.55,
        "_people": 35.68,
        "_property": 1,
        "_price": 111.7,
        "map": property_101
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82391523#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/79k/78537/82391523/78537_28864_3_55_100774_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/79k/78537/82391523/78537_28864_3_55_100774_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/79k/78537/82391523/78537_28864_3_55_100774_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/79k/78537/82391523/78537_28864_3_55_100774_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/79k/78537/82391523/78537_28864_3_55_100774_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/79k/78537/82391523/78537_28864_3_55_100774_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/79k/78537/82391523/78537_28864_3_55_100774_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/79k/78537/82391523/78537_28864_3_55_100774_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/79k/78537/82391523/78537_28864_3_55_100774_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/79k/78537/82391523/78537_28864_3_55_100774_IMG_10_0000.jpg"
        ],
        "price": [
            {
                "value": 283995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Florish Road, \r\nRenfrewshire,\r\nErskine,\r\nPA8",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.42705,
            "lat": 55.89485
        },
        "_place": 3.1,
        "_people": 30.23,
        "_property": 2,
        "_price": 162.68,
        "map": property_102
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116686283#",
        "title": "5 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14554/116686283/14554_31120182_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 1,
        "address": "Kendal Close, Farnborough",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.780485,
            "lat": 51.29322
        },
        "_place": 0.02,
        "_people": 23.28,
        "_property": 0,
        "_price": 97.6,
        "map": property_103
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111886226#",
        "title": "2 bedroom semi-detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/50k/49972/111886226/49972_30877300_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49972/111886226/49972_30877300_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49972/111886226/49972_30877300_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49972/111886226/49972_30877300_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49972/111886226/49972_30877300_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49972/111886226/49972_30877300_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49972/111886226/49972_30877300_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 255000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Criccieth",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.239546,
            "lat": 52.919181
        },
        "_place": 4.89,
        "_people": 21.29,
        "_property": 5,
        "_price": 164.66,
        "map": property_104
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114980630#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/2k/1660/114980630/1660_NPA305062_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1660/114980630/1660_NPA305062_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1660/114980630/1660_NPA305062_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1660/114980630/1660_NPA305062_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1660/114980630/1660_NPA305062_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1660/114980630/1660_NPA305062_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1660/114980630/1660_NPA305062_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1660/114980630/1660_NPA305062_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1660/114980630/1660_NPA305062_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1660/114980630/1660_NPA305062_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1660/114980630/1660_NPA305062_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1660/114980630/1660_NPA305062_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 360000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Hainault Avenue, Giffard Park, Milton Keynes",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.74276,
            "lat": 52.06633
        },
        "_place": 1.83,
        "_people": 27.75,
        "_property": 1,
        "_price": 184.45,
        "map": property_105
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82960394#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/87k/86716/82960394/86716_WES150239_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86716/82960394/86716_WES150239_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86716/82960394/86716_WES150239_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86716/82960394/86716_WES150239_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86716/82960394/86716_WES150239_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86716/82960394/86716_WES150239_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86716/82960394/86716_WES150239_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86716/82960394/86716_WES150239_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86716/82960394/86716_WES150239_IMG_08_0001.jpeg"
        ],
        "price": [
            {
                "value": 1185000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Gray's Inn Road, London, WC1X",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.112057,
            "lat": 51.519141
        },
        "_place": 6.64,
        "_people": 20.38,
        "_property": 1,
        "_price": 128.63,
        "map": property_106
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117235925#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/4k/3816/117235925/3816_HED170385_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3816/117235925/3816_HED170385_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3816/117235925/3816_HED170385_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3816/117235925/3816_HED170385_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3816/117235925/3816_HED170385_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3816/117235925/3816_HED170385_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3816/117235925/3816_HED170385_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3816/117235925/3816_HED170385_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3816/117235925/3816_HED170385_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3816/117235925/3816_HED170385_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3816/117235925/3816_HED170385_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3816/117235925/3816_HED170385_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3816/117235925/3816_HED170385_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3816/117235925/3816_HED170385_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3816/117235925/3816_HED170385_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Geers Wood, Heathfield, East Sussex, TN21",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.247296,
            "lat": 50.964168
        },
        "_place": 0.25,
        "_people": 21.32,
        "_property": 2,
        "_price": 185.28,
        "map": property_107
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115536899#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/138k/137417/115536899/137417_11211691_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/138k/137417/115536899/137417_11211691_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/138k/137417/115536899/137417_11211691_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/138k/137417/115536899/137417_11211691_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/138k/137417/115536899/137417_11211691_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/138k/137417/115536899/137417_11211691_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/138k/137417/115536899/137417_11211691_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/138k/137417/115536899/137417_11211691_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/138k/137417/115536899/137417_11211691_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/138k/137417/115536899/137417_11211691_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/138k/137417/115536899/137417_11211691_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/138k/137417/115536899/137417_11211691_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 569950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Fullers Meadow, Wantage",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.40235,
            "lat": 51.59274
        },
        "_place": 5.45,
        "_people": 33.29,
        "_property": 5,
        "_price": 165.44,
        "map": property_108
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117452576#",
        "title": "5 bedroom villa",
        "images": [
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91804/117452576/91804_8235_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 670000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 6,
        "address": "1A Ard Dorch, BROADFORD, Isle of Skye, IV49 9AJ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -6.00928,
            "lat": 57.27993
        },
        "_place": 2.45,
        "_people": 18.14,
        "_property": 2,
        "_price": 130.57,
        "map": property_109
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114644618#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1955/114644618/1955_COR110854_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 725000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 4,
        "address": "High Street, Stanion, KETTERING",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.65528,
            "lat": 52.47176
        },
        "_place": 2.4,
        "_people": 31.73,
        "_property": 5,
        "_price": 108.28,
        "map": property_110
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82638312#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/224k/223916/82638312/223916_28684_3_39_100774_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/224k/223916/82638312/223916_28684_3_39_100774_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/224k/223916/82638312/223916_28684_3_39_100774_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/224k/223916/82638312/223916_28684_3_39_100774_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/224k/223916/82638312/223916_28684_3_39_100774_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/224k/223916/82638312/223916_28684_3_39_100774_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/224k/223916/82638312/223916_28684_3_39_100774_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/224k/223916/82638312/223916_28684_3_39_100774_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/224k/223916/82638312/223916_28684_3_39_100774_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/224k/223916/82638312/223916_28684_3_39_100774_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/224k/223916/82638312/223916_28684_3_39_100774_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/224k/223916/82638312/223916_28684_3_39_100774_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/224k/223916/82638312/223916_28684_3_39_100774_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/224k/223916/82638312/223916_28684_3_39_100774_IMG_14_0000.jpg"
        ],
        "price": [
            {
                "value": 665000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Stonehill Road, Chertsey, KT16 0AG",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.53428,
            "lat": 51.37731
        },
        "_place": 8.76,
        "_people": 28.77,
        "_property": 0,
        "_price": 117.35,
        "map": property_111
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110884661#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157823/110884661/157823_30831988_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 745000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Alpine Road, Old Colwyn",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.691182,
            "lat": 53.286483
        },
        "_place": 11.77,
        "_people": 25.15,
        "_property": 2,
        "_price": 136.51,
        "map": property_112
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112288814#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/112288814/35507_30893397_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Crosspark Terrace, Mevagissey, St. Austell",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.789705,
            "lat": 50.274158
        },
        "_place": 6.17,
        "_people": 32.41,
        "_property": 1,
        "_price": 114.22,
        "map": property_113
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115285274#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/142k/141344/115285274/141344_11203623_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Cambridge Street, Aylesbury",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.81134,
            "lat": 51.81826
        },
        "_place": 8.2,
        "_people": 25.58,
        "_property": 3,
        "_price": 197.65,
        "map": property_114
    },
    {
        "url": "https://www.rightmove.co.uk/properties/57640170#",
        "title": "3 bedroom semi-detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_29_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_30_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_31_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_32_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_27_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_33_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_23_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_24_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_25_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_28_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_34_0000.jpg"
        ],
        "price": [
            {
                "value": 175000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Dunaros Gardens, Salen",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.94611,
            "lat": 56.5145
        },
        "_place": 10.71,
        "_people": 25.3,
        "_property": 4,
        "_price": 184.49,
        "map": property_115
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108621422#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/108621422/77231_LLA210180_IMG_45_0000.jpeg"
        ],
        "price": [
            {
                "value": 845000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "Llanfaethlu, Holyhead, Ynys Mon, LL65",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.563954,
            "lat": 53.350296
        },
        "_place": 6.4,
        "_people": 21.93,
        "_property": 4,
        "_price": 141.19,
        "map": property_116
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107974289#",
        "title": "2 bedroom penthouse",
        "images": [
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75987/107974289/75987_30690732_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 395000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Moor Road, Bramhope, Leeds",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.624486,
            "lat": 53.884063
        },
        "_place": 10.97,
        "_people": 27.52,
        "_property": 0,
        "_price": 90.54,
        "map": property_117
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116547473#",
        "title": "3 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/219k/218915/116547473/218915_5048_3_58_100040_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/219k/218915/116547473/218915_5048_3_58_100040_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/219k/218915/116547473/218915_5048_3_58_100040_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/219k/218915/116547473/218915_5048_3_58_100040_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/219k/218915/116547473/218915_5048_3_58_100040_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/219k/218915/116547473/218915_5048_3_58_100040_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/219k/218915/116547473/218915_5048_3_58_100040_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/219k/218915/116547473/218915_5048_3_58_100040_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/219k/218915/116547473/218915_5048_3_58_100040_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/219k/218915/116547473/218915_5048_3_58_100040_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/219k/218915/116547473/218915_5048_3_58_100040_IMG_21_0000.jpg"
        ],
        "price": [
            {
                "value": 176995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Off Elm Road,\r\nBlaydon-on-Tyne,\r\nNE21 5AW",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.71184,
            "lat": 54.95995
        },
        "_place": 6.84,
        "_people": 24.55,
        "_property": 3,
        "_price": 112.19,
        "map": property_118
    },
    {
        "url": "https://www.rightmove.co.uk/properties/99413216#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/64k/63765/99413216/63765_2676616_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/64k/63765/99413216/63765_2676616_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/64k/63765/99413216/63765_2676616_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/64k/63765/99413216/63765_2676616_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/64k/63765/99413216/63765_2676616_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/64k/63765/99413216/63765_2676616_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/64k/63765/99413216/63765_2676616_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/64k/63765/99413216/63765_2676616_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/64k/63765/99413216/63765_2676616_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/64k/63765/99413216/63765_2676616_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/64k/63765/99413216/63765_2676616_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/64k/63765/99413216/63765_2676616_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/64k/63765/99413216/63765_2676616_IMG_12_0000.jpg"
        ],
        "price": [
            {
                "value": 999995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Redriff Road, London SE16",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.04298,
            "lat": 51.49549
        },
        "_place": 4.92,
        "_people": 19.67,
        "_property": 2,
        "_price": 178.62,
        "map": property_119
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107712845#",
        "title": "5 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/107712845/17469_30682362_IMG_25_0000.jpeg"
        ],
        "price": [
            {
                "value": 465000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "George Hill, Llandeilo",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.994807,
            "lat": 51.882015
        },
        "_place": 7.38,
        "_people": 19.97,
        "_property": 3,
        "_price": 128.38,
        "map": property_120
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107857352#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33630/107857352/33630_30680017_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 625000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "1 Blake Mews, Blake Street, York",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.084611,
            "lat": 53.961212
        },
        "_place": 5.49,
        "_people": 33.7,
        "_property": 2,
        "_price": 98.24,
        "map": property_121
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116147153#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/135k/134456/116147153/134456_P537_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 127500,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Rothesay Crescent, Coatbridge, North Lanarkshire, ML5",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.01442,
            "lat": 55.84909
        },
        "_place": 7.53,
        "_people": 20.87,
        "_property": 4,
        "_price": 137.55,
        "map": property_122
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112129619#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/88k/87979/112129619/87979_100662033172_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87979/112129619/87979_100662033172_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87979/112129619/87979_100662033172_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87979/112129619/87979_100662033172_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87979/112129619/87979_100662033172_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87979/112129619/87979_100662033172_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87979/112129619/87979_100662033172_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87979/112129619/87979_100662033172_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87979/112129619/87979_100662033172_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87979/112129619/87979_100662033172_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87979/112129619/87979_100662033172_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 204950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Chamber Road, Oldham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.12773,
            "lat": 53.52729
        },
        "_place": 8.42,
        "_people": 30.59,
        "_property": 1,
        "_price": 145.35,
        "map": property_123
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107720048#",
        "title": "7 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/107720048/9883_20492997_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 775000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": 7,
        "address": "Romsey Road, Lyndhurst, SO43",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.576899,
            "lat": 50.875322
        },
        "_place": 11.72,
        "_people": 25.03,
        "_property": 0,
        "_price": 137.47,
        "map": property_124
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112468355#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_25_0001.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_24_0001.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24302/112468355/24302_11105895_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 1075000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Manor Road, Bramhall",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.174619,
            "lat": 53.371113
        },
        "_place": 7.47,
        "_people": 23.17,
        "_property": 4,
        "_price": 134.5,
        "map": property_125
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111539645#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76672/111539645/76672_30863440_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 320000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 4,
        "address": "Whinstone, South Street West, Falkland, Fife, KY15 ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.208798,
            "lat": 56.252691
        },
        "_place": 8.52,
        "_people": 23.87,
        "_property": 5,
        "_price": 177.7,
        "map": property_126
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113183690#",
        "title": "3 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/25k/24410/113183690/24410_11134881_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 985000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Candela Yard, Crouch End N8 ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.120742,
            "lat": 51.58179
        },
        "_place": 7.5,
        "_people": 31.14,
        "_property": 4,
        "_price": 136.84,
        "map": property_127
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112769483#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8417/112769483/8417_WAL210273_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 529000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Harriet Drive, Rochester, ME1",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.49249,
            "lat": 51.378967
        },
        "_place": 9.58,
        "_people": 25.27,
        "_property": 3,
        "_price": 113.95,
        "map": property_128
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81563880#",
        "title": "7 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/81563880/19299_30804035_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 295000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": 3,
        "address": "Monument Hill, Carmarthen",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.323393,
            "lat": 51.854118
        },
        "_place": 7.73,
        "_people": 19.92,
        "_property": 2,
        "_price": 152.21,
        "map": property_129
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82741719#",
        "title": "Detached house",
        "images": [
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228056/82741719/228056_11236371_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Foxhole Road, Beauly",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.459671,
            "lat": 57.414223
        },
        "_place": 4.41,
        "_people": 22.16,
        "_property": 4,
        "_price": 104.63,
        "map": property_130
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116509421#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_20_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_21_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38703/116509421/38703_Zhuntersnarboroug0000021778_IMG_22_0001.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": " Cosby Road, Broughton Astley, Leicestershire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.225688,
            "lat": 52.531704
        },
        "_place": 1.94,
        "_people": 32.8,
        "_property": 0,
        "_price": 190.21,
        "map": property_131
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114059018#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114059018/72877_386953_IMG_17_0001.jpeg"
        ],
        "price": [
            {
                "value": 595000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Angley Road, Cranbrook, Kent, TN17 3LR",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.524375,
            "lat": 51.095455
        },
        "_place": 8.41,
        "_people": 23.15,
        "_property": 2,
        "_price": 157.19,
        "map": property_132
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112179980#",
        "title": "4 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4075/112179980/4075_SLE210588_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 650000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Ryland Road, Welton, LN2",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.477661,
            "lat": 53.307474
        },
        "_place": 1.77,
        "_people": 18.42,
        "_property": 2,
        "_price": 152.71,
        "map": property_133
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109846322#",
        "title": "2 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/109846322/12250_RS0620_IMG_42_0000.jpeg"
        ],
        "price": [
            {
                "value": 249950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 3,
        "address": "Siloh Chapel, Dyffryn Ardudwy, LL44 2EF",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.096884,
            "lat": 52.787555
        },
        "_place": 4.01,
        "_people": 28.35,
        "_property": 4,
        "_price": 97.42,
        "map": property_134
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109938683#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96041/109938683/96041_JMB1630_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 565000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Main Street, WR10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.06702,
            "lat": 52.13784
        },
        "_place": 2.64,
        "_people": 20.92,
        "_property": 3,
        "_price": 103.73,
        "map": property_135
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115350242#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/229k/228749/115350242/228749_4_15_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/229k/228749/115350242/228749_4_15_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228749/115350242/228749_4_15_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228749/115350242/228749_4_15_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228749/115350242/228749_4_15_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228749/115350242/228749_4_15_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228749/115350242/228749_4_15_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228749/115350242/228749_4_15_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228749/115350242/228749_4_15_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228749/115350242/228749_4_15_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228749/115350242/228749_4_15_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228749/115350242/228749_4_15_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228749/115350242/228749_4_15_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228749/115350242/228749_4_15_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 354950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Marlborough Way,\r\nNewdale,\r\nTelford,\r\nTF3",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.47673,
            "lat": 52.68413
        },
        "_place": 7.13,
        "_people": 22.11,
        "_property": 0,
        "_price": 174.15,
        "map": property_137
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117714968#",
        "title": "3 bedroom lodge",
        "images": [
            "https://media.rightmove.co.uk/195k/194636/117714968/194636_4298_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117714968/194636_4298_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117714968/194636_4298_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117714968/194636_4298_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117714968/194636_4298_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117714968/194636_4298_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117714968/194636_4298_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117714968/194636_4298_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 229995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Lifton, Devon, PL16 0RL",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.23106,
            "lat": 50.68819
        },
        "_place": 4.31,
        "_people": 27.69,
        "_property": 4,
        "_price": 121.88,
        "map": property_138
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112748588#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/112748588/80004_7085_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 225000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Rowanbank Cottage, Dalmally, Argyll",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.988405,
            "lat": 56.401531
        },
        "_place": 1.13,
        "_people": 24.3,
        "_property": 1,
        "_price": 122.55,
        "map": property_139
    },
    {
        "url": "https://www.rightmove.co.uk/properties/84105295#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/45k/44858/84105295/44858_101975008043_IMG_19_0000.jpg"
        ],
        "price": [
            {
                "value": 865000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "77 Muswell Hill, Muswell Hill",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.1414,
            "lat": 51.59044
        },
        "_place": 8.53,
        "_people": 25.1,
        "_property": 3,
        "_price": 182.86,
        "map": property_140
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110116655#",
        "title": "3 bedroom duplex",
        "images": [
            "https://media.rightmove.co.uk/16k/15013/110116655/15013_4401094_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/110116655/15013_4401094_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/110116655/15013_4401094_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/110116655/15013_4401094_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/110116655/15013_4401094_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/110116655/15013_4401094_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/110116655/15013_4401094_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/110116655/15013_4401094_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/110116655/15013_4401094_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/110116655/15013_4401094_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/110116655/15013_4401094_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/110116655/15013_4401094_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Baltic Quay Penthouse, Mill Road, Gateshead, Tyne and Wear",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.597235,
            "lat": 54.967937
        },
        "_place": 10.17,
        "_people": 27.96,
        "_property": 3,
        "_price": 146.8,
        "map": property_141
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115772579#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Oakley Road, Luton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.456484,
            "lat": 51.893646
        },
        "_place": 6.32,
        "_people": 33.03,
        "_property": 3,
        "_price": 162.94,
        "map": property_142
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113187827#",
        "title": "1 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/78k/77043/113187827/77043_255934_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 170000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Crown Street, Seahouses",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.653924,
            "lat": 55.580722
        },
        "_place": 9.33,
        "_people": 23.87,
        "_property": 1,
        "_price": 114.34,
        "map": property_143
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117097835#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/87k/86675/117097835/86675_184913_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86675/117097835/86675_184913_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86675/117097835/86675_184913_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86675/117097835/86675_184913_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86675/117097835/86675_184913_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86675/117097835/86675_184913_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86675/117097835/86675_184913_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86675/117097835/86675_184913_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86675/117097835/86675_184913_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86675/117097835/86675_184913_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86675/117097835/86675_184913_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86675/117097835/86675_184913_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86675/117097835/86675_184913_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86675/117097835/86675_184913_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86675/117097835/86675_184913_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 129995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Flat 26 Kelburne Court, 51 Glasgow Road Paisley, PA1 3AF",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.408427,
            "lat": 55.847745
        },
        "_place": 11.15,
        "_people": 18.91,
        "_property": 3,
        "_price": 191.07,
        "map": property_144
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110328644#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225575/110328644/225575_30801334_IMG_28_0000.jpeg"
        ],
        "price": [
            {
                "value": 125000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Sweethill Terrace, Coatbridge",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.999323,
            "lat": 55.847866
        },
        "_place": 10.09,
        "_people": 35.77,
        "_property": 3,
        "_price": 93.82,
        "map": property_145
    },
    {
        "url": "https://www.rightmove.co.uk/properties/76696698#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_54_0001.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_00_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_55_0001.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_01_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_02_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_03_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_04_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_05_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_06_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_07_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_08_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_09_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_10_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_11_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_12_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_13_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_14_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_15_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_16_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_17_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_18_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_19_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_20_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_21_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_22_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_23_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_24_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_25_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_26_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_27_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_28_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_29_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_30_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_31_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_32_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_33_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_34_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_35_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_36_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_37_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_38_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_39_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_40_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_41_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_42_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_43_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_44_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_45_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_46_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_47_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_48_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_49_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_50_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_51_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_52_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_53_0002.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_56_0001.jpeg",
            "https://media.rightmove.co.uk/214k/213791/76696698/213791_S16870_IMG_57_0001.jpeg"
        ],
        "price": [
            {
                "value": 775000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 4,
        "address": "Gwalchmai,Holyhead,LL65 4SP",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.419468,
            "lat": 53.257381
        },
        "_place": 1.85,
        "_people": 32.65,
        "_property": 4,
        "_price": 142.27,
        "map": property_146
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117324368#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/13k/12201/117324368/12201_RED210732_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12201/117324368/12201_RED210732_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12201/117324368/12201_RED210732_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12201/117324368/12201_RED210732_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12201/117324368/12201_RED210732_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12201/117324368/12201_RED210732_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12201/117324368/12201_RED210732_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12201/117324368/12201_RED210732_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12201/117324368/12201_RED210732_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12201/117324368/12201_RED210732_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12201/117324368/12201_RED210732_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12201/117324368/12201_RED210732_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12201/117324368/12201_RED210732_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12201/117324368/12201_RED210732_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 125000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Tomlinson Way, Middlesbrough",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.187744,
            "lat": 54.566236
        },
        "_place": 9.68,
        "_people": 20.36,
        "_property": 4,
        "_price": 97.36,
        "map": property_147
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117391361#",
        "title": "2 bedroom penthouse",
        "images": [
            "https://media.rightmove.co.uk/76k/75989/117391361/75989_RDI190425_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75989/117391361/75989_RDI190425_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75989/117391361/75989_RDI190425_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75989/117391361/75989_RDI190425_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75989/117391361/75989_RDI190425_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75989/117391361/75989_RDI190425_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75989/117391361/75989_RDI190425_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75989/117391361/75989_RDI190425_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75989/117391361/75989_RDI190425_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75989/117391361/75989_RDI190425_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75989/117391361/75989_RDI190425_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75989/117391361/75989_RDI190425_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 975000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Ridgewood, Brooklands Road, Weybridge, Surrey, KT13",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.457144,
            "lat": 51.358166
        },
        "_place": 11.38,
        "_people": 23.06,
        "_property": 4,
        "_price": 141.48,
        "map": property_148
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112811645#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/81k/80190/112811645/80190_SC1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80190/112811645/80190_SC1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80190/112811645/80190_SC1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80190/112811645/80190_SC1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80190/112811645/80190_SC1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80190/112811645/80190_SC1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80190/112811645/80190_SC1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80190/112811645/80190_SC1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80190/112811645/80190_SC1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80190/112811645/80190_SC1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80190/112811645/80190_SC1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80190/112811645/80190_SC1_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 240000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "School Drive, Knottingley, West Yorkshire, WF11",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.27099,
            "lat": 53.71247
        },
        "_place": 10.73,
        "_people": 29.56,
        "_property": 1,
        "_price": 159.38,
        "map": property_149
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81877275#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/239k/238382/81877275/238382_4_H709272_IMG_01_0003.jpg",
            "https://media.rightmove.co.uk/239k/238382/81877275/238382_4_H709272_IMG_02_0005.jpg",
            "https://media.rightmove.co.uk/239k/238382/81877275/238382_4_H709272_IMG_03_0005.jpg",
            "https://media.rightmove.co.uk/239k/238382/81877275/238382_4_H709272_IMG_04_0005.jpg",
            "https://media.rightmove.co.uk/239k/238382/81877275/238382_4_H709272_IMG_05_0005.jpg",
            "https://media.rightmove.co.uk/239k/238382/81877275/238382_4_H709272_IMG_06_0005.jpg",
            "https://media.rightmove.co.uk/239k/238382/81877275/238382_4_H709272_IMG_07_0005.jpg",
            "https://media.rightmove.co.uk/239k/238382/81877275/238382_4_H709272_IMG_08_0005.jpg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Blounts Green,\r\nUttoxeter,\r\nST14",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.88254,
            "lat": 52.88972
        },
        "_place": 10.14,
        "_people": 31.95,
        "_property": 1,
        "_price": 100.76,
        "map": property_150
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117304289#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/166k/165113/117304289/165113_11251692_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/166k/165113/117304289/165113_11251692_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/166k/165113/117304289/165113_11251692_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/166k/165113/117304289/165113_11251692_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/166k/165113/117304289/165113_11251692_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/166k/165113/117304289/165113_11251692_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/166k/165113/117304289/165113_11251692_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/166k/165113/117304289/165113_11251692_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/166k/165113/117304289/165113_11251692_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/166k/165113/117304289/165113_11251692_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/166k/165113/117304289/165113_11251692_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/166k/165113/117304289/165113_11251692_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/166k/165113/117304289/165113_11251692_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/166k/165113/117304289/165113_11251692_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 675000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Mint Road, Liss",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.880363,
            "lat": 51.050419
        },
        "_place": 10.61,
        "_people": 27.38,
        "_property": 3,
        "_price": 164.81,
        "map": property_151
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117122246#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/231k/230474/117122246/230474_3_37_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/231k/230474/117122246/230474_3_37_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/231k/230474/117122246/230474_3_37_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/231k/230474/117122246/230474_3_37_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/231k/230474/117122246/230474_3_37_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/231k/230474/117122246/230474_3_37_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/231k/230474/117122246/230474_3_37_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/231k/230474/117122246/230474_3_37_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/231k/230474/117122246/230474_3_37_IMG_09_0000.jpg"
        ],
        "price": [
            {
                "value": 360000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Easton lane,\r\nChippenham,\r\nSN14 0RW",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.14382,
            "lat": 51.43773
        },
        "_place": 6.06,
        "_people": 35.45,
        "_property": 0,
        "_price": 178.45,
        "map": property_152
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82836114#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76227/82836114/76227_AYRSH_001222_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 110000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Herbertson Crescent, North Ayrshire, KA12",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.65746,
            "lat": 55.62169
        },
        "_place": 5.63,
        "_people": 25.6,
        "_property": 3,
        "_price": 123.9,
        "map": property_153
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82489866#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/215k/214268/82489866/214268_4_48_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82489866/214268_4_48_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82489866/214268_4_48_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82489866/214268_4_48_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82489866/214268_4_48_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82489866/214268_4_48_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82489866/214268_4_48_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82489866/214268_4_48_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82489866/214268_4_48_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82489866/214268_4_48_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82489866/214268_4_48_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82489866/214268_4_48_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82489866/214268_4_48_IMG_13_0000.jpg"
        ],
        "price": [
            {
                "value": 241995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Farmhill Place,\r\nSaltcoats,\r\nKA21",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.77982,
            "lat": 55.65152
        },
        "_place": 7.85,
        "_people": 19.18,
        "_property": 1,
        "_price": 102.61,
        "map": property_154
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109756520#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/39k/38106/109756520/38106_402861_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38106/109756520/38106_402861_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38106/109756520/38106_402861_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38106/109756520/38106_402861_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38106/109756520/38106_402861_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38106/109756520/38106_402861_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38106/109756520/38106_402861_IMG_06_0001.jpeg"
        ],
        "price": [
            {
                "value": 120000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "East View, Boldon Colliery",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.461101,
            "lat": 54.947876
        },
        "_place": 0.54,
        "_people": 18.31,
        "_property": 5,
        "_price": 114.97,
        "map": property_155
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116840744#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238397/116840744/238397_WEL210180_IMG_40_0000.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Adforton, Leintwardine",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.881701,
            "lat": 52.335015
        },
        "_place": 7.62,
        "_people": 20.12,
        "_property": 5,
        "_price": 178.26,
        "map": property_156
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113928227#",
        "title": "2 bedroom cottage",
        "images": [
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/113928227/7368_30976225_IMG_36_0000.jpeg"
        ],
        "price": [
            {
                "value": 275000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 3,
        "address": "Towngate, Newsome, Huddersfield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.785675,
            "lat": 53.628425
        },
        "_place": 3.22,
        "_people": 35.66,
        "_property": 3,
        "_price": 167.23,
        "map": property_157
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82772697#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/215k/214268/82772697/214268_3_59_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82772697/214268_3_59_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82772697/214268_3_59_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82772697/214268_3_59_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82772697/214268_3_59_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82772697/214268_3_59_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82772697/214268_3_59_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82772697/214268_3_59_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82772697/214268_3_59_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82772697/214268_3_59_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82772697/214268_3_59_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/215k/214268/82772697/214268_3_59_IMG_12_0000.jpg"
        ],
        "price": [
            {
                "value": 179995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Farmhill Place,\r\nSaltcoats,\r\nKA21",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.77982,
            "lat": 55.65152
        },
        "_place": 10.21,
        "_people": 20.75,
        "_property": 1,
        "_price": 110.1,
        "map": property_158
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82976454#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/202k/201461/82976454/201461_3_H7584244_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/202k/201461/82976454/201461_3_H7584244_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/202k/201461/82976454/201461_3_H7584244_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/202k/201461/82976454/201461_3_H7584244_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/202k/201461/82976454/201461_3_H7584244_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/202k/201461/82976454/201461_3_H7584244_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/202k/201461/82976454/201461_3_H7584244_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/202k/201461/82976454/201461_3_H7584244_IMG_22_0000.jpg"
        ],
        "price": [
            {
                "value": 445000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Madgwick Lane, Chichester, \r\nWest Sussex \r\nPO18 0GD",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.75463,
            "lat": 50.84795
        },
        "_place": 2,
        "_people": 22.34,
        "_property": 2,
        "_price": 125.74,
        "map": property_159
    },
    {
        "url": "https://www.rightmove.co.uk/properties/84031624#",
        "title": "2 bedroom maisonette",
        "images": [
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/1k/655/84031624/655_MEB190040_IMG_17_0001.jpeg"
        ],
        "price": [
            {
                "value": 499950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 3,
        "address": "Menai Quay, Menai Bridge, Anglesey, North Wales, LL59",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.16221,
            "lat": 53.2246
        },
        "_place": 3.7,
        "_people": 26.08,
        "_property": 0,
        "_price": 177.48,
        "map": property_160
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81607329#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Llanrug",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.192509,
            "lat": 53.158695
        },
        "_place": 0.6,
        "_people": 27.77,
        "_property": 2,
        "_price": 111.34,
        "map": property_161
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115652723#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104957/115652723/104957_103202004955_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 495000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Plot 1 Manor Rise, Manor Road, Kiveton Park Station, S26 6PB",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.23933,
            "lat": 53.33608
        },
        "_place": 1.68,
        "_people": 34.26,
        "_property": 0,
        "_price": 102.4,
        "map": property_162
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110293649#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293649/237629_4402_IMG_30_0000.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 5,
        "address": "Mannin Veen, Lheaney Road, Ramsey",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.382977,
            "lat": 54.31707
        },
        "_place": 10.16,
        "_people": 29.03,
        "_property": 2,
        "_price": 147.14,
        "map": property_163
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117059963#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41864/117059963/41864_102737001952_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 525000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Filbert Drive, Tilehurst, Reading",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.05056,
            "lat": 51.45924
        },
        "_place": 0.98,
        "_people": 33.53,
        "_property": 3,
        "_price": 136.08,
        "map": property_164
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110726444#",
        "title": "2 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/9k/8484/110726444/8484_WBA210332_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/110726444/8484_WBA210332_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/110726444/8484_WBA210332_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/110726444/8484_WBA210332_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/110726444/8484_WBA210332_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/110726444/8484_WBA210332_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/110726444/8484_WBA210332_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/110726444/8484_WBA210332_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/110726444/8484_WBA210332_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/110726444/8484_WBA210332_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/110726444/8484_WBA210332_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/110726444/8484_WBA210332_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/110726444/8484_WBA210332_IMG_12_0001.jpeg"
        ],
        "price": [
            {
                "value": 139000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Bayfield, West Allotment, Newcastle upon Tyne, Tyne and Wear, NE27",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.512143,
            "lat": 55.029938
        },
        "_place": 10.46,
        "_people": 26.17,
        "_property": 2,
        "_price": 121.82,
        "map": property_165
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116855084#",
        "title": "3 bedroom town house",
        "images": [
            "https://media.rightmove.co.uk/47k/46386/116855084/46386_100723005248_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46386/116855084/46386_100723005248_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46386/116855084/46386_100723005248_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46386/116855084/46386_100723005248_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46386/116855084/46386_100723005248_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46386/116855084/46386_100723005248_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46386/116855084/46386_100723005248_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46386/116855084/46386_100723005248_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46386/116855084/46386_100723005248_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46386/116855084/46386_100723005248_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 860000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Harlow Gardens, Kingston Upon Thames",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.29387,
            "lat": 51.40103
        },
        "_place": 2.38,
        "_people": 22.63,
        "_property": 3,
        "_price": 187.56,
        "map": property_166
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113926742#",
        "title": "3 bedroom barn conversion",
        "images": [
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38076/113926742/38076_30976124_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Kilburn Court, Kilburn, York",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.213518,
            "lat": 54.208454
        },
        "_place": 9.28,
        "_people": 28.17,
        "_property": 2,
        "_price": 146.8,
        "map": property_167
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116900780#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/74k/73954/116900780/73954_THA200132_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/74k/73954/116900780/73954_THA200132_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/74k/73954/116900780/73954_THA200132_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/74k/73954/116900780/73954_THA200132_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/74k/73954/116900780/73954_THA200132_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/74k/73954/116900780/73954_THA200132_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/74k/73954/116900780/73954_THA200132_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/74k/73954/116900780/73954_THA200132_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/74k/73954/116900780/73954_THA200132_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/74k/73954/116900780/73954_THA200132_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 725000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Long Crendon, Aylesbury",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.994274,
            "lat": 51.77109
        },
        "_place": 11.33,
        "_people": 20,
        "_property": 3,
        "_price": 134.76,
        "map": property_168
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115265105#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13787/115265105/13787_21570353_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 775000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Guildford Road, Runfold, GU10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.748436,
            "lat": 51.220686
        },
        "_place": 8.48,
        "_people": 26.7,
        "_property": 5,
        "_price": 128.29,
        "map": property_169
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116854448#",
        "title": "2 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/29k/28286/116854448/28286_22197577_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28286/116854448/28286_22197577_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28286/116854448/28286_22197577_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28286/116854448/28286_22197577_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28286/116854448/28286_22197577_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28286/116854448/28286_22197577_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28286/116854448/28286_22197577_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28286/116854448/28286_22197577_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28286/116854448/28286_22197577_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28286/116854448/28286_22197577_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28286/116854448/28286_22197577_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28286/116854448/28286_22197577_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 325000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Beech Hill, Dent, Sedbergh, LA10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.452898,
            "lat": 54.278161
        },
        "_place": 5.85,
        "_people": 25.38,
        "_property": 2,
        "_price": 152.23,
        "map": property_170
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117673643#",
        "title": "3 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/9k/8511/117673643/8511_WAK210613_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8511/117673643/8511_WAK210613_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8511/117673643/8511_WAK210613_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8511/117673643/8511_WAK210613_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8511/117673643/8511_WAK210613_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8511/117673643/8511_WAK210613_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8511/117673643/8511_WAK210613_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8511/117673643/8511_WAK210613_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8511/117673643/8511_WAK210613_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8511/117673643/8511_WAK210613_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8511/117673643/8511_WAK210613_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8511/117673643/8511_WAK210613_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8511/117673643/8511_WAK210613_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8511/117673643/8511_WAK210613_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 250000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Mort Lane, Tyldesley, Manchester, Greater Manchester, M29",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.438632,
            "lat": 53.516056
        },
        "_place": 7.84,
        "_people": 34.04,
        "_property": 3,
        "_price": 117.65,
        "map": property_171
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114391646#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8966/114391646/8966_GSH210129_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Wellesley Court, Stonecot Hill, Sutton, SM3",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.214175,
            "lat": 51.382332
        },
        "_place": 5.2,
        "_people": 34.74,
        "_property": 4,
        "_price": 130.17,
        "map": property_172
    },
    {
        "url": "https://www.rightmove.co.uk/properties/88780756#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_20_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_23_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_24_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_21_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/4k/3785/88780756/3785_KIN130290_IMG_09_0000.jpg"
        ],
        "price": [
            {
                "value": 925000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "The Dell, Dunny Lane, Chipperfield, WD4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.50095,
            "lat": 51.70177
        },
        "_place": 8.31,
        "_people": 21.65,
        "_property": 0,
        "_price": 105.16,
        "map": property_173
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112425119#",
        "title": "2 bedroom town house",
        "images": [
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43422/112425119/43422_100157006286_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 795000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Old Portsmouth, Hampshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.109268,
            "lat": 50.792275
        },
        "_place": 6.36,
        "_people": 33.88,
        "_property": 5,
        "_price": 103.41,
        "map": property_174
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116481020#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/3k/2137/116481020/2137_WAT107281_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2137/116481020/2137_WAT107281_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2137/116481020/2137_WAT107281_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2137/116481020/2137_WAT107281_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2137/116481020/2137_WAT107281_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2137/116481020/2137_WAT107281_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2137/116481020/2137_WAT107281_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2137/116481020/2137_WAT107281_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2137/116481020/2137_WAT107281_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2137/116481020/2137_WAT107281_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2137/116481020/2137_WAT107281_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2137/116481020/2137_WAT107281_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2137/116481020/2137_WAT107281_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2137/116481020/2137_WAT107281_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Harvest Road, Watton, Thetford",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.84026,
            "lat": 52.57254
        },
        "_place": 8.92,
        "_people": 34.92,
        "_property": 2,
        "_price": 168.52,
        "map": property_175
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114165941#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67401/114165941/67401_MEDSP99336365_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 625000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Snodhurst Avenue, Chatham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.51775,
            "lat": 51.3516
        },
        "_place": 4.32,
        "_people": 27.18,
        "_property": 2,
        "_price": 106.7,
        "map": property_176
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117341885#",
        "title": "3 bedroom link detached house",
        "images": [
            "https://media.rightmove.co.uk/243k/242633/117341885/242633_Villa63_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242633/117341885/242633_Villa63_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242633/117341885/242633_Villa63_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242633/117341885/242633_Villa63_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/243k/242633/117341885/242633_Villa63_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 435000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Redlands Close\r\nHighworth,\r\nWiltshire,\r\nSN6 7SW",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.71336,
            "lat": 51.62305
        },
        "_place": 7.95,
        "_people": 28.29,
        "_property": 5,
        "_price": 111.79,
        "map": property_177
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117448130#",
        "title": "4 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12665/117448130/12665_10678664_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 445000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Eaton Avenue, Bletchley, Milton Keynes",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.72547,
            "lat": 51.993042
        },
        "_place": 9.65,
        "_people": 24.54,
        "_property": 4,
        "_price": 189.34,
        "map": property_178
    },
    {
        "url": "https://www.rightmove.co.uk/properties/98144681#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/122k/121954/98144681/121954_JT2020RH_IMG_00_0000.JPG",
            "https://media.rightmove.co.uk/122k/121954/98144681/121954_JT2020RH_IMG_01_0000.JPG",
            "https://media.rightmove.co.uk/122k/121954/98144681/121954_JT2020RH_IMG_02_0000.JPG",
            "https://media.rightmove.co.uk/122k/121954/98144681/121954_JT2020RH_IMG_03_0000.JPG",
            "https://media.rightmove.co.uk/122k/121954/98144681/121954_JT2020RH_IMG_04_0000.JPG",
            "https://media.rightmove.co.uk/122k/121954/98144681/121954_JT2020RH_IMG_05_0000.JPG",
            "https://media.rightmove.co.uk/122k/121954/98144681/121954_JT2020RH_IMG_06_0000.JPG",
            "https://media.rightmove.co.uk/122k/121954/98144681/121954_JT2020RH_IMG_07_0000.JPG",
            "https://media.rightmove.co.uk/122k/121954/98144681/121954_JT2020RH_IMG_08_0000.JPG",
            "https://media.rightmove.co.uk/122k/121954/98144681/121954_JT2020RH_IMG_09_0000.JPG",
            "https://media.rightmove.co.uk/122k/121954/98144681/121954_JT2020RH_IMG_12_0000.JPG"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Station Way, Buckhurst Hill, Essex, IG9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.04288,
            "lat": 51.61777
        },
        "_place": 9,
        "_people": 24.04,
        "_property": 2,
        "_price": 136.31,
        "map": property_179
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81952797#",
        "title": "2 bedroom ground floor flat",
        "images": [
            "https://media.rightmove.co.uk/78k/77902/81952797/77902_11166739_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/81952797/77902_11166739_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/81952797/77902_11166739_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/81952797/77902_11166739_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/81952797/77902_11166739_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/81952797/77902_11166739_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/81952797/77902_11166739_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/81952797/77902_11166739_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/81952797/77902_11166739_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/81952797/77902_11166739_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/81952797/77902_11166739_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/81952797/77902_11166739_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/81952797/77902_11166739_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/81952797/77902_11166739_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/81952797/77902_11166739_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 435000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Hickory Grange, Higher Lane, Whitefield, Manchester",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.294489,
            "lat": 53.544067
        },
        "_place": 1.53,
        "_people": 33.3,
        "_property": 1,
        "_price": 199.18,
        "map": property_180
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114112610#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/73k/72877/114112610/72877_592856_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114112610/72877_592856_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114112610/72877_592856_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114112610/72877_592856_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114112610/72877_592856_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114112610/72877_592856_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114112610/72877_592856_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114112610/72877_592856_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114112610/72877_592856_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114112610/72877_592856_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114112610/72877_592856_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/73k/72877/114112610/72877_592856_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 650000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "West Road, Goudhurst, Kent, TN17 1AA",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.458178,
            "lat": 51.112579
        },
        "_place": 8.61,
        "_people": 28.83,
        "_property": 1,
        "_price": 123.7,
        "map": property_181
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117322478#",
        "title": "4 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 1150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Kingsborough Place, Kingston Upon Thames, Surrey, KT2",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.287484,
            "lat": 51.416012
        },
        "_place": 1.88,
        "_people": 22.68,
        "_property": 4,
        "_price": 200.32,
        "map": property_182
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114775283#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114775283/151121_11190135_IMG_35_0000.jpeg"
        ],
        "price": [
            {
                "value": 635000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 4,
        "address": "Kenwick Views, Louth",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.009793,
            "lat": 53.355156
        },
        "_place": 1.43,
        "_people": 24.8,
        "_property": 5,
        "_price": 172.58,
        "map": property_183
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113896370#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/113896370/200930_HSS184271_IMG_29_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Normanby Road, Lincoln, LN1",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.676277,
            "lat": 53.328006
        },
        "_place": 6.71,
        "_people": 25.81,
        "_property": 3,
        "_price": 110.5,
        "map": property_184
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113253830#",
        "title": "4 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/225k/224303/113253830/224303_100717019337_IMG_43_0000.jpeg"
        ],
        "price": [
            {
                "value": 625000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Leverington, Wisbech",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.13847,
            "lat": 52.67512
        },
        "_place": 5.18,
        "_people": 31.31,
        "_property": 3,
        "_price": 136.3,
        "map": property_185
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116474525#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203507/116474525/203507_1243419-1_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 525000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Vellum Drive, Sittingbourne, ME10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.710893,
            "lat": 51.350049
        },
        "_place": 6.81,
        "_people": 21.04,
        "_property": 4,
        "_price": 110.45,
        "map": property_186
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114555854#",
        "title": "5 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/9k/8469/114555854/8469_INV210320_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8469/114555854/8469_INV210320_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8469/114555854/8469_INV210320_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8469/114555854/8469_INV210320_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8469/114555854/8469_INV210320_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8469/114555854/8469_INV210320_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8469/114555854/8469_INV210320_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8469/114555854/8469_INV210320_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8469/114555854/8469_INV210320_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8469/114555854/8469_INV210320_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8469/114555854/8469_INV210320_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8469/114555854/8469_INV210320_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8469/114555854/8469_INV210320_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8469/114555854/8469_INV210320_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8469/114555854/8469_INV210320_IMG_14_0001.jpeg"
        ],
        "price": [
            {
                "value": 410000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "Auldearn, Nairn, IV12",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.797942,
            "lat": 57.574826
        },
        "_place": 5.21,
        "_people": 31.14,
        "_property": 2,
        "_price": 90.18,
        "map": property_187
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117221162#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76541/117221162/76541_25087_IMG_31_0000.jpeg"
        ],
        "price": [
            {
                "value": 370000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Swallow Wood Road, Aston Manor, Swallownest, Sheffield, S26 4SU",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.33056,
            "lat": 53.36061
        },
        "_place": 7.46,
        "_people": 26.17,
        "_property": 1,
        "_price": 168.96,
        "map": property_188
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82862550#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/8k/7564/82862550/7564_AVA200016_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7564/82862550/7564_AVA200016_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7564/82862550/7564_AVA200016_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7564/82862550/7564_AVA200016_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7564/82862550/7564_AVA200016_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7564/82862550/7564_AVA200016_IMG_05_0000.jpeg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Samara, South Lane, Ash, Surrey, GU12",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.717307,
            "lat": 51.242812
        },
        "_place": 4.18,
        "_people": 34.11,
        "_property": 4,
        "_price": 96.09,
        "map": property_189
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117083111#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8586/117083111/8586_529037572_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Vincent Close, Duston, Northampton, NN5",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.962892,
            "lat": 52.248924
        },
        "_place": 4.84,
        "_people": 29.42,
        "_property": 2,
        "_price": 118.99,
        "map": property_190
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117014369#",
        "title": "1 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/72k/71140/117014369/71140_31134735_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71140/117014369/71140_31134735_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71140/117014369/71140_31134735_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71140/117014369/71140_31134735_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71140/117014369/71140_31134735_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71140/117014369/71140_31134735_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71140/117014369/71140_31134735_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71140/117014369/71140_31134735_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 1075000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Shaftesbury Avenue, Shaftesbury Avenue",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.126561,
            "lat": 51.51622
        },
        "_place": 10.54,
        "_people": 33.15,
        "_property": 4,
        "_price": 148.23,
        "map": property_191
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116691902#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/77k/76417/116691902/76417_MMT301335_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76417/116691902/76417_MMT301335_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76417/116691902/76417_MMT301335_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76417/116691902/76417_MMT301335_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76417/116691902/76417_MMT301335_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76417/116691902/76417_MMT301335_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76417/116691902/76417_MMT301335_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76417/116691902/76417_MMT301335_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76417/116691902/76417_MMT301335_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76417/116691902/76417_MMT301335_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76417/116691902/76417_MMT301335_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76417/116691902/76417_MMT301335_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76417/116691902/76417_MMT301335_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 1000000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Land At Peterchurch Road, Peterchurch, Hereford",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -2.95198,
            "lat": 52.04006
        },
        "_place": 5.05,
        "_people": 20.55,
        "_property": 1,
        "_price": 143.3,
        "map": property_192
    },
    {
        "url": "https://www.rightmove.co.uk/properties/87657308#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/13k/12215/87657308/12215_29347284_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12215/87657308/12215_29347284_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12215/87657308/12215_29347284_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12215/87657308/12215_29347284_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12215/87657308/12215_29347284_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12215/87657308/12215_29347284_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12215/87657308/12215_29347284_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12215/87657308/12215_29347284_IMG_31_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "The Roebuck, The Rise, Halloughton Road, Southwell",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.963887,
            "lat": 53.074882
        },
        "_place": 0.7,
        "_people": 35.78,
        "_property": 1,
        "_price": 169.31,
        "map": property_193
    },
    {
        "url": "https://www.rightmove.co.uk/properties/77992339#",
        "title": "1 bedroom retirement property",
        "images": [
            "https://media.rightmove.co.uk/29k/28151/77992339/28151_534767_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/29k/28151/77992339/28151_534767_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/29k/28151/77992339/28151_534767_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/29k/28151/77992339/28151_534767_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/29k/28151/77992339/28151_534767_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/29k/28151/77992339/28151_534767_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/29k/28151/77992339/28151_534767_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/29k/28151/77992339/28151_534767_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/29k/28151/77992339/28151_534767_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/29k/28151/77992339/28151_534767_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/29k/28151/77992339/28151_534767_IMG_11_0000.jpg"
        ],
        "price": [
            {
                "value": 250000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": null,
        "address": "Church Road, Bembridge, Isle of Wight, PO35 5AA",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.090315,
            "lat": 50.691029
        },
        "_place": 3.02,
        "_people": 29.29,
        "_property": 4,
        "_price": 200.67,
        "map": property_194
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110293670#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_45_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_46_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_47_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_48_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_49_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_50_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_51_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_52_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_53_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293670/237629_3495_IMG_54_0000.jpeg"
        ],
        "price": [
            {
                "value": 785000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "31, Cronk Cullyn, Colby",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.699869,
            "lat": 54.097968
        },
        "_place": 6.23,
        "_people": 22.41,
        "_property": 5,
        "_price": 93.59,
        "map": property_195
    },
    {
        "url": "https://www.rightmove.co.uk/properties/80235006#",
        "title": "12 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/52k/51441/80235006/51441_30640336_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51441/80235006/51441_30640336_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51441/80235006/51441_30640336_IMG_02_0000.jpeg"
        ],
        "price": [
            {
                "value": 795000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 12,
        "bathrooms": null,
        "address": "Manchester Road, Buxton, Derbyshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.917093,
            "lat": 53.259597
        },
        "_place": 5.45,
        "_people": 18.18,
        "_property": 5,
        "_price": 154.79,
        "map": property_196
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114191405#",
        "title": "6 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 3,
        "address": "Abbey Road, Pontrhydfendigaid, SY25",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.863278,
            "lat": 52.279693
        },
        "_place": 5.53,
        "_people": 28.17,
        "_property": 3,
        "_price": 121.07,
        "map": property_197
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117403445#",
        "title": "2 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/58k/57475/117403445/57475_11249413_IMG_08_0002.jpeg",
            "https://media.rightmove.co.uk/58k/57475/117403445/57475_11249413_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57475/117403445/57475_11249413_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57475/117403445/57475_11249413_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57475/117403445/57475_11249413_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57475/117403445/57475_11249413_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57475/117403445/57475_11249413_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57475/117403445/57475_11249413_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 395000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Christies Cottage, Corbridge",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.015904,
            "lat": 54.973438
        },
        "_place": 3.74,
        "_people": 18.04,
        "_property": 4,
        "_price": 184.3,
        "map": property_198
    },
    {
        "url": "https://www.rightmove.co.uk/properties/80618924#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_20_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_21_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/152k/151070/80618924/151070_592218-1_IMG_23_0000.jpg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Thorpe Park Road, Peterborough, PE3",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.268347,
            "lat": 52.577494
        },
        "_place": 8.31,
        "_people": 20.87,
        "_property": 2,
        "_price": 99.99,
        "map": property_199
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116745560#",
        "title": "2 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/40k/39356/116745560/39356_01058_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39356/116745560/39356_01058_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39356/116745560/39356_01058_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39356/116745560/39356_01058_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39356/116745560/39356_01058_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39356/116745560/39356_01058_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39356/116745560/39356_01058_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39356/116745560/39356_01058_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39356/116745560/39356_01058_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 215000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Zouch Close, Tidworth, Wiltshire, SP9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.66773,
            "lat": 51.24358
        },
        "_place": 7.95,
        "_people": 33.76,
        "_property": 0,
        "_price": 159.21,
        "map": property_200
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113233343#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113233343/34262_11134713_IMG_27_0000.jpeg"
        ],
        "price": [
            {
                "value": 300000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Thelma Avenue, Brown Edge, ST6",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.138126,
            "lat": 53.077774
        },
        "_place": 11.08,
        "_people": 30.73,
        "_property": 2,
        "_price": 139.59,
        "map": property_201
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112250582#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/112250582/238916_14476911_9981936_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 225000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 4,
        "address": "Serenus ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.282711,
            "lat": 58.298036
        },
        "_place": 6.12,
        "_people": 29.59,
        "_property": 1,
        "_price": 148.76,
        "map": property_202
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117201776#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/53k/52375/117201776/52375_LND210055_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52375/117201776/52375_LND210055_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52375/117201776/52375_LND210055_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52375/117201776/52375_LND210055_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52375/117201776/52375_LND210055_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52375/117201776/52375_LND210055_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52375/117201776/52375_LND210055_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52375/117201776/52375_LND210055_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52375/117201776/52375_LND210055_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 667950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Great Tattenhams, Epsom, Surrey, KT18",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.236406,
            "lat": 51.308383
        },
        "_place": 1.91,
        "_people": 30.65,
        "_property": 1,
        "_price": 181.55,
        "map": property_203
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113483057#",
        "title": "4 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/146k/145337/113483057/145337_30954096_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/146k/145337/113483057/145337_30954096_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145337/113483057/145337_30954096_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145337/113483057/145337_30954096_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145337/113483057/145337_30954096_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145337/113483057/145337_30954096_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145337/113483057/145337_30954096_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145337/113483057/145337_30954096_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145337/113483057/145337_30954096_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145337/113483057/145337_30954096_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/146k/145337/113483057/145337_30954096_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/146k/145337/113483057/145337_30954096_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/146k/145337/113483057/145337_30954096_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145337/113483057/145337_30954096_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Kingsway, Cheadle, Cheshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.224116,
            "lat": 53.387674
        },
        "_place": 6.68,
        "_people": 31.93,
        "_property": 4,
        "_price": 137.68,
        "map": property_204
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117650327#",
        "title": "4 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/95k/94808/117650327/94808_31163732_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/117650327/94808_31163732_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/117650327/94808_31163732_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/117650327/94808_31163732_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/117650327/94808_31163732_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/117650327/94808_31163732_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/117650327/94808_31163732_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 355000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "High Street, Grantown On Spey",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.612288,
            "lat": 57.326938
        },
        "_place": 2.7,
        "_people": 32.84,
        "_property": 3,
        "_price": 161.32,
        "map": property_205
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117022481#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_14_0000.png",
            "https://media.rightmove.co.uk/163k/162725/117022481/162725_LTT_PRP_LFSYCL_486_688107234_IMG_15_0000.png"
        ],
        "price": [
            {
                "value": 155000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Kings Road, Middlesbrough, North Yorkshire, TS3 6EP",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.210746,
            "lat": 54.568961
        },
        "_place": 2.03,
        "_people": 23.63,
        "_property": 5,
        "_price": 185.67,
        "map": property_206
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114316079#",
        "title": "2 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/57k/56957/114316079/56957_21556225_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/114316079/56957_21556225_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/114316079/56957_21556225_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/114316079/56957_21556225_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/114316079/56957_21556225_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/114316079/56957_21556225_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/114316079/56957_21556225_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/114316079/56957_21556225_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/114316079/56957_21556225_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/114316079/56957_21556225_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/114316079/56957_21556225_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 119000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Mayfield Terrace, Colinsburgh, Leven, KY9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.843268,
            "lat": 56.220499
        },
        "_place": 11.48,
        "_people": 35.41,
        "_property": 4,
        "_price": 110.04,
        "map": property_207
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114181112#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/39k/38606/114181112/38606_30990735_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38606/114181112/38606_30990735_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38606/114181112/38606_30990735_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38606/114181112/38606_30990735_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38606/114181112/38606_30990735_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38606/114181112/38606_30990735_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38606/114181112/38606_30990735_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38606/114181112/38606_30990735_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38606/114181112/38606_30990735_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38606/114181112/38606_30990735_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38606/114181112/38606_30990735_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38606/114181112/38606_30990735_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38606/114181112/38606_30990735_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38606/114181112/38606_30990735_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38606/114181112/38606_30990735_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Seaton Road, Hornsea",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.180787,
            "lat": 53.910327
        },
        "_place": 5.32,
        "_people": 18.73,
        "_property": 4,
        "_price": 112.89,
        "map": property_208
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82728363#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/244k/243599/82728363/243599_4_194_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/244k/243599/82728363/243599_4_194_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243599/82728363/243599_4_194_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243599/82728363/243599_4_194_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243599/82728363/243599_4_194_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243599/82728363/243599_4_194_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243599/82728363/243599_4_194_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243599/82728363/243599_4_194_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243599/82728363/243599_4_194_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 287000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "West Mill,\r\nWest Mill Road,\r\nMarkinch,\r\nFife,\r\nKY7 6ZP",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.14977,
            "lat": 56.19995
        },
        "_place": 2.36,
        "_people": 26.33,
        "_property": 4,
        "_price": 122.19,
        "map": property_209
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115264319#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203513/115264319/203513_1270993-1_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Nevada Road, Canvey Island, SS8",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.600292,
            "lat": 51.528474
        },
        "_place": 3.43,
        "_people": 25.75,
        "_property": 1,
        "_price": 90.66,
        "map": property_210
    },
    {
        "url": "https://www.rightmove.co.uk/properties/93561742#",
        "title": "3 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/78k/77256/93561742/77256_3_5_IMG_01_0001.JPG",
            "https://media.rightmove.co.uk/78k/77256/93561742/77256_3_5_IMG_02_0000.JPG",
            "https://media.rightmove.co.uk/78k/77256/93561742/77256_3_5_IMG_03_0000.JPG",
            "https://media.rightmove.co.uk/78k/77256/93561742/77256_3_5_IMG_04_0000.JPG",
            "https://media.rightmove.co.uk/78k/77256/93561742/77256_3_5_IMG_05_0000.JPG",
            "https://media.rightmove.co.uk/78k/77256/93561742/77256_3_5_IMG_06_0000.JPG",
            "https://media.rightmove.co.uk/78k/77256/93561742/77256_3_5_IMG_07_0000.JPG",
            "https://media.rightmove.co.uk/78k/77256/93561742/77256_3_5_IMG_08_0000.JPG",
            "https://media.rightmove.co.uk/78k/77256/93561742/77256_3_5_IMG_09_0000.JPG"
        ],
        "price": [
            {
                "value": 430000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Townsend Road,\r\nShrivenham,\r\nSN6 8HR",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.66402,
            "lat": 51.59532
        },
        "_place": 8.6,
        "_people": 26.74,
        "_property": 5,
        "_price": 171.65,
        "map": property_211
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82148133#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/82148133/77046_262961_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 260000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Birch Place, Tain",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.065341,
            "lat": 57.805924
        },
        "_place": 9.22,
        "_people": 24.53,
        "_property": 0,
        "_price": 96.78,
        "map": property_212
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117340091#",
        "title": "2 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/12k/11202/117340091/11202_11243025_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11202/117340091/11202_11243025_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11202/117340091/11202_11243025_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11202/117340091/11202_11243025_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11202/117340091/11202_11243025_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11202/117340091/11202_11243025_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11202/117340091/11202_11243025_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11202/117340091/11202_11243025_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11202/117340091/11202_11243025_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11202/117340091/11202_11243025_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11202/117340091/11202_11243025_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11202/117340091/11202_11243025_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11202/117340091/11202_11243025_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Heron Close, Aylesbury",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.777543,
            "lat": 51.813702
        },
        "_place": 7.84,
        "_people": 20.4,
        "_property": 5,
        "_price": 93.71,
        "map": property_213
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116334188#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116334188/29648_ANW160176_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 395000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Bracken Hill, Gunnerton, Northumberland, NE48",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.14936,
            "lat": 55.07
        },
        "_place": 6.13,
        "_people": 29.38,
        "_property": 2,
        "_price": 160.5,
        "map": property_214
    },
    {
        "url": "https://www.rightmove.co.uk/properties/87570794#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_01_0001.jpg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67989/87570794/67989_4817635_IMG_25_0000.jpeg"
        ],
        "price": [
            {
                "value": 250000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Near Priory Cottage, 85 Pontefract Road, Ackworth, Pontefract, West Yorkshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.335716,
            "lat": 53.655419
        },
        "_place": 9.42,
        "_people": 28.22,
        "_property": 3,
        "_price": 124.95,
        "map": property_215
    },
    {
        "url": "https://www.rightmove.co.uk/properties/79212912#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/64k/63326/79212912/63326_30520373_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 365000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Montford Bridge, Shrewsbury",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.832307,
            "lat": 52.753898
        },
        "_place": 8.25,
        "_people": 34.08,
        "_property": 2,
        "_price": 160.86,
        "map": property_216
    },
    {
        "url": "https://www.rightmove.co.uk/properties/77582941#",
        "title": "Plot",
        "images": [
            "https://media.rightmove.co.uk/57k/56503/77582941/56503_37082_FRCEANNTREIGH_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/57k/56503/77582941/56503_37082_FRCEANNTREIGH_IMG_01_0000.jpg"
        ],
        "price": [
            {
                "value": 100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Plot: Ceann Traigh Breige, Ardery, Strontian",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -5.680423,
            "lat": 56.693754
        },
        "_place": 8.43,
        "_people": 24.36,
        "_property": 4,
        "_price": 148.47,
        "map": property_217
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112096979#",
        "title": "3 bedroom smallholding",
        "images": [
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/112096979/237065_30889532_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Cefn Coch, Welshpool",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.39275,
            "lat": 52.61073
        },
        "_place": 8.45,
        "_people": 26.42,
        "_property": 1,
        "_price": 162.31,
        "map": property_218
    },
    {
        "url": "https://www.rightmove.co.uk/properties/94607435#",
        "title": "1 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/95k/94246/94607435/94246_1136158_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/95k/94246/94607435/94246_1136158_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/95k/94246/94607435/94246_1136158_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/95k/94246/94607435/94246_1136158_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/95k/94246/94607435/94246_1136158_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/95k/94246/94607435/94246_1136158_IMG_06_0000.jpg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Red Lion Square, Holborn, London, WC1R",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.118256,
            "lat": 51.518858
        },
        "_place": 8.45,
        "_people": 21.51,
        "_property": 0,
        "_price": 111.23,
        "map": property_219
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117389741#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/94k/93913/117389741/93913_MARTL0402-t-4092_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/117389741/93913_MARTL0402-t-4092_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/117389741/93913_MARTL0402-t-4092_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/117389741/93913_MARTL0402-t-4092_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/117389741/93913_MARTL0402-t-4092_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/117389741/93913_MARTL0402-t-4092_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/117389741/93913_MARTL0402-t-4092_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/117389741/93913_MARTL0402-t-4092_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/117389741/93913_MARTL0402-t-4092_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/117389741/93913_MARTL0402-t-4092_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/117389741/93913_MARTL0402-t-4092_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/117389741/93913_MARTL0402-t-4092_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/117389741/93913_MARTL0402-t-4092_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 115000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "MacDonald terrace, Lochgilphead, PA31",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.423898,
            "lat": 56.036526
        },
        "_place": 7.52,
        "_people": 33.41,
        "_property": 5,
        "_price": 140.36,
        "map": property_220
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108348659#",
        "title": "4 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_10_0001.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Slade Hooton, Laughton, Sheffield, South Yorkshire, S25",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.214744,
            "lat": 53.396715
        },
        "_place": 9.63,
        "_people": 19.29,
        "_property": 0,
        "_price": 174.93,
        "map": property_221
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115006127#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/1k/208/115006127/208_MMK210436_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/1k/208/115006127/208_MMK210436_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/1k/208/115006127/208_MMK210436_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/1k/208/115006127/208_MMK210436_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/1k/208/115006127/208_MMK210436_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/1k/208/115006127/208_MMK210436_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/1k/208/115006127/208_MMK210436_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/1k/208/115006127/208_MMK210436_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/1k/208/115006127/208_MMK210436_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/1k/208/115006127/208_MMK210436_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/1k/208/115006127/208_MMK210436_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/1k/208/115006127/208_MMK210436_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/1k/208/115006127/208_MMK210436_IMG_12_0001.jpeg"
        ],
        "price": [
            {
                "value": 700000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Buckingham Road, Bletchley, Milton Keynes, ., MK3",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.7513,
            "lat": 51.991448
        },
        "_place": 10.41,
        "_people": 35.54,
        "_property": 4,
        "_price": 199.35,
        "map": property_222
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116224349#",
        "title": "2 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/7k/6914/116224349/6914_22000853_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6914/116224349/6914_22000853_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6914/116224349/6914_22000853_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6914/116224349/6914_22000853_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6914/116224349/6914_22000853_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6914/116224349/6914_22000853_IMG_05_0000.jpeg"
        ],
        "price": [
            {
                "value": 274950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Shaftesbury Mount, Hawley Hill, BLACKWATER, GU17",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.788363,
            "lat": 51.32428
        },
        "_place": 2.48,
        "_people": 27.57,
        "_property": 0,
        "_price": 150.15,
        "map": property_223
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117552539#",
        "title": "3 bedroom semi-detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/40k/39329/117552539/39329_7981809_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39329/117552539/39329_7981809_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39329/117552539/39329_7981809_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39329/117552539/39329_7981809_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39329/117552539/39329_7981809_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39329/117552539/39329_7981809_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39329/117552539/39329_7981809_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39329/117552539/39329_7981809_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39329/117552539/39329_7981809_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39329/117552539/39329_7981809_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39329/117552539/39329_7981809_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39329/117552539/39329_7981809_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39329/117552539/39329_7981809_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 162500,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Park Avenue North, Middlesbrough",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.182223,
            "lat": 54.551304
        },
        "_place": 5.49,
        "_people": 33.38,
        "_property": 5,
        "_price": 184.61,
        "map": property_224
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112836014#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/207k/206516/112836014/206516_trimdonbtt_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 145000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Bank Top Terrace, Trimdon Village, County Durham, TS29",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.4331,
            "lat": 54.70276
        },
        "_place": 2,
        "_people": 29.63,
        "_property": 4,
        "_price": 109.92,
        "map": property_225
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117440327#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/18k/17622/117440327/17622_SAL210736_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17622/117440327/17622_SAL210736_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17622/117440327/17622_SAL210736_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17622/117440327/17622_SAL210736_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17622/117440327/17622_SAL210736_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17622/117440327/17622_SAL210736_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17622/117440327/17622_SAL210736_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17622/117440327/17622_SAL210736_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17622/117440327/17622_SAL210736_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17622/117440327/17622_SAL210736_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17622/117440327/17622_SAL210736_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17622/117440327/17622_SAL210736_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Fownhope Avenue, Sale, Greater Manchester, M33",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.33534,
            "lat": 53.41821
        },
        "_place": 9.35,
        "_people": 22.78,
        "_property": 1,
        "_price": 197.7,
        "map": property_226
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107469032#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203012/107469032/203012_1186965-1_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 225000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Wergs Hall, Codsall, wolverhampton, WV8",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.195225,
            "lat": 52.610178
        },
        "_place": 5.98,
        "_people": 21.61,
        "_property": 1,
        "_price": 194.92,
        "map": property_227
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113242706#",
        "title": "2 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/48k/47528/113242706/47528_30942746_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47528/113242706/47528_30942746_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47528/113242706/47528_30942746_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47528/113242706/47528_30942746_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47528/113242706/47528_30942746_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47528/113242706/47528_30942746_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47528/113242706/47528_30942746_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47528/113242706/47528_30942746_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47528/113242706/47528_30942746_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47528/113242706/47528_30942746_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47528/113242706/47528_30942746_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/48k/47528/113242706/47528_30942746_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 120000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "High Street, Blaenau Ffestiniog",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.930038,
            "lat": 52.993019
        },
        "_place": 5.58,
        "_people": 34.66,
        "_property": 3,
        "_price": 156.22,
        "map": property_228
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82458234#",
        "title": "5 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12584/82458234/12584_BurnLea_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 380000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "Burnley Road, Todmorden, West Yorkshire, OL14",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.0989,
            "lat": 53.71722
        },
        "_place": 9.88,
        "_people": 18.5,
        "_property": 4,
        "_price": 130.06,
        "map": property_229
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117154850#",
        "title": "4 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/210k/209159/117154850/209159_11248825_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 725000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Raskelf Road, Easingwold",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.212583,
            "lat": 54.122627
        },
        "_place": 7.65,
        "_people": 19.98,
        "_property": 3,
        "_price": 160.93,
        "map": property_230
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114339497#",
        "title": "Plot",
        "images": [
            "https://media.rightmove.co.uk/51k/50515/114339497/50515_CAD190154_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50515/114339497/50515_CAD190154_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50515/114339497/50515_CAD190154_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50515/114339497/50515_CAD190154_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50515/114339497/50515_CAD190154_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50515/114339497/50515_CAD190154_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50515/114339497/50515_CAD190154_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 125000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Kirk Stables, Main Street, Dailly, Girvan, South Ayrshire, KA26",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.719501,
            "lat": 55.277783
        },
        "_place": 11.43,
        "_people": 25.08,
        "_property": 0,
        "_price": 190.16,
        "map": property_231
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117140537#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/46k/45030/117140537/45030_101034014883_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45030/117140537/45030_101034014883_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45030/117140537/45030_101034014883_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45030/117140537/45030_101034014883_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45030/117140537/45030_101034014883_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45030/117140537/45030_101034014883_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45030/117140537/45030_101034014883_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45030/117140537/45030_101034014883_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45030/117140537/45030_101034014883_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45030/117140537/45030_101034014883_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45030/117140537/45030_101034014883_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45030/117140537/45030_101034014883_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45030/117140537/45030_101034014883_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45030/117140537/45030_101034014883_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45030/117140537/45030_101034014883_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 5,
        "address": "Station Road, South Brent",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.83405,
            "lat": 50.42679
        },
        "_place": 1.29,
        "_people": 24.01,
        "_property": 1,
        "_price": 93.68,
        "map": property_232
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116575142#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/53k/52273/116575142/52273_101637005603_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52273/116575142/52273_101637005603_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52273/116575142/52273_101637005603_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52273/116575142/52273_101637005603_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52273/116575142/52273_101637005603_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52273/116575142/52273_101637005603_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52273/116575142/52273_101637005603_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52273/116575142/52273_101637005603_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52273/116575142/52273_101637005603_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52273/116575142/52273_101637005603_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52273/116575142/52273_101637005603_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 395000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Beaconsfield Terrace, Northampton",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.89135,
            "lat": 52.24575
        },
        "_place": 5.24,
        "_people": 18.14,
        "_property": 3,
        "_price": 142.74,
        "map": property_233
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115934165#",
        "title": "6 bedroom block of apartments",
        "images": [
            "https://media.rightmove.co.uk/75k/74945/115934165/74945_wnkmdp004793_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74945/115934165/74945_wnkmdp004793_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74945/115934165/74945_wnkmdp004793_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74945/115934165/74945_wnkmdp004793_IMG_01_0000.jpeg"
        ],
        "price": [
            {
                "value": 525000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 6,
        "address": "St. Thomas Road, Spalding, Lincolnshire, PE11",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.15658,
            "lat": 52.78517
        },
        "_place": 4.62,
        "_people": 31.57,
        "_property": 2,
        "_price": 184.02,
        "map": property_234
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114492365#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167234/114492365/167234_102149KEN_IMG_28_0000.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Kenleigh Drive, Boston, Lincolnshire, PE21",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.00717,
            "lat": 52.97612
        },
        "_place": 9.65,
        "_people": 23.25,
        "_property": 2,
        "_price": 95.27,
        "map": property_235
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114459908#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 260000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Fort William Road, Newtonmore, PH20",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.128615,
            "lat": 57.063093
        },
        "_place": 10.05,
        "_people": 27.78,
        "_property": 1,
        "_price": 116.97,
        "map": property_236
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113484635#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34262/113484635/34262_11137695_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 275000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Springbank Avenue, Endon, ST9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.118136,
            "lat": 53.071503
        },
        "_place": 2.65,
        "_people": 18.12,
        "_property": 0,
        "_price": 184.31,
        "map": property_237
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82461231#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88676/82461231/88676_31093726_IMG_26_0000.jpeg"
        ],
        "price": [
            {
                "value": 399950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "First Avenue, Bridlington",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.183138,
            "lat": 54.090526
        },
        "_place": 0.08,
        "_people": 23.17,
        "_property": 2,
        "_price": 135.46,
        "map": property_238
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113529071#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22103/113529071/22103_30950110_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 540000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 4,
        "address": "Church Drive, Hoylandswaine, Sheffield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.611427,
            "lat": 53.542396
        },
        "_place": 7.04,
        "_people": 29.87,
        "_property": 0,
        "_price": 159.5,
        "map": property_239
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116994794#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/216k/215720/116994794/215720_21833121_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215720/116994794/215720_21833121_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215720/116994794/215720_21833121_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215720/116994794/215720_21833121_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215720/116994794/215720_21833121_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215720/116994794/215720_21833121_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215720/116994794/215720_21833121_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215720/116994794/215720_21833121_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215720/116994794/215720_21833121_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215720/116994794/215720_21833121_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "St Mary's Hill, Hurstbourne Priors, Whitchurch, RG28",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.381224,
            "lat": 51.239772
        },
        "_place": 1.82,
        "_people": 33.68,
        "_property": 4,
        "_price": 137.93,
        "map": property_240
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115404572#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223625/115404572/223625_HSS189592_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Agricola Gardens, Wallsend, NE28",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.517247,
            "lat": 55.014764
        },
        "_place": 2.01,
        "_people": 18.82,
        "_property": 3,
        "_price": 113.23,
        "map": property_241
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113448605#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/113448605/69996_102866003776_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 282450,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Phoenix",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.53011,
            "lat": 53.79554
        },
        "_place": 11.13,
        "_people": 32.94,
        "_property": 3,
        "_price": 159.94,
        "map": property_242
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111874253#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/79k/78958/111874253/78958_PRC68268_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78958/111874253/78958_PRC68268_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78958/111874253/78958_PRC68268_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78958/111874253/78958_PRC68268_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78958/111874253/78958_PRC68268_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78958/111874253/78958_PRC68268_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78958/111874253/78958_PRC68268_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78958/111874253/78958_PRC68268_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78958/111874253/78958_PRC68268_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78958/111874253/78958_PRC68268_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 159950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Maes Ty Canol, Baglan, Port Talbot, Neath Port Talbot. SA12 8UP",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.801484,
            "lat": 51.619851
        },
        "_place": 4.91,
        "_people": 21.94,
        "_property": 5,
        "_price": 176.25,
        "map": property_243
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115508789#",
        "title": "4 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/95k/94760/115508789/94760_101073011383_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94760/115508789/94760_101073011383_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94760/115508789/94760_101073011383_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94760/115508789/94760_101073011383_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94760/115508789/94760_101073011383_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94760/115508789/94760_101073011383_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94760/115508789/94760_101073011383_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94760/115508789/94760_101073011383_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94760/115508789/94760_101073011383_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94760/115508789/94760_101073011383_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94760/115508789/94760_101073011383_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94760/115508789/94760_101073011383_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94760/115508789/94760_101073011383_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 900000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 4,
        "address": "Hurst Street, East Oxford, OX4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.235884,
            "lat": 51.744103
        },
        "_place": 10.29,
        "_people": 25.55,
        "_property": 4,
        "_price": 131.19,
        "map": property_244
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114280154#",
        "title": "4 bedroom mews property",
        "images": [
            "https://media.rightmove.co.uk/69k/68212/114280154/68212_11146449_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68212/114280154/68212_11146449_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68212/114280154/68212_11146449_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68212/114280154/68212_11146449_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68212/114280154/68212_11146449_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68212/114280154/68212_11146449_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68212/114280154/68212_11146449_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68212/114280154/68212_11146449_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68212/114280154/68212_11146449_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 385000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 4,
        "address": "Orchard Rise, Gee Cross",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.066948,
            "lat": 53.438202
        },
        "_place": 7.95,
        "_people": 28.27,
        "_property": 1,
        "_price": 191.08,
        "map": property_245
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116136284#",
        "title": "4 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116136284/103727_BHO211292_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 522000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Hillside Road, Burbage, Hinckley, Leicestershire, LE10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.375485,
            "lat": 52.531355
        },
        "_place": 2.16,
        "_people": 22.17,
        "_property": 5,
        "_price": 95.13,
        "map": property_246
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117317444#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64626/117317444/64626_8604834_IMG_31_0000.jpeg"
        ],
        "price": [
            {
                "value": 580000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Preston Road, Clayton-Le-Woods",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.638883,
            "lat": 53.706463
        },
        "_place": 4.36,
        "_people": 34.17,
        "_property": 3,
        "_price": 191.93,
        "map": property_247
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115008977#",
        "title": "2 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/66k/65755/115008977/65755_WTH210212_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/115008977/65755_WTH210212_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/115008977/65755_WTH210212_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/115008977/65755_WTH210212_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/115008977/65755_WTH210212_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/115008977/65755_WTH210212_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/115008977/65755_WTH210212_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/115008977/65755_WTH210212_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/115008977/65755_WTH210212_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/115008977/65755_WTH210212_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/115008977/65755_WTH210212_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/115008977/65755_WTH210212_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 260000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "High Street, Clifford, Wetherby, West Yorkshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.35003,
            "lat": 53.89278
        },
        "_place": 2.04,
        "_people": 25.44,
        "_property": 4,
        "_price": 101.63,
        "map": property_248
    },
    {
        "url": "https://www.rightmove.co.uk/properties/86837593#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_23_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_20_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_21_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/91k/90266/86837593/90266_10645078_IMG_24_0000.jpg"
        ],
        "price": [
            {
                "value": 700000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Ivy Dene Lane, Ashurst Wood, East Grinstead",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.020915,
            "lat": 51.113815
        },
        "_place": 8.79,
        "_people": 27.64,
        "_property": 3,
        "_price": 91.58,
        "map": property_249
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108781775#",
        "title": "3 bedroom character property",
        "images": [
            "https://media.rightmove.co.uk/10k/9183/108781775/9183_11009439_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9183/108781775/9183_11009439_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9183/108781775/9183_11009439_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9183/108781775/9183_11009439_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9183/108781775/9183_11009439_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9183/108781775/9183_11009439_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9183/108781775/9183_11009439_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9183/108781775/9183_11009439_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9183/108781775/9183_11009439_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9183/108781775/9183_11009439_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9183/108781775/9183_11009439_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Main Street, Hethe",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.13704,
            "lat": 51.96097
        },
        "_place": 7.64,
        "_people": 24.31,
        "_property": 2,
        "_price": 164.46,
        "map": property_250
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115336781#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/194k/193358/115336781/193358_149698_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/115336781/193358_149698_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/115336781/193358_149698_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/115336781/193358_149698_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/115336781/193358_149698_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/115336781/193358_149698_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/115336781/193358_149698_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/115336781/193358_149698_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/115336781/193358_149698_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/115336781/193358_149698_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193358/115336781/193358_149698_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "218 St. Faiths Road, Old Catton, Norwich, Norfolk, NR6 7AG",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.295686,
            "lat": 52.662376
        },
        "_place": 6.24,
        "_people": 24.97,
        "_property": 1,
        "_price": 97.36,
        "map": property_251
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115398212#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/9k/8484/115398212/8484_WBA210445_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/115398212/8484_WBA210445_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/115398212/8484_WBA210445_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/115398212/8484_WBA210445_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/115398212/8484_WBA210445_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/115398212/8484_WBA210445_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/115398212/8484_WBA210445_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/115398212/8484_WBA210445_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/115398212/8484_WBA210445_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/115398212/8484_WBA210445_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/115398212/8484_WBA210445_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/115398212/8484_WBA210445_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8484/115398212/8484_WBA210445_IMG_12_0001.jpeg"
        ],
        "price": [
            {
                "value": 410000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Holystone Avenue, Whitley Bay, Tyne and Wear, NE25",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.442822,
            "lat": 55.036802
        },
        "_place": 9.04,
        "_people": 22.89,
        "_property": 4,
        "_price": 94.08,
        "map": property_252
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112866581#",
        "title": "7 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62758/112866581/62758_30925542_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 950000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": 5,
        "address": "Manor Drive, Chorlton, Manchester, M21 ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.261074,
            "lat": 53.424086
        },
        "_place": 9.62,
        "_people": 34.49,
        "_property": 0,
        "_price": 123.82,
        "map": property_253
    },
    {
        "url": "https://www.rightmove.co.uk/properties/85796338#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/167k/166499/85796338/166499_8145398_IMG_20_0000.jpg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Lees Farm Drive, Madeley, Telford, TF7 5SU",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.460007,
            "lat": 52.631767
        },
        "_place": 7.87,
        "_people": 26.6,
        "_property": 1,
        "_price": 172.38,
        "map": property_254
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117385427#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/85k/84914/117385427/84914_31153957_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84914/117385427/84914_31153957_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84914/117385427/84914_31153957_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84914/117385427/84914_31153957_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84914/117385427/84914_31153957_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84914/117385427/84914_31153957_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84914/117385427/84914_31153957_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84914/117385427/84914_31153957_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84914/117385427/84914_31153957_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84914/117385427/84914_31153957_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84914/117385427/84914_31153957_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84914/117385427/84914_31153957_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84914/117385427/84914_31153957_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84914/117385427/84914_31153957_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "St. James Avenue, Bexhill-On-Sea",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.479222,
            "lat": 50.853866
        },
        "_place": 11.48,
        "_people": 23.35,
        "_property": 4,
        "_price": 116.54,
        "map": property_255
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114385574#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/47k/46716/114385574/46716_GSF210201_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46716/114385574/46716_GSF210201_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46716/114385574/46716_GSF210201_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46716/114385574/46716_GSF210201_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46716/114385574/46716_GSF210201_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46716/114385574/46716_GSF210201_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46716/114385574/46716_GSF210201_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46716/114385574/46716_GSF210201_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46716/114385574/46716_GSF210201_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 275000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Block C, Shepherd's Quay, Clive Street, NE29",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.443968,
            "lat": 55.004871
        },
        "_place": 1.63,
        "_people": 33.74,
        "_property": 1,
        "_price": 153.21,
        "map": property_256
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109759286#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_08_0001.jpeg"
        ],
        "price": [
            {
                "value": 655000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Skiff Wood, Howwood, Johnstone, Renfrewshire, PA9",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.544087,
            "lat": 55.805795
        },
        "_place": 9.73,
        "_people": 34.35,
        "_property": 4,
        "_price": 113.7,
        "map": property_257
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112851701#",
        "title": "4 bedroom semi-detached villa",
        "images": [
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237365/112851701/237365_11129360_IMG_42_0000.jpeg"
        ],
        "price": [
            {
                "value": 320000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Abbotshall Road, Kirkcaldy",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.16925,
            "lat": 56.10578
        },
        "_place": 10.57,
        "_people": 20.59,
        "_property": 0,
        "_price": 152.06,
        "map": property_258
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115523336#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/56k/55237/115523336/55237_BRD210526_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/115523336/55237_BRD210526_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/115523336/55237_BRD210526_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/115523336/55237_BRD210526_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/115523336/55237_BRD210526_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/115523336/55237_BRD210526_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/115523336/55237_BRD210526_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/115523336/55237_BRD210526_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/115523336/55237_BRD210526_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/115523336/55237_BRD210526_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/115523336/55237_BRD210526_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 775000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Mirabelle, Eckington, Worcestershire, WR10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.116243,
            "lat": 52.073258
        },
        "_place": 5.82,
        "_people": 24.68,
        "_property": 4,
        "_price": 102.92,
        "map": property_259
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81963564#",
        "title": "3 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/1k/526/81963564/526_SUT210260_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/1k/526/81963564/526_SUT210260_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/1k/526/81963564/526_SUT210260_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/1k/526/81963564/526_SUT210260_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/1k/526/81963564/526_SUT210260_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/1k/526/81963564/526_SUT210260_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/1k/526/81963564/526_SUT210260_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/1k/526/81963564/526_SUT210260_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/1k/526/81963564/526_SUT210260_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/1k/526/81963564/526_SUT210260_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/1k/526/81963564/526_SUT210260_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/1k/526/81963564/526_SUT210260_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/1k/526/81963564/526_SUT210260_IMG_12_0001.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Stanstead Manor, St. James Road, Sutton, SM1",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.199269,
            "lat": 51.360367
        },
        "_place": 8.07,
        "_people": 21.89,
        "_property": 5,
        "_price": 181.46,
        "map": property_260
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116730563#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/44k/43340/116730563/43340_2004_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43340/116730563/43340_2004_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43340/116730563/43340_2004_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43340/116730563/43340_2004_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43340/116730563/43340_2004_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43340/116730563/43340_2004_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43340/116730563/43340_2004_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43340/116730563/43340_2004_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43340/116730563/43340_2004_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43340/116730563/43340_2004_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43340/116730563/43340_2004_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "The Avenue, Minehead, Somerset, TA24",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.47181,
            "lat": 51.2071
        },
        "_place": 8.7,
        "_people": 29.82,
        "_property": 3,
        "_price": 183.11,
        "map": property_261
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117342005#",
        "title": "2 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/7k/6492/117342005/6492_RSAME_643302_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6492/117342005/6492_RSAME_643302_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6492/117342005/6492_RSAME_643302_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6492/117342005/6492_RSAME_643302_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6492/117342005/6492_RSAME_643302_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6492/117342005/6492_RSAME_643302_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6492/117342005/6492_RSAME_643302_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6492/117342005/6492_RSAME_643302_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6492/117342005/6492_RSAME_643302_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6492/117342005/6492_RSAME_643302_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6492/117342005/6492_RSAME_643302_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6492/117342005/6492_RSAME_643302_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6492/117342005/6492_RSAME_643302_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Pineapple Road, Amersham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.585834,
            "lat": 51.670365
        },
        "_place": 2.29,
        "_people": 32.85,
        "_property": 0,
        "_price": 132.78,
        "map": property_262
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115078829#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/53k/52910/115078829/52910_31039744_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52910/115078829/52910_31039744_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52910/115078829/52910_31039744_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52910/115078829/52910_31039744_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52910/115078829/52910_31039744_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52910/115078829/52910_31039744_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52910/115078829/52910_31039744_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52910/115078829/52910_31039744_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 725000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 5,
        "address": "Carleton Road, Pontefract",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.291434,
            "lat": 53.675045
        },
        "_place": 3.23,
        "_people": 21.09,
        "_property": 5,
        "_price": 142.2,
        "map": property_263
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117443090#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13739/117443090/13739_31156931_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 380000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Clementhorpe Lane, Gilberdyke",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.740508,
            "lat": 53.752223
        },
        "_place": 5.82,
        "_people": 21.94,
        "_property": 5,
        "_price": 144.94,
        "map": property_264
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112767857#",
        "title": "4 bedroom town house",
        "images": [
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5211/112767857/5211_SR8729_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 785000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Market Hill, Cowes, Isle Of Wight, PO31",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.29994,
            "lat": 50.76329
        },
        "_place": 10.45,
        "_people": 28.65,
        "_property": 1,
        "_price": 140.16,
        "map": property_265
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111005738#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/50k/49567/111005738/49567_CHI210324_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/111005738/49567_CHI210324_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/111005738/49567_CHI210324_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/111005738/49567_CHI210324_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/111005738/49567_CHI210324_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/111005738/49567_CHI210324_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/111005738/49567_CHI210324_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/111005738/49567_CHI210324_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/111005738/49567_CHI210324_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/111005738/49567_CHI210324_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/111005738/49567_CHI210324_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/111005738/49567_CHI210324_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/111005738/49567_CHI210324_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Monks Hill, Westbourne, Emsworth, Hampshire, PO10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.927241,
            "lat": 50.867967
        },
        "_place": 2.49,
        "_people": 27.68,
        "_property": 3,
        "_price": 148.38,
        "map": property_266
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114833003#",
        "title": "3 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 340000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Achavandra Muir, Dornoch",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.071918,
            "lat": 57.919178
        },
        "_place": 10.76,
        "_people": 25.16,
        "_property": 5,
        "_price": 144.97,
        "map": property_267
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116803295#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105566/116803295/105566_30604413_IMG_30_0000.jpeg"
        ],
        "price": [
            {
                "value": 1200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Longend Lane, Marden, Tonbridge",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.465543,
            "lat": 51.178699
        },
        "_place": 8.49,
        "_people": 26.08,
        "_property": 3,
        "_price": 132.03,
        "map": property_268
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116070176#",
        "title": "2 bedroom maisonette",
        "images": [
            "https://media.rightmove.co.uk/11k/10291/116070176/10291_GPS210350_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/116070176/10291_GPS210350_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/116070176/10291_GPS210350_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/116070176/10291_GPS210350_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/116070176/10291_GPS210350_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/116070176/10291_GPS210350_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/116070176/10291_GPS210350_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/116070176/10291_GPS210350_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/116070176/10291_GPS210350_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/116070176/10291_GPS210350_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/116070176/10291_GPS210350_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/116070176/10291_GPS210350_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 325000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Tudor Drive, Gidea Park, RM2",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.195297,
            "lat": 51.583206
        },
        "_place": 3.24,
        "_people": 33.09,
        "_property": 0,
        "_price": 159.38,
        "map": property_269
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116383505#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/32k/31193/116383505/31193_102927014518_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31193/116383505/31193_102927014518_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31193/116383505/31193_102927014518_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31193/116383505/31193_102927014518_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31193/116383505/31193_102927014518_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31193/116383505/31193_102927014518_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31193/116383505/31193_102927014518_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31193/116383505/31193_102927014518_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31193/116383505/31193_102927014518_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31193/116383505/31193_102927014518_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31193/116383505/31193_102927014518_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31193/116383505/31193_102927014518_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Woodland Drive, Southwell",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.95911,
            "lat": 53.08218
        },
        "_place": 0.17,
        "_people": 28.19,
        "_property": 3,
        "_price": 131.58,
        "map": property_270
    },
    {
        "url": "https://www.rightmove.co.uk/properties/102331061#",
        "title": "9 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/205k/204503/102331061/204503_S18818_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/205k/204503/102331061/204503_S18818_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/205k/204503/102331061/204503_S18818_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/205k/204503/102331061/204503_S18818_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/205k/204503/102331061/204503_S18818_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/205k/204503/102331061/204503_S18818_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/205k/204503/102331061/204503_S18818_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/205k/204503/102331061/204503_S18818_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/205k/204503/102331061/204503_S18818_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/205k/204503/102331061/204503_S18818_IMG_09_0001.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 9,
        "bathrooms": 2,
        "address": "North Road East,Plymouth,PL4 6AW",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.140881,
            "lat": 50.377106
        },
        "_place": 6.57,
        "_people": 21.73,
        "_property": 0,
        "_price": 173.48,
        "map": property_271
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108577418#",
        "title": "8 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34169/108577418/34169_YEO210219_IMG_29_0000.jpeg"
        ],
        "price": [
            {
                "value": 1195000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 8,
        "bathrooms": 4,
        "address": "Claycastle, Haselbury Plucknett, Crewkerne, TA18",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.746175,
            "lat": 50.893346
        },
        "_place": 0.35,
        "_people": 31.87,
        "_property": 2,
        "_price": 126.66,
        "map": property_272
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117627470#",
        "title": "2 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/24k/23252/117627470/23252_10620206_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23252/117627470/23252_10620206_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23252/117627470/23252_10620206_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23252/117627470/23252_10620206_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23252/117627470/23252_10620206_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23252/117627470/23252_10620206_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23252/117627470/23252_10620206_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23252/117627470/23252_10620206_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23252/117627470/23252_10620206_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23252/117627470/23252_10620206_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23252/117627470/23252_10620206_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23252/117627470/23252_10620206_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23252/117627470/23252_10620206_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23252/117627470/23252_10620206_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23252/117627470/23252_10620206_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 265000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Woodside, Coalbrookdale, Telford, Shropshire.",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.489434,
            "lat": 52.640625
        },
        "_place": 3.01,
        "_people": 18.24,
        "_property": 3,
        "_price": 136.62,
        "map": property_273
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110147024#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67557/110147024/67557_141463_IMG_26_0000.jpeg"
        ],
        "price": [
            {
                "value": 199500,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "135 Forest Road, Selkirk TD7 5DD",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.845438,
            "lat": 55.548915
        },
        "_place": 4.45,
        "_people": 26.73,
        "_property": 1,
        "_price": 181.05,
        "map": property_274
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111020840#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68839/111020840/68839_11044341_IMG_26_0000.jpeg"
        ],
        "price": [
            {
                "value": 1000000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Thorp Avenue, Morpeth, Northumberland",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.685891,
            "lat": 55.171528
        },
        "_place": 11.18,
        "_people": 30.36,
        "_property": 5,
        "_price": 111.78,
        "map": property_275
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115356827#",
        "title": "5 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5096/115356827/5096_YAR200121_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 395000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Low Lane, High Leven, Yarm, Stockton On Tees, TS15",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.298342,
            "lat": 54.508369
        },
        "_place": 6.66,
        "_people": 18.84,
        "_property": 3,
        "_price": 158.96,
        "map": property_276
    },
    {
        "url": "https://www.rightmove.co.uk/properties/70635796#",
        "title": "7 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_20_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_21_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_25_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_24_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_23_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_26_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_30_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_29_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_27_0000.jpg",
            "https://media.rightmove.co.uk/69k/68041/70635796/68041_MFL6173462_IMG_28_0000.jpg"
        ],
        "price": [
            {
                "value": 485000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": 5,
        "address": "Ardencraig House Apartments, High Craigmore, Rothesay, PA20 9EP",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.029149,
            "lat": 55.83796
        },
        "_place": 12,
        "_people": 32.11,
        "_property": 5,
        "_price": 200.94,
        "map": property_277
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113586923#",
        "title": "Plot",
        "images": [
            "https://media.rightmove.co.uk/75k/74409/113586923/74409_30959769_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 115000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Plot 27 at Bro'r Dderwen, Clynderwen",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.728092,
            "lat": 51.835678
        },
        "_place": 6.22,
        "_people": 27.97,
        "_property": 0,
        "_price": 195.49,
        "map": property_278
    },
    {
        "url": "https://www.rightmove.co.uk/properties/104613293#",
        "title": "2 bedroom retirement property",
        "images": [
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229325/104613293/229325_EPSOM_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 484500,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "6 Spinning Wheel Way, \r\nWallington, \r\nHackbridge, \r\nSM6 7GD",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.15573,
            "lat": 51.37898
        },
        "_place": 6.18,
        "_people": 22.17,
        "_property": 2,
        "_price": 185.53,
        "map": property_279
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117442079#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Priory Road, St. Olaves, Great Yarmouth",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.62435,
            "lat": 52.53474
        },
        "_place": 6.11,
        "_people": 33.3,
        "_property": 2,
        "_price": 97.39,
        "map": property_280
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112469414#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/70k/69486/112469414/69486_MLS210204_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/70k/69486/112469414/69486_MLS210204_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/70k/69486/112469414/69486_MLS210204_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/70k/69486/112469414/69486_MLS210204_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/70k/69486/112469414/69486_MLS210204_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/70k/69486/112469414/69486_MLS210204_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/70k/69486/112469414/69486_MLS210204_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/70k/69486/112469414/69486_MLS210204_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/70k/69486/112469414/69486_MLS210204_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/70k/69486/112469414/69486_MLS210204_IMG_09_0001.jpeg"
        ],
        "price": [
            {
                "value": 708950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Peel Lodge, Dean Street, Marlow, Buckinghamshire, SL7",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.77718,
            "lat": 51.574084
        },
        "_place": 8.47,
        "_people": 24.24,
        "_property": 4,
        "_price": 150,
        "map": property_281
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117714689#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9449/117714689/9449_LNT210234_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 360000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "West Point, 14 Marine Parade West, Lee-On-The-Solent, Hampshire, PO13",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.204326,
            "lat": 50.80262
        },
        "_place": 5.63,
        "_people": 27.48,
        "_property": 5,
        "_price": 172.48,
        "map": property_282
    },
    {
        "url": "https://www.rightmove.co.uk/properties/94924664#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_20_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_21_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_23_0000.jpg",
            "https://media.rightmove.co.uk/107k/106691/94924664/106691_101549001549_IMG_24_0000.jpg"
        ],
        "price": [
            {
                "value": 224950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "One Park West, Liverpool",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -2.99072,
            "lat": 53.40316
        },
        "_place": 10.96,
        "_people": 29.65,
        "_property": 4,
        "_price": 165.71,
        "map": property_283
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114712424#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/204k/203174/114712424/203174_1222004-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203174/114712424/203174_1222004-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203174/114712424/203174_1222004-1_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203174/114712424/203174_1222004-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203174/114712424/203174_1222004-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203174/114712424/203174_1222004-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203174/114712424/203174_1222004-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203174/114712424/203174_1222004-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203174/114712424/203174_1222004-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203174/114712424/203174_1222004-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203174/114712424/203174_1222004-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203174/114712424/203174_1222004-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203174/114712424/203174_1222004-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203174/114712424/203174_1222004-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203174/114712424/203174_1222004-1_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Tetchill Brook Road, Ellesmere, SY12",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.898089,
            "lat": 52.902383
        },
        "_place": 5.66,
        "_people": 23.11,
        "_property": 3,
        "_price": 176.34,
        "map": property_284
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111788753#",
        "title": "5 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41082/111788753/41082_4920920_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 245000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Gorsefield Close, Radcliffe, Manchester, Lancashire",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -2.329175,
            "lat": 53.564764
        },
        "_place": 2.82,
        "_people": 21.97,
        "_property": 3,
        "_price": 143.73,
        "map": property_285
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114111041#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228839/114111041/228839_EM-48686_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 270000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Beresford Crescent, Reddish",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.15969,
            "lat": 53.43813
        },
        "_place": 7.47,
        "_people": 21.15,
        "_property": 3,
        "_price": 121.44,
        "map": property_286
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115898372#",
        "title": "12 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/241k/240671/115898372/240671_barnguesthouse_IMG_26_0000.jpeg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 12,
        "bathrooms": null,
        "address": "YO62",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.91726,
            "lat": 54.30039
        },
        "_place": 10.11,
        "_people": 33.06,
        "_property": 5,
        "_price": 122.56,
        "map": property_287
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117171308#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/171k/170981/117171308/170981_AVE210275_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/171k/170981/117171308/170981_AVE210275_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/171k/170981/117171308/170981_AVE210275_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/171k/170981/117171308/170981_AVE210275_IMG_03_0000.jpeg"
        ],
        "price": [
            {
                "value": 110000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Castle Reach, Kingsacre Drive, Burford, Tenbury Wells, WR15",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.56885,
            "lat": 52.30524
        },
        "_place": 8.02,
        "_people": 26.35,
        "_property": 4,
        "_price": 108.03,
        "map": property_288
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115531571#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74064/115531571/74064_HSS189165_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 270000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Grange Park Road, Manchester, M9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.198679,
            "lat": 53.52504
        },
        "_place": 8.92,
        "_people": 20.24,
        "_property": 2,
        "_price": 165.75,
        "map": property_289
    },
    {
        "url": "https://www.rightmove.co.uk/properties/74465305#",
        "title": "4 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/71k/70464/74465305/70464_29141834_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/71k/70464/74465305/70464_29141834_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/71k/70464/74465305/70464_29141834_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/71k/70464/74465305/70464_29141834_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/71k/70464/74465305/70464_29141834_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/71k/70464/74465305/70464_29141834_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/71k/70464/74465305/70464_29141834_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/71k/70464/74465305/70464_29141834_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/71k/70464/74465305/70464_29141834_IMG_09_0000.jpg"
        ],
        "price": [
            {
                "value": 445000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Station Bank, Ryton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.776465,
            "lat": 54.971324
        },
        "_place": 7.55,
        "_people": 31.24,
        "_property": 3,
        "_price": 119.49,
        "map": property_290
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111563927#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/127k/126928/111563927/126928_1640_IMG_35_0000.jpeg"
        ],
        "price": [
            {
                "value": 1042500,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Carriage House Goodwin Street, London, N4",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.107789,
            "lat": 51.564171
        },
        "_place": 3.71,
        "_people": 20.92,
        "_property": 1,
        "_price": 147.13,
        "map": property_291
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82148847#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/82148847/74409_31043868_IMG_27_0000.jpeg"
        ],
        "price": [
            {
                "value": 275000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Springfield Park, NARBERTH",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.738029,
            "lat": 51.801239
        },
        "_place": 2.79,
        "_people": 33.67,
        "_property": 1,
        "_price": 155.67,
        "map": property_292
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115050173#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/115050173/140027_31038328_IMG_31_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Abberwick Walk, Greenside, Newcastle Upon Tyne",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.652361,
            "lat": 55.021396
        },
        "_place": 0.86,
        "_people": 32.97,
        "_property": 4,
        "_price": 95.48,
        "map": property_293
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115881119#",
        "title": "2 bedroom cottage",
        "images": [
            "https://media.rightmove.co.uk/94k/93913/115881119/93913_UNSWJ01-t-4066_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115881119/93913_UNSWJ01-t-4066_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115881119/93913_UNSWJ01-t-4066_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115881119/93913_UNSWJ01-t-4066_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115881119/93913_UNSWJ01-t-4066_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115881119/93913_UNSWJ01-t-4066_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115881119/93913_UNSWJ01-t-4066_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115881119/93913_UNSWJ01-t-4066_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115881119/93913_UNSWJ01-t-4066_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115881119/93913_UNSWJ01-t-4066_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115881119/93913_UNSWJ01-t-4066_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115881119/93913_UNSWJ01-t-4066_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115881119/93913_UNSWJ01-t-4066_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115881119/93913_UNSWJ01-t-4066_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 270000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Bruichladdich, Isle of Islay, PA49",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -6.359806,
            "lat": 55.781677
        },
        "_place": 0.55,
        "_people": 34.89,
        "_property": 2,
        "_price": 164.93,
        "map": property_294
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116570885#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/30k/29082/116570885/29082_RHP210270_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29082/116570885/29082_RHP210270_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29082/116570885/29082_RHP210270_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29082/116570885/29082_RHP210270_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29082/116570885/29082_RHP210270_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29082/116570885/29082_RHP210270_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29082/116570885/29082_RHP210270_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29082/116570885/29082_RHP210270_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29082/116570885/29082_RHP210270_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29082/116570885/29082_RHP210270_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29082/116570885/29082_RHP210270_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29082/116570885/29082_RHP210270_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Laurel Road, Woodland Rise, Hexham, Northumberland, NE46",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.072207,
            "lat": 54.968007
        },
        "_place": 0.14,
        "_people": 24.61,
        "_property": 2,
        "_price": 135.85,
        "map": property_295
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116590454#",
        "title": "2 bedroom retirement property",
        "images": [
            "https://media.rightmove.co.uk/78k/77282/116590454/77282_31117019_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77282/116590454/77282_31117019_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77282/116590454/77282_31117019_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77282/116590454/77282_31117019_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77282/116590454/77282_31117019_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77282/116590454/77282_31117019_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77282/116590454/77282_31117019_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77282/116590454/77282_31117019_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77282/116590454/77282_31117019_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77282/116590454/77282_31117019_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77282/116590454/77282_31117019_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77282/116590454/77282_31117019_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 480000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Palace Road, Ripon",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.529035,
            "lat": 54.14553
        },
        "_place": 4.86,
        "_people": 21.59,
        "_property": 4,
        "_price": 161.01,
        "map": property_296
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111191519#",
        "title": "2 bedroom cottage",
        "images": [
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92707/111191519/92707_PDP1005407_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 199950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Foster Clough, Heights Road, Mytholmroyd, Hebden Bridge",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.97373,
            "lat": 53.74028
        },
        "_place": 6.69,
        "_people": 18.15,
        "_property": 1,
        "_price": 131.45,
        "map": property_297
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81356226#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/2k/1702/81356226/1702_WVL305462_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1702/81356226/1702_WVL305462_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1702/81356226/1702_WVL305462_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1702/81356226/1702_WVL305462_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1702/81356226/1702_WVL305462_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1702/81356226/1702_WVL305462_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1702/81356226/1702_WVL305462_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1702/81356226/1702_WVL305462_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1702/81356226/1702_WVL305462_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1702/81356226/1702_WVL305462_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1702/81356226/1702_WVL305462_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1702/81356226/1702_WVL305462_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 310000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Topham Avenue, Worcester",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.17698,
            "lat": 52.19697
        },
        "_place": 3.62,
        "_people": 35.93,
        "_property": 0,
        "_price": 134.74,
        "map": property_298
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109471811#",
        "title": "8 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_18_0001.jpeg"
        ],
        "price": [
            {
                "value": 449000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 8,
        "bathrooms": 2,
        "address": "Rhosgoch, Anglesey, LL66",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.400525,
            "lat": 53.373844
        },
        "_place": 10.65,
        "_people": 19.62,
        "_property": 0,
        "_price": 140.52,
        "map": property_299
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112096841#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_00_0002.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_01_0002.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5935/112096841/5935_6729_DIMO_IMG_30_0000.jpeg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "The Avenue, Alverstoke",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.149325,
            "lat": 50.790984
        },
        "_place": 9.19,
        "_people": 20.74,
        "_property": 2,
        "_price": 162.96,
        "map": property_300
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108910130#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9035/108910130/9035_3653e_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 775000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Eltham Palace Road, London, SE9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.0322,
            "lat": 51.44979
        },
        "_place": 9.06,
        "_people": 19.82,
        "_property": 3,
        "_price": 169.58,
        "map": property_301
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117009230#",
        "title": "3 bedroom detached villa",
        "images": [
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/168k/167159/117009230/167159_2210_IMG_29_0000.jpeg"
        ],
        "price": [
            {
                "value": 189995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "11 Millbarr Grove, Barrmill",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.59932,
            "lat": 55.72954
        },
        "_place": 3.19,
        "_people": 21.93,
        "_property": 3,
        "_price": 174.21,
        "map": property_302
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108914480#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/143k/142202/108914480/142202_lawersplot_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142202/108914480/142202_lawersplot_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142202/108914480/142202_lawersplot_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142202/108914480/142202_lawersplot_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142202/108914480/142202_lawersplot_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142202/108914480/142202_lawersplot_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142202/108914480/142202_lawersplot_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142202/108914480/142202_lawersplot_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142202/108914480/142202_lawersplot_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Lawers Plot, Perthshire, PH152PA",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.14677,
            "lat": 56.53113
        },
        "_place": 2.78,
        "_people": 30.49,
        "_property": 4,
        "_price": 125.52,
        "map": property_303
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117621743#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/57k/56629/117621743/56629_103561003857_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56629/117621743/56629_103561003857_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56629/117621743/56629_103561003857_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56629/117621743/56629_103561003857_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56629/117621743/56629_103561003857_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56629/117621743/56629_103561003857_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56629/117621743/56629_103561003857_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56629/117621743/56629_103561003857_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56629/117621743/56629_103561003857_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56629/117621743/56629_103561003857_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56629/117621743/56629_103561003857_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56629/117621743/56629_103561003857_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56629/117621743/56629_103561003857_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56629/117621743/56629_103561003857_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56629/117621743/56629_103561003857_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 625000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Joy Lane, Chichester, West Sussex",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.784733,
            "lat": 50.838664
        },
        "_place": 0.79,
        "_people": 22.05,
        "_property": 5,
        "_price": 141.58,
        "map": property_304
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117561248#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_45_0000.jpeg",
            "https://media.rightmove.co.uk/147k/146762/117561248/146762_185319_IMG_46_0000.jpeg"
        ],
        "price": [
            {
                "value": 250000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Cul Na Sithe, Stirling Street, Blackford, PH4 1QA",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.778698,
            "lat": 56.261011
        },
        "_place": 0.58,
        "_people": 21.04,
        "_property": 0,
        "_price": 142.93,
        "map": property_305
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113793380#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/113793380/46299_BAR210424_IMG_17_0001.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Ivy Bank Close, Ingbirchworth, Penistone, Sheffield, S36",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.670863,
            "lat": 53.553112
        },
        "_place": 9.61,
        "_people": 27.2,
        "_property": 3,
        "_price": 175.03,
        "map": property_306
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114393020#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56305/114393020/56305_31000809_IMG_30_0000.jpeg"
        ],
        "price": [
            {
                "value": 925000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Brede Hill, Brede",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.597353,
            "lat": 50.933341
        },
        "_place": 10.01,
        "_people": 27.11,
        "_property": 2,
        "_price": 176.23,
        "map": property_307
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114741890#",
        "title": "5 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29732/114741890/29732_601594_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 650000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "Somervell Road, Harrow, Middlesex, HA2 8TZ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.370205,
            "lat": 51.556461
        },
        "_place": 10.21,
        "_people": 28.04,
        "_property": 2,
        "_price": 125,
        "map": property_308
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109708724#",
        "title": "5 bedroom barn conversion",
        "images": [
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/1k/327/109708724/327_POD210110_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 580000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "Eachwick, Northumberland, Tyne and Wear, England, NE18",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.826481,
            "lat": 55.022385
        },
        "_place": 10.96,
        "_people": 27.54,
        "_property": 1,
        "_price": 102.05,
        "map": property_309
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115772579#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125461/115772579/125461_11048252_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Oakley Road, Luton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.456484,
            "lat": 51.893646
        },
        "_place": 11.95,
        "_people": 35.79,
        "_property": 5,
        "_price": 160.14,
        "map": property_310
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82459554#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/159k/158957/82459554/158957_HYS102559_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/159k/158957/82459554/158957_HYS102559_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/159k/158957/82459554/158957_HYS102559_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/159k/158957/82459554/158957_HYS102559_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/159k/158957/82459554/158957_HYS102559_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/159k/158957/82459554/158957_HYS102559_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/159k/158957/82459554/158957_HYS102559_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/159k/158957/82459554/158957_HYS102559_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/159k/158957/82459554/158957_HYS102559_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/159k/158957/82459554/158957_HYS102559_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/159k/158957/82459554/158957_HYS102559_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/159k/158957/82459554/158957_HYS102559_IMG_02_0000.jpeg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Verulam Road, Greenford",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.36531,
            "lat": 51.53001
        },
        "_place": 2.97,
        "_people": 34.04,
        "_property": 3,
        "_price": 113.76,
        "map": property_311
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116027936#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/167k/166331/116027936/166331_1187617_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166331/116027936/166331_1187617_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166331/116027936/166331_1187617_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166331/116027936/166331_1187617_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166331/116027936/166331_1187617_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166331/116027936/166331_1187617_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166331/116027936/166331_1187617_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166331/116027936/166331_1187617_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166331/116027936/166331_1187617_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166331/116027936/166331_1187617_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166331/116027936/166331_1187617_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166331/116027936/166331_1187617_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166331/116027936/166331_1187617_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Barn Rise, Wembley Park, Wembley, HA9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.278886,
            "lat": 51.569169
        },
        "_place": 6.67,
        "_people": 33.1,
        "_property": 5,
        "_price": 98.06,
        "map": property_312
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109231592#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_21_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_20_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_23_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_22_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/87k/86381/109231592/86381_100600004470_IMG_13_0001.jpeg"
        ],
        "price": [
            {
                "value": 499999,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Eastwoods Road , Hinckley ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.356687,
            "lat": 52.547972
        },
        "_place": 1.98,
        "_people": 19.32,
        "_property": 4,
        "_price": 146.98,
        "map": property_313
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117204419#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3178/117204419/3178_337790_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 520000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "20 Riverbank Road, Kendal, Cumbria, LA9 5JS",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.751594,
            "lat": 54.310417
        },
        "_place": 9.31,
        "_people": 24.99,
        "_property": 3,
        "_price": 94.7,
        "map": property_314
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116749202#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/204k/203807/116749202/203807_264615_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203807/116749202/203807_264615_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203807/116749202/203807_264615_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203807/116749202/203807_264615_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203807/116749202/203807_264615_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203807/116749202/203807_264615_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203807/116749202/203807_264615_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203807/116749202/203807_264615_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203807/116749202/203807_264615_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203807/116749202/203807_264615_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203807/116749202/203807_264615_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203807/116749202/203807_264615_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 495000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Horsham Road, Crawley",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.205876,
            "lat": 51.082274
        },
        "_place": 11.94,
        "_people": 28.71,
        "_property": 5,
        "_price": 99.7,
        "map": property_315
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114879197#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Cooden Drive, Bexhill-On-Sea",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.43052,
            "lat": 50.83324
        },
        "_place": 5.13,
        "_people": 19.45,
        "_property": 1,
        "_price": 94.17,
        "map": property_316
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116795183#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/116795183/94808_31125799_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 325000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Cromdale",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.527877,
            "lat": 57.344779
        },
        "_place": 3.73,
        "_people": 33.49,
        "_property": 5,
        "_price": 155.39,
        "map": property_317
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117526139#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/189k/188387/117526139/188387_11258054_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/189k/188387/117526139/188387_11258054_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/189k/188387/117526139/188387_11258054_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/189k/188387/117526139/188387_11258054_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/189k/188387/117526139/188387_11258054_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/189k/188387/117526139/188387_11258054_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/189k/188387/117526139/188387_11258054_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/189k/188387/117526139/188387_11258054_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/189k/188387/117526139/188387_11258054_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/189k/188387/117526139/188387_11258054_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/189k/188387/117526139/188387_11258054_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/189k/188387/117526139/188387_11258054_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/189k/188387/117526139/188387_11258054_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 725000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Multiple Buildings, Witham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.585325,
            "lat": 52.76365
        },
        "_place": 7.03,
        "_people": 31.09,
        "_property": 4,
        "_price": 157.11,
        "map": property_318
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117152000#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/76k/75548/117152000/75548_101276032881_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75548/117152000/75548_101276032881_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75548/117152000/75548_101276032881_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75548/117152000/75548_101276032881_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75548/117152000/75548_101276032881_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75548/117152000/75548_101276032881_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75548/117152000/75548_101276032881_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75548/117152000/75548_101276032881_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75548/117152000/75548_101276032881_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75548/117152000/75548_101276032881_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 395000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Steyne Road, Bembridge",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.083852,
            "lat": 50.685671
        },
        "_place": 4.7,
        "_people": 28.09,
        "_property": 1,
        "_price": 176.93,
        "map": property_319
    },
    {
        "url": "https://www.rightmove.co.uk/properties/86300504#",
        "title": "1 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/63k/62080/86300504/62080_LON190574_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/63k/62080/86300504/62080_LON190574_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/63k/62080/86300504/62080_LON190574_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/63k/62080/86300504/62080_LON190574_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/63k/62080/86300504/62080_LON190574_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/63k/62080/86300504/62080_LON190574_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/63k/62080/86300504/62080_LON190574_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/63k/62080/86300504/62080_LON190574_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/63k/62080/86300504/62080_LON190574_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/63k/62080/86300504/62080_LON190574_IMG_20_0000.jpg"
        ],
        "price": [
            {
                "value": 995000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Floral Street, London, WC2E",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.124891,
            "lat": 51.512074
        },
        "_place": 11.96,
        "_people": 28.89,
        "_property": 0,
        "_price": 170.37,
        "map": property_320
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113863430#",
        "title": "2 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 240000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Lagavulin, Isle of Islay, PA42",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -6.125073,
            "lat": 55.636096
        },
        "_place": 7.73,
        "_people": 28.6,
        "_property": 2,
        "_price": 147.83,
        "map": property_321
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117743006#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/49k/48638/117743006/48638_TRS210747_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48638/117743006/48638_TRS210747_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48638/117743006/48638_TRS210747_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48638/117743006/48638_TRS210747_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48638/117743006/48638_TRS210747_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48638/117743006/48638_TRS210747_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48638/117743006/48638_TRS210747_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 825000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Carbis Bay, St Ives, TR26",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.47488,
            "lat": 50.195734
        },
        "_place": 11.11,
        "_people": 35.19,
        "_property": 0,
        "_price": 103.78,
        "map": property_322
    },
    {
        "url": "https://www.rightmove.co.uk/properties/76582494#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/2k/1910/76582494/1910_BWB111681_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1910/76582494/1910_BWB111681_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1910/76582494/1910_BWB111681_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1910/76582494/1910_BWB111681_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1910/76582494/1910_BWB111681_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/2k/1910/76582494/1910_BWB111681_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1910/76582494/1910_BWB111681_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1910/76582494/1910_BWB111681_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1910/76582494/1910_BWB111681_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1910/76582494/1910_BWB111681_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1910/76582494/1910_BWB111681_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1910/76582494/1910_BWB111681_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1910/76582494/1910_BWB111681_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1910/76582494/1910_BWB111681_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 340000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Waterbelly Lane, Sutterton, Boston",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.09245,
            "lat": 52.88875
        },
        "_place": 1.95,
        "_people": 27.04,
        "_property": 4,
        "_price": 106.19,
        "map": property_323
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82858698#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/70k/69998/82858698/69998_ZK1103538_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/82858698/69998_ZK1103538_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/82858698/69998_ZK1103538_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/82858698/69998_ZK1103538_IMG_03_0000.jpeg"
        ],
        "price": [
            {
                "value": 700000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Fairview Road, Halesworth",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": 1.5148,
            "lat": 52.35043
        },
        "_place": 6.26,
        "_people": 30.05,
        "_property": 5,
        "_price": 167.96,
        "map": property_324
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116333147#",
        "title": "2 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/213k/212768/116333147/212768_RS0786_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212768/116333147/212768_RS0786_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212768/116333147/212768_RS0786_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212768/116333147/212768_RS0786_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212768/116333147/212768_RS0786_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212768/116333147/212768_RS0786_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212768/116333147/212768_RS0786_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212768/116333147/212768_RS0786_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212768/116333147/212768_RS0786_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212768/116333147/212768_RS0786_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212768/116333147/212768_RS0786_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212768/116333147/212768_RS0786_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212768/116333147/212768_RS0786_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Trefleur Close, Boscastle",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.698169,
            "lat": 50.684629
        },
        "_place": 7.5,
        "_people": 24.92,
        "_property": 4,
        "_price": 199.4,
        "map": property_325
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115205183#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80404/115205183/80404_NHO200116_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 995000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Lawn Manor, Barnet Lane, Elstree, Hertfordshire, WD6",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.290312,
            "lat": 51.6429
        },
        "_place": 6.29,
        "_people": 35.45,
        "_property": 3,
        "_price": 196.18,
        "map": property_326
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110844263#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_46_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_48_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_45_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_47_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/110844263/191906_30824234_IMG_49_0000.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Meidrim, Carmarthen",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.479775,
            "lat": 51.861156
        },
        "_place": 7.49,
        "_people": 31.65,
        "_property": 3,
        "_price": 193.51,
        "map": property_327
    },
    {
        "url": "https://www.rightmove.co.uk/properties/94510654#",
        "title": "4 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/244k/243410/94510654/243410_4_6_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/244k/243410/94510654/243410_4_6_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/244k/243410/94510654/243410_4_6_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/244k/243410/94510654/243410_4_6_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/244k/243410/94510654/243410_4_6_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/244k/243410/94510654/243410_4_6_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/244k/243410/94510654/243410_4_6_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/244k/243410/94510654/243410_4_6_IMG_08_0000.jpg"
        ],
        "price": [
            {
                "value": 460000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Henley Road,\r\nIpswich,\r\nSuffolk,\r\nIP1 6RL",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": 1.14972,
            "lat": 52.08022
        },
        "_place": 1.12,
        "_people": 26.56,
        "_property": 4,
        "_price": 168.57,
        "map": property_328
    },
    {
        "url": "https://www.rightmove.co.uk/properties/57640170#",
        "title": "3 bedroom semi-detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_29_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_30_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_31_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_32_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_27_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_33_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_23_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_24_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_25_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_28_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/103k/102578/57640170/102578_102593001166_IMG_34_0000.jpg"
        ],
        "price": [
            {
                "value": 175000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Dunaros Gardens, Salen",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.94611,
            "lat": 56.5145
        },
        "_place": 2.2,
        "_people": 35.36,
        "_property": 4,
        "_price": 133.66,
        "map": property_329
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116795942#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2987/116795942/2987_11162559_IMG_34_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Carr Cottage, Carr House Lane, Wrightington, WN6 9SH",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.699407,
            "lat": 53.620892
        },
        "_place": 7.8,
        "_people": 28.98,
        "_property": 0,
        "_price": 142.31,
        "map": property_330
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115349855#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55692/115349855/55692_10992620_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 865000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Salisbury Road, Harrow",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.343078,
            "lat": 51.584923
        },
        "_place": 7.64,
        "_people": 21.82,
        "_property": 2,
        "_price": 174.17,
        "map": property_331
    },
    {
        "url": "https://www.rightmove.co.uk/properties/106714316#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_01_0027.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_02_0030.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_03_0032.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_04_0030.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_05_0030.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_06_0030.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_07_0032.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_08_0034.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_09_0034.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_10_0033.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_11_0032.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_12_0032.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_13_0032.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_14_0031.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_15_0033.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_16_0033.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_17_0033.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_18_0031.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_19_0033.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_20_0033.jpg",
            "https://media.rightmove.co.uk/228k/227684/106714316/227684_8080010000012_5_30_101178_IMG_21_0029.jpg"
        ],
        "price": [
            {
                "value": 359995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "Flatts Lane, \r\nNormanby, Middlesbrough, \r\nTS6 0NN",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.14966,
            "lat": 54.54311
        },
        "_place": 9.64,
        "_people": 19.55,
        "_property": 3,
        "_price": 179.2,
        "map": property_332
    },
    {
        "url": "https://www.rightmove.co.uk/properties/78233571#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_12_0002.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_13_0002.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_14_0002.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_15_0002.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/8k/7558/78233571/7558_FIN210043_IMG_20_0001.jpeg"
        ],
        "price": [
            {
                "value": 1120000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Windermere Avenue, Finchley, N3",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.193561,
            "lat": 51.593409
        },
        "_place": 4.91,
        "_people": 25.06,
        "_property": 4,
        "_price": 176.21,
        "map": property_333
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112071776#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/38k/37281/112071776/37281_30888263_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37281/112071776/37281_30888263_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37281/112071776/37281_30888263_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37281/112071776/37281_30888263_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37281/112071776/37281_30888263_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37281/112071776/37281_30888263_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37281/112071776/37281_30888263_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37281/112071776/37281_30888263_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37281/112071776/37281_30888263_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37281/112071776/37281_30888263_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 395000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "De Havilland House, 12 North Road, Stevenage, Hertfordshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.209116,
            "lat": 51.923384
        },
        "_place": 7.65,
        "_people": 27.33,
        "_property": 3,
        "_price": 160.8,
        "map": property_334
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116862266#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/1k/320/116862266/320_SBB210572_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/1k/320/116862266/320_SBB210572_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/1k/320/116862266/320_SBB210572_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/1k/320/116862266/320_SBB210572_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/1k/320/116862266/320_SBB210572_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/1k/320/116862266/320_SBB210572_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/1k/320/116862266/320_SBB210572_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/1k/320/116862266/320_SBB210572_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/1k/320/116862266/320_SBB210572_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/1k/320/116862266/320_SBB210572_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/1k/320/116862266/320_SBB210572_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/1k/320/116862266/320_SBB210572_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/1k/320/116862266/320_SBB210572_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/1k/320/116862266/320_SBB210572_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/1k/320/116862266/320_SBB210572_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Pavilion Drive, Kemsley, Sittingbourne, ME10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.736765,
            "lat": 51.361703
        },
        "_place": 1.13,
        "_people": 30.31,
        "_property": 2,
        "_price": 127.39,
        "map": property_335
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113412776#",
        "title": "2 bedroom cottage",
        "images": [
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34904/113412776/34904_1390_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Broughton, Stockbridge, Hampshire SO20",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.562744,
            "lat": 51.096667
        },
        "_place": 1.93,
        "_people": 28.5,
        "_property": 1,
        "_price": 157.3,
        "map": property_336
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114190979#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/45k/44597/114190979/44597_TRU210064_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44597/114190979/44597_TRU210064_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44597/114190979/44597_TRU210064_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44597/114190979/44597_TRU210064_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44597/114190979/44597_TRU210064_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44597/114190979/44597_TRU210064_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44597/114190979/44597_TRU210064_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44597/114190979/44597_TRU210064_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44597/114190979/44597_TRU210064_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44597/114190979/44597_TRU210064_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44597/114190979/44597_TRU210064_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 589950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "An-Manerji, Lezerea, Porkellis, Helston, TR13",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.244697,
            "lat": 50.1545
        },
        "_place": 10.9,
        "_people": 21.56,
        "_property": 5,
        "_price": 105.54,
        "map": property_337
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82841844#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/37k/36287/82841844/36287_100243003237_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 435000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Idalilian, Burley Gate",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.580414,
            "lat": 52.122638
        },
        "_place": 4.36,
        "_people": 24.44,
        "_property": 1,
        "_price": 166.35,
        "map": property_338
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116282732#",
        "title": "2 bedroom semi-detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/33k/32585/116282732/32585_RHM101845_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32585/116282732/32585_RHM101845_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32585/116282732/32585_RHM101845_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32585/116282732/32585_RHM101845_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32585/116282732/32585_RHM101845_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32585/116282732/32585_RHM101845_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32585/116282732/32585_RHM101845_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32585/116282732/32585_RHM101845_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32585/116282732/32585_RHM101845_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32585/116282732/32585_RHM101845_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32585/116282732/32585_RHM101845_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32585/116282732/32585_RHM101845_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32585/116282732/32585_RHM101845_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32585/116282732/32585_RHM101845_IMG_01_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Briscoe Road, Rainham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.21129,
            "lat": 51.52811
        },
        "_place": 10.05,
        "_people": 28.79,
        "_property": 2,
        "_price": 117.25,
        "map": property_339
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111706022#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111319/111706022/111319_30870829_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 480000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Durham Road, Gateshead, Tyne and Wear",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.599283,
            "lat": 54.94289
        },
        "_place": 4.82,
        "_people": 34.11,
        "_property": 3,
        "_price": 190.54,
        "map": property_340
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116949278#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/130k/129406/116949278/129406_:QT294335_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129406/116949278/129406_:QT294335_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129406/116949278/129406_:QT294335_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129406/116949278/129406_:QT294335_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129406/116949278/129406_:QT294335_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129406/116949278/129406_:QT294335_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129406/116949278/129406_:QT294335_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129406/116949278/129406_:QT294335_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129406/116949278/129406_:QT294335_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129406/116949278/129406_:QT294335_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129406/116949278/129406_:QT294335_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/130k/129406/116949278/129406_:QT294335_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 275000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Cairn Slowne, Aviemore, PH22",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.818656,
            "lat": 57.196954
        },
        "_place": 4.35,
        "_people": 33.44,
        "_property": 4,
        "_price": 90.82,
        "map": property_341
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107992901#",
        "title": "4 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/65k/64145/107992901/64145_S64207_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64145/107992901/64145_S64207_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64145/107992901/64145_S64207_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64145/107992901/64145_S64207_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64145/107992901/64145_S64207_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64145/107992901/64145_S64207_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64145/107992901/64145_S64207_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64145/107992901/64145_S64207_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64145/107992901/64145_S64207_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64145/107992901/64145_S64207_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64145/107992901/64145_S64207_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Cantley Gardens, Newbury Park, IG2",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.07949,
            "lat": 51.57504
        },
        "_place": 3.26,
        "_people": 33.16,
        "_property": 5,
        "_price": 116.28,
        "map": property_342
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115542161#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/9k/8652/115542161/8652_MAI210009_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8652/115542161/8652_MAI210009_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8652/115542161/8652_MAI210009_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8652/115542161/8652_MAI210009_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8652/115542161/8652_MAI210009_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8652/115542161/8652_MAI210009_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8652/115542161/8652_MAI210009_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8652/115542161/8652_MAI210009_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8652/115542161/8652_MAI210009_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8652/115542161/8652_MAI210009_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8652/115542161/8652_MAI210009_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8652/115542161/8652_MAI210009_IMG_11_0001.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Lucas Road, Snodland, ME6",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.43007,
            "lat": 51.325863
        },
        "_place": 9.3,
        "_people": 23.25,
        "_property": 3,
        "_price": 110.75,
        "map": property_344
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117357188#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/117357188/91289_31153129_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 360000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 1,
        "address": "Lon Ganol, Llandegfan, Anglesey",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.138949,
            "lat": 53.243572
        },
        "_place": 8.33,
        "_people": 34.3,
        "_property": 1,
        "_price": 99.38,
        "map": property_345
    },
    {
        "url": "https://www.rightmove.co.uk/properties/63218613#",
        "title": "Property",
        "images": [
            "https://media.rightmove.co.uk/50k/49759/63218613/49759_9393963_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/50k/49759/63218613/49759_9393963_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/50k/49759/63218613/49759_9393963_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/50k/49759/63218613/49759_9393963_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/50k/49759/63218613/49759_9393963_IMG_05_0000.jpg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "High Street, Broseley",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -2.481262,
            "lat": 52.612312
        },
        "_place": 1.26,
        "_people": 31.58,
        "_property": 3,
        "_price": 116.74,
        "map": property_346
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115162013#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/184k/183794/115162013/183794_SUR210023_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183794/115162013/183794_SUR210023_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183794/115162013/183794_SUR210023_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183794/115162013/183794_SUR210023_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183794/115162013/183794_SUR210023_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183794/115162013/183794_SUR210023_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183794/115162013/183794_SUR210023_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183794/115162013/183794_SUR210023_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183794/115162013/183794_SUR210023_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183794/115162013/183794_SUR210023_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183794/115162013/183794_SUR210023_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183794/115162013/183794_SUR210023_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183794/115162013/183794_SUR210023_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183794/115162013/183794_SUR210023_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 1175000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Riverside Villas, Portsmouth Road, Long Ditton, Surbiton, KT6",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.318833,
            "lat": 51.391737
        },
        "_place": 10.6,
        "_people": 28.76,
        "_property": 5,
        "_price": 119.54,
        "map": property_347
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109291352#",
        "title": "3 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Llanbedrgoch, Anglesey, LL76",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.258121,
            "lat": 53.298454
        },
        "_place": 3.62,
        "_people": 20.28,
        "_property": 4,
        "_price": 164.36,
        "map": property_348
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115825961#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/105k/104422/115825961/104422_LOW106085_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104422/115825961/104422_LOW106085_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104422/115825961/104422_LOW106085_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104422/115825961/104422_LOW106085_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104422/115825961/104422_LOW106085_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104422/115825961/104422_LOW106085_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104422/115825961/104422_LOW106085_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104422/115825961/104422_LOW106085_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104422/115825961/104422_LOW106085_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104422/115825961/104422_LOW106085_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104422/115825961/104422_LOW106085_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104422/115825961/104422_LOW106085_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104422/115825961/104422_LOW106085_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104422/115825961/104422_LOW106085_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Irex Road, LOWESTOFT",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.73134,
            "lat": 52.45181
        },
        "_place": 11.41,
        "_people": 29.61,
        "_property": 3,
        "_price": 124.67,
        "map": property_349
    },
    {
        "url": "https://www.rightmove.co.uk/properties/94633093#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/218k/217814/94633093/217814_4_44_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/218k/217814/94633093/217814_4_44_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/218k/217814/94633093/217814_4_44_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/218k/217814/94633093/217814_4_44_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/218k/217814/94633093/217814_4_44_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/218k/217814/94633093/217814_4_44_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/218k/217814/94633093/217814_4_44_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/218k/217814/94633093/217814_4_44_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/218k/217814/94633093/217814_4_44_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/218k/217814/94633093/217814_4_44_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/218k/217814/94633093/217814_4_44_IMG_11_0000.jpg"
        ],
        "price": [
            {
                "value": 530000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Station Road,\r\nLower Stondon,\r\nSG16 6JJ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.31187,
            "lat": 52.00655
        },
        "_place": 0.35,
        "_people": 29.9,
        "_property": 2,
        "_price": 186.32,
        "map": property_350
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114185093#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/2k/1808/114185093/1808_HWT104875_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1808/114185093/1808_HWT104875_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1808/114185093/1808_HWT104875_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1808/114185093/1808_HWT104875_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1808/114185093/1808_HWT104875_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1808/114185093/1808_HWT104875_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1808/114185093/1808_HWT104875_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1808/114185093/1808_HWT104875_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1808/114185093/1808_HWT104875_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1808/114185093/1808_HWT104875_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1808/114185093/1808_HWT104875_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1808/114185093/1808_HWT104875_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1808/114185093/1808_HWT104875_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1808/114185093/1808_HWT104875_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Cozens Grove, Shrivenham, Swindon",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.66864,
            "lat": 51.59497
        },
        "_place": 9.54,
        "_people": 30.26,
        "_property": 2,
        "_price": 100.65,
        "map": property_351
    },
    {
        "url": "https://www.rightmove.co.uk/properties/75208077#",
        "title": "Detached house",
        "images": [
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_27_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_25_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_26_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_28_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_29_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_30_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_31_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_32_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_33_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_34_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_35_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_36_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_37_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_38_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_39_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_40_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_41_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_42_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_43_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_44_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_45_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_46_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_47_0000.jpg",
            "https://media.rightmove.co.uk/27k/26208/75208077/26208_STR200148_IMG_48_0000.jpg"
        ],
        "price": [
            {
                "value": 835000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Hawksland Hall, Greenrig Road, Hawksland, Lesmahagow, Lanark, ML11",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.83273,
            "lat": 55.645708
        },
        "_place": 10.73,
        "_people": 30.52,
        "_property": 1,
        "_price": 114.2,
        "map": property_352
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113311295#",
        "title": "2 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/5k/4980/113311295/4980_OLN210438_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4980/113311295/4980_OLN210438_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4980/113311295/4980_OLN210438_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4980/113311295/4980_OLN210438_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4980/113311295/4980_OLN210438_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4980/113311295/4980_OLN210438_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4980/113311295/4980_OLN210438_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4980/113311295/4980_OLN210438_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4980/113311295/4980_OLN210438_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4980/113311295/4980_OLN210438_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4980/113311295/4980_OLN210438_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4980/113311295/4980_OLN210438_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4980/113311295/4980_OLN210438_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4980/113311295/4980_OLN210438_IMG_13_0001.jpeg"
        ],
        "price": [
            {
                "value": 140000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Sandstone Road East, Old Swan, Liverpool, Merseyside, L13",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.917726,
            "lat": 53.418053
        },
        "_place": 1.77,
        "_people": 31.09,
        "_property": 3,
        "_price": 122.02,
        "map": property_353
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114512696#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89204/114512696/89204_11126637_IMG_02_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Burton Old Road, Streethay, Lichfield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.793337,
            "lat": 52.689613
        },
        "_place": 8.82,
        "_people": 27.01,
        "_property": 4,
        "_price": 189.22,
        "map": property_354
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111339425#",
        "title": "1 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/78k/77043/111339425/77043_246148_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77043/111339425/77043_246148_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77043/111339425/77043_246148_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77043/111339425/77043_246148_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77043/111339425/77043_246148_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77043/111339425/77043_246148_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77043/111339425/77043_246148_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77043/111339425/77043_246148_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "The Wynding , Chathill,Beadnell",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.637763,
            "lat": 55.556325
        },
        "_place": 10.15,
        "_people": 34.07,
        "_property": 4,
        "_price": 181.26,
        "map": property_355
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116229788#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55509/116229788/55509_101385008586_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 240000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Victoria Road, Old Town, Swindon",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.77712,
            "lat": 51.55324
        },
        "_place": 9.02,
        "_people": 22.02,
        "_property": 4,
        "_price": 124.19,
        "map": property_356
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117448037#",
        "title": "3 bedroom semi-detached villa",
        "images": [
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54028/117448037/54028_22564895_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 179000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Limepark Crescent, Kelty, KY4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.370258,
            "lat": 56.138563
        },
        "_place": 11.9,
        "_people": 25.94,
        "_property": 1,
        "_price": 153.44,
        "map": property_357
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82667541#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/205k/204476/82667541/204476_0147newstamford_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204476/82667541/204476_0147newstamford_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204476/82667541/204476_0147newstamford_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204476/82667541/204476_0147newstamford_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204476/82667541/204476_0147newstamford_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204476/82667541/204476_0147newstamford_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204476/82667541/204476_0147newstamford_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204476/82667541/204476_0147newstamford_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204476/82667541/204476_0147newstamford_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 244995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Reginald Road, \r\nSt Helens,\r\nWA9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.71337,
            "lat": 53.42231
        },
        "_place": 8.99,
        "_people": 35.8,
        "_property": 0,
        "_price": 183.06,
        "map": property_358
    },
    {
        "url": "https://www.rightmove.co.uk/properties/91106131#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/228k/227501/91106131/227501_HOF210088_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/228k/227501/91106131/227501_HOF210088_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/228k/227501/91106131/227501_HOF210088_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/228k/227501/91106131/227501_HOF210088_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/228k/227501/91106131/227501_HOF210088_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/228k/227501/91106131/227501_HOF210088_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/228k/227501/91106131/227501_HOF210088_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/228k/227501/91106131/227501_HOF210088_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/228k/227501/91106131/227501_HOF210088_IMG_03_0000.jpeg"
        ],
        "price": [
            {
                "value": 345000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Hayfield Wood, Sam's Lane, Broad Blunsdon, SN26",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.778227,
            "lat": 51.613481
        },
        "_place": 11.4,
        "_people": 23.45,
        "_property": 0,
        "_price": 127.41,
        "map": property_359
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114660725#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/156k/155756/114660725/155756_KEN190109_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 960000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 4,
        "address": "Ackenthwaite, Milnthorpe, Cumbria",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.758059,
            "lat": 54.231986
        },
        "_place": 3.98,
        "_people": 34.4,
        "_property": 4,
        "_price": 182.6,
        "map": property_360
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115261715#",
        "title": "7 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 1100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": 4,
        "address": "Chaplin Road, Wembley, HA0",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.310927,
            "lat": 51.549666
        },
        "_place": 6.91,
        "_people": 21.64,
        "_property": 4,
        "_price": 200.1,
        "map": property_361
    },
    {
        "url": "https://www.rightmove.co.uk/properties/80194056#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/9k/8494/80194056/8494_MOR210099_IMG_00_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8494/80194056/8494_MOR210099_IMG_01_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8494/80194056/8494_MOR210099_IMG_02_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8494/80194056/8494_MOR210099_IMG_03_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8494/80194056/8494_MOR210099_IMG_04_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8494/80194056/8494_MOR210099_IMG_05_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8494/80194056/8494_MOR210099_IMG_06_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8494/80194056/8494_MOR210099_IMG_07_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8494/80194056/8494_MOR210099_IMG_08_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8494/80194056/8494_MOR210099_IMG_09_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8494/80194056/8494_MOR210099_IMG_10_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8494/80194056/8494_MOR210099_IMG_11_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8494/80194056/8494_MOR210099_IMG_12_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8494/80194056/8494_MOR210099_IMG_13_0002.jpeg",
            "https://media.rightmove.co.uk/9k/8494/80194056/8494_MOR210099_IMG_14_0002.jpeg"
        ],
        "price": [
            {
                "value": 130000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Halifax Road, Dewsbury, West Yorkshire, WF13",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.639351,
            "lat": 53.698257
        },
        "_place": 0.4,
        "_people": 18.46,
        "_property": 3,
        "_price": 110.37,
        "map": property_362
    },
    {
        "url": "https://www.rightmove.co.uk/properties/89784224#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_20_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_21_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_23_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_24_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_25_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_26_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_27_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_28_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_29_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_00_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_30_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_31_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_32_0000.jpg",
            "https://media.rightmove.co.uk/162k/161345/89784224/161345_greenFIELD_IMG_04_0000.jpg"
        ],
        "price": [
            {
                "value": 625000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Greenfield Road, Stevenage, Hertfordshire, SG1",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.19844,
            "lat": 51.91046
        },
        "_place": 0.08,
        "_people": 30.51,
        "_property": 3,
        "_price": 148.91,
        "map": property_363
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111654221#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/23k/22100/111654221/22100_100439037202_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22100/111654221/22100_100439037202_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22100/111654221/22100_100439037202_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22100/111654221/22100_100439037202_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22100/111654221/22100_100439037202_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22100/111654221/22100_100439037202_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22100/111654221/22100_100439037202_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 745000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Docking",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.62454,
            "lat": 52.90888
        },
        "_place": 5.65,
        "_people": 24.1,
        "_property": 4,
        "_price": 93.65,
        "map": property_365
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117405341#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154778/117405341/154778_31155466_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Torrin, Isle Of Skye",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -6.010851,
            "lat": 57.208477
        },
        "_place": 2.82,
        "_people": 21.08,
        "_property": 2,
        "_price": 127.09,
        "map": property_366
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107783441#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82115/107783441/82115_102397005085_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 1200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Mayfield Avenue, North Finchley,N12",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.17042,
            "lat": 51.61931
        },
        "_place": 9.84,
        "_people": 31.98,
        "_property": 5,
        "_price": 148.66,
        "map": property_367
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82452765#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109787/82452765/109787_101386004385_IMG_28_0000.jpeg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Ritchie Close, Crick, Northamptonshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.13104,
            "lat": 52.34747
        },
        "_place": 8.98,
        "_people": 34.91,
        "_property": 1,
        "_price": 90.27,
        "map": property_368
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117671465#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/8k/7541/117671465/7541_1029647_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7541/117671465/7541_1029647_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7541/117671465/7541_1029647_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7541/117671465/7541_1029647_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7541/117671465/7541_1029647_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7541/117671465/7541_1029647_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7541/117671465/7541_1029647_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7541/117671465/7541_1029647_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7541/117671465/7541_1029647_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7541/117671465/7541_1029647_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7541/117671465/7541_1029647_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7541/117671465/7541_1029647_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 775000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Hiltingbury",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.38195,
            "lat": 50.9981
        },
        "_place": 11.98,
        "_people": 21.7,
        "_property": 1,
        "_price": 159.7,
        "map": property_369
    },
    {
        "url": "https://www.rightmove.co.uk/properties/89132879#",
        "title": "Studio flat",
        "images": [
            "https://media.rightmove.co.uk/67k/66478/89132879/66478_LLA190028_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/67k/66478/89132879/66478_LLA190028_IMG_04_0000.jpg"
        ],
        "price": [
            {
                "value": 300000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Smithfield Street, Llanidloes, Powys, SY18",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.541456,
            "lat": 52.447532
        },
        "_place": 10.54,
        "_people": 20.44,
        "_property": 5,
        "_price": 129.36,
        "map": property_370
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115223837#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151052/115223837/151052_31048947_IMG_25_0000.jpeg"
        ],
        "price": [
            {
                "value": 800000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Martley, Worcestershire, WR6 6PA",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -2.362689,
            "lat": 52.238296
        },
        "_place": 3.8,
        "_people": 33.64,
        "_property": 5,
        "_price": 109.25,
        "map": property_371
    },
    {
        "url": "https://www.rightmove.co.uk/properties/79039854#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_37_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Llaneilian, Amlwch",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.322777,
            "lat": 53.40925
        },
        "_place": 0.27,
        "_people": 22.87,
        "_property": 0,
        "_price": 106.5,
        "map": property_372
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114220805#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/12k/11508/114220805/11508_0160_HRT016018083_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11508/114220805/11508_0160_HRT016018083_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11508/114220805/11508_0160_HRT016018083_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11508/114220805/11508_0160_HRT016018083_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11508/114220805/11508_0160_HRT016018083_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11508/114220805/11508_0160_HRT016018083_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11508/114220805/11508_0160_HRT016018083_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11508/114220805/11508_0160_HRT016018083_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11508/114220805/11508_0160_HRT016018083_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11508/114220805/11508_0160_HRT016018083_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11508/114220805/11508_0160_HRT016018083_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11508/114220805/11508_0160_HRT016018083_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11508/114220805/11508_0160_HRT016018083_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11508/114220805/11508_0160_HRT016018083_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11508/114220805/11508_0160_HRT016018083_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Grange Close, Hayes",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.424546,
            "lat": 51.522484
        },
        "_place": 6.28,
        "_people": 22.05,
        "_property": 0,
        "_price": 160.55,
        "map": property_373
    },
    {
        "url": "https://www.rightmove.co.uk/properties/75618592#",
        "title": "6 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_20_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_21_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_23_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_24_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_25_0000.jpg",
            "https://media.rightmove.co.uk/80k/79870/75618592/79870_29277926_IMG_26_0000.jpg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 4,
        "address": "Church Street, Ruyton Xi Towns, Shrewsbury",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.901587,
            "lat": 52.793708
        },
        "_place": 7.1,
        "_people": 30.53,
        "_property": 4,
        "_price": 159.8,
        "map": property_374
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117626783#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/1k/216/117626783/216_WWA211262_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/1k/216/117626783/216_WWA211262_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/1k/216/117626783/216_WWA211262_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/1k/216/117626783/216_WWA211262_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/1k/216/117626783/216_WWA211262_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/1k/216/117626783/216_WWA211262_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/1k/216/117626783/216_WWA211262_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/1k/216/117626783/216_WWA211262_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/1k/216/117626783/216_WWA211262_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/1k/216/117626783/216_WWA211262_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 230000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Grand Junction Way, Walsall, West Midlands, WS1",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.987255,
            "lat": 52.566079
        },
        "_place": 6.43,
        "_people": 23.32,
        "_property": 4,
        "_price": 124.91,
        "map": property_375
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114055991#",
        "title": "6 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/55k/54046/114055991/54046_OTL210142_IMG_02_0000.jpeg"
        ],
        "price": [
            {
                "value": 1000000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 2,
        "address": "Arthington Lane, Arthington, Otley, LS21",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.583863,
            "lat": 53.897927
        },
        "_place": 9.31,
        "_people": 32.82,
        "_property": 3,
        "_price": 134.46,
        "map": property_376
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117728312#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/46k/45762/117728312/45762_31168239_IMG_41_0000.jpeg"
        ],
        "price": [
            {
                "value": 650000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Campkin Gardens, St Leonards-on-sea",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.545637,
            "lat": 50.89051
        },
        "_place": 0.19,
        "_people": 35.69,
        "_property": 2,
        "_price": 198.9,
        "map": property_377
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114771731#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_45_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_46_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125020/114771731/125020_MAH001500212955312366_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 725000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Old Waterford Arms, High Road, Hertford, SG14",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.09857,
            "lat": 51.81717
        },
        "_place": 5.95,
        "_people": 24.53,
        "_property": 3,
        "_price": 113.84,
        "map": property_378
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117549869#",
        "title": "2 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/239k/238280/117549869/238280_S51076_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117549869/238280_S51076_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117549869/238280_S51076_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117549869/238280_S51076_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117549869/238280_S51076_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117549869/238280_S51076_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117549869/238280_S51076_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117549869/238280_S51076_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117549869/238280_S51076_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117549869/238280_S51076_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117549869/238280_S51076_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117549869/238280_S51076_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117549869/238280_S51076_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117549869/238280_S51076_IMG_13_0000.png"
        ],
        "price": [
            {
                "value": 365000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Westmoor Park,Tavistock,PL19 9AB",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.141894,
            "lat": 50.543571
        },
        "_place": 4.23,
        "_people": 32.39,
        "_property": 4,
        "_price": 107.36,
        "map": property_379
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107191241#",
        "title": "5 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/96k/95516/107191241/95516_10305406_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 240000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Grant Avenue, Wavertree, Liverpool, L15",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.918442,
            "lat": 53.391975
        },
        "_place": 11.21,
        "_people": 26.73,
        "_property": 3,
        "_price": 162.05,
        "map": property_380
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117009959#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/42k/41330/117009959/41330_22217351_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41330/117009959/41330_22217351_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41330/117009959/41330_22217351_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41330/117009959/41330_22217351_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41330/117009959/41330_22217351_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41330/117009959/41330_22217351_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41330/117009959/41330_22217351_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41330/117009959/41330_22217351_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41330/117009959/41330_22217351_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41330/117009959/41330_22217351_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41330/117009959/41330_22217351_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41330/117009959/41330_22217351_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41330/117009959/41330_22217351_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41330/117009959/41330_22217351_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41330/117009959/41330_22217351_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 345000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Primrose Crescent, Boston, PE21",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.057628,
            "lat": 52.976076
        },
        "_place": 3.59,
        "_people": 22.02,
        "_property": 3,
        "_price": 153,
        "map": property_381
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115228874#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_45_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_47_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56957/115228874/56957_21824467_IMG_46_0000.jpeg"
        ],
        "price": [
            {
                "value": 249000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Main Street, Methilhill, Leven, KY8",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.042588,
            "lat": 56.185921
        },
        "_place": 1.44,
        "_people": 27.85,
        "_property": 4,
        "_price": 161.17,
        "map": property_382
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116457773#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/94k/93913/116457773/93913_ORRXM01-t-4072_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/116457773/93913_ORRXM01-t-4072_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/116457773/93913_ORRXM01-t-4072_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/116457773/93913_ORRXM01-t-4072_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/116457773/93913_ORRXM01-t-4072_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/116457773/93913_ORRXM01-t-4072_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/116457773/93913_ORRXM01-t-4072_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/116457773/93913_ORRXM01-t-4072_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/116457773/93913_ORRXM01-t-4072_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/116457773/93913_ORRXM01-t-4072_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/116457773/93913_ORRXM01-t-4072_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/116457773/93913_ORRXM01-t-4072_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 152500,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Lagavulin, Isle of Islay, PA42",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -6.123594,
            "lat": 55.636416
        },
        "_place": 1.63,
        "_people": 21.64,
        "_property": 2,
        "_price": 172.29,
        "map": property_383
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82880064#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56974/82880064/56974_CEN200581_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 345000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Boardman Fold Road, Alkrington, Middleton, Manchester, M24",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.19655,
            "lat": 53.534358
        },
        "_place": 8.68,
        "_people": 33.31,
        "_property": 1,
        "_price": 183.62,
        "map": property_384
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111822620#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50055/111822620/50055_30877177_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Cross Beck and Paddock, Soutergate, Kirkby-In-Furness",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.185999,
            "lat": 54.22282
        },
        "_place": 0.43,
        "_people": 35.92,
        "_property": 2,
        "_price": 138.71,
        "map": property_385
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117239495#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9405/117239495/9405_31146272_IMG_34_0000.jpeg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Queen Victoria Road, Bradway, Sheffield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.520949,
            "lat": 53.320045
        },
        "_place": 9.35,
        "_people": 18.1,
        "_property": 4,
        "_price": 174.85,
        "map": property_386
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116523908#",
        "title": "4 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/15k/14930/116523908/14930_WGN210262_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14930/116523908/14930_WGN210262_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14930/116523908/14930_WGN210262_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14930/116523908/14930_WGN210262_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14930/116523908/14930_WGN210262_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14930/116523908/14930_WGN210262_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14930/116523908/14930_WGN210262_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Kennet Road, Wroughton, SN4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.794243,
            "lat": 51.529307
        },
        "_place": 2.86,
        "_people": 19.69,
        "_property": 3,
        "_price": 91,
        "map": property_387
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111704396#",
        "title": "6 bedroom block of apartments",
        "images": [
            "https://media.rightmove.co.uk/49k/48300/111704396/48300_100567003573_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48300/111704396/48300_100567003573_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48300/111704396/48300_100567003573_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48300/111704396/48300_100567003573_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48300/111704396/48300_100567003573_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48300/111704396/48300_100567003573_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48300/111704396/48300_100567003573_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48300/111704396/48300_100567003573_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 790000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": null,
        "address": "Foxhall Road, Ipswich",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": 1.17293,
            "lat": 52.05351
        },
        "_place": 5.76,
        "_people": 20.18,
        "_property": 3,
        "_price": 148.29,
        "map": property_388
    },
    {
        "url": "https://www.rightmove.co.uk/properties/105867074#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_47_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_45_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_46_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/105867074/200294_30588951_IMG_29_0000.jpeg"
        ],
        "price": [
            {
                "value": 440000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Church Farm, South Kelsey, Market Rasen",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.432166,
            "lat": 53.470432
        },
        "_place": 4.68,
        "_people": 25.16,
        "_property": 2,
        "_price": 112.15,
        "map": property_389
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117402365#",
        "title": "7 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_45_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_46_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_47_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70458/117402365/70458_31155161_IMG_48_0000.jpeg"
        ],
        "price": [
            {
                "value": 680000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": 7,
        "address": "Forsinain Steading, Forsinard Sutherland KW13 6YT",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.863775,
            "lat": 58.415939
        },
        "_place": 6.41,
        "_people": 34.41,
        "_property": 5,
        "_price": 181.5,
        "map": property_390
    },
    {
        "url": "https://www.rightmove.co.uk/properties/106168484#",
        "title": "2 bedroom mobile home",
        "images": [
            "https://media.rightmove.co.uk/5k/4838/106168484/4838_MCD210024_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4838/106168484/4838_MCD210024_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4838/106168484/4838_MCD210024_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4838/106168484/4838_MCD210024_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4838/106168484/4838_MCD210024_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4838/106168484/4838_MCD210024_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4838/106168484/4838_MCD210024_IMG_06_0001.jpeg"
        ],
        "price": [
            {
                "value": 260000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "King Edward Park, Baddesley Road, North Baddesley, Southampton, SO52",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.399599,
            "lat": 50.994955
        },
        "_place": 10.56,
        "_people": 34.55,
        "_property": 5,
        "_price": 142.43,
        "map": property_391
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113579120#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168254/113579120/168254_100439036984_IMG_27_0000.jpeg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 4,
        "address": "Caudle Springs",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.86145,
            "lat": 52.57841
        },
        "_place": 2.81,
        "_people": 28.06,
        "_property": 2,
        "_price": 166.15,
        "map": property_392
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116567807#",
        "title": "2 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/119k/118042/116567807/118042_P20728_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118042/116567807/118042_P20728_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118042/116567807/118042_P20728_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118042/116567807/118042_P20728_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118042/116567807/118042_P20728_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118042/116567807/118042_P20728_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118042/116567807/118042_P20728_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118042/116567807/118042_P20728_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118042/116567807/118042_P20728_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118042/116567807/118042_P20728_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118042/116567807/118042_P20728_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118042/116567807/118042_P20728_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Montcalm Road, Charlton, SE7",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.04169,
            "lat": 51.47849
        },
        "_place": 3.43,
        "_people": 24.64,
        "_property": 5,
        "_price": 145.76,
        "map": property_393
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109471811#",
        "title": "8 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/1k/650/109471811/650_HLH210101_IMG_18_0001.jpeg"
        ],
        "price": [
            {
                "value": 449000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 8,
        "bathrooms": 2,
        "address": "Rhosgoch, Anglesey, LL66",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.400525,
            "lat": 53.373844
        },
        "_place": 5.25,
        "_people": 21.19,
        "_property": 2,
        "_price": 103.82,
        "map": property_394
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117189935#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50992/117189935/50992_31136238_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 225000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Laburnum Avenue, Garden Village, Hull",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.314724,
            "lat": 53.763442
        },
        "_place": 5.36,
        "_people": 32.02,
        "_property": 3,
        "_price": 126.68,
        "map": property_395
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108322736#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/68k/67690/108322736/67690_401--Marble--Arch--Apartments--Harroby--Street--W1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67690/108322736/67690_401--Marble--Arch--Apartments--Harroby--Street--W1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67690/108322736/67690_401--Marble--Arch--Apartments--Harroby--Street--W1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67690/108322736/67690_401--Marble--Arch--Apartments--Harroby--Street--W1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67690/108322736/67690_401--Marble--Arch--Apartments--Harroby--Street--W1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67690/108322736/67690_401--Marble--Arch--Apartments--Harroby--Street--W1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67690/108322736/67690_401--Marble--Arch--Apartments--Harroby--Street--W1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67690/108322736/67690_401--Marble--Arch--Apartments--Harroby--Street--W1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/68k/67690/108322736/67690_401--Marble--Arch--Apartments--Harroby--Street--W1_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 725000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Harrowby Street, London, W1H",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.16413,
            "lat": 51.51724
        },
        "_place": 3.36,
        "_people": 29.03,
        "_property": 4,
        "_price": 171.84,
        "map": property_396
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116824607#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7024/116824607/7024_7024_MMU1314_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 580000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "2 Old Rectory Green,\nSefton Village,\nLiverpool,\nL29 6YD",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.974428,
            "lat": 53.502163
        },
        "_place": 10.28,
        "_people": 26.63,
        "_property": 1,
        "_price": 110.55,
        "map": property_397
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114384293#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/20k/19497/114384293/19497_31001502_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19497/114384293/19497_31001502_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19497/114384293/19497_31001502_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19497/114384293/19497_31001502_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19497/114384293/19497_31001502_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19497/114384293/19497_31001502_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19497/114384293/19497_31001502_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19497/114384293/19497_31001502_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19497/114384293/19497_31001502_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19497/114384293/19497_31001502_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19497/114384293/19497_31001502_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 349995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Queensway, Wilcott, SY4 ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.923694,
            "lat": 52.763497
        },
        "_place": 6.66,
        "_people": 22.63,
        "_property": 3,
        "_price": 160.85,
        "map": property_398
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117068108#",
        "title": "4 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/16k/15143/117068108/15143_31131013_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15143/117068108/15143_31131013_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15143/117068108/15143_31131013_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15143/117068108/15143_31131013_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15143/117068108/15143_31131013_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15143/117068108/15143_31131013_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15143/117068108/15143_31131013_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15143/117068108/15143_31131013_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15143/117068108/15143_31131013_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15143/117068108/15143_31131013_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 315000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Furness Road, Fallowfield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.221248,
            "lat": 53.44387
        },
        "_place": 10.33,
        "_people": 25.88,
        "_property": 1,
        "_price": 172.46,
        "map": property_399
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113231000#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11259/113231000/11259_100953086300_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Princess Street, Chase Terrace, Burntwood, Staffordshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.938825,
            "lat": 52.683839
        },
        "_place": 7.07,
        "_people": 20.33,
        "_property": 4,
        "_price": 180.41,
        "map": property_400
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117741950#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44189/117741950/44189_NTH210471_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Kirkholme Gardens, Harlestone Road, Northampton, Northamptonshire, NN5",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.927361,
            "lat": 52.248664
        },
        "_place": 7.35,
        "_people": 34.34,
        "_property": 3,
        "_price": 128.93,
        "map": property_401
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107151146#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_29_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38933/107151146/38933_10905752_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Llanfachraeth",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.533017,
            "lat": 53.316734
        },
        "_place": 8.87,
        "_people": 32.6,
        "_property": 3,
        "_price": 147.12,
        "map": property_402
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115078841#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/115078841/234488_103433000204_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Onchan, Isle Of Man",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.440873,
            "lat": 54.17078
        },
        "_place": 11.5,
        "_people": 34.63,
        "_property": 4,
        "_price": 164.74,
        "map": property_403
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117072188#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3181/117072188/3181_100251022990_IMG_27_0000.jpeg"
        ],
        "price": [
            {
                "value": 290000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Apartment 14 Heathcliffe Court, Redhills Road, Arnside, Cumbria LA5 0AT",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.84116,
            "lat": 54.197972
        },
        "_place": 8.41,
        "_people": 26.5,
        "_property": 0,
        "_price": 178.89,
        "map": property_404
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114979613#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/45k/44388/114979613/44388_31033044_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44388/114979613/44388_31033044_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44388/114979613/44388_31033044_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44388/114979613/44388_31033044_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44388/114979613/44388_31033044_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44388/114979613/44388_31033044_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44388/114979613/44388_31033044_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44388/114979613/44388_31033044_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44388/114979613/44388_31033044_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44388/114979613/44388_31033044_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44388/114979613/44388_31033044_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44388/114979613/44388_31033044_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 315000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Dean Close, Burford, Tenbury Wells",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.591751,
            "lat": 52.317584
        },
        "_place": 8.89,
        "_people": 32.8,
        "_property": 1,
        "_price": 157.09,
        "map": property_405
    },
    {
        "url": "https://www.rightmove.co.uk/properties/92736038#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/39k/38453/92736038/38453_100043018368_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/39k/38453/92736038/38453_100043018368_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/39k/38453/92736038/38453_100043018368_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/39k/38453/92736038/38453_100043018368_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/39k/38453/92736038/38453_100043018368_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/39k/38453/92736038/38453_100043018368_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/39k/38453/92736038/38453_100043018368_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/39k/38453/92736038/38453_100043018368_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/39k/38453/92736038/38453_100043018368_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/39k/38453/92736038/38453_100043018368_IMG_10_0000.jpg"
        ],
        "price": [
            {
                "value": 250000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Bullock Fair Close, Harleston IP20 9AT",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.299452,
            "lat": 52.403235
        },
        "_place": 3.09,
        "_people": 32.95,
        "_property": 3,
        "_price": 96.18,
        "map": property_406
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116109530#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/2k/1603/116109530/1603_WSL308757_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1603/116109530/1603_WSL308757_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1603/116109530/1603_WSL308757_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1603/116109530/1603_WSL308757_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1603/116109530/1603_WSL308757_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1603/116109530/1603_WSL308757_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1603/116109530/1603_WSL308757_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1603/116109530/1603_WSL308757_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1603/116109530/1603_WSL308757_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 170000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Bader Road, Walsall",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.02313,
            "lat": 52.58719
        },
        "_place": 9.78,
        "_people": 23.82,
        "_property": 0,
        "_price": 115.53,
        "map": property_407
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82562986#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_20_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_21_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_23_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_24_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_25_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_26_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_27_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_28_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_29_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_30_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_31_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_32_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_33_0000.jpg",
            "https://media.rightmove.co.uk/68k/67998/82562986/67998_STP200204_IMG_34_0000.jpg"
        ],
        "price": [
            {
                "value": 270000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Fairway View, Elisabeth Gardens, Reddish, Stockport, SK5",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.16551,
            "lat": 53.439234
        },
        "_place": 4.44,
        "_people": 28.11,
        "_property": 5,
        "_price": 118.43,
        "map": property_408
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117595520#",
        "title": "4 bedroom town house",
        "images": [
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29132/117595520/29132_31159021_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 975000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Chadwick Place, Long Ditton, Surbiton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.31812,
            "lat": 51.389881
        },
        "_place": 7.98,
        "_people": 35.57,
        "_property": 3,
        "_price": 196.98,
        "map": property_409
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115338335#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/88k/87925/115338335/87925_TEM200138_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87925/115338335/87925_TEM200138_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87925/115338335/87925_TEM200138_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87925/115338335/87925_TEM200138_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87925/115338335/87925_TEM200138_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87925/115338335/87925_TEM200138_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87925/115338335/87925_TEM200138_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87925/115338335/87925_TEM200138_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 999950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Clifton Gardens, London",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.197853,
            "lat": 51.579917
        },
        "_place": 3.6,
        "_people": 29.03,
        "_property": 3,
        "_price": 161.64,
        "map": property_410
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117348449#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/84k/83683/117348449/83683_31152533_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83683/117348449/83683_31152533_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83683/117348449/83683_31152533_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83683/117348449/83683_31152533_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83683/117348449/83683_31152533_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83683/117348449/83683_31152533_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83683/117348449/83683_31152533_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83683/117348449/83683_31152533_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83683/117348449/83683_31152533_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83683/117348449/83683_31152533_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83683/117348449/83683_31152533_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83683/117348449/83683_31152533_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83683/117348449/83683_31152533_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Sunnythwaite, Hawkshead Hall Lodges, Hawkshead, Ambleside",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.003051,
            "lat": 54.381244
        },
        "_place": 7.56,
        "_people": 31.39,
        "_property": 4,
        "_price": 171.74,
        "map": property_411
    },
    {
        "url": "https://www.rightmove.co.uk/properties/73742112#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/16k/15942/73742112/15942_1149992_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/16k/15942/73742112/15942_1149992_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/16k/15942/73742112/15942_1149992_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/16k/15942/73742112/15942_1149992_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/16k/15942/73742112/15942_1149992_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/16k/15942/73742112/15942_1149992_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/16k/15942/73742112/15942_1149992_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/16k/15942/73742112/15942_1149992_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/16k/15942/73742112/15942_1149992_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/16k/15942/73742112/15942_1149992_IMG_10_0000.jpg"
        ],
        "price": [
            {
                "value": 1200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Courtfield Gardens, South Kensington, London, SW5",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.18814,
            "lat": 51.493819
        },
        "_place": 7.28,
        "_people": 19.97,
        "_property": 4,
        "_price": 191.28,
        "map": property_412
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111230759#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/9k/8794/111230759/8794_11058537_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/111230759/8794_11058537_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/111230759/8794_11058537_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/111230759/8794_11058537_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/111230759/8794_11058537_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/111230759/8794_11058537_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/111230759/8794_11058537_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/111230759/8794_11058537_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/111230759/8794_11058537_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/111230759/8794_11058537_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 965000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Leatherhead, KT22",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.354829,
            "lat": 51.31583
        },
        "_place": 3.7,
        "_people": 30.37,
        "_property": 3,
        "_price": 108.11,
        "map": property_413
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112208918#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/3k/2063/112208918/2063_NOR135644_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2063/112208918/2063_NOR135644_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2063/112208918/2063_NOR135644_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2063/112208918/2063_NOR135644_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2063/112208918/2063_NOR135644_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2063/112208918/2063_NOR135644_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2063/112208918/2063_NOR135644_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2063/112208918/2063_NOR135644_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2063/112208918/2063_NOR135644_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2063/112208918/2063_NOR135644_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2063/112208918/2063_NOR135644_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2063/112208918/2063_NOR135644_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2063/112208918/2063_NOR135644_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2063/112208918/2063_NOR135644_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 390000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "The Old Barley Market, Norwich",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": 1.29023,
            "lat": 52.62869
        },
        "_place": 9.94,
        "_people": 27.79,
        "_property": 2,
        "_price": 108.46,
        "map": property_414
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111247034#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/44k/43949/111247034/43949_30848435_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43949/111247034/43949_30848435_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43949/111247034/43949_30848435_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43949/111247034/43949_30848435_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43949/111247034/43949_30848435_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43949/111247034/43949_30848435_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43949/111247034/43949_30848435_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43949/111247034/43949_30848435_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43949/111247034/43949_30848435_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43949/111247034/43949_30848435_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 170000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Southgate Court, Market Weighton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.664681,
            "lat": 53.863222
        },
        "_place": 10.37,
        "_people": 33.55,
        "_property": 5,
        "_price": 193.09,
        "map": property_415
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114742643#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/114742643/237629_5100_IMG_40_0000.jpeg"
        ],
        "price": [
            {
                "value": 650000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "23, Park Close, Glen Vine",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.551588,
            "lat": 54.177383
        },
        "_place": 1.52,
        "_people": 22.68,
        "_property": 2,
        "_price": 97.49,
        "map": property_416
    },
    {
        "url": "https://www.rightmove.co.uk/properties/102191477#",
        "title": "7 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_28_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2110/102191477/2110_MIH105487_IMG_33_0000.jpeg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": 2,
        "address": "Doniford, Watchet",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.3071,
            "lat": 51.17875
        },
        "_place": 8.42,
        "_people": 21.81,
        "_property": 4,
        "_price": 174.39,
        "map": property_417
    },
    {
        "url": "https://www.rightmove.co.uk/properties/95420951#",
        "title": "1 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/11k/10373/95420951/10373_PD2048_IMG_00_0000.JPG",
            "https://media.rightmove.co.uk/11k/10373/95420951/10373_PD2048_IMG_01_0000.JPG",
            "https://media.rightmove.co.uk/11k/10373/95420951/10373_PD2048_IMG_02_0000.JPG",
            "https://media.rightmove.co.uk/11k/10373/95420951/10373_PD2048_IMG_03_0000.JPG",
            "https://media.rightmove.co.uk/11k/10373/95420951/10373_PD2048_IMG_04_0000.JPG",
            "https://media.rightmove.co.uk/11k/10373/95420951/10373_PD2048_IMG_05_0000.JPG",
            "https://media.rightmove.co.uk/11k/10373/95420951/10373_PD2048_IMG_06_0000.JPG",
            "https://media.rightmove.co.uk/11k/10373/95420951/10373_PD2048_IMG_07_0000.JPG"
        ],
        "price": [
            {
                "value": 250000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": null,
        "address": "Trethevy PL34",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.72348,
            "lat": 50.67088
        },
        "_place": 1.83,
        "_people": 30,
        "_property": 3,
        "_price": 130.97,
        "map": property_418
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115859876#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/10k/9058/115859876/9058_LIN210401_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9058/115859876/9058_LIN210401_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9058/115859876/9058_LIN210401_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9058/115859876/9058_LIN210401_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9058/115859876/9058_LIN210401_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9058/115859876/9058_LIN210401_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9058/115859876/9058_LIN210401_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9058/115859876/9058_LIN210401_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9058/115859876/9058_LIN210401_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9058/115859876/9058_LIN210401_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9058/115859876/9058_LIN210401_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9058/115859876/9058_LIN210401_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9058/115859876/9058_LIN210401_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9058/115859876/9058_LIN210401_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9058/115859876/9058_LIN210401_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Fosse Lane, Thorpe-on-the-Hill, Lincoln, Lincolnshire, LN6",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.64214,
            "lat": 53.17751
        },
        "_place": 2.77,
        "_people": 30.16,
        "_property": 3,
        "_price": 160.18,
        "map": property_419
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116459582#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116459582/104497_OLDORCHARDCHURCHWALK_IMG_40_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Church Walk, Little Addington, NN14",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.59317,
            "lat": 52.35092
        },
        "_place": 4.58,
        "_people": 24.66,
        "_property": 0,
        "_price": 167.62,
        "map": property_420
    },
    {
        "url": "https://www.rightmove.co.uk/properties/103754645#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/37k/36245/103754645/36245_LIT210032_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36245/103754645/36245_LIT210032_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36245/103754645/36245_LIT210032_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36245/103754645/36245_LIT210032_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36245/103754645/36245_LIT210032_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36245/103754645/36245_LIT210032_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36245/103754645/36245_LIT210032_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36245/103754645/36245_LIT210032_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36245/103754645/36245_LIT210032_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36245/103754645/36245_LIT210032_IMG_09_0002.jpeg"
        ],
        "price": [
            {
                "value": 595000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Plot 15, 3 Honeysuckle Close, 72 Scothern Road, Nettleham, LN2",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.482011,
            "lat": 53.272078
        },
        "_place": 7.72,
        "_people": 27.58,
        "_property": 3,
        "_price": 164.99,
        "map": property_421
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115873862#",
        "title": "Plot",
        "images": [
            "https://media.rightmove.co.uk/238k/237065/115873862/237065_31082078_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/115873862/237065_31082078_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/115873862/237065_31082078_IMG_02_0000.png",
            "https://media.rightmove.co.uk/238k/237065/115873862/237065_31082078_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237065/115873862/237065_31082078_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 675000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Rochdale Road East, Heywood",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.19525,
            "lat": 53.60071
        },
        "_place": 5.18,
        "_people": 32.99,
        "_property": 1,
        "_price": 150.94,
        "map": property_422
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111405764#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/72k/71140/111405764/71140_30837381_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71140/111405764/71140_30837381_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71140/111405764/71140_30837381_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71140/111405764/71140_30837381_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71140/111405764/71140_30837381_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71140/111405764/71140_30837381_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71140/111405764/71140_30837381_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/72k/71140/111405764/71140_30837381_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 1200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Tavistock Place, Bloomsbury",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.126702,
            "lat": 51.52535
        },
        "_place": 9.59,
        "_people": 22.02,
        "_property": 1,
        "_price": 107.09,
        "map": property_423
    },
    {
        "url": "https://www.rightmove.co.uk/properties/98503448#",
        "title": "1 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/66k/65827/98503448/65827_SLA180236_IMG_01_0013.jpeg",
            "https://media.rightmove.co.uk/66k/65827/98503448/65827_SLA180236_IMG_02_0012.jpeg",
            "https://media.rightmove.co.uk/66k/65827/98503448/65827_SLA180236_IMG_03_0004.jpeg",
            "https://media.rightmove.co.uk/66k/65827/98503448/65827_SLA180236_IMG_04_0005.jpeg",
            "https://media.rightmove.co.uk/66k/65827/98503448/65827_SLA180236_IMG_05_0005.jpeg",
            "https://media.rightmove.co.uk/66k/65827/98503448/65827_SLA180236_IMG_06_0003.jpeg",
            "https://media.rightmove.co.uk/66k/65827/98503448/65827_SLA180236_IMG_07_0002.jpeg",
            "https://media.rightmove.co.uk/66k/65827/98503448/65827_SLA180236_IMG_08_0002.jpeg"
        ],
        "price": [
            {
                "value": 975000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": null,
        "address": "Pont Street, London, SW1X",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.162834,
            "lat": 51.4971
        },
        "_place": 0.79,
        "_people": 27.83,
        "_property": 5,
        "_price": 174.97,
        "map": property_424
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115270787#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/64k/63602/115270787/63602_GOR303518_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63602/115270787/63602_GOR303518_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63602/115270787/63602_GOR303518_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63602/115270787/63602_GOR303518_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63602/115270787/63602_GOR303518_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63602/115270787/63602_GOR303518_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63602/115270787/63602_GOR303518_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63602/115270787/63602_GOR303518_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63602/115270787/63602_GOR303518_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63602/115270787/63602_GOR303518_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63602/115270787/63602_GOR303518_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63602/115270787/63602_GOR303518_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63602/115270787/63602_GOR303518_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63602/115270787/63602_GOR303518_IMG_02_0000.jpeg"
        ],
        "price": [
            {
                "value": 200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Caswell Street, LLANELLI",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.15379,
            "lat": 51.67396
        },
        "_place": 3.96,
        "_people": 30.07,
        "_property": 5,
        "_price": 166.05,
        "map": property_425
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115005161#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79396/115005161/79396_1654_IMG_18_0001.jpeg"
        ],
        "price": [
            {
                "value": 549995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 4,
        "address": "Peterborough Road, Whittlesey, Peterborough",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.15127,
            "lat": 52.5605
        },
        "_place": 9.93,
        "_people": 21.48,
        "_property": 1,
        "_price": 148.15,
        "map": property_426
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116080298#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/3k/2135/116080298/2135_WAK121489_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2135/116080298/2135_WAK121489_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2135/116080298/2135_WAK121489_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2135/116080298/2135_WAK121489_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2135/116080298/2135_WAK121489_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2135/116080298/2135_WAK121489_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2135/116080298/2135_WAK121489_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2135/116080298/2135_WAK121489_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2135/116080298/2135_WAK121489_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2135/116080298/2135_WAK121489_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2135/116080298/2135_WAK121489_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2135/116080298/2135_WAK121489_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2135/116080298/2135_WAK121489_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2135/116080298/2135_WAK121489_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 4,
        "address": "Beckside, Flockton, Wakefield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.63509,
            "lat": 53.629
        },
        "_place": 4.25,
        "_people": 25.2,
        "_property": 4,
        "_price": 188.27,
        "map": property_427
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116619014#",
        "title": "4 bedroom barn conversion",
        "images": [
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116619014/200930_HSS191091_IMG_29_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Main Street, Birkin, WF11",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.197664,
            "lat": 53.733483
        },
        "_place": 5.35,
        "_people": 21.23,
        "_property": 4,
        "_price": 166.77,
        "map": property_428
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117674048#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117674048/200930_HSS193747_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 270000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Broomcliffe Gardens, Barnsley, S72",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.411317,
            "lat": 53.591815
        },
        "_place": 2.29,
        "_people": 24.18,
        "_property": 2,
        "_price": 97.11,
        "map": property_429
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116672294#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/201k/200930/116672294/200930_HSS188309_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116672294/200930_HSS188309_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116672294/200930_HSS188309_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116672294/200930_HSS188309_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116672294/200930_HSS188309_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116672294/200930_HSS188309_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116672294/200930_HSS188309_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116672294/200930_HSS188309_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116672294/200930_HSS188309_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116672294/200930_HSS188309_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116672294/200930_HSS188309_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116672294/200930_HSS188309_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116672294/200930_HSS188309_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/116672294/200930_HSS188309_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 210000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Benningholme Lane, Hull, HU11",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.268425,
            "lat": 53.838465
        },
        "_place": 4.19,
        "_people": 35.95,
        "_property": 2,
        "_price": 122.21,
        "map": property_430
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117441062#",
        "title": "4 bedroom town house",
        "images": [
            "https://media.rightmove.co.uk/32k/31418/117441062/31418_31156778_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31418/117441062/31418_31156778_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31418/117441062/31418_31156778_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31418/117441062/31418_31156778_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31418/117441062/31418_31156778_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31418/117441062/31418_31156778_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31418/117441062/31418_31156778_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31418/117441062/31418_31156778_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 325000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Chetwynd Court, Stafford",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.116445,
            "lat": 52.802543
        },
        "_place": 1.63,
        "_people": 31.14,
        "_property": 0,
        "_price": 158.12,
        "map": property_431
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109733909#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/33k/32489/109733909/32489_8561_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/109733909/32489_8561_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/109733909/32489_8561_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/109733909/32489_8561_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/109733909/32489_8561_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/109733909/32489_8561_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/109733909/32489_8561_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/109733909/32489_8561_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/109733909/32489_8561_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/109733909/32489_8561_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/109733909/32489_8561_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Portreath, Nr. Redruth, Cornall",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.289347,
            "lat": 50.26241
        },
        "_place": 7.93,
        "_people": 23.7,
        "_property": 0,
        "_price": 112.27,
        "map": property_432
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117049583#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/160k/159908/117049583/159908_Zhuntersripon0000006871_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Old Mill Court, Watermill Lane, Ripon, North Yorkshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.5596,
            "lat": 54.1892
        },
        "_place": 11.09,
        "_people": 24.31,
        "_property": 2,
        "_price": 168.01,
        "map": property_433
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117149969#",
        "title": "5 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/87k/86578/117149969/86578_103010006452_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 725000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Village Way, Ashford",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.46389,
            "lat": 51.43675
        },
        "_place": 1.46,
        "_people": 18.57,
        "_property": 0,
        "_price": 156.12,
        "map": property_434
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114247016#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/80k/79755/114247016/79755_MerchantsWharf-441950-Manchester-MW8981_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79755/114247016/79755_MerchantsWharf-441950-Manchester-MW8981_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79755/114247016/79755_MerchantsWharf-441950-Manchester-MW8981_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79755/114247016/79755_MerchantsWharf-441950-Manchester-MW8981_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79755/114247016/79755_MerchantsWharf-441950-Manchester-MW8981_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79755/114247016/79755_MerchantsWharf-441950-Manchester-MW8981_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79755/114247016/79755_MerchantsWharf-441950-Manchester-MW8981_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79755/114247016/79755_MerchantsWharf-441950-Manchester-MW8981_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79755/114247016/79755_MerchantsWharf-441950-Manchester-MW8981_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79755/114247016/79755_MerchantsWharf-441950-Manchester-MW8981_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 441950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "M5 Hands Off Investment, Ordsall Lane, Manchester, M5 4TT",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -2.26948,
            "lat": 53.47267
        },
        "_place": 9.3,
        "_people": 34.68,
        "_property": 3,
        "_price": 124.64,
        "map": property_435
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109892384#",
        "title": "5 bedroom link detached house",
        "images": [
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/109892384/200930_HSS175760_IMG_27_0000.jpeg"
        ],
        "price": [
            {
                "value": 420000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Front Street, Treeton, Rotherham, S60",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.350722,
            "lat": 53.385089
        },
        "_place": 6.69,
        "_people": 31.31,
        "_property": 3,
        "_price": 156.8,
        "map": property_436
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116421335#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/203k/202775/116421335/202775_1280517-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202775/116421335/202775_1280517-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202775/116421335/202775_1280517-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202775/116421335/202775_1280517-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202775/116421335/202775_1280517-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202775/116421335/202775_1280517-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202775/116421335/202775_1280517-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202775/116421335/202775_1280517-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202775/116421335/202775_1280517-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202775/116421335/202775_1280517-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202775/116421335/202775_1280517-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202775/116421335/202775_1280517-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202775/116421335/202775_1280517-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202775/116421335/202775_1280517-1_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202775/116421335/202775_1280517-1_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Glorian Estate, Amlwch, LL68",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.335869,
            "lat": 53.411191
        },
        "_place": 10.27,
        "_people": 30.17,
        "_property": 2,
        "_price": 114.69,
        "map": property_437
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82249469#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_16_0012.jpeg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_17_0012.jpeg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_20_0000.jpg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_21_0000.jpg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_23_0000.jpg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_24_0000.jpg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_25_0000.jpg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_26_0000.jpg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_27_0000.jpg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_28_0000.jpg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_29_0000.jpg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_30_0000.jpg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/137k/136148/82249469/136148_2325712_IMG_34_0000.jpeg"
        ],
        "price": [
            {
                "value": 1190000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Capital Building, Embassy Gardens, Nine Elms, London, SW11",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.135012,
            "lat": 51.481562
        },
        "_place": 5.03,
        "_people": 23.96,
        "_property": 5,
        "_price": 124.21,
        "map": property_438
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112295924#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31262/112295924/31262_MIL210304_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Bentgate Close, Newhey, Rochdale, OL16",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.102563,
            "lat": 53.600516
        },
        "_place": 3.28,
        "_people": 28.18,
        "_property": 0,
        "_price": 161.13,
        "map": property_439
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82294864#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/130k/129988/82294864/129988_101554001325_IMG_18_0000.jpg"
        ],
        "price": [
            {
                "value": 195000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Whitsand Bay Fort, Whitsands, Millbrook",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.23699,
            "lat": 50.3418
        },
        "_place": 3.8,
        "_people": 27.01,
        "_property": 1,
        "_price": 97.01,
        "map": property_440
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110775044#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/49k/48755/110775044/48755_GLD210244_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48755/110775044/48755_GLD210244_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48755/110775044/48755_GLD210244_IMG_02_0001.jpeg"
        ],
        "price": [
            {
                "value": 715000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 4,
        "address": "Plot 3 - Athron Hill, Milnathort, Kinross, KY13",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.460561,
            "lat": 56.247444
        },
        "_place": 6.4,
        "_people": 23.87,
        "_property": 0,
        "_price": 150.86,
        "map": property_441
    },
    {
        "url": "https://www.rightmove.co.uk/properties/103710638#",
        "title": "7 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46139/103710638/46139_RS0518_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": null,
        "address": "***Development Opportunity***,East Street, Ashburton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.755372,
            "lat": 50.51564
        },
        "_place": 6.42,
        "_people": 27.28,
        "_property": 4,
        "_price": 140.66,
        "map": property_442
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107717255#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11111/107717255/11111_30676055_IMG_42_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Allison Drive, Huddersfield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.780902,
            "lat": 53.667144
        },
        "_place": 7.52,
        "_people": 24.13,
        "_property": 5,
        "_price": 113.4,
        "map": property_443
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82668453#",
        "title": "3 bedroom penthouse",
        "images": [
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22907/82668453/22907_SNP_BRM_LFSYCL_576_765109921_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 675000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Ravenswood Court, Ladybrook Road, Bramhall",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -2.177967,
            "lat": 53.371746
        },
        "_place": 10.96,
        "_people": 35.61,
        "_property": 4,
        "_price": 114.42,
        "map": property_444
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117342512#",
        "title": "5 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/69k/68230/117342512/68230_22478762_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68230/117342512/68230_22478762_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68230/117342512/68230_22478762_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68230/117342512/68230_22478762_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68230/117342512/68230_22478762_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68230/117342512/68230_22478762_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68230/117342512/68230_22478762_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68230/117342512/68230_22478762_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68230/117342512/68230_22478762_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68230/117342512/68230_22478762_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68230/117342512/68230_22478762_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68230/117342512/68230_22478762_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68230/117342512/68230_22478762_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68230/117342512/68230_22478762_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 495000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Lamanva Close, Illogan, Redruth, TR16",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.262287,
            "lat": 50.244101
        },
        "_place": 8.43,
        "_people": 30.12,
        "_property": 4,
        "_price": 141.57,
        "map": property_445
    },
    {
        "url": "https://www.rightmove.co.uk/properties/106183976#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/43k/42896/106183976/42896_3348_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42896/106183976/42896_3348_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42896/106183976/42896_3348_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42896/106183976/42896_3348_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42896/106183976/42896_3348_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42896/106183976/42896_3348_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42896/106183976/42896_3348_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42896/106183976/42896_3348_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42896/106183976/42896_3348_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42896/106183976/42896_3348_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42896/106183976/42896_3348_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42896/106183976/42896_3348_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42896/106183976/42896_3348_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Rose Bank Suite, Holyrood House, Wells Road, Malvern, Worcestershire, WR14",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.329972,
            "lat": 52.108582
        },
        "_place": 8.65,
        "_people": 24.5,
        "_property": 1,
        "_price": 154.27,
        "map": property_446
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115820849#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/9k/8322/115820849/8322_h6t66_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8322/115820849/8322_h6t66_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8322/115820849/8322_h6t66_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8322/115820849/8322_h6t66_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8322/115820849/8322_h6t66_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8322/115820849/8322_h6t66_IMG_05_0000.jpeg"
        ],
        "price": [
            {
                "value": 395000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Manica Close, Bordon, Hampshire, GU35",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.86331,
            "lat": 51.10985
        },
        "_place": 3.79,
        "_people": 18.14,
        "_property": 1,
        "_price": 155.96,
        "map": property_447
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114420725#",
        "title": "House",
        "images": [
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 159950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "House, Llanidloes, Wales",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.5401,
            "lat": 52.4495
        },
        "_place": 10.71,
        "_people": 21.23,
        "_property": 4,
        "_price": 159.91,
        "map": property_448
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114685082#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/18k/17628/114685082/17628_SAF210697_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17628/114685082/17628_SAF210697_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17628/114685082/17628_SAF210697_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17628/114685082/17628_SAF210697_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17628/114685082/17628_SAF210697_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17628/114685082/17628_SAF210697_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17628/114685082/17628_SAF210697_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17628/114685082/17628_SAF210697_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17628/114685082/17628_SAF210697_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17628/114685082/17628_SAF210697_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17628/114685082/17628_SAF210697_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17628/114685082/17628_SAF210697_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17628/114685082/17628_SAF210697_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17628/114685082/17628_SAF210697_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17628/114685082/17628_SAF210697_IMG_14_0001.jpeg"
        ],
        "price": [
            {
                "value": 280000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Grassfield Avenue, Salford, M7",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.26671,
            "lat": 53.501534
        },
        "_place": 0.96,
        "_people": 20.97,
        "_property": 0,
        "_price": 111.88,
        "map": property_449
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116014697#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21485/116014697/21485_WDB210788_IMG_25_0000.jpeg"
        ],
        "price": [
            {
                "value": 210000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Wolfberry Drive, Liverpool, L11",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.914479,
            "lat": 53.439818
        },
        "_place": 1.32,
        "_people": 24.04,
        "_property": 3,
        "_price": 190.78,
        "map": property_450
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114431648#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/1k/632/114431648/632_MPH210594_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/1k/632/114431648/632_MPH210594_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/1k/632/114431648/632_MPH210594_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/1k/632/114431648/632_MPH210594_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/1k/632/114431648/632_MPH210594_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/1k/632/114431648/632_MPH210594_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/1k/632/114431648/632_MPH210594_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/1k/632/114431648/632_MPH210594_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/1k/632/114431648/632_MPH210594_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/1k/632/114431648/632_MPH210594_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/1k/632/114431648/632_MPH210594_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/1k/632/114431648/632_MPH210594_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/1k/632/114431648/632_MPH210594_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/1k/632/114431648/632_MPH210594_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/1k/632/114431648/632_MPH210594_IMG_14_0001.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Hartwell Road, Portsmouth, Hampshire, England, PO3",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.050868,
            "lat": 50.827196
        },
        "_place": 3.37,
        "_people": 34.63,
        "_property": 2,
        "_price": 159.94,
        "map": property_451
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112886567#",
        "title": "1 bedroom semi-detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/42k/41855/112886567/41855_LUC1001723_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/112886567/41855_LUC1001723_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/112886567/41855_LUC1001723_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/112886567/41855_LUC1001723_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/112886567/41855_LUC1001723_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/112886567/41855_LUC1001723_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/112886567/41855_LUC1001723_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/112886567/41855_LUC1001723_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/112886567/41855_LUC1001723_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/112886567/41855_LUC1001723_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/112886567/41855_LUC1001723_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/112886567/41855_LUC1001723_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/112886567/41855_LUC1001723_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 189500,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Tilly Ba Lou, Moelfre, Moelfre",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.23859,
            "lat": 53.35032
        },
        "_place": 7.88,
        "_people": 24.41,
        "_property": 0,
        "_price": 187.73,
        "map": property_452
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115163024#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9028/115163024/9028_MVS115306_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 289995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Richards Grove, (Land At 18 Chapel Street), Kirkby In Ashfield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.26678,
            "lat": 53.10092
        },
        "_place": 6.15,
        "_people": 28.58,
        "_property": 0,
        "_price": 131.73,
        "map": property_453
    },
    {
        "url": "https://www.rightmove.co.uk/properties/67973777#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_23_0001.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_22_0002.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_24_0001.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_25_0001.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_26_0001.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_27_0001.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_30_0000.jpg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_29_0000.jpg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_31_0000.jpg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/84k/83903/67973777/83903_8031207_IMG_05_0000.jpg"
        ],
        "price": [
            {
                "value": 695000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "27 Stuart Court, Butt Street, Minchinhampton, GL6",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.183174,
            "lat": 51.705399
        },
        "_place": 6.68,
        "_people": 26.16,
        "_property": 1,
        "_price": 130.67,
        "map": property_454
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114991832#",
        "title": "3 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/100k/99865/114991832/99865_31034292_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99865/114991832/99865_31034292_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99865/114991832/99865_31034292_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99865/114991832/99865_31034292_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99865/114991832/99865_31034292_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99865/114991832/99865_31034292_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99865/114991832/99865_31034292_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/100k/99865/114991832/99865_31034292_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 975000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Patio Close, Clarence Avenue",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.134091,
            "lat": 51.453308
        },
        "_place": 3.53,
        "_people": 27.51,
        "_property": 1,
        "_price": 120.76,
        "map": property_455
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114523121#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127717/114523121/127717_RS0437_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 330000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Moss Crescent, Ryton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.779733,
            "lat": 54.965949
        },
        "_place": 10.45,
        "_people": 21.74,
        "_property": 3,
        "_price": 138.65,
        "map": property_456
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117344765#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10975/117344765/10975_31151719_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 335000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Marlborough Avenue, Hessle",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.442766,
            "lat": 53.725402
        },
        "_place": 7.63,
        "_people": 19.62,
        "_property": 1,
        "_price": 109.57,
        "map": property_457
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116741552#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/244k/243194/116741552/243194_Plot_5_howarth_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243194/116741552/243194_Plot_5_howarth_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243194/116741552/243194_Plot_5_howarth_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243194/116741552/243194_Plot_5_howarth_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243194/116741552/243194_Plot_5_howarth_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243194/116741552/243194_Plot_5_howarth_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243194/116741552/243194_Plot_5_howarth_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243194/116741552/243194_Plot_5_howarth_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243194/116741552/243194_Plot_5_howarth_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/244k/243194/116741552/243194_Plot_5_howarth_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 585000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "687 Bury Road,\r\nRochdale,\r\nOL11 4AU",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.19705,
            "lat": 53.61051
        },
        "_place": 5.24,
        "_people": 22.85,
        "_property": 2,
        "_price": 115.08,
        "map": property_458
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115795847#",
        "title": "4 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/115795847/191903_30089396_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 280000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Clos Y Dderwen, Cross Hands, Llanelli",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.081763,
            "lat": 51.799766
        },
        "_place": 8.71,
        "_people": 23.51,
        "_property": 4,
        "_price": 98.21,
        "map": property_459
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116970269#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/205k/204359/116970269/204359_257265_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Old Lane, Shevington, Wigan",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.686012,
            "lat": 53.572656
        },
        "_place": 2.26,
        "_people": 23.86,
        "_property": 1,
        "_price": 194.38,
        "map": property_460
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115744430#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 139950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Nursery Cottages, Minard, By Inverary, PA32",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.284985,
            "lat": 56.093531
        },
        "_place": 6.77,
        "_people": 21.04,
        "_property": 1,
        "_price": 136.22,
        "map": property_461
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82656354#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/80k/79866/82656354/79866_4_H644380_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/80k/79866/82656354/79866_4_H644380_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/80k/79866/82656354/79866_4_H644380_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/80k/79866/82656354/79866_4_H644380_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/80k/79866/82656354/79866_4_H644380_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/80k/79866/82656354/79866_4_H644380_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/80k/79866/82656354/79866_4_H644380_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/80k/79866/82656354/79866_4_H644380_IMG_21_0000.jpg",
            "https://media.rightmove.co.uk/80k/79866/82656354/79866_4_H644380_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/80k/79866/82656354/79866_4_H644380_IMG_25_0000.jpg"
        ],
        "price": [
            {
                "value": 334995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "St. Michaels Avenue,\r\nNew Hartley,\r\nNE25",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.51811,
            "lat": 55.08406
        },
        "_place": 11.2,
        "_people": 28.67,
        "_property": 5,
        "_price": 135.46,
        "map": property_462
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107459867#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/107459867/152222_10976096_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 295000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Shore Road, Knock, Point, Isle Of Lewis",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -6.268726,
            "lat": 58.202461
        },
        "_place": 8.94,
        "_people": 19.69,
        "_property": 1,
        "_price": 140.01,
        "map": property_463
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117485060#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/128k/127789/117485060/127789_31158373_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 229950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Manger Gardens, Illingworth, Halifax",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.888,
            "lat": 53.743858
        },
        "_place": 2.36,
        "_people": 26.66,
        "_property": 0,
        "_price": 180.21,
        "map": property_464
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114357023#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/234k/233336/114357023/233336_8032610000010_4_55_101178_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/234k/233336/114357023/233336_8032610000010_4_55_101178_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/234k/233336/114357023/233336_8032610000010_4_55_101178_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/234k/233336/114357023/233336_8032610000010_4_55_101178_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/234k/233336/114357023/233336_8032610000010_4_55_101178_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/234k/233336/114357023/233336_8032610000010_4_55_101178_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/234k/233336/114357023/233336_8032610000010_4_55_101178_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/234k/233336/114357023/233336_8032610000010_4_55_101178_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/234k/233336/114357023/233336_8032610000010_4_55_101178_IMG_09_0000.jpg"
        ],
        "price": [
            {
                "value": 280995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Kennaway Avenue,\r\nPerth,\r\nPerthshire,\r\nPH1 0AW",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.49294,
            "lat": 56.4237
        },
        "_place": 5.89,
        "_people": 33.65,
        "_property": 2,
        "_price": 91.92,
        "map": property_465
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116511299#",
        "title": "2 bedroom barn conversion",
        "images": [
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/59k/58082/116511299/58082_100629007459_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 250000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Main Road, Marsh Lane, Sheffield, S21",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.39684,
            "lat": 53.3057
        },
        "_place": 10.32,
        "_people": 29.49,
        "_property": 2,
        "_price": 106.25,
        "map": property_466
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117660749#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37526/117660749/37526_31164500_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 4,
        "address": "Llys Dyffryn, St. Asaph",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.439214,
            "lat": 53.251693
        },
        "_place": 0.13,
        "_people": 29.42,
        "_property": 4,
        "_price": 135.5,
        "map": property_467
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113978987#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/58k/57859/113978987/57859_30978696_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/113978987/57859_30978696_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/113978987/57859_30978696_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/113978987/57859_30978696_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/113978987/57859_30978696_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/113978987/57859_30978696_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/113978987/57859_30978696_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/113978987/57859_30978696_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/113978987/57859_30978696_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/113978987/57859_30978696_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 725000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Popplyfield Court, Seaton, Seaham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.381981,
            "lat": 54.843963
        },
        "_place": 5.51,
        "_people": 25.37,
        "_property": 0,
        "_price": 115.98,
        "map": property_468
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117056576#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/93k/92921/117056576/92921_101973005063_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92921/117056576/92921_101973005063_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92921/117056576/92921_101973005063_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92921/117056576/92921_101973005063_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92921/117056576/92921_101973005063_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92921/117056576/92921_101973005063_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92921/117056576/92921_101973005063_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92921/117056576/92921_101973005063_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92921/117056576/92921_101973005063_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 225000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Kendal, Purfleet-on-Thames",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": 0.246677,
            "lat": 51.479861
        },
        "_place": 10.27,
        "_people": 20.72,
        "_property": 5,
        "_price": 151.92,
        "map": property_469
    },
    {
        "url": "https://www.rightmove.co.uk/properties/105801503#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151109/105801503/151109_1160754-1_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 240000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Grove Lane, Knottingley, WF11",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.252223,
            "lat": 53.707634
        },
        "_place": 11.14,
        "_people": 32.47,
        "_property": 3,
        "_price": 93.03,
        "map": property_470
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109092602#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96166/109092602/96166_101553003356_IMG_34_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Tippin's Lane, Kirkby-in-Furness, Cumbria",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.185177,
            "lat": 54.212152
        },
        "_place": 5.9,
        "_people": 35.72,
        "_property": 5,
        "_price": 117.32,
        "map": property_471
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115487402#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35507/115487402/35507_31054437_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 795000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Carlyon Bay",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.744962,
            "lat": 50.333652
        },
        "_place": 11.81,
        "_people": 34.14,
        "_property": 0,
        "_price": 112.55,
        "map": property_472
    },
    {
        "url": "https://www.rightmove.co.uk/properties/90875812#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/82k/81089/90875812/81089_DSS210081_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81089/90875812/81089_DSS210081_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81089/90875812/81089_DSS210081_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81089/90875812/81089_DSS210081_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81089/90875812/81089_DSS210081_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81089/90875812/81089_DSS210081_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81089/90875812/81089_DSS210081_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81089/90875812/81089_DSS210081_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81089/90875812/81089_DSS210081_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81089/90875812/81089_DSS210081_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81089/90875812/81089_DSS210081_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81089/90875812/81089_DSS210081_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81089/90875812/81089_DSS210081_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81089/90875812/81089_DSS210081_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81089/90875812/81089_DSS210081_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Orchard Rise, Droitwich Spa, WR9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.159,
            "lat": 52.255564
        },
        "_place": 11.89,
        "_people": 23.79,
        "_property": 1,
        "_price": 137.33,
        "map": property_473
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116272613#",
        "title": "4 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/184k/183563/116272613/183563_ILI210113_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/116272613/183563_ILI210113_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/116272613/183563_ILI210113_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/116272613/183563_ILI210113_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/116272613/183563_ILI210113_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/116272613/183563_ILI210113_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/116272613/183563_ILI210113_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/116272613/183563_ILI210113_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/116272613/183563_ILI210113_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/116272613/183563_ILI210113_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/116272613/183563_ILI210113_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/116272613/183563_ILI210113_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/116272613/183563_ILI210113_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/116272613/183563_ILI210113_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/116272613/183563_ILI210113_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Brettingham Court, Hinton St. George",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.828271,
            "lat": 50.908594
        },
        "_place": 1.73,
        "_people": 24.24,
        "_property": 3,
        "_price": 143.13,
        "map": property_474
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82842243#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13030/82842243/13030_35E_IMG_27_0000.jpeg"
        ],
        "price": [
            {
                "value": 930000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "East Street Fareham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.17046,
            "lat": 50.85318
        },
        "_place": 8.36,
        "_people": 27.01,
        "_property": 4,
        "_price": 92.41,
        "map": property_475
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116405819#",
        "title": "4 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230609/116405819/230609_31107704_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 249950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 4,
        "address": "Station Road, Dunning, Perth",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.588613,
            "lat": 56.31332
        },
        "_place": 9.92,
        "_people": 24.79,
        "_property": 2,
        "_price": 108.41,
        "map": property_476
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116813084#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4821/116813084/4821_BPG210134_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Merchiston Drive, Brookfield, PA5",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.52687,
            "lat": 55.844468
        },
        "_place": 1.79,
        "_people": 25.66,
        "_property": 2,
        "_price": 147.46,
        "map": property_477
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117434213#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38879/117434213/38879_10305765_IMG_30_0000.jpeg"
        ],
        "price": [
            {
                "value": 399950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Mona Drive, Penmaenmawr",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.902678,
            "lat": 53.277172
        },
        "_place": 6.55,
        "_people": 30.79,
        "_property": 4,
        "_price": 182.68,
        "map": property_478
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117594734#",
        "title": "2 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 105000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "16 Helen's Terrace, Kilwinning, KA13 6DB",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.699708,
            "lat": 55.64915
        },
        "_place": 7.48,
        "_people": 26.85,
        "_property": 4,
        "_price": 168.89,
        "map": property_479
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82155150#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38432/82155150/38432_31044437_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Jubilee Way, Rogerstone, Newport",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.057916,
            "lat": 51.584999
        },
        "_place": 5.9,
        "_people": 19.26,
        "_property": 1,
        "_price": 117.33,
        "map": property_480
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81034986#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/80k/79914/81034986/79914_5762136_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79914/81034986/79914_5762136_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79914/81034986/79914_5762136_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79914/81034986/79914_5762136_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79914/81034986/79914_5762136_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79914/81034986/79914_5762136_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79914/81034986/79914_5762136_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79914/81034986/79914_5762136_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79914/81034986/79914_5762136_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79914/81034986/79914_5762136_IMG_00_0001.jpeg"
        ],
        "price": [
            {
                "value": 1100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "St John's Wood Court, St John's Wood, NW8",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.17352,
            "lat": 51.52745
        },
        "_place": 5.67,
        "_people": 19.34,
        "_property": 4,
        "_price": 119.63,
        "map": property_481
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115261715#",
        "title": "7 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5865/115261715/5865_GER214974_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 1100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": 4,
        "address": "Chaplin Road, Wembley, HA0",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.310927,
            "lat": 51.549666
        },
        "_place": 9.53,
        "_people": 24.74,
        "_property": 1,
        "_price": 191.71,
        "map": property_482
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117437801#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/214k/213371/117437801/213371_PLY212320_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/214k/213371/117437801/213371_PLY212320_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/214k/213371/117437801/213371_PLY212320_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/214k/213371/117437801/213371_PLY212320_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/214k/213371/117437801/213371_PLY212320_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/214k/213371/117437801/213371_PLY212320_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/214k/213371/117437801/213371_PLY212320_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/214k/213371/117437801/213371_PLY212320_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/214k/213371/117437801/213371_PLY212320_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/214k/213371/117437801/213371_PLY212320_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/214k/213371/117437801/213371_PLY212320_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/214k/213371/117437801/213371_PLY212320_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/214k/213371/117437801/213371_PLY212320_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/214k/213371/117437801/213371_PLY212320_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Montrose Way, Plymouth, PL5",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.146506,
            "lat": 50.406599
        },
        "_place": 2.93,
        "_people": 27.99,
        "_property": 2,
        "_price": 124.23,
        "map": property_483
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117053327#",
        "title": "4 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/23k/22293/117053327/22293_BAR150246_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22293/117053327/22293_BAR150246_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22293/117053327/22293_BAR150246_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22293/117053327/22293_BAR150246_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22293/117053327/22293_BAR150246_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22293/117053327/22293_BAR150246_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22293/117053327/22293_BAR150246_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22293/117053327/22293_BAR150246_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22293/117053327/22293_BAR150246_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22293/117053327/22293_BAR150246_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 1000000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Barnes Avenue, London, SW13",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.239323,
            "lat": 51.482668
        },
        "_place": 2.15,
        "_people": 31.84,
        "_property": 3,
        "_price": 123.28,
        "map": property_484
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116769521#",
        "title": "2 bedroom cottage",
        "images": [
            "https://media.rightmove.co.uk/57k/56252/116769521/56252_11236826_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56252/116769521/56252_11236826_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56252/116769521/56252_11236826_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56252/116769521/56252_11236826_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56252/116769521/56252_11236826_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56252/116769521/56252_11236826_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56252/116769521/56252_11236826_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56252/116769521/56252_11236826_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56252/116769521/56252_11236826_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56252/116769521/56252_11236826_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56252/116769521/56252_11236826_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 255000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Llangefni, Anglesey",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.346681,
            "lat": 53.242428
        },
        "_place": 10.21,
        "_people": 33.06,
        "_property": 4,
        "_price": 102.44,
        "map": property_485
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108154514#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3055/108154514/3055_30702087_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 270000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Handley Road, New Whittington, Chesterfield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.408421,
            "lat": 53.285984
        },
        "_place": 9.39,
        "_people": 30.17,
        "_property": 1,
        "_price": 106.57,
        "map": property_486
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117145295#",
        "title": "2 bedroom lodge",
        "images": [
            "https://media.rightmove.co.uk/195k/194636/117145295/194636_4083_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117145295/194636_4083_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117145295/194636_4083_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117145295/194636_4083_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117145295/194636_4083_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117145295/194636_4083_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117145295/194636_4083_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117145295/194636_4083_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117145295/194636_4083_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117145295/194636_4083_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/117145295/194636_4083_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 299995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Aldeburgh, Suffolk, IP15 5DW",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.59741,
            "lat": 52.1595
        },
        "_place": 7.78,
        "_people": 28.51,
        "_property": 2,
        "_price": 121.18,
        "map": property_487
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117036689#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/205k/204293/117036689/204293_3b95970c-8151-4748-ba19-8a825bc3cafe-155_4_36_101368_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/205k/204293/117036689/204293_3b95970c-8151-4748-ba19-8a825bc3cafe-155_4_36_101368_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/205k/204293/117036689/204293_3b95970c-8151-4748-ba19-8a825bc3cafe-155_4_36_101368_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/205k/204293/117036689/204293_3b95970c-8151-4748-ba19-8a825bc3cafe-155_4_36_101368_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/205k/204293/117036689/204293_3b95970c-8151-4748-ba19-8a825bc3cafe-155_4_36_101368_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/205k/204293/117036689/204293_3b95970c-8151-4748-ba19-8a825bc3cafe-155_4_36_101368_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/205k/204293/117036689/204293_3b95970c-8151-4748-ba19-8a825bc3cafe-155_4_36_101368_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/205k/204293/117036689/204293_3b95970c-8151-4748-ba19-8a825bc3cafe-155_4_36_101368_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/205k/204293/117036689/204293_3b95970c-8151-4748-ba19-8a825bc3cafe-155_4_36_101368_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/205k/204293/117036689/204293_3b95970c-8151-4748-ba19-8a825bc3cafe-155_4_36_101368_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/205k/204293/117036689/204293_3b95970c-8151-4748-ba19-8a825bc3cafe-155_4_36_101368_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/205k/204293/117036689/204293_3b95970c-8151-4748-ba19-8a825bc3cafe-155_4_36_101368_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/205k/204293/117036689/204293_3b95970c-8151-4748-ba19-8a825bc3cafe-155_4_36_101368_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/205k/204293/117036689/204293_3b95970c-8151-4748-ba19-8a825bc3cafe-155_4_36_101368_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/205k/204293/117036689/204293_3b95970c-8151-4748-ba19-8a825bc3cafe-155_4_36_101368_IMG_15_0000.jpg"
        ],
        "price": [
            {
                "value": 509995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Castle Farm Way TF2 9XA",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.41209,
            "lat": 52.685
        },
        "_place": 4.11,
        "_people": 22.65,
        "_property": 0,
        "_price": 93.9,
        "map": property_488
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114249098#",
        "title": "1 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/45k/44321/114249098/44321_GLG210157_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/114249098/44321_GLG210157_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/114249098/44321_GLG210157_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/114249098/44321_GLG210157_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/114249098/44321_GLG210157_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/114249098/44321_GLG210157_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/114249098/44321_GLG210157_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/114249098/44321_GLG210157_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/114249098/44321_GLG210157_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/114249098/44321_GLG210157_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/114249098/44321_GLG210157_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/114249098/44321_GLG210157_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 135000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "The Cottage, Clachan, Tarbert, PA29",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.5589,
            "lat": 55.74773
        },
        "_place": 7.22,
        "_people": 25.21,
        "_property": 3,
        "_price": 168.53,
        "map": property_489
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111409073#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/93k/92341/111409073/92341_SAS210193_IMG_20_0001.jpeg",
            "https://media.rightmove.co.uk/93k/92341/111409073/92341_SAS210193_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92341/111409073/92341_SAS210193_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92341/111409073/92341_SAS210193_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92341/111409073/92341_SAS210193_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92341/111409073/92341_SAS210193_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92341/111409073/92341_SAS210193_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92341/111409073/92341_SAS210193_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92341/111409073/92341_SAS210193_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92341/111409073/92341_SAS210193_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92341/111409073/92341_SAS210193_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 1175000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 4,
        "address": "Kiln Lane, Leigh, Tonbridge, TN11",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.211564,
            "lat": 51.195731
        },
        "_place": 6.09,
        "_people": 27.54,
        "_property": 1,
        "_price": 145.5,
        "map": property_490
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116902925#",
        "title": "2 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/66k/65755/116902925/65755_WTH100790_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/116902925/65755_WTH100790_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/116902925/65755_WTH100790_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/116902925/65755_WTH100790_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/116902925/65755_WTH100790_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/116902925/65755_WTH100790_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/116902925/65755_WTH100790_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/116902925/65755_WTH100790_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/116902925/65755_WTH100790_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/116902925/65755_WTH100790_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65755/116902925/65755_WTH100790_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 290000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "High Street, Clifford, Wetherby, West Yorkshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.348875,
            "lat": 53.892982
        },
        "_place": 2.62,
        "_people": 26.63,
        "_property": 4,
        "_price": 132.73,
        "map": property_491
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81785088#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/176k/175598/81785088/175598_4_552_IMG_02_0000.JPG",
            "https://media.rightmove.co.uk/176k/175598/81785088/175598_4_552_IMG_03_0000.JPG",
            "https://media.rightmove.co.uk/176k/175598/81785088/175598_4_552_IMG_04_0000.JPG",
            "https://media.rightmove.co.uk/176k/175598/81785088/175598_4_552_IMG_01_0001.JPG",
            "https://media.rightmove.co.uk/176k/175598/81785088/175598_4_552_IMG_05_0000.JPG",
            "https://media.rightmove.co.uk/176k/175598/81785088/175598_4_552_IMG_06_0000.JPG",
            "https://media.rightmove.co.uk/176k/175598/81785088/175598_4_552_IMG_07_0000.JPG",
            "https://media.rightmove.co.uk/176k/175598/81785088/175598_4_552_IMG_08_0000.JPG",
            "https://media.rightmove.co.uk/176k/175598/81785088/175598_4_552_IMG_09_0000.JPG",
            "https://media.rightmove.co.uk/176k/175598/81785088/175598_4_552_IMG_10_0000.JPG"
        ],
        "price": [
            {
                "value": 609995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Randall Avenue\r\nMilton Keynes,\r\nMK8 1AF",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.8099,
            "lat": 52.03242
        },
        "_place": 5.17,
        "_people": 29.27,
        "_property": 2,
        "_price": 135.83,
        "map": property_492
    },
    {
        "url": "https://www.rightmove.co.uk/properties/86648486#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/39k/38693/86648486/38693_BSE180207_IMG_29_0000.jpg",
            "https://media.rightmove.co.uk/39k/38693/86648486/38693_BSE180207_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/39k/38693/86648486/38693_BSE180207_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/39k/38693/86648486/38693_BSE180207_IMG_21_0000.jpg",
            "https://media.rightmove.co.uk/39k/38693/86648486/38693_BSE180207_IMG_23_0000.jpg",
            "https://media.rightmove.co.uk/39k/38693/86648486/38693_BSE180207_IMG_20_0000.jpg",
            "https://media.rightmove.co.uk/39k/38693/86648486/38693_BSE180207_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/39k/38693/86648486/38693_BSE180207_IMG_24_0000.jpg",
            "https://media.rightmove.co.uk/39k/38693/86648486/38693_BSE180207_IMG_25_0000.jpg",
            "https://media.rightmove.co.uk/39k/38693/86648486/38693_BSE180207_IMG_26_0000.jpg",
            "https://media.rightmove.co.uk/39k/38693/86648486/38693_BSE180207_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/39k/38693/86648486/38693_BSE180207_IMG_27_0000.jpg",
            "https://media.rightmove.co.uk/39k/38693/86648486/38693_BSE180207_IMG_28_0000.jpg",
            "https://media.rightmove.co.uk/39k/38693/86648486/38693_BSE180207_IMG_17_0000.jpg"
        ],
        "price": [
            {
                "value": 249950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Nowton, Suffolk",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": 0.72796,
            "lat": 52.220671
        },
        "_place": 8.74,
        "_people": 18.26,
        "_property": 2,
        "_price": 109.34,
        "map": property_493
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116520269#",
        "title": "4 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74681/116520269/74681_386993_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 170000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Fairview Gardens, Norton, Stockton-on-Tees, Durham, TS20 1UA",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.33631,
            "lat": 54.59461
        },
        "_place": 3,
        "_people": 20.17,
        "_property": 0,
        "_price": 111.41,
        "map": property_494
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114880316#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/114880316/238280_S45591_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Burleigh Road,Shiphay,Torquay,TQ2 6JX",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.56414,
            "lat": 50.476067
        },
        "_place": 11.57,
        "_people": 21.86,
        "_property": 3,
        "_price": 180.15,
        "map": property_495
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115043849#",
        "title": "1 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/4k/3734/115043849/3734_21827948_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3734/115043849/3734_21827948_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3734/115043849/3734_21827948_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3734/115043849/3734_21827948_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3734/115043849/3734_21827948_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3734/115043849/3734_21827948_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3734/115043849/3734_21827948_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3734/115043849/3734_21827948_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3734/115043849/3734_21827948_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3734/115043849/3734_21827948_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3734/115043849/3734_21827948_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3734/115043849/3734_21827948_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3734/115043849/3734_21827948_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3734/115043849/3734_21827948_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3734/115043849/3734_21827948_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 220000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 2,
        "address": "Newport Road, Woolstone, Milton Keynes, MK15",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.726932,
            "lat": 52.040082
        },
        "_place": 8.01,
        "_people": 34.04,
        "_property": 4,
        "_price": 113.62,
        "map": property_496
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117008762#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/117008762/95786_42401_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 175000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "17 Busant Drive, Kirkwall, KW15 1XW",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.9785,
            "lat": 58.9835
        },
        "_place": 0.86,
        "_people": 20.61,
        "_property": 2,
        "_price": 161.12,
        "map": property_497
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117409571#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18509/117409571/18509_BRI-GC512ZLKER_IMG_28_0000.jpeg"
        ],
        "price": [
            {
                "value": 324950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Foxcroft Drive, Brighouse, HD6",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.79702,
            "lat": 53.6925
        },
        "_place": 5.38,
        "_people": 34.6,
        "_property": 4,
        "_price": 103.42,
        "map": property_498
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117326474#",
        "title": "3 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/1k/147/117326474/147_CRO210252_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Woodland Rise West, Sheringham, NR26",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.20956,
            "lat": 52.9311
        },
        "_place": 0.58,
        "_people": 26.23,
        "_property": 2,
        "_price": 95.44,
        "map": property_499
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113173865#",
        "title": "5 bedroom town house",
        "images": [
            "https://media.rightmove.co.uk/231k/230411/113173865/230411_21292382_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230411/113173865/230411_21292382_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230411/113173865/230411_21292382_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230411/113173865/230411_21292382_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230411/113173865/230411_21292382_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230411/113173865/230411_21292382_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230411/113173865/230411_21292382_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230411/113173865/230411_21292382_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230411/113173865/230411_21292382_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230411/113173865/230411_21292382_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230411/113173865/230411_21292382_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230411/113173865/230411_21292382_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230411/113173865/230411_21292382_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230411/113173865/230411_21292382_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/231k/230411/113173865/230411_21292382_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 270000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "North Guildry Street, Elgin, IV30",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.315758,
            "lat": 57.646853
        },
        "_place": 8.7,
        "_people": 30.21,
        "_property": 5,
        "_price": 100.2,
        "map": property_500
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116900666#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104497/116900666/104497_4BRACKENCLOSE_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 250000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Bracken Close, Kettering, Northamptonshire, NN16",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.72625,
            "lat": 52.41518
        },
        "_place": 11.14,
        "_people": 24.24,
        "_property": 0,
        "_price": 195.7,
        "map": property_501
    },
    {
        "url": "https://www.rightmove.co.uk/properties/102591995#",
        "title": "2 bedroom retirement property",
        "images": [
            "https://media.rightmove.co.uk/84k/83903/102591995/83903_10770810_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/102591995/83903_10770810_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/102591995/83903_10770810_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/102591995/83903_10770810_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/102591995/83903_10770810_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/102591995/83903_10770810_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/102591995/83903_10770810_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/102591995/83903_10770810_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/102591995/83903_10770810_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/102591995/83903_10770810_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/102591995/83903_10770810_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/102591995/83903_10770810_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/102591995/83903_10770810_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83903/102591995/83903_10770810_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 275000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Hayes End Manor, South Petherton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.803563,
            "lat": 50.946804
        },
        "_place": 6.2,
        "_people": 34.26,
        "_property": 2,
        "_price": 182.68,
        "map": property_502
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116579267#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2027/116579267/2027_IPS116485_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 300000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Church Close, Lower Somersham, Ipswich",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.05061,
            "lat": 52.09533
        },
        "_place": 4.72,
        "_people": 33.28,
        "_property": 2,
        "_price": 191.72,
        "map": property_503
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113469866#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/219k/218729/113469866/218729_30953185_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/219k/218729/113469866/218729_30953185_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/219k/218729/113469866/218729_30953185_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/219k/218729/113469866/218729_30953185_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/219k/218729/113469866/218729_30953185_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/219k/218729/113469866/218729_30953185_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/219k/218729/113469866/218729_30953185_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/219k/218729/113469866/218729_30953185_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 950000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Caversham Road, Kentish Town",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.137988,
            "lat": 51.548764
        },
        "_place": 9.6,
        "_people": 34.73,
        "_property": 3,
        "_price": 175.81,
        "map": property_504
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114459908#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/114459908/235532_1257053-1_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 260000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Fort William Road, Newtonmore, PH20",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.128615,
            "lat": 57.063093
        },
        "_place": 8.47,
        "_people": 24.56,
        "_property": 1,
        "_price": 163.15,
        "map": property_505
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117191372#",
        "title": "2 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15769/117191372/15769_31143988_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 105000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Burns Drive, Johnstone",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.515118,
            "lat": 55.825576
        },
        "_place": 3.37,
        "_people": 24.37,
        "_property": 0,
        "_price": 93.19,
        "map": property_506
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110668745#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_28_0001.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35312/110668745/35312_30818088_IMG_18_0001.jpeg"
        ],
        "price": [
            {
                "value": 485000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Cringle Moor Chase, Great Broughton, Middlesbrough",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.149158,
            "lat": 54.444587
        },
        "_place": 8.15,
        "_people": 18.45,
        "_property": 5,
        "_price": 192.95,
        "map": property_507
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114982532#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189485/114982532/189485_21457527_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 525000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Brent Street, Highbridge, TA9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.953499,
            "lat": 51.248964
        },
        "_place": 5.32,
        "_people": 27.82,
        "_property": 4,
        "_price": 103.87,
        "map": property_508
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116224325#",
        "title": "2 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109054/116224325/109054_100465010740_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 155000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Midhill Road, Heeley",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.46485,
            "lat": 53.36373
        },
        "_place": 2.82,
        "_people": 35.39,
        "_property": 4,
        "_price": 107.8,
        "map": property_509
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114190919#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/90k/89389/114190919/89389_10509039_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 675000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Ulnes Walton Lane, Ulnes Walton, PR26 8LT",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.736479,
            "lat": 53.677597
        },
        "_place": 11.53,
        "_people": 24.48,
        "_property": 0,
        "_price": 150.32,
        "map": property_510
    },
    {
        "url": "https://www.rightmove.co.uk/properties/72933819#",
        "title": "5 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/101k/100624/72933819/100624_29565130_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/101k/100624/72933819/100624_29565130_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/101k/100624/72933819/100624_29565130_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/101k/100624/72933819/100624_29565130_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/101k/100624/72933819/100624_29565130_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/101k/100624/72933819/100624_29565130_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/101k/100624/72933819/100624_29565130_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/101k/100624/72933819/100624_29565130_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/101k/100624/72933819/100624_29565130_IMG_09_0000.jpg"
        ],
        "price": [
            {
                "value": 965000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Norbury Avenue, Norbury",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.116548,
            "lat": 51.409901
        },
        "_place": 5.91,
        "_people": 18.99,
        "_property": 2,
        "_price": 139.37,
        "map": property_511
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113285624#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/29k/28749/113285624/28749_406795_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/29k/28749/113285624/28749_406795_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/29k/28749/113285624/28749_406795_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/29k/28749/113285624/28749_406795_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/29k/28749/113285624/28749_406795_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/29k/28749/113285624/28749_406795_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/29k/28749/113285624/28749_406795_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/29k/28749/113285624/28749_406795_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/29k/28749/113285624/28749_406795_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/29k/28749/113285624/28749_406795_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/29k/28749/113285624/28749_406795_IMG_10_0001.jpeg"
        ],
        "price": [
            {
                "value": 170000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Osborne Avenue, South Shields",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.418273,
            "lat": 54.992458
        },
        "_place": 0.76,
        "_people": 32.1,
        "_property": 5,
        "_price": 175.1,
        "map": property_512
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115744430#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/115744430/93913_COLQA0201-t-4062_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 139950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Nursery Cottages, Minard, By Inverary, PA32",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.284985,
            "lat": 56.093531
        },
        "_place": 5.82,
        "_people": 18.81,
        "_property": 1,
        "_price": 157.18,
        "map": property_513
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110035505#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/62k/61524/110035505/61524_11033385_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61524/110035505/61524_11033385_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61524/110035505/61524_11033385_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61524/110035505/61524_11033385_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61524/110035505/61524_11033385_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61524/110035505/61524_11033385_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61524/110035505/61524_11033385_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61524/110035505/61524_11033385_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61524/110035505/61524_11033385_IMG_08_0001.jpeg"
        ],
        "price": [
            {
                "value": 339950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Portsmouth Road, Milford",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.640579,
            "lat": 51.171642
        },
        "_place": 7.31,
        "_people": 27.8,
        "_property": 1,
        "_price": 139.58,
        "map": property_514
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114488654#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/38k/37955/114488654/37955_RIC080204_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37955/114488654/37955_RIC080204_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37955/114488654/37955_RIC080204_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37955/114488654/37955_RIC080204_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37955/114488654/37955_RIC080204_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37955/114488654/37955_RIC080204_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37955/114488654/37955_RIC080204_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37955/114488654/37955_RIC080204_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37955/114488654/37955_RIC080204_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37955/114488654/37955_RIC080204_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37955/114488654/37955_RIC080204_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37955/114488654/37955_RIC080204_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 1050000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Burlington House, Kings Road, Richmond, Surrey, TW10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.294945,
            "lat": 51.4566
        },
        "_place": 8.52,
        "_people": 28.77,
        "_property": 4,
        "_price": 143.41,
        "map": property_515
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116901464#",
        "title": "5 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55546/116901464/55546_2098283_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 1150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Tulsemere Road, West Norwood",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.09754,
            "lat": 51.43776
        },
        "_place": 0.72,
        "_people": 20.29,
        "_property": 3,
        "_price": 179.99,
        "map": property_516
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111938192#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/111938192/77234_VAE210321_IMG_29_0000.jpeg"
        ],
        "price": [
            {
                "value": 650000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Trefor, Caernarfon, Gwynedd, LL54",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.42356,
            "lat": 52.992
        },
        "_place": 6.48,
        "_people": 24.82,
        "_property": 2,
        "_price": 99.16,
        "map": property_517
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114603899#",
        "title": "4 bedroom link detached house",
        "images": [
            "https://media.rightmove.co.uk/78k/77088/114603899/77088_261758_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77088/114603899/77088_261758_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77088/114603899/77088_261758_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/114603899/77088_261758_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/114603899/77088_261758_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/114603899/77088_261758_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/114603899/77088_261758_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/114603899/77088_261758_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/114603899/77088_261758_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/114603899/77088_261758_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/114603899/77088_261758_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/114603899/77088_261758_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/114603899/77088_261758_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/114603899/77088_261758_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 300000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Old Bolingbroke, Nr. Spilsby",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.017261,
            "lat": 53.166845
        },
        "_place": 7.78,
        "_people": 19.08,
        "_property": 5,
        "_price": 185.18,
        "map": property_518
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115144997#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/203k/202925/115144997/202925_1252981-1_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Sandpit Close, Ipswich, IP4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.216192,
            "lat": 52.05213
        },
        "_place": 8.56,
        "_people": 35.07,
        "_property": 1,
        "_price": 197.26,
        "map": property_520
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82223550#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/215k/214334/82223550/214334_5_26_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214334/82223550/214334_5_26_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214334/82223550/214334_5_26_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214334/82223550/214334_5_26_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214334/82223550/214334_5_26_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214334/82223550/214334_5_26_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214334/82223550/214334_5_26_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214334/82223550/214334_5_26_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214334/82223550/214334_5_26_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214334/82223550/214334_5_26_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214334/82223550/214334_5_26_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214334/82223550/214334_5_26_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214334/82223550/214334_5_26_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 319950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "Junction Road,\r\nNorton,\r\nStockton-On-Tees,\r\nTS20",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.3238,
            "lat": 54.594
        },
        "_place": 3.83,
        "_people": 31.91,
        "_property": 5,
        "_price": 96.15,
        "map": property_521
    },
    {
        "url": "https://www.rightmove.co.uk/properties/106341473#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/13k/12872/106341473/12872_RUS190029_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12872/106341473/12872_RUS190029_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12872/106341473/12872_RUS190029_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12872/106341473/12872_RUS190029_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12872/106341473/12872_RUS190029_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12872/106341473/12872_RUS190029_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12872/106341473/12872_RUS190029_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12872/106341473/12872_RUS190029_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12872/106341473/12872_RUS190029_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12872/106341473/12872_RUS190029_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 725000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Ladygate Lane, Ruislip, Middlesex, HA4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.439049,
            "lat": 51.581078
        },
        "_place": 11.4,
        "_people": 20.54,
        "_property": 5,
        "_price": 122.21,
        "map": property_522
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116249183#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28902/116249183/28902_100659007347_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Kings Drive, Hopton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.081,
            "lat": 52.83132
        },
        "_place": 11.02,
        "_people": 26.51,
        "_property": 2,
        "_price": 125.01,
        "map": property_523
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115509923#",
        "title": "Plot",
        "images": [
            "https://media.rightmove.co.uk/51k/50283/115509923/50283_31062356_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50283/115509923/50283_31062356_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50283/115509923/50283_31062356_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50283/115509923/50283_31062356_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50283/115509923/50283_31062356_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Talybont",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.095378,
            "lat": 52.778144
        },
        "_place": 5.04,
        "_people": 29.01,
        "_property": 5,
        "_price": 170.11,
        "map": property_524
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116782586#",
        "title": "3 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105694/116782586/105694_31124825_IMG_28_0000.jpeg"
        ],
        "price": [
            {
                "value": 269950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Kateholm, Bacup",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.196551,
            "lat": 53.72533
        },
        "_place": 6.33,
        "_people": 33.53,
        "_property": 3,
        "_price": 114.41,
        "map": property_525
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111650228#",
        "title": "3 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/5k/4952/111650228/4952_LOH210161_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4952/111650228/4952_LOH210161_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4952/111650228/4952_LOH210161_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4952/111650228/4952_LOH210161_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4952/111650228/4952_LOH210161_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4952/111650228/4952_LOH210161_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4952/111650228/4952_LOH210161_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4952/111650228/4952_LOH210161_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4952/111650228/4952_LOH210161_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4952/111650228/4952_LOH210161_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4952/111650228/4952_LOH210161_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4952/111650228/4952_LOH210161_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4952/111650228/4952_LOH210161_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4952/111650228/4952_LOH210161_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4952/111650228/4952_LOH210161_IMG_14_0001.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Forest Road, Loughborough, Leicestershire, ., LE11",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.22888,
            "lat": 52.759918
        },
        "_place": 10.38,
        "_people": 19.74,
        "_property": 0,
        "_price": 158.03,
        "map": property_526
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112476965#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/78k/77776/112476965/77776_OXP104006_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/112476965/77776_OXP104006_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/112476965/77776_OXP104006_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/112476965/77776_OXP104006_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/112476965/77776_OXP104006_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/112476965/77776_OXP104006_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/112476965/77776_OXP104006_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/112476965/77776_OXP104006_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/112476965/77776_OXP104006_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/112476965/77776_OXP104006_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/112476965/77776_OXP104006_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/112476965/77776_OXP104006_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/112476965/77776_OXP104006_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 395000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Singleton Drive, Grange Farm, Milton Keynes",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.80931,
            "lat": 52.02171
        },
        "_place": 11.59,
        "_people": 20.57,
        "_property": 2,
        "_price": 122.29,
        "map": property_527
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113820647#",
        "title": "2 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/238k/237155/113820647/237155_2_10_IMG_03_0044.jpg",
            "https://media.rightmove.co.uk/238k/237155/113820647/237155_2_10_IMG_04_0031.jpg",
            "https://media.rightmove.co.uk/238k/237155/113820647/237155_2_10_IMG_01_0062.jpg",
            "https://media.rightmove.co.uk/238k/237155/113820647/237155_2_10_IMG_02_0030.jpg",
            "https://media.rightmove.co.uk/238k/237155/113820647/237155_2_10_IMG_21_0001.jpg",
            "https://media.rightmove.co.uk/238k/237155/113820647/237155_2_10_IMG_22_0002.jpg",
            "https://media.rightmove.co.uk/238k/237155/113820647/237155_2_10_IMG_23_0002.jpg",
            "https://media.rightmove.co.uk/238k/237155/113820647/237155_2_10_IMG_24_0003.jpg",
            "https://media.rightmove.co.uk/238k/237155/113820647/237155_2_10_IMG_25_0002.jpg",
            "https://media.rightmove.co.uk/238k/237155/113820647/237155_2_10_IMG_26_0002.jpg",
            "https://media.rightmove.co.uk/238k/237155/113820647/237155_2_10_IMG_27_0001.jpg",
            "https://media.rightmove.co.uk/238k/237155/113820647/237155_2_10_IMG_28_0001.jpg",
            "https://media.rightmove.co.uk/238k/237155/113820647/237155_2_10_IMG_29_0000.jpg",
            "https://media.rightmove.co.uk/238k/237155/113820647/237155_2_10_IMG_30_0000.jpg"
        ],
        "price": [
            {
                "value": 180000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "off Newton Park,\r\nKirkhill,\r\nIV5 7QB",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.40752,
            "lat": 57.47772
        },
        "_place": 9.4,
        "_people": 31.98,
        "_property": 0,
        "_price": 183.54,
        "map": property_528
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117658292#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28688/117658292/28688_1kirkdale_IMG_02_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Highfield Lane, YO62",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.99499,
            "lat": 54.25711
        },
        "_place": 3.37,
        "_people": 30.41,
        "_property": 0,
        "_price": 99.23,
        "map": property_529
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114370901#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238916/114370901/238916_14672277_10083821_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 300000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Kyle Croft ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.408422,
            "lat": 58.287794
        },
        "_place": 0.43,
        "_people": 22.39,
        "_property": 5,
        "_price": 165.82,
        "map": property_530
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114255905#",
        "title": "3 bedroom maisonette",
        "images": [
            "https://media.rightmove.co.uk/92k/91745/114255905/91745_fel117_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91745/114255905/91745_fel117_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91745/114255905/91745_fel117_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91745/114255905/91745_fel117_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91745/114255905/91745_fel117_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91745/114255905/91745_fel117_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91745/114255905/91745_fel117_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91745/114255905/91745_fel117_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91745/114255905/91745_fel117_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 149950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Y Felinheli, Caernarfonshire, LL56",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.20637,
            "lat": 53.18891
        },
        "_place": 3.21,
        "_people": 21.02,
        "_property": 5,
        "_price": 167.2,
        "map": property_531
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116972405#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/104k/103936/116972405/103936_756322_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103936/116972405/103936_756322_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103936/116972405/103936_756322_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103936/116972405/103936_756322_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103936/116972405/103936_756322_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103936/116972405/103936_756322_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103936/116972405/103936_756322_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103936/116972405/103936_756322_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103936/116972405/103936_756322_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103936/116972405/103936_756322_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103936/116972405/103936_756322_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103936/116972405/103936_756322_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103936/116972405/103936_756322_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103936/116972405/103936_756322_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Heaton Road, Heaton Moor",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.175431,
            "lat": 53.4186
        },
        "_place": 11.49,
        "_people": 19.69,
        "_property": 4,
        "_price": 168.54,
        "map": property_532
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117207818#",
        "title": "5 bedroom link detached house",
        "images": [
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107954/117207818/107954_3bb0498c-804c-466d-b3c6-6e7339413d01_IMG_37_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Mill Lane, Dronfield, S18",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.46504,
            "lat": 53.30009
        },
        "_place": 10.92,
        "_people": 35.87,
        "_property": 4,
        "_price": 140.09,
        "map": property_533
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114780587#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/152k/151121/114780587/151121_11190368_IMG_35_0000.jpeg"
        ],
        "price": [
            {
                "value": 625000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Kenwick Views, Louth",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.009793,
            "lat": 53.355156
        },
        "_place": 10.67,
        "_people": 28.55,
        "_property": 3,
        "_price": 142.84,
        "map": property_534
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114382553#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_20_0001.jpeg",
            "https://media.rightmove.co.uk/120k/119425/114382553/119425_SEF180341_IMG_21_0001.jpeg"
        ],
        "price": [
            {
                "value": 325000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Litherland Park, Litherland, L21",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.00197,
            "lat": 53.472994
        },
        "_place": 6.29,
        "_people": 32.66,
        "_property": 1,
        "_price": 104.66,
        "map": property_535
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110293595#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/110293595/237629_4821_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 359000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "80,  Ballabrooie Drive, Douglas",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.499853,
            "lat": 54.155819
        },
        "_place": 2.38,
        "_people": 20.67,
        "_property": 3,
        "_price": 93.6,
        "map": property_536
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115021799#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/22k/21704/115021799/21704_MIN210112_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 460000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Washford, Somerset, TA23",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.360135,
            "lat": 51.161176
        },
        "_place": 10.08,
        "_people": 20.25,
        "_property": 1,
        "_price": 115.73,
        "map": property_537
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113902469#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77153/113902469/77153_30974594_IMG_17_0001.jpeg"
        ],
        "price": [
            {
                "value": 625000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "137 Tally Ho Road, Shadoxhurst, Ashford",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.823157,
            "lat": 51.108871
        },
        "_place": 6.43,
        "_people": 25.55,
        "_property": 1,
        "_price": 153.88,
        "map": property_538
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113252942#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/53k/52785/113252942/52785_HIH210108_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52785/113252942/52785_HIH210108_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52785/113252942/52785_HIH210108_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52785/113252942/52785_HIH210108_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52785/113252942/52785_HIH210108_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52785/113252942/52785_HIH210108_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52785/113252942/52785_HIH210108_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52785/113252942/52785_HIH210108_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52785/113252942/52785_HIH210108_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52785/113252942/52785_HIH210108_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52785/113252942/52785_HIH210108_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52785/113252942/52785_HIH210108_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 985000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Monsell Road, London, N4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.101311,
            "lat": 51.561001
        },
        "_place": 11.47,
        "_people": 29.2,
        "_property": 5,
        "_price": 128.4,
        "map": property_539
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114381347#",
        "title": "7 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_21_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_22_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_24_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_23_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_25_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/216k/215357/114381347/215357_102903010370_IMG_20_0001.jpeg"
        ],
        "price": [
            {
                "value": 800000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": 2,
        "address": "Church Street, St. Ives, PE27",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.07942,
            "lat": 52.32723
        },
        "_place": 2.35,
        "_people": 33.85,
        "_property": 3,
        "_price": 127.27,
        "map": property_540
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116153129#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82286/116153129/82286_100864011395_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 340000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Manor Occupation Road, Royston, Barnsley",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.46142,
            "lat": 53.59949
        },
        "_place": 10.24,
        "_people": 33.21,
        "_property": 1,
        "_price": 128.1,
        "map": property_541
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116757008#",
        "title": "2 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/116757008/74081_SS588049_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 145000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "The Bungalow, Walls, Shetland, Shetland Islands, ZE2 9PF",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.5574,
            "lat": 60.22467
        },
        "_place": 2.56,
        "_people": 26.08,
        "_property": 3,
        "_price": 179.02,
        "map": property_542
    },
    {
        "url": "https://www.rightmove.co.uk/properties/69928344#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/13k/12522/69928344/12522_10191135_IMG_26_0000.jpg",
            "https://media.rightmove.co.uk/13k/12522/69928344/12522_10191135_IMG_11_0001.jpg",
            "https://media.rightmove.co.uk/13k/12522/69928344/12522_10191135_IMG_20_0000.jpg",
            "https://media.rightmove.co.uk/13k/12522/69928344/12522_10191135_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/13k/12522/69928344/12522_10191135_IMG_25_0000.jpg",
            "https://media.rightmove.co.uk/13k/12522/69928344/12522_10191135_IMG_23_0000.jpg",
            "https://media.rightmove.co.uk/13k/12522/69928344/12522_10191135_IMG_21_0000.jpg",
            "https://media.rightmove.co.uk/13k/12522/69928344/12522_10191135_IMG_27_0000.jpg",
            "https://media.rightmove.co.uk/13k/12522/69928344/12522_10191135_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/13k/12522/69928344/12522_10191135_IMG_24_0000.jpg",
            "https://media.rightmove.co.uk/13k/12522/69928344/12522_10191135_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/13k/12522/69928344/12522_10191135_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/13k/12522/69928344/12522_10191135_IMG_12_0000.jpg"
        ],
        "price": [
            {
                "value": 130000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Fromow Gardens, Windlesham, GU20 6QN",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.656125,
            "lat": 51.363323
        },
        "_place": 11.33,
        "_people": 32.47,
        "_property": 2,
        "_price": 132.29,
        "map": property_543
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82876017#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_08_0000.png",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_09_0000.png",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_10_0000.png",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_11_0000.png",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241196/82876017/241196_newFMV_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 799950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "2D & 2E Welwyn Road,\r\nHertford,\r\nSG14 2HG",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.10997,
            "lat": 51.80152
        },
        "_place": 7.98,
        "_people": 31.44,
        "_property": 4,
        "_price": 188.84,
        "map": property_544
    },
    {
        "url": "https://www.rightmove.co.uk/properties/87968053#",
        "title": "4 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/52k/51511/87968053/51511_969080_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/52k/51511/87968053/51511_969080_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/52k/51511/87968053/51511_969080_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/52k/51511/87968053/51511_969080_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/52k/51511/87968053/51511_969080_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/52k/51511/87968053/51511_969080_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/52k/51511/87968053/51511_969080_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/52k/51511/87968053/51511_969080_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/52k/51511/87968053/51511_969080_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/52k/51511/87968053/51511_969080_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/52k/51511/87968053/51511_969080_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/52k/51511/87968053/51511_969080_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/52k/51511/87968053/51511_969080_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/52k/51511/87968053/51511_969080_IMG_14_0001.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Southampton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.390773,
            "lat": 50.893896
        },
        "_place": 1.96,
        "_people": 30.92,
        "_property": 3,
        "_price": 170.2,
        "map": property_545
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114492203#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/112k/111857/114492203/111857_CLI650551_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111857/114492203/111857_CLI650551_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111857/114492203/111857_CLI650551_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111857/114492203/111857_CLI650551_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111857/114492203/111857_CLI650551_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111857/114492203/111857_CLI650551_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111857/114492203/111857_CLI650551_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111857/114492203/111857_CLI650551_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111857/114492203/111857_CLI650551_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111857/114492203/111857_CLI650551_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111857/114492203/111857_CLI650551_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111857/114492203/111857_CLI650551_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111857/114492203/111857_CLI650551_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/112k/111857/114492203/111857_CLI650551_IMG_13_0001.jpeg"
        ],
        "price": [
            {
                "value": 1100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Rosscourt Mansions, 4 Palace Street, London, SW1E",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.143129,
            "lat": 51.498884
        },
        "_place": 10.86,
        "_people": 27.21,
        "_property": 4,
        "_price": 164.15,
        "map": property_546
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116162060#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/101k/100183/116162060/100183_LWN48706_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100183/116162060/100183_LWN48706_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100183/116162060/100183_LWN48706_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100183/116162060/100183_LWN48706_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100183/116162060/100183_LWN48706_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100183/116162060/100183_LWN48706_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100183/116162060/100183_LWN48706_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100183/116162060/100183_LWN48706_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100183/116162060/100183_LWN48706_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100183/116162060/100183_LWN48706_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100183/116162060/100183_LWN48706_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100183/116162060/100183_LWN48706_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100183/116162060/100183_LWN48706_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100183/116162060/100183_LWN48706_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/101k/100183/116162060/100183_LWN48706_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 1110000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "The Makers, Nile Street, N1",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.09025,
            "lat": 51.52955
        },
        "_place": 5.48,
        "_people": 24.4,
        "_property": 0,
        "_price": 199.54,
        "map": property_547
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113449514#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_12_0000.png",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_10_0000.png",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118942/113449514/118942_SN01274_IMG_43_0000.jpeg"
        ],
        "price": [
            {
                "value": 215000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "**LAST REMAINING TWO BEDROOM ON FOURTH FLOOR**Plot 405 Hampden House, Arlesey, Bedfordshire, SG15",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.26684,
            "lat": 52.0032
        },
        "_place": 4.76,
        "_people": 18.94,
        "_property": 0,
        "_price": 116.23,
        "map": property_548
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115125386#",
        "title": "2 bedroom cottage",
        "images": [
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95786/115125386/95786_41826_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 185000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Scarrataing, Sandwick, KW16 3JB",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.2762,
            "lat": 59.0412
        },
        "_place": 3.58,
        "_people": 30.64,
        "_property": 0,
        "_price": 102.84,
        "map": property_549
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114333161#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/4k/3487/114333161/3487_TAT210235_IMG_15_0001.jpeg"
        ],
        "price": [
            {
                "value": 230000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Woodcroft, Crapstone, Yelverton, Devon, PL20",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.11219,
            "lat": 50.49056
        },
        "_place": 1.45,
        "_people": 23.71,
        "_property": 3,
        "_price": 175.4,
        "map": property_550
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109399436#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/63k/62200/109399436/62200_30755298_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62200/109399436/62200_30755298_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62200/109399436/62200_30755298_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62200/109399436/62200_30755298_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62200/109399436/62200_30755298_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62200/109399436/62200_30755298_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62200/109399436/62200_30755298_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62200/109399436/62200_30755298_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62200/109399436/62200_30755298_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62200/109399436/62200_30755298_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62200/109399436/62200_30755298_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62200/109399436/62200_30755298_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62200/109399436/62200_30755298_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62200/109399436/62200_30755298_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Newbury Park, Ledbury, Herefordshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.428552,
            "lat": 52.042791
        },
        "_place": 11.03,
        "_people": 23.33,
        "_property": 5,
        "_price": 93.89,
        "map": property_551
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117131708#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34514/117131708/34514_31140707_IMG_31_0000.jpeg"
        ],
        "price": [
            {
                "value": 329995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Mount Park, Springwell Village",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.553176,
            "lat": 54.915836
        },
        "_place": 9.03,
        "_people": 25.38,
        "_property": 2,
        "_price": 156.26,
        "map": property_552
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116520701#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/169k/168656/116520701/168656_101177008004_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/116520701/168656_101177008004_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/116520701/168656_101177008004_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/116520701/168656_101177008004_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/116520701/168656_101177008004_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/116520701/168656_101177008004_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/116520701/168656_101177008004_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/116520701/168656_101177008004_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/116520701/168656_101177008004_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/169k/168656/116520701/168656_101177008004_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Beccles Road, Gorleston",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.71668,
            "lat": 52.5835
        },
        "_place": 7.97,
        "_people": 18.92,
        "_property": 1,
        "_price": 199.55,
        "map": property_553
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113575718#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/129k/128515/113575718/128515_DUS210405_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 700000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Osborne Road, Dunstable, LU6",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.525435,
            "lat": 51.881044
        },
        "_place": 4.44,
        "_people": 26.47,
        "_property": 2,
        "_price": 167.55,
        "map": property_554
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115493726#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 227000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Roselynn, Gibblestone Road, Scalloway, Shetland, Shetland Islands, ZE1 0TX",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.2766,
            "lat": 60.13885
        },
        "_place": 1,
        "_people": 22.11,
        "_property": 5,
        "_price": 93.61,
        "map": property_555
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116618777#",
        "title": "4 bedroom town house",
        "images": [
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23981/116618777/23981_22001087_IMG_44_0000.jpeg"
        ],
        "price": [
            {
                "value": 310000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": " Howey, Llandrindod Wells, LD1",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.389788,
            "lat": 52.217815
        },
        "_place": 8.75,
        "_people": 35.42,
        "_property": 4,
        "_price": 127.41,
        "map": property_557
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114847619#",
        "title": "4 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/114847619/9407_31026998_IMG_25_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Smithy Hill, Bradwell, Hope Valley",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.743559,
            "lat": 53.327285
        },
        "_place": 2.17,
        "_people": 31.91,
        "_property": 4,
        "_price": 140.55,
        "map": property_558
    },
    {
        "url": "https://www.rightmove.co.uk/properties/88305961#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/49k/48758/88305961/48758_GLD210019_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48758/88305961/48758_GLD210019_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48758/88305961/48758_GLD210019_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48758/88305961/48758_GLD210019_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48758/88305961/48758_GLD210019_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48758/88305961/48758_GLD210019_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48758/88305961/48758_GLD210019_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48758/88305961/48758_GLD210019_IMG_07_0001.jpeg"
        ],
        "price": [
            {
                "value": 159995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Plot 7 - The Millhouse, Bridge Street, Paisley, Renfrewshire, PA1",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.42104,
            "lat": 55.843177
        },
        "_place": 5.98,
        "_people": 34.35,
        "_property": 1,
        "_price": 197.74,
        "map": property_559
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81947505#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/8k/7011/81947505/7011_PFHCC_641095_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7011/81947505/7011_PFHCC_641095_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7011/81947505/7011_PFHCC_641095_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7011/81947505/7011_PFHCC_641095_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7011/81947505/7011_PFHCC_641095_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7011/81947505/7011_PFHCC_641095_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7011/81947505/7011_PFHCC_641095_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7011/81947505/7011_PFHCC_641095_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7011/81947505/7011_PFHCC_641095_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7011/81947505/7011_PFHCC_641095_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7011/81947505/7011_PFHCC_641095_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7011/81947505/7011_PFHCC_641095_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7011/81947505/7011_PFHCC_641095_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7011/81947505/7011_PFHCC_641095_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 469950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "William Price Gardens, Fareham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.182138,
            "lat": 50.85761
        },
        "_place": 9.7,
        "_people": 29.06,
        "_property": 3,
        "_price": 157.98,
        "map": property_560
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117602345#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/33k/32489/117602345/32489_8732_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/117602345/32489_8732_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/117602345/32489_8732_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/117602345/32489_8732_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/117602345/32489_8732_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/117602345/32489_8732_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/117602345/32489_8732_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/117602345/32489_8732_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/117602345/32489_8732_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/117602345/32489_8732_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32489/117602345/32489_8732_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Tregenna Fields, Camborne, Cornwall",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.306189,
            "lat": 50.208853
        },
        "_place": 5.12,
        "_people": 30.86,
        "_property": 5,
        "_price": 116.34,
        "map": property_561
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115651343#",
        "title": "7 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140282/115651343/140282_101587015207_IMG_35_0000.jpeg"
        ],
        "price": [
            {
                "value": 900000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": 4,
        "address": "Leziate, King's Lynn",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.48883,
            "lat": 52.74419
        },
        "_place": 9.91,
        "_people": 18.97,
        "_property": 5,
        "_price": 90.8,
        "map": property_562
    },
    {
        "url": "https://www.rightmove.co.uk/properties/80273739#",
        "title": "4 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/80273739/46562_WIM210032_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 1175000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Park Road, Kingston, Surrey, KT2",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.28801,
            "lat": 51.41543
        },
        "_place": 10.77,
        "_people": 21.83,
        "_property": 5,
        "_price": 141.98,
        "map": property_563
    },
    {
        "url": "https://www.rightmove.co.uk/properties/105644933#",
        "title": "5 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/85k/84512/105644933/84512_201098967_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/85k/84512/105644933/84512_201098967_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/85k/84512/105644933/84512_201098967_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/85k/84512/105644933/84512_201098967_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/85k/84512/105644933/84512_201098967_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/85k/84512/105644933/84512_201098967_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/85k/84512/105644933/84512_201098967_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/85k/84512/105644933/84512_201098967_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/85k/84512/105644933/84512_201098967_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84512/105644933/84512_201098967_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84512/105644933/84512_201098967_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84512/105644933/84512_201098967_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Gyllyngdune Gardens, Ilford, IG3",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.103108,
            "lat": 51.554928
        },
        "_place": 8,
        "_people": 34.87,
        "_property": 3,
        "_price": 128.01,
        "map": property_564
    },
    {
        "url": "https://www.rightmove.co.uk/properties/83003754#",
        "title": "1 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/83003754/40100_OBN210050_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 230000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Craigmore Cottage, Gruline, Isle of Mull, PA71",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.983463,
            "lat": 56.487746
        },
        "_place": 0.57,
        "_people": 34.69,
        "_property": 3,
        "_price": 104.29,
        "map": property_565
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82663644#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30662/82663644/30662_11228404_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 185000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Ferry Approach, South Shields",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.438898,
            "lat": 54.997551
        },
        "_place": 7.34,
        "_people": 33.1,
        "_property": 1,
        "_price": 151.16,
        "map": property_566
    },
    {
        "url": "https://www.rightmove.co.uk/properties/98663351#",
        "title": "6 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/1k/128/98663351/128_ALD190058_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/1k/128/98663351/128_ALD190058_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/1k/128/98663351/128_ALD190058_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/1k/128/98663351/128_ALD190058_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/1k/128/98663351/128_ALD190058_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/1k/128/98663351/128_ALD190058_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/1k/128/98663351/128_ALD190058_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/1k/128/98663351/128_ALD190058_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/1k/128/98663351/128_ALD190058_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/1k/128/98663351/128_ALD190058_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/1k/128/98663351/128_ALD190058_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/1k/128/98663351/128_ALD190058_IMG_06_0000.jpg"
        ],
        "price": [
            {
                "value": 1125000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 4,
        "address": "Heatherley Wood Alderley Park, Nether Alderley, Cheshire, SK10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.23061,
            "lat": 53.2777
        },
        "_place": 9.06,
        "_people": 30.05,
        "_property": 0,
        "_price": 152.25,
        "map": property_567
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117418409#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13478/117418409/13478_31155890_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Ullswater Road, Flixton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.387576,
            "lat": 53.454933
        },
        "_place": 10.95,
        "_people": 18.33,
        "_property": 1,
        "_price": 186.46,
        "map": property_568
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110196545#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/108k/107675/110196545/107675_XO2065_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 204950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Drovers Gate, Crieff, Perthshire , PH7 3SE",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.84334,
            "lat": 56.36705
        },
        "_place": 0.96,
        "_people": 29.59,
        "_property": 1,
        "_price": 127.82,
        "map": property_569
    },
    {
        "url": "https://www.rightmove.co.uk/properties/104814569#",
        "title": "1 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/81k/80905/104814569/80905_WES210189_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80905/104814569/80905_WES210189_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/81k/80905/104814569/80905_WES210189_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/81k/80905/104814569/80905_WES210189_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/81k/80905/104814569/80905_WES210189_IMG_04_0001.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "High Holborn, WC1V",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.116998,
            "lat": 51.518153
        },
        "_place": 5.72,
        "_people": 26.72,
        "_property": 1,
        "_price": 141.75,
        "map": property_570
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115493726#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74081/115493726/74081_SSROSE_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 227000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Roselynn, Gibblestone Road, Scalloway, Shetland, Shetland Islands, ZE1 0TX",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.2766,
            "lat": 60.13885
        },
        "_place": 11.45,
        "_people": 20.53,
        "_property": 3,
        "_price": 102.5,
        "map": property_571
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114951137#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/42k/41033/114951137/41033_OXF012173181_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41033/114951137/41033_OXF012173181_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41033/114951137/41033_OXF012173181_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41033/114951137/41033_OXF012173181_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41033/114951137/41033_OXF012173181_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41033/114951137/41033_OXF012173181_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41033/114951137/41033_OXF012173181_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41033/114951137/41033_OXF012173181_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41033/114951137/41033_OXF012173181_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41033/114951137/41033_OXF012173181_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41033/114951137/41033_OXF012173181_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41033/114951137/41033_OXF012173181_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41033/114951137/41033_OXF012173181_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41033/114951137/41033_OXF012173181_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41033/114951137/41033_OXF012173181_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 1200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "Lower Road, Postcombe, Thame, Oxfordshire, OX9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.974993,
            "lat": 51.694178
        },
        "_place": 5.46,
        "_people": 33.38,
        "_property": 3,
        "_price": 134.91,
        "map": property_572
    },
    {
        "url": "https://www.rightmove.co.uk/properties/79795128#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/42k/41823/79795128/41823_CBS210052_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/42k/41823/79795128/41823_CBS210052_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/42k/41823/79795128/41823_CBS210052_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/42k/41823/79795128/41823_CBS210052_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/42k/41823/79795128/41823_CBS210052_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/42k/41823/79795128/41823_CBS210052_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/42k/41823/79795128/41823_CBS210052_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/42k/41823/79795128/41823_CBS210052_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/42k/41823/79795128/41823_CBS210052_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/42k/41823/79795128/41823_CBS210052_IMG_09_0001.jpeg"
        ],
        "price": [
            {
                "value": 695000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Fairfields, 26 Green Lane, Cobham, Surrey, KT11",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.390377,
            "lat": 51.339076
        },
        "_place": 5.61,
        "_people": 21.53,
        "_property": 5,
        "_price": 95.51,
        "map": property_573
    },
    {
        "url": "https://www.rightmove.co.uk/properties/83022738#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_12_0000.jpg"
        ],
        "price": [
            {
                "value": 284995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Greenhurst Road,\r\nAshton-Under-Lyne,\r\nOL6 8BQ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.07776,
            "lat": 53.50513
        },
        "_place": 5.44,
        "_people": 18.35,
        "_property": 2,
        "_price": 180.6,
        "map": property_574
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115700297#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9536/115700297/9536_LOS210374_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 725000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Hackamore, Benfleet, SS7",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": 0.593255,
            "lat": 51.564302
        },
        "_place": 1.2,
        "_people": 32.5,
        "_property": 5,
        "_price": 135.14,
        "map": property_575
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116737496#",
        "title": "Semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/18k/17586/116737496/17586_RED210548_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17586/116737496/17586_RED210548_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17586/116737496/17586_RED210548_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17586/116737496/17586_RED210548_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17586/116737496/17586_RED210548_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17586/116737496/17586_RED210548_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17586/116737496/17586_RED210548_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17586/116737496/17586_RED210548_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17586/116737496/17586_RED210548_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17586/116737496/17586_RED210548_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17586/116737496/17586_RED210548_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17586/116737496/17586_RED210548_IMG_11_0001.jpeg"
        ],
        "price": [
            {
                "value": 160000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Furnival Road, Manchester, Greater Manchester, M18",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.181163,
            "lat": 53.464734
        },
        "_place": 7.2,
        "_people": 26.05,
        "_property": 1,
        "_price": 177.91,
        "map": property_576
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117030755#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64298/117030755/64298_ADZ210520_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 645000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Ashby-de-la-Zouch, Leicestershire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.479177,
            "lat": 52.745934
        },
        "_place": 10.56,
        "_people": 33.59,
        "_property": 0,
        "_price": 172.12,
        "map": property_577
    },
    {
        "url": "https://www.rightmove.co.uk/properties/97000286#",
        "title": "1 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_23_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_24_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_25_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_26_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_27_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_28_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_29_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_30_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_31_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_32_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_33_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_34_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_35_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_36_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_37_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_38_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_39_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_40_0000.jpg",
            "https://media.rightmove.co.uk/7k/6464/97000286/6464_CWE200237_IMG_41_0000.jpg"
        ],
        "price": [
            {
                "value": 775000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Artillery Mansions, Victoria Street, Westminster, SW1H",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.134726,
            "lat": 51.497508
        },
        "_place": 9.08,
        "_people": 18.51,
        "_property": 5,
        "_price": 152.59,
        "map": property_578
    },
    {
        "url": "https://www.rightmove.co.uk/properties/83003205#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/33k/32391/83003205/32391_31136373_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32391/83003205/32391_31136373_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32391/83003205/32391_31136373_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32391/83003205/32391_31136373_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32391/83003205/32391_31136373_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32391/83003205/32391_31136373_IMG_05_0000.jpeg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Shelton Street, Bloomsbury",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.126135,
            "lat": 51.513568
        },
        "_place": 8.31,
        "_people": 28.48,
        "_property": 5,
        "_price": 101.96,
        "map": property_579
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114429038#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61474/114429038/61474_100095010612_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 755000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Southwold, Suffolk",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.68114,
            "lat": 52.32835
        },
        "_place": 2.52,
        "_people": 33.41,
        "_property": 1,
        "_price": 161.74,
        "map": property_580
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117598466#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68365/117598466/68365_11140100_IMG_28_0000.jpeg"
        ],
        "price": [
            {
                "value": 260000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Linacre Road, Eccleshall",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.249585,
            "lat": 52.8567
        },
        "_place": 10.78,
        "_people": 19.62,
        "_property": 5,
        "_price": 109.85,
        "map": property_581
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117646880#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237629/117646880/237629_5163_IMG_26_0000.jpeg"
        ],
        "price": [
            {
                "value": 219950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Slieau Ree Apartments, Union Mills",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.521862,
            "lat": 54.169096
        },
        "_place": 10.8,
        "_people": 24.01,
        "_property": 3,
        "_price": 171.3,
        "map": property_582
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112979069#",
        "title": "2 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94727/112979069/94727_103280009356_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 245000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Willow Park, Burnhouse, North Ayrshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.5758,
            "lat": 55.71826
        },
        "_place": 10.4,
        "_people": 30.98,
        "_property": 0,
        "_price": 101.53,
        "map": property_583
    },
    {
        "url": "https://www.rightmove.co.uk/properties/78071947#",
        "title": "3 bedroom ground floor flat",
        "images": [
            "https://media.rightmove.co.uk/72k/71551/78071947/71551_LA1278_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/72k/71551/78071947/71551_LA1278_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/72k/71551/78071947/71551_LA1278_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/72k/71551/78071947/71551_LA1278_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/72k/71551/78071947/71551_LA1278_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/72k/71551/78071947/71551_LA1278_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/72k/71551/78071947/71551_LA1278_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/72k/71551/78071947/71551_LA1278_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/72k/71551/78071947/71551_LA1278_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/72k/71551/78071947/71551_LA1278_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 220000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Wemyss Point, Undercliff Road, Wemyss Bay",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.89509,
            "lat": 55.88599
        },
        "_place": 6.74,
        "_people": 29.05,
        "_property": 3,
        "_price": 188.98,
        "map": property_584
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110376092#",
        "title": "2 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/47k/46428/110376092/46428_SUN210386_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46428/110376092/46428_SUN210386_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46428/110376092/46428_SUN210386_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46428/110376092/46428_SUN210386_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46428/110376092/46428_SUN210386_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46428/110376092/46428_SUN210386_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46428/110376092/46428_SUN210386_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46428/110376092/46428_SUN210386_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46428/110376092/46428_SUN210386_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46428/110376092/46428_SUN210386_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46428/110376092/46428_SUN210386_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46428/110376092/46428_SUN210386_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46428/110376092/46428_SUN210386_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46428/110376092/46428_SUN210386_IMG_13_0001.jpeg"
        ],
        "price": [
            {
                "value": 115000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Borodin Avenue, Sunderland, Tyne and Wear, SR5",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.458526,
            "lat": 54.9292
        },
        "_place": 1.66,
        "_people": 18.35,
        "_property": 1,
        "_price": 183.27,
        "map": property_585
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112916462#",
        "title": "3 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/54k/53258/112916462/53258_7411139_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53258/112916462/53258_7411139_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53258/112916462/53258_7411139_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53258/112916462/53258_7411139_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53258/112916462/53258_7411139_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53258/112916462/53258_7411139_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53258/112916462/53258_7411139_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53258/112916462/53258_7411139_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53258/112916462/53258_7411139_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53258/112916462/53258_7411139_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Between Streets, Cobham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.413477,
            "lat": 51.329937
        },
        "_place": 6.14,
        "_people": 35.15,
        "_property": 1,
        "_price": 154.89,
        "map": property_586
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109658744#",
        "title": "3 bedroom barn conversion",
        "images": [
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4944/109658744/4944_LED210321_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Todd Lane North, Lostock Hall, Preston, PR5",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.680846,
            "lat": 53.733624
        },
        "_place": 8.18,
        "_people": 20.27,
        "_property": 2,
        "_price": 178.72,
        "map": property_587
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116152181#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/9k/8664/116152181/8664_RAI210124_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8664/116152181/8664_RAI210124_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8664/116152181/8664_RAI210124_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8664/116152181/8664_RAI210124_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8664/116152181/8664_RAI210124_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8664/116152181/8664_RAI210124_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8664/116152181/8664_RAI210124_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8664/116152181/8664_RAI210124_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8664/116152181/8664_RAI210124_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/9k/8664/116152181/8664_RAI210124_IMG_09_0001.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Sandy Dell, Hempstead, Gillingham, Kent, ME7",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.57475,
            "lat": 51.33928
        },
        "_place": 5.07,
        "_people": 27.95,
        "_property": 1,
        "_price": 194.5,
        "map": property_588
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116782871#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/123k/122296/116782871/122296_102806015009_IMG_39_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Thunder Lane, Thorpe St. Andrew",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.34322,
            "lat": 52.6335
        },
        "_place": 8.2,
        "_people": 35.75,
        "_property": 2,
        "_price": 192.93,
        "map": property_589
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117082448#",
        "title": "5 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76230/117082448/76230_SME210358_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Harbour View Crescent, Penzance, TR18",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.534111,
            "lat": 50.124811
        },
        "_place": 6.76,
        "_people": 26.36,
        "_property": 0,
        "_price": 199.08,
        "map": property_590
    },
    {
        "url": "https://www.rightmove.co.uk/properties/98307032#",
        "title": "6 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_01_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_37_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_36_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_17_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_19_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_02_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_03_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_09_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_08_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_07_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_10_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_11_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_13_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_14_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_15_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_21_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_22_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_23_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_24_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_25_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_27_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_28_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_29_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_35_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_38_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_39_0000.JPG",
            "https://media.rightmove.co.uk/152k/151070/98307032/151070_1025591-1_IMG_04_0000.JPG"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 4,
        "address": "High Street, Finedon, NN9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.655358,
            "lat": 52.338978
        },
        "_place": 11.76,
        "_people": 28.21,
        "_property": 1,
        "_price": 129.07,
        "map": property_591
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116901941#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217160/116901941/217160_11203456_IMG_42_0000.jpeg"
        ],
        "price": [
            {
                "value": 625000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Carleton Park Avenue, Pontefract",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.309266,
            "lat": 53.683132
        },
        "_place": 7.64,
        "_people": 35.86,
        "_property": 3,
        "_price": 107.89,
        "map": property_592
    },
    {
        "url": "https://www.rightmove.co.uk/properties/80254688#",
        "title": "Plot",
        "images": [
            "https://media.rightmove.co.uk/100k/99326/80254688/99326_PlotStDuthusPlace_IMG_00_0000.JPG",
            "https://media.rightmove.co.uk/100k/99326/80254688/99326_PlotStDuthusPlace_IMG_01_0000.JPG",
            "https://media.rightmove.co.uk/100k/99326/80254688/99326_PlotStDuthusPlace_IMG_02_0000.JPG",
            "https://media.rightmove.co.uk/100k/99326/80254688/99326_PlotStDuthusPlace_IMG_03_0000.JPG"
        ],
        "price": [
            {
                "value": 100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Development Plot, St Duthus Place, Tain, Ross-Shire, IV19 1JA",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.05149,
            "lat": 57.81393
        },
        "_place": 1.44,
        "_people": 24.46,
        "_property": 5,
        "_price": 127.51,
        "map": property_593
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117245435#",
        "title": "4 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/97k/96913/117245435/96913_22241860_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Warwick Lane, Rainham, RM13",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.226882,
            "lat": 51.524377
        },
        "_place": 3.72,
        "_people": 29.48,
        "_property": 1,
        "_price": 134.04,
        "map": property_594
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116785301#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183752/116785301/183752_31120444_IMG_28_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Parc Yr Eos, Hermon, Glogue",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.61505,
            "lat": 51.956069
        },
        "_place": 6.46,
        "_people": 22.17,
        "_property": 3,
        "_price": 92.37,
        "map": property_595
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116214692#",
        "title": "Studio apartment",
        "images": [
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": 1,
        "address": "High Holborn, Holborn",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.116701,
            "lat": 51.518162
        },
        "_place": 6.95,
        "_people": 32.5,
        "_property": 2,
        "_price": 119.79,
        "map": property_596
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117310925#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30876/117310925/30876_8144847_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 849950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Sunnybank Close, Mayfield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.263381,
            "lat": 51.019794
        },
        "_place": 0.34,
        "_people": 35.43,
        "_property": 0,
        "_price": 195.33,
        "map": property_597
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115773389#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/115773389/84391_10369685_IMG_27_0000.jpeg"
        ],
        "price": [
            {
                "value": 440950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Birch Row, Broseley",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.482294,
            "lat": 52.613457
        },
        "_place": 0.83,
        "_people": 31.65,
        "_property": 5,
        "_price": 147.57,
        "map": property_598
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116341592#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 700000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Solent View Road, Seaview, Isle of Wight",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.1131,
            "lat": 50.71616
        },
        "_place": 9.93,
        "_people": 22.23,
        "_property": 4,
        "_price": 188.45,
        "map": property_599
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115663376#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/170k/169073/115663376/169073_11078748_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169073/115663376/169073_11078748_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169073/115663376/169073_11078748_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169073/115663376/169073_11078748_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169073/115663376/169073_11078748_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169073/115663376/169073_11078748_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169073/115663376/169073_11078748_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169073/115663376/169073_11078748_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169073/115663376/169073_11078748_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169073/115663376/169073_11078748_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169073/115663376/169073_11078748_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169073/115663376/169073_11078748_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 230000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Brooklands House, Eccleshall Road, Stafford",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.131669,
            "lat": 52.81861
        },
        "_place": 3.89,
        "_people": 34.01,
        "_property": 5,
        "_price": 96.3,
        "map": property_600
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111958229#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/160k/159476/111958229/159476_2_559_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/160k/159476/111958229/159476_2_559_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/160k/159476/111958229/159476_2_559_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/160k/159476/111958229/159476_2_559_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/160k/159476/111958229/159476_2_559_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/160k/159476/111958229/159476_2_559_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/160k/159476/111958229/159476_2_559_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/160k/159476/111958229/159476_2_559_IMG_08_0000.jpg"
        ],
        "price": [
            {
                "value": 227000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Dargavel Village, Bishopton, \r\nPA7 5FW",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.51623,
            "lat": 55.90591
        },
        "_place": 5.38,
        "_people": 25.71,
        "_property": 4,
        "_price": 120.54,
        "map": property_601
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116859290#",
        "title": "4 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/116859290/39890_31121211_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 495000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Trinity Paddock, Station Road, Duns",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.340164,
            "lat": 55.77248
        },
        "_place": 11.68,
        "_people": 33.28,
        "_property": 3,
        "_price": 169.92,
        "map": property_602
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111911516#",
        "title": "House",
        "images": [
            "https://media.rightmove.co.uk/84k/83515/111911516/83515_5016_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83515/111911516/83515_5016_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83515/111911516/83515_5016_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83515/111911516/83515_5016_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83515/111911516/83515_5016_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83515/111911516/83515_5016_IMG_05_0000.jpeg"
        ],
        "price": [
            {
                "value": 235000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Building Plot at Pendoggett, Cornwall Collection",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.790956,
            "lat": 50.581741
        },
        "_place": 7.84,
        "_people": 34.47,
        "_property": 1,
        "_price": 164.06,
        "map": property_603
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115821344#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/70k/69996/115821344/69996_102866004688_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/115821344/69996_102866004688_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/115821344/69996_102866004688_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/115821344/69996_102866004688_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/115821344/69996_102866004688_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69996/115821344/69996_102866004688_IMG_05_0000.jpeg"
        ],
        "price": [
            {
                "value": 229950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Leeds City Centre Apartments",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.54117,
            "lat": 53.79747
        },
        "_place": 3.69,
        "_people": 23.97,
        "_property": 4,
        "_price": 199.74,
        "map": property_604
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113177174#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/110k/109382/113177174/109382_GRC210468_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/113177174/109382_GRC210468_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/113177174/109382_GRC210468_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/113177174/109382_GRC210468_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/113177174/109382_GRC210468_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/113177174/109382_GRC210468_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/113177174/109382_GRC210468_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/113177174/109382_GRC210468_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/113177174/109382_GRC210468_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/113177174/109382_GRC210468_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/113177174/109382_GRC210468_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/113177174/109382_GRC210468_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/113177174/109382_GRC210468_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/113177174/109382_GRC210468_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 699950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Tilehouse Way, Denham, Buckinghamshire, UB9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.503054,
            "lat": 51.579872
        },
        "_place": 5.27,
        "_people": 25.72,
        "_property": 2,
        "_price": 128.55,
        "map": property_605
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115041785#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/9k/8764/115041785/8764_ROM210410_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8764/115041785/8764_ROM210410_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8764/115041785/8764_ROM210410_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8764/115041785/8764_ROM210410_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8764/115041785/8764_ROM210410_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8764/115041785/8764_ROM210410_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8764/115041785/8764_ROM210410_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8764/115041785/8764_ROM210410_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8764/115041785/8764_ROM210410_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8764/115041785/8764_ROM210410_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8764/115041785/8764_ROM210410_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8764/115041785/8764_ROM210410_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8764/115041785/8764_ROM210410_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8764/115041785/8764_ROM210410_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 625000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Upton Crescent, Nursling, Southampton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.46608,
            "lat": 50.95292
        },
        "_place": 11.04,
        "_people": 19.66,
        "_property": 2,
        "_price": 130.02,
        "map": property_606
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114728081#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/3k/2922/114728081/2922_31020712_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2922/114728081/2922_31020712_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2922/114728081/2922_31020712_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2922/114728081/2922_31020712_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2922/114728081/2922_31020712_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2922/114728081/2922_31020712_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2922/114728081/2922_31020712_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2922/114728081/2922_31020712_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2922/114728081/2922_31020712_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2922/114728081/2922_31020712_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2922/114728081/2922_31020712_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2922/114728081/2922_31020712_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2922/114728081/2922_31020712_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2922/114728081/2922_31020712_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2922/114728081/2922_31020712_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 795000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Everton Road, Hordle, Hampshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.606155,
            "lat": 50.751889
        },
        "_place": 10.52,
        "_people": 25.88,
        "_property": 5,
        "_price": 183.85,
        "map": property_607
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116885258#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10238/116885258/10238_31130395_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 1000000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "New Pond Hill, Cross In Hand, Heathfield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.23092,
            "lat": 50.964847
        },
        "_place": 1.61,
        "_people": 18.32,
        "_property": 4,
        "_price": 159.15,
        "map": property_608
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117714278#",
        "title": "Terraced house",
        "images": [
            "https://media.rightmove.co.uk/56k/55237/117714278/55237_BRD210482_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/117714278/55237_BRD210482_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/117714278/55237_BRD210482_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/117714278/55237_BRD210482_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/117714278/55237_BRD210482_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/117714278/55237_BRD210482_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/117714278/55237_BRD210482_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/117714278/55237_BRD210482_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/117714278/55237_BRD210482_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/117714278/55237_BRD210482_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55237/117714278/55237_BRD210482_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 925000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "High Street, Broadway, Worcestershire, WR12",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.856781,
            "lat": 52.036011
        },
        "_place": 4.67,
        "_people": 26.28,
        "_property": 4,
        "_price": 119.15,
        "map": property_609
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115698539#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15719/115698539/15719_11179010_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 280000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Ebba Point, Hebburn",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.506217,
            "lat": 54.961048
        },
        "_place": 11.87,
        "_people": 32.73,
        "_property": 3,
        "_price": 143.32,
        "map": property_610
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116998100#",
        "title": "5 bedroom barn conversion",
        "images": [
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225197/116998100/225197_COU080263_IMG_28_0000.jpeg"
        ],
        "price": [
            {
                "value": 775000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Thornfield, Manor Farm Barns, Pensham, WR10 3HB",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.087921,
            "lat": 52.096418
        },
        "_place": 2.25,
        "_people": 31.06,
        "_property": 5,
        "_price": 122.29,
        "map": property_611
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114233351#",
        "title": "2 bedroom town house",
        "images": [
            "https://media.rightmove.co.uk/39k/38702/114233351/38702_ALD210123_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38702/114233351/38702_ALD210123_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38702/114233351/38702_ALD210123_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38702/114233351/38702_ALD210123_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38702/114233351/38702_ALD210123_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38702/114233351/38702_ALD210123_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38702/114233351/38702_ALD210123_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/39k/38702/114233351/38702_ALD210123_IMG_07_0001.jpeg"
        ],
        "price": [
            {
                "value": 595000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Aldeburgh, Suffolk",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.602169,
            "lat": 52.154752
        },
        "_place": 1.32,
        "_people": 25.88,
        "_property": 1,
        "_price": 192.86,
        "map": property_612
    },
    {
        "url": "https://www.rightmove.co.uk/properties/80199327#",
        "title": "1 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/64k/63077/80199327/63077_MRY012115224_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63077/80199327/63077_MRY012115224_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63077/80199327/63077_MRY012115224_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63077/80199327/63077_MRY012115224_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/64k/63077/80199327/63077_MRY012115224_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 895000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": null,
        "address": "Blandford Street, London, W1U",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.153279,
            "lat": 51.518538
        },
        "_place": 9.07,
        "_people": 21.37,
        "_property": 1,
        "_price": 181.86,
        "map": property_613
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117692306#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53217/117692306/53217_31166541_IMG_27_0000.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Birchdale Road, Waterloo, Liverpool",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.028486,
            "lat": 53.47829
        },
        "_place": 3.76,
        "_people": 24.46,
        "_property": 5,
        "_price": 115.08,
        "map": property_614
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113177054#",
        "title": "5 bedroom link detached house",
        "images": [
            "https://media.rightmove.co.uk/49k/48620/113177054/48620_NRS210130_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48620/113177054/48620_NRS210130_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48620/113177054/48620_NRS210130_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48620/113177054/48620_NRS210130_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48620/113177054/48620_NRS210130_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48620/113177054/48620_NRS210130_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48620/113177054/48620_NRS210130_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48620/113177054/48620_NRS210130_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48620/113177054/48620_NRS210130_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48620/113177054/48620_NRS210130_IMG_09_0001.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Saltgate, Beccles, Suffolk, NR34",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.562385,
            "lat": 52.459164
        },
        "_place": 1.54,
        "_people": 32.03,
        "_property": 1,
        "_price": 163.54,
        "map": property_615
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117486407#",
        "title": "4 bedroom property",
        "images": [
            "https://media.rightmove.co.uk/81k/80004/117486407/80004_7717_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/117486407/80004_7717_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/117486407/80004_7717_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/117486407/80004_7717_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/117486407/80004_7717_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/117486407/80004_7717_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/117486407/80004_7717_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/117486407/80004_7717_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80004/117486407/80004_7717_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Plot 3, Scammadale Glen, Kilninver, By Oban, Argyll",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -5.482505,
            "lat": 56.324233
        },
        "_place": 6.43,
        "_people": 30.59,
        "_property": 0,
        "_price": 185.89,
        "map": property_616
    },
    {
        "url": "https://www.rightmove.co.uk/properties/106876094#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46562/106876094/46562_WIM210031_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 1125000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Park Road, Kingston, Surrey, KT2",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.28801,
            "lat": 51.41543
        },
        "_place": 0.86,
        "_people": 18.38,
        "_property": 3,
        "_price": 200.17,
        "map": property_617
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117394517#",
        "title": "6 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/106k/105263/117394517/105263_WED210100_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105263/117394517/105263_WED210100_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105263/117394517/105263_WED210100_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105263/117394517/105263_WED210100_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105263/117394517/105263_WED210100_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105263/117394517/105263_WED210100_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/106k/105263/117394517/105263_WED210100_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 755000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": null,
        "address": "Fair Rigg Guest House, Bowness-On-Windermere, LA23",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -2.918929,
            "lat": 54.350613
        },
        "_place": 6.83,
        "_people": 35.11,
        "_property": 4,
        "_price": 125.08,
        "map": property_618
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115121138#",
        "title": "6 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/67k/66502/115121138/66502_100335011442_IMG_25_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 4,
        "address": "Church Road, Brandon",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.61612,
            "lat": 52.444511
        },
        "_place": 8.89,
        "_people": 21.83,
        "_property": 2,
        "_price": 140.35,
        "map": property_619
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113596961#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_20_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_21_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_22_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_23_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_24_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_25_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_26_0001.jpeg",
            "https://media.rightmove.co.uk/18k/17820/113596961/17820_SED210275_IMG_27_0001.jpeg"
        ],
        "price": [
            {
                "value": 995000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Willow House- Terrys Lodge Farm, Terrys Lodge Road, Sevenoaks, Kent, TN15",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.280734,
            "lat": 51.323132
        },
        "_place": 10.71,
        "_people": 20.56,
        "_property": 0,
        "_price": 139.04,
        "map": property_620
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117721097#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/84k/83407/117721097/83407_4931958_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83407/117721097/83407_4931958_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83407/117721097/83407_4931958_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83407/117721097/83407_4931958_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83407/117721097/83407_4931958_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83407/117721097/83407_4931958_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83407/117721097/83407_4931958_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83407/117721097/83407_4931958_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83407/117721097/83407_4931958_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83407/117721097/83407_4931958_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83407/117721097/83407_4931958_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83407/117721097/83407_4931958_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 429950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Abbey View, BEXHILL-ON-SEA, East Sussex",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.481099,
            "lat": 50.852712
        },
        "_place": 1.51,
        "_people": 18.88,
        "_property": 1,
        "_price": 119.6,
        "map": property_621
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116391599#",
        "title": "5 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29648/116391599/29648_ANW210337_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Ford Terrace, Broomhaugh, Riding Mill, Northumberland, NE44",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.968557,
            "lat": 54.949066
        },
        "_place": 8.2,
        "_people": 31.6,
        "_property": 3,
        "_price": 115,
        "map": property_622
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115821452#",
        "title": "3 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16569/115821452/16569_RX123210_IMG_26_0000.jpeg"
        ],
        "price": [
            {
                "value": 215000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Elm Drive, Maybole, KA19",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.679748,
            "lat": 55.359009
        },
        "_place": 7.04,
        "_people": 32.84,
        "_property": 5,
        "_price": 98.92,
        "map": property_623
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117417749#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/78k/77088/117417749/77088_108051_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117417749/77088_108051_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117417749/77088_108051_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117417749/77088_108051_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117417749/77088_108051_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117417749/77088_108051_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117417749/77088_108051_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117417749/77088_108051_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117417749/77088_108051_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117417749/77088_108051_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117417749/77088_108051_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117417749/77088_108051_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 180000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Highfield Avenue, Mansfield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.223318,
            "lat": 53.215355
        },
        "_place": 5.7,
        "_people": 24.25,
        "_property": 0,
        "_price": 189.79,
        "map": property_624
    },
    {
        "url": "https://www.rightmove.co.uk/properties/99332729#",
        "title": "9 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/69k/68741/99332729/68741_NEW200362_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/69k/68741/99332729/68741_NEW200362_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/69k/68741/99332729/68741_NEW200362_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/69k/68741/99332729/68741_NEW200362_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/69k/68741/99332729/68741_NEW200362_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/69k/68741/99332729/68741_NEW200362_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/69k/68741/99332729/68741_NEW200362_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/69k/68741/99332729/68741_NEW200362_IMG_16_0000.jpg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 9,
        "bathrooms": 5,
        "address": "Belle Grove Villas, Spital Tongues, Newcastle Upon Tyne, NE2",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.629944,
            "lat": 54.981911
        },
        "_place": 3.73,
        "_people": 35.95,
        "_property": 1,
        "_price": 166.69,
        "map": property_625
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82441752#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/186k/185813/82441752/185813_4_223_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/186k/185813/82441752/185813_4_223_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/186k/185813/82441752/185813_4_223_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/186k/185813/82441752/185813_4_223_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/186k/185813/82441752/185813_4_223_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/186k/185813/82441752/185813_4_223_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/186k/185813/82441752/185813_4_223_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/186k/185813/82441752/185813_4_223_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/186k/185813/82441752/185813_4_223_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/186k/185813/82441752/185813_4_223_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 300995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Stable Gardens,\r\nGalashiels,\r\nTD1",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.76453,
            "lat": 55.61287
        },
        "_place": 1.98,
        "_people": 31.62,
        "_property": 0,
        "_price": 101.8,
        "map": property_626
    },
    {
        "url": "https://www.rightmove.co.uk/properties/83148254#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_07_0001.jpg",
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_02_0001.jpg",
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_06_0001.jpg",
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_05_0001.jpg",
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_04_0001.jpg",
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_09_0001.jpg",
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_03_0001.jpg",
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_08_0001.jpg",
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_01_0001.jpg",
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/110k/109054/83148254/109054_100465007636_IMG_16_0000.jpg"
        ],
        "price": [
            {
                "value": 162500,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Jet Centro, St Marys Road ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.46953,
            "lat": 53.37355
        },
        "_place": 0.33,
        "_people": 22.15,
        "_property": 5,
        "_price": 163.21,
        "map": property_627
    },
    {
        "url": "https://www.rightmove.co.uk/properties/75945083#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_00_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_41_0001.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_42_0001.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_20_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_21_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_23_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_24_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_25_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_26_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_27_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_28_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_29_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_30_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_31_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_32_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_33_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_34_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_35_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_36_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_37_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_38_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_39_0000.jpg",
            "https://media.rightmove.co.uk/126k/125020/75945083/125020_MAH00150021177_IMG_40_0000.jpg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Green End, Braughing, Ware, SG11",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.02471,
            "lat": 51.9133
        },
        "_place": 3.93,
        "_people": 19.44,
        "_property": 2,
        "_price": 198.13,
        "map": property_628
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116883413#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11562/116883413/11562_0110_HRT011011737_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Sylvanus, Bracknell",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.770969,
            "lat": 51.391714
        },
        "_place": 3.68,
        "_people": 21.59,
        "_property": 5,
        "_price": 132.61,
        "map": property_629
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116354894#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/89k/88669/116354894/88669_100361015775_IMG_29_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "The Poplars, Forncett St Peter",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.15674,
            "lat": 52.50093
        },
        "_place": 1.11,
        "_people": 27.25,
        "_property": 5,
        "_price": 117.38,
        "map": property_630
    },
    {
        "url": "https://www.rightmove.co.uk/properties/106229033#",
        "title": "2 bedroom maisonette",
        "images": [
            "https://media.rightmove.co.uk/2k/1895/106229033/1895_ECT103278_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1895/106229033/1895_ECT103278_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1895/106229033/1895_ECT103278_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1895/106229033/1895_ECT103278_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1895/106229033/1895_ECT103278_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1895/106229033/1895_ECT103278_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1895/106229033/1895_ECT103278_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1895/106229033/1895_ECT103278_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1895/106229033/1895_ECT103278_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1895/106229033/1895_ECT103278_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1895/106229033/1895_ECT103278_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1895/106229033/1895_ECT103278_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1895/106229033/1895_ECT103278_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1895/106229033/1895_ECT103278_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1895/106229033/1895_ECT103278_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 1100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Tadema Road, London",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.18152,
            "lat": 51.47875
        },
        "_place": 3.19,
        "_people": 28.14,
        "_property": 4,
        "_price": 121.05,
        "map": property_631
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109353020#",
        "title": "6 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/2k/1680/109353020/1680_HRW308731_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1680/109353020/1680_HRW308731_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1680/109353020/1680_HRW308731_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1680/109353020/1680_HRW308731_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1680/109353020/1680_HRW308731_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1680/109353020/1680_HRW308731_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1680/109353020/1680_HRW308731_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1680/109353020/1680_HRW308731_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1680/109353020/1680_HRW308731_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1680/109353020/1680_HRW308731_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1680/109353020/1680_HRW308731_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1680/109353020/1680_HRW308731_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1680/109353020/1680_HRW308731_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1680/109353020/1680_HRW308731_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1680/109353020/1680_HRW308731_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 2,
        "address": "Cunningham Park, Harrow",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.34592,
            "lat": 51.58765
        },
        "_place": 7.69,
        "_people": 31.73,
        "_property": 2,
        "_price": 173.35,
        "map": property_632
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117440774#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191903/117440774/191903_31152359_IMG_33_0000.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 4,
        "address": "Caerbryn Road, Penygroes",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.047179,
            "lat": 51.801212
        },
        "_place": 3.96,
        "_people": 22.32,
        "_property": 3,
        "_price": 177.18,
        "map": property_633
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110650820#",
        "title": "6 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/93k/92263/110650820/92263_S111_IMG_19_0001.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 2,
        "address": "The Cliff, Seaton Carew, Hartlepool, TS25",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.188233,
            "lat": 54.66403
        },
        "_place": 5,
        "_people": 23.45,
        "_property": 2,
        "_price": 147.9,
        "map": property_634
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115738049#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175607/115738049/175607_11212897_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 525000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Cyprus Road, Titchfield Common",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.258419,
            "lat": 50.853256
        },
        "_place": 11.32,
        "_people": 34.13,
        "_property": 5,
        "_price": 105.86,
        "map": property_635
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82973877#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/234k/233027/82973877/233027_4_127_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/234k/233027/82973877/233027_4_127_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/234k/233027/82973877/233027_4_127_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/234k/233027/82973877/233027_4_127_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/234k/233027/82973877/233027_4_127_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/234k/233027/82973877/233027_4_127_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/234k/233027/82973877/233027_4_127_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/234k/233027/82973877/233027_4_127_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/234k/233027/82973877/233027_4_127_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/234k/233027/82973877/233027_4_127_IMG_10_0000.jpg"
        ],
        "price": [
            {
                "value": 241995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Newfield Gardens,\r\nStonehouse,\r\nLarkhall,\r\nML9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.99117,
            "lat": 55.69094
        },
        "_place": 8.06,
        "_people": 35.04,
        "_property": 5,
        "_price": 115.91,
        "map": property_636
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117364412#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74061/117364412/74061_HSS192985_IMG_27_0000.jpeg"
        ],
        "price": [
            {
                "value": 130000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "St. Ambrose Croft, Bootle, L30",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.977899,
            "lat": 53.489399
        },
        "_place": 10.16,
        "_people": 26.57,
        "_property": 1,
        "_price": 92.66,
        "map": property_637
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115137842#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/1k/216/115137842/216_WWA211659_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/1k/216/115137842/216_WWA211659_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/1k/216/115137842/216_WWA211659_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/1k/216/115137842/216_WWA211659_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/1k/216/115137842/216_WWA211659_IMG_04_0001.jpeg"
        ],
        "price": [
            {
                "value": 170000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Thorpe Road, Walsall, WS1",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.982752,
            "lat": 52.57461
        },
        "_place": 0.61,
        "_people": 24.14,
        "_property": 4,
        "_price": 182.23,
        "map": property_639
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114856265#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42215/114856265/42215_31027330_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 675000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Holmesdale Road, London",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.089101,
            "lat": 51.395061
        },
        "_place": 0.3,
        "_people": 21.85,
        "_property": 2,
        "_price": 193.7,
        "map": property_640
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107281577#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/1k/677/107281577/677_LYM210084_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 995000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Almansa Way, Lymington, SO41",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.536625,
            "lat": 50.761956
        },
        "_place": 7.21,
        "_people": 34.96,
        "_property": 0,
        "_price": 195.27,
        "map": property_641
    },
    {
        "url": "https://www.rightmove.co.uk/properties/105890918#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77167/105890918/77167_12ASHER_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 275000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Asher Drive, Todmorden, West Yorkshire, OL14",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.10786,
            "lat": 53.72226
        },
        "_place": 11.28,
        "_people": 18.21,
        "_property": 5,
        "_price": 195.53,
        "map": property_642
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114178373#",
        "title": "3 bedroom cottage",
        "images": [
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237926/114178373/237926_00011_IMG_35_0000.jpeg"
        ],
        "price": [
            {
                "value": 1100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Threapwood, ST10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.93758,
            "lat": 52.98246
        },
        "_place": 0.63,
        "_people": 31.89,
        "_property": 3,
        "_price": 137.21,
        "map": property_643
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116134265#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44321/116134265/44321_GLG210052_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 4,
        "address": "Inveruel, Colintraive, Argyll & Bute, PA22",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.18534,
            "lat": 55.95936
        },
        "_place": 11.31,
        "_people": 19.59,
        "_property": 3,
        "_price": 138.56,
        "map": property_644
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115853543#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4911/115853543/4911_PHI210518_IMG_18_0001.jpeg"
        ],
        "price": [
            {
                "value": 485000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Aubrey Close, Hayling Island, Hampshire, ., PO11",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.99,
            "lat": 50.790001
        },
        "_place": 6.79,
        "_people": 24.35,
        "_property": 0,
        "_price": 135.61,
        "map": property_645
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115707794#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/6k/5625/115707794/5625_9454572_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5625/115707794/5625_9454572_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5625/115707794/5625_9454572_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5625/115707794/5625_9454572_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5625/115707794/5625_9454572_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5625/115707794/5625_9454572_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5625/115707794/5625_9454572_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5625/115707794/5625_9454572_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5625/115707794/5625_9454572_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5625/115707794/5625_9454572_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5625/115707794/5625_9454572_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5625/115707794/5625_9454572_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Dollings Yard, Chesham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.613869,
            "lat": 51.707951
        },
        "_place": 2.34,
        "_people": 20.79,
        "_property": 2,
        "_price": 185.92,
        "map": property_646
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116685605#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12333/116685605/12333_REA210388_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 675000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Lance Way, Arborfield Green, Reading",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.89781,
            "lat": 51.38255
        },
        "_place": 10.49,
        "_people": 18.1,
        "_property": 4,
        "_price": 181.51,
        "map": property_647
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117433097#",
        "title": "4 bedroom property",
        "images": [
            "https://media.rightmove.co.uk/236k/235223/117433097/235223_4_Plot_060_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/236k/235223/117433097/235223_4_Plot_060_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/236k/235223/117433097/235223_4_Plot_060_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/236k/235223/117433097/235223_4_Plot_060_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/236k/235223/117433097/235223_4_Plot_060_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/236k/235223/117433097/235223_4_Plot_060_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/236k/235223/117433097/235223_4_Plot_060_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/236k/235223/117433097/235223_4_Plot_060_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/236k/235223/117433097/235223_4_Plot_060_IMG_09_0000.jpg"
        ],
        "price": [
            {
                "value": 369995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Highfield Lane,\r\nSheffield,\r\nS60",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.36916,
            "lat": 53.38187
        },
        "_place": 9.65,
        "_people": 22.26,
        "_property": 0,
        "_price": 196.16,
        "map": property_648
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117597161#",
        "title": "4 bedroom town house",
        "images": [
            "https://media.rightmove.co.uk/85k/84319/117597161/84319_PRA11105_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84319/117597161/84319_PRA11105_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84319/117597161/84319_PRA11105_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84319/117597161/84319_PRA11105_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84319/117597161/84319_PRA11105_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84319/117597161/84319_PRA11105_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84319/117597161/84319_PRA11105_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84319/117597161/84319_PRA11105_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84319/117597161/84319_PRA11105_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 735000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Dock Meadow Reach, Hanwell, London. W7",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.338479,
            "lat": 51.501665
        },
        "_place": 10.73,
        "_people": 25.36,
        "_property": 0,
        "_price": 98.18,
        "map": property_649
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107533955#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/2k/1656/107533955/1656_BED309612_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1656/107533955/1656_BED309612_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1656/107533955/1656_BED309612_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1656/107533955/1656_BED309612_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1656/107533955/1656_BED309612_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1656/107533955/1656_BED309612_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1656/107533955/1656_BED309612_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1656/107533955/1656_BED309612_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1656/107533955/1656_BED309612_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1656/107533955/1656_BED309612_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1656/107533955/1656_BED309612_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1656/107533955/1656_BED309612_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1656/107533955/1656_BED309612_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1656/107533955/1656_BED309612_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Bedford Road, Wixams, Bedford",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.46398,
            "lat": 52.09171
        },
        "_place": 10.38,
        "_people": 22.08,
        "_property": 1,
        "_price": 140,
        "map": property_650
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116331827#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/31k/30461/116331827/30461_4289325_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30461/116331827/30461_4289325_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30461/116331827/30461_4289325_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30461/116331827/30461_4289325_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30461/116331827/30461_4289325_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30461/116331827/30461_4289325_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30461/116331827/30461_4289325_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30461/116331827/30461_4289325_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30461/116331827/30461_4289325_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30461/116331827/30461_4289325_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30461/116331827/30461_4289325_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Tackley, Oxfordshire, OX5",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.298,
            "lat": 51.88
        },
        "_place": 7.07,
        "_people": 31.37,
        "_property": 3,
        "_price": 106.19,
        "map": property_651
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117745691#",
        "title": "3 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42944/117745691/42944_CAM210221_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 320000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Talgos Close, Shallow Adit, Redruth, Cornwall",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.208784,
            "lat": 50.248084
        },
        "_place": 7.71,
        "_people": 27.11,
        "_property": 4,
        "_price": 99.22,
        "map": property_652
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117131123#",
        "title": "4 bedroom property",
        "images": [
            "https://media.rightmove.co.uk/233k/232841/117131123/232841_4_Plot_85_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/233k/232841/117131123/232841_4_Plot_85_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/233k/232841/117131123/232841_4_Plot_85_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/233k/232841/117131123/232841_4_Plot_85_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/233k/232841/117131123/232841_4_Plot_85_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/233k/232841/117131123/232841_4_Plot_85_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/233k/232841/117131123/232841_4_Plot_85_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/233k/232841/117131123/232841_4_Plot_85_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/233k/232841/117131123/232841_4_Plot_85_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/233k/232841/117131123/232841_4_Plot_85_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/233k/232841/117131123/232841_4_Plot_85_IMG_11_0000.jpg"
        ],
        "price": [
            {
                "value": 359995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Stewarton,\r\nKA3 5LG",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.50655,
            "lat": 55.67628
        },
        "_place": 2.09,
        "_people": 25.99,
        "_property": 5,
        "_price": 181.84,
        "map": property_653
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110831003#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/110831003/52719_2251260_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 1000000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Henslowe Road, East Dulwich",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.06727,
            "lat": 51.45615
        },
        "_place": 3.67,
        "_people": 22.43,
        "_property": 4,
        "_price": 113.68,
        "map": property_654
    },
    {
        "url": "https://www.rightmove.co.uk/properties/87166933#",
        "title": "Detached house",
        "images": [
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_22_0002.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_20_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_23_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48638/87166933/48638_TRS200691_IMG_15_0001.jpeg"
        ],
        "price": [
            {
                "value": 525000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Treswell, Launceston, Cornwall, PL15",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.430218,
            "lat": 50.579514
        },
        "_place": 10.78,
        "_people": 21.09,
        "_property": 4,
        "_price": 200.99,
        "map": property_655
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114764996#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95200/114764996/95200_11074447_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Jobie Wood Close, Sprowston, Norwich, NR7",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.334081,
            "lat": 52.662197
        },
        "_place": 11.61,
        "_people": 27.59,
        "_property": 2,
        "_price": 124.05,
        "map": property_656
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117465821#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/75k/74909/117465821/74909_BRC1SAL1005118_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74909/117465821/74909_BRC1SAL1005118_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74909/117465821/74909_BRC1SAL1005118_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74909/117465821/74909_BRC1SAL1005118_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74909/117465821/74909_BRC1SAL1005118_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74909/117465821/74909_BRC1SAL1005118_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74909/117465821/74909_BRC1SAL1005118_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 325000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "The Knowlings, Whitchurch",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.33204,
            "lat": 51.22623
        },
        "_place": 8.06,
        "_people": 33.24,
        "_property": 1,
        "_price": 199.01,
        "map": property_657
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116582756#",
        "title": "4 bedroom cottage",
        "images": [
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51893/116582756/51893_22186853_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 695000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Dam Lane, Rixton, Warrington, WA3",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.472952,
            "lat": 53.423514
        },
        "_place": 9.7,
        "_people": 35.27,
        "_property": 4,
        "_price": 149.44,
        "map": property_658
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116888987#",
        "title": "2 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/5k/4656/116888987/4656_31130657_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/116888987/4656_31130657_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/116888987/4656_31130657_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/116888987/4656_31130657_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/116888987/4656_31130657_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/116888987/4656_31130657_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/116888987/4656_31130657_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4656/116888987/4656_31130657_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 270000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Beechwood Avenue, Monkseaton, NE25 ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.469822,
            "lat": 55.041058
        },
        "_place": 4.71,
        "_people": 27.06,
        "_property": 2,
        "_price": 145.39,
        "map": property_659
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82659357#",
        "title": "3 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17780/82659357/17780_WBF210607_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 700000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "West Byfleet, Surrey, KT14",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.51368,
            "lat": 51.332459
        },
        "_place": 8.57,
        "_people": 28.6,
        "_property": 5,
        "_price": 185.08,
        "map": property_660
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111827771#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/36k/35198/111827771/35198_PRA10556_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35198/111827771/35198_PRA10556_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35198/111827771/35198_PRA10556_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35198/111827771/35198_PRA10556_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35198/111827771/35198_PRA10556_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35198/111827771/35198_PRA10556_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35198/111827771/35198_PRA10556_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35198/111827771/35198_PRA10556_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/36k/35198/111827771/35198_PRA10556_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 121000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "East Millwood Road, Speke, Liverpool, Merseyside, L24",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.824234,
            "lat": 53.346677
        },
        "_place": 11.83,
        "_people": 33.33,
        "_property": 0,
        "_price": 125.51,
        "map": property_661
    },
    {
        "url": "https://www.rightmove.co.uk/properties/78901623#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37841/78901623/37841_BDI210094_IMG_31_0000.jpeg"
        ],
        "price": [
            {
                "value": 735000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "St Peters Grange, Rickmansworth Lane, Chalfont St. Peter, Buckinghamshire, SL9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.542944,
            "lat": 51.620723
        },
        "_place": 5.45,
        "_people": 32.24,
        "_property": 4,
        "_price": 144.34,
        "map": property_662
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117322478#",
        "title": "4 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1541/117322478/1541_198_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 1150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Kingsborough Place, Kingston Upon Thames, Surrey, KT2",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.287484,
            "lat": 51.416012
        },
        "_place": 2.13,
        "_people": 33.37,
        "_property": 5,
        "_price": 137.21,
        "map": property_663
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116221094#",
        "title": "5 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8490/116221094/8490_11220101_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Ailsworth",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.351402,
            "lat": 52.577232
        },
        "_place": 6.76,
        "_people": 24.59,
        "_property": 3,
        "_price": 143.21,
        "map": property_664
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117236261#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11716/117236261/11716_8884134_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 535000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Tring",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.663222,
            "lat": 51.797432
        },
        "_place": 8.07,
        "_people": 29.42,
        "_property": 1,
        "_price": 195.52,
        "map": property_665
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116214692#",
        "title": "Studio apartment",
        "images": [
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": 1,
        "address": "High Holborn, Holborn",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.116701,
            "lat": 51.518162
        },
        "_place": 5.86,
        "_people": 31.09,
        "_property": 3,
        "_price": 162.06,
        "map": property_666
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116403089#",
        "title": "7 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/119k/118501/116403089/118501_31107390_IMG_44_0000.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": 5,
        "address": "Speybank, 52, Crown Drive, Inverness, IV2 3QG",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.210772,
            "lat": 57.478267
        },
        "_place": 9.98,
        "_people": 25.16,
        "_property": 2,
        "_price": 139.51,
        "map": property_667
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114200897#",
        "title": "3 bedroom smallholding",
        "images": [
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17469/114200897/17469_30991831_IMG_34_0000.jpeg"
        ],
        "price": [
            {
                "value": 695000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Betws",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.972551,
            "lat": 51.774753
        },
        "_place": 9.04,
        "_people": 22.08,
        "_property": 5,
        "_price": 108.04,
        "map": property_668
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116401838#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/15k/14653/116401838/14653_31107302_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14653/116401838/14653_31107302_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14653/116401838/14653_31107302_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14653/116401838/14653_31107302_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14653/116401838/14653_31107302_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14653/116401838/14653_31107302_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14653/116401838/14653_31107302_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14653/116401838/14653_31107302_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14653/116401838/14653_31107302_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 720000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Constance Road, Whitton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.363362,
            "lat": 51.450599
        },
        "_place": 0.34,
        "_people": 23.38,
        "_property": 1,
        "_price": 187.28,
        "map": property_669
    },
    {
        "url": "https://www.rightmove.co.uk/properties/80254688#",
        "title": "Plot",
        "images": [
            "https://media.rightmove.co.uk/100k/99326/80254688/99326_PlotStDuthusPlace_IMG_00_0000.JPG",
            "https://media.rightmove.co.uk/100k/99326/80254688/99326_PlotStDuthusPlace_IMG_01_0000.JPG",
            "https://media.rightmove.co.uk/100k/99326/80254688/99326_PlotStDuthusPlace_IMG_02_0000.JPG",
            "https://media.rightmove.co.uk/100k/99326/80254688/99326_PlotStDuthusPlace_IMG_03_0000.JPG"
        ],
        "price": [
            {
                "value": 100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Development Plot, St Duthus Place, Tain, Ross-Shire, IV19 1JA",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.05149,
            "lat": 57.81393
        },
        "_place": 0.65,
        "_people": 29.33,
        "_property": 2,
        "_price": 176.49,
        "map": property_670
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110627858#",
        "title": "6 bedroom farm house",
        "images": [
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234488/110627858/234488_103433000468_IMG_41_0000.jpeg"
        ],
        "price": [
            {
                "value": 799950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 3,
        "address": "Kirk Michael, Isle Of Man",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.607239,
            "lat": 54.260782
        },
        "_place": 7.19,
        "_people": 20.64,
        "_property": 0,
        "_price": 101.1,
        "map": property_671
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115637591#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124153/115637591/124153_101372003280_IMG_30_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 4,
        "address": "Church Gardens, March, Cambs., PE15 0BQ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.06525,
            "lat": 52.56567
        },
        "_place": 9.25,
        "_people": 28.8,
        "_property": 1,
        "_price": 174.92,
        "map": property_672
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115103876#",
        "title": "5 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/144k/143549/115103876/143549_ALN210075_IMG_45_0000.jpeg"
        ],
        "price": [
            {
                "value": 640000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 4,
        "address": "Lucker Road, Bamburgh, Northumberland, NE69",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.717976,
            "lat": 55.605676
        },
        "_place": 4.38,
        "_people": 23.57,
        "_property": 1,
        "_price": 155.69,
        "map": property_673
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113838041#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14498/113838041/14498_30831824_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Richmond Avenue, Kings Hill, West Malling",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.40482,
            "lat": 51.271953
        },
        "_place": 3.49,
        "_people": 19.03,
        "_property": 5,
        "_price": 127.35,
        "map": property_674
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111780635#",
        "title": "6 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/79k/78798/111780635/78798_P50_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78798/111780635/78798_P50_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78798/111780635/78798_P50_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78798/111780635/78798_P50_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78798/111780635/78798_P50_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78798/111780635/78798_P50_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78798/111780635/78798_P50_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78798/111780635/78798_P50_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 599000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": null,
        "address": "Knighton Road, Aylestone, Leicester, LE2",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.11418,
            "lat": 52.6104
        },
        "_place": 9.66,
        "_people": 23.54,
        "_property": 5,
        "_price": 179.87,
        "map": property_675
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117068090#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/19k/18371/117068090/18371_31137161_IMG_00_0000.png",
            "https://media.rightmove.co.uk/19k/18371/117068090/18371_31137161_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/19k/18371/117068090/18371_31137161_IMG_02_0000.png",
            "https://media.rightmove.co.uk/19k/18371/117068090/18371_31137161_IMG_03_0000.png",
            "https://media.rightmove.co.uk/19k/18371/117068090/18371_31137161_IMG_04_0000.png"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Morlas Meadows, St. Martins",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.016857,
            "lat": 52.920581
        },
        "_place": 5.21,
        "_people": 34.7,
        "_property": 1,
        "_price": 186.4,
        "map": property_676
    },
    {
        "url": "https://www.rightmove.co.uk/properties/77030003#",
        "title": "4 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/194k/193388/77030003/193388_VSM13EY-452_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/194k/193388/77030003/193388_VSM13EY-452_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/194k/193388/77030003/193388_VSM13EY-452_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/194k/193388/77030003/193388_VSM13EY-452_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/194k/193388/77030003/193388_VSM13EY-452_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/194k/193388/77030003/193388_VSM13EY-452_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/194k/193388/77030003/193388_VSM13EY-452_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/194k/193388/77030003/193388_VSM13EY-452_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/194k/193388/77030003/193388_VSM13EY-452_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/194k/193388/77030003/193388_VSM13EY-452_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/194k/193388/77030003/193388_VSM13EY-452_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/194k/193388/77030003/193388_VSM13EY-452_IMG_00_0000.jpg"
        ],
        "price": [
            {
                "value": 525000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Gloucester Gardens, Sutton, Surrey, SM1",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.19138,
            "lat": 51.37863
        },
        "_place": 11.56,
        "_people": 19.23,
        "_property": 3,
        "_price": 193.89,
        "map": property_677
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113893751#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/211k/210869/113893751/210869_30974022_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 625000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Church View, Burton Latimer, Northamptonshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.67452,
            "lat": 52.36709
        },
        "_place": 8.82,
        "_people": 24.97,
        "_property": 0,
        "_price": 140.4,
        "map": property_678
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117722666#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/32k/31484/117722666/31484_6052_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 645000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Atherley Road, Hayling Island",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.987838,
            "lat": 50.795734
        },
        "_place": 4.07,
        "_people": 35,
        "_property": 5,
        "_price": 119.51,
        "map": property_679
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113863430#",
        "title": "2 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/94k/93913/113863430/93913_WHITE06-t-4016_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 240000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Lagavulin, Isle of Islay, PA42",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -6.125073,
            "lat": 55.636096
        },
        "_place": 10.82,
        "_people": 32.12,
        "_property": 2,
        "_price": 197.96,
        "map": property_680
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116757500#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/5k/4986/116757500/4986_PAY200642_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4986/116757500/4986_PAY200642_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4986/116757500/4986_PAY200642_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4986/116757500/4986_PAY200642_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4986/116757500/4986_PAY200642_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4986/116757500/4986_PAY200642_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4986/116757500/4986_PAY200642_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4986/116757500/4986_PAY200642_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4986/116757500/4986_PAY200642_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4986/116757500/4986_PAY200642_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 175000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Merlinford Avenue, Renfrew, Renfrewshire, PA4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.378186,
            "lat": 55.875466
        },
        "_place": 4.54,
        "_people": 23.91,
        "_property": 1,
        "_price": 194.29,
        "map": property_681
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115775756#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37349/115775756/37349_100439037101_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "South Wootton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.42896,
            "lat": 52.77592
        },
        "_place": 8.96,
        "_people": 29.46,
        "_property": 0,
        "_price": 193.46,
        "map": property_682
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112369628#",
        "title": "4 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/39k/38957/112369628/38957_KLLIP_639696_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/112369628/38957_KLLIP_639696_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/112369628/38957_KLLIP_639696_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/112369628/38957_KLLIP_639696_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/112369628/38957_KLLIP_639696_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/112369628/38957_KLLIP_639696_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/112369628/38957_KLLIP_639696_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/112369628/38957_KLLIP_639696_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/112369628/38957_KLLIP_639696_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/112369628/38957_KLLIP_639696_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/112369628/38957_KLLIP_639696_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/112369628/38957_KLLIP_639696_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/112369628/38957_KLLIP_639696_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/112369628/38957_KLLIP_639696_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 495000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "The Avenue, Liphook",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.810819,
            "lat": 51.080574
        },
        "_place": 6.67,
        "_people": 31.65,
        "_property": 1,
        "_price": 112.87,
        "map": property_683
    },
    {
        "url": "https://www.rightmove.co.uk/properties/94779943#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/232k/231788/94779943/231788_4_37_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/232k/231788/94779943/231788_4_37_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/232k/231788/94779943/231788_4_37_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/232k/231788/94779943/231788_4_37_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/232k/231788/94779943/231788_4_37_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/232k/231788/94779943/231788_4_37_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/232k/231788/94779943/231788_4_37_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/232k/231788/94779943/231788_4_37_IMG_08_0000.jpg"
        ],
        "price": [
            {
                "value": 590000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Hedgerow Close, Basingstoke, RG23 7FT",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.14676,
            "lat": 51.23222
        },
        "_place": 10.43,
        "_people": 24.57,
        "_property": 1,
        "_price": 162.73,
        "map": property_684
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117009806#",
        "title": "3 bedroom character property",
        "images": [
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/167k/166175/117009806/166175_11242680_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Decoy Road, Potter Heigham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.568371,
            "lat": 52.72849
        },
        "_place": 7.27,
        "_people": 25.09,
        "_property": 0,
        "_price": 187.07,
        "map": property_685
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110196545#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/108k/107675/110196545/107675_XO2065_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 204950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Drovers Gate, Crieff, Perthshire , PH7 3SE",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.84334,
            "lat": 56.36705
        },
        "_place": 7.82,
        "_people": 29.03,
        "_property": 4,
        "_price": 185.26,
        "map": property_686
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110384900#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/83k/82361/110384900/82361_WNS210055_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82361/110384900/82361_WNS210055_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82361/110384900/82361_WNS210055_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82361/110384900/82361_WNS210055_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82361/110384900/82361_WNS210055_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82361/110384900/82361_WNS210055_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82361/110384900/82361_WNS210055_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82361/110384900/82361_WNS210055_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82361/110384900/82361_WNS210055_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82361/110384900/82361_WNS210055_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82361/110384900/82361_WNS210055_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82361/110384900/82361_WNS210055_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82361/110384900/82361_WNS210055_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82361/110384900/82361_WNS210055_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 915000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Nightingale Lane, London, SW12",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.156646,
            "lat": 51.450323
        },
        "_place": 8.36,
        "_people": 34.67,
        "_property": 2,
        "_price": 173.53,
        "map": property_687
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82482999#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/157k/156431/82482999/156431_5_H7966290_IMG_01_0001.jpg",
            "https://media.rightmove.co.uk/157k/156431/82482999/156431_5_H7966290_IMG_02_0001.jpg",
            "https://media.rightmove.co.uk/157k/156431/82482999/156431_5_H7966290_IMG_03_0001.jpg",
            "https://media.rightmove.co.uk/157k/156431/82482999/156431_5_H7966290_IMG_04_0003.jpg",
            "https://media.rightmove.co.uk/157k/156431/82482999/156431_5_H7966290_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/157k/156431/82482999/156431_5_H7966290_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/157k/156431/82482999/156431_5_H7966290_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/157k/156431/82482999/156431_5_H7966290_IMG_21_0000.jpg"
        ],
        "price": [
            {
                "value": 509995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "Bearscroft Lane,\r\nLondon Road,\r\nGodmanchester,\r\nHuntingdon,\r\nPE29",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.15686,
            "lat": 52.31057
        },
        "_place": 10.5,
        "_people": 19.11,
        "_property": 2,
        "_price": 120.62,
        "map": property_688
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115158017#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/65k/64482/115158017/64482_1well_IMG_25_0000.jpeg"
        ],
        "price": [
            {
                "value": 215000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Well Court, Chirnside, Berwickshire, TD11",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.2157,
            "lat": 55.80135
        },
        "_place": 4.1,
        "_people": 31.51,
        "_property": 4,
        "_price": 194.6,
        "map": property_689
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115827656#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8794/115827656/8794_11210163_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 849950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Fetcham, KT22",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.345835,
            "lat": 51.292187
        },
        "_place": 1.63,
        "_people": 24.29,
        "_property": 4,
        "_price": 95.38,
        "map": property_690
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116939780#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/239k/238268/116939780/238268_S50007_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238268/116939780/238268_S50007_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238268/116939780/238268_S50007_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238268/116939780/238268_S50007_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238268/116939780/238268_S50007_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238268/116939780/238268_S50007_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238268/116939780/238268_S50007_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 140000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Bywell View,Stocksfield,NE43 7LG",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.898806,
            "lat": 54.947105
        },
        "_place": 3.57,
        "_people": 26.22,
        "_property": 1,
        "_price": 107.39,
        "map": property_691
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116402930#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/116402930/4939_LAS210323_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 205000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Gooding Crescent, Stevenston, North Ayrshire, KA20",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.76825,
            "lat": 55.64297
        },
        "_place": 10.7,
        "_people": 28.5,
        "_property": 3,
        "_price": 195.97,
        "map": property_692
    },
    {
        "url": "https://www.rightmove.co.uk/properties/106538231#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/6k/5046/106538231/5046_TAY210061_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/6k/5046/106538231/5046_TAY210061_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/6k/5046/106538231/5046_TAY210061_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/6k/5046/106538231/5046_TAY210061_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/6k/5046/106538231/5046_TAY210061_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/6k/5046/106538231/5046_TAY210061_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/6k/5046/106538231/5046_TAY210061_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/6k/5046/106538231/5046_TAY210061_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/6k/5046/106538231/5046_TAY210061_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/6k/5046/106538231/5046_TAY210061_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/6k/5046/106538231/5046_TAY210061_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/6k/5046/106538231/5046_TAY210061_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/6k/5046/106538231/5046_TAY210061_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/6k/5046/106538231/5046_TAY210061_IMG_13_0001.jpeg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Cortland Drive, Bramley, Tadley, Hampshire, RG26",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.07228,
            "lat": 51.32463
        },
        "_place": 9.58,
        "_people": 24.13,
        "_property": 3,
        "_price": 183.26,
        "map": property_693
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116365838#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103727/116365838/103727_BHO211190_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "Halifax Road, Huddersfield, West Yorkshire, HD3",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.816071,
            "lat": 53.662727
        },
        "_place": 4.94,
        "_people": 25.92,
        "_property": 1,
        "_price": 156.74,
        "map": property_694
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112251956#",
        "title": "4 bedroom character property",
        "images": [
            "https://media.rightmove.co.uk/9k/8320/112251956/8320_GTU100298_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8320/112251956/8320_GTU100298_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8320/112251956/8320_GTU100298_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8320/112251956/8320_GTU100298_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8320/112251956/8320_GTU100298_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8320/112251956/8320_GTU100298_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8320/112251956/8320_GTU100298_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8320/112251956/8320_GTU100298_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8320/112251956/8320_GTU100298_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8320/112251956/8320_GTU100298_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8320/112251956/8320_GTU100298_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8320/112251956/8320_GTU100298_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8320/112251956/8320_GTU100298_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 795000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Drurys Lane, Seaton, Oakham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.66921,
            "lat": 52.57602
        },
        "_place": 5.27,
        "_people": 24.33,
        "_property": 2,
        "_price": 190.05,
        "map": property_695
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110374304#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/82k/81127/110374304/81127_B1992_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 399950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Maes Llydan, LL74",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.22895,
            "lat": 53.31349
        },
        "_place": 10.34,
        "_people": 35.99,
        "_property": 4,
        "_price": 104.14,
        "map": property_696
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116365688#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/54k/53907/116365688/53907_SUM180339_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53907/116365688/53907_SUM180339_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53907/116365688/53907_SUM180339_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53907/116365688/53907_SUM180339_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53907/116365688/53907_SUM180339_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53907/116365688/53907_SUM180339_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53907/116365688/53907_SUM180339_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53907/116365688/53907_SUM180339_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53907/116365688/53907_SUM180339_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53907/116365688/53907_SUM180339_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53907/116365688/53907_SUM180339_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53907/116365688/53907_SUM180339_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53907/116365688/53907_SUM180339_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53907/116365688/53907_SUM180339_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53907/116365688/53907_SUM180339_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 265000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Ambrose Rise, Wheatley, Oxford",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.1283,
            "lat": 51.74511
        },
        "_place": 4.66,
        "_people": 24.78,
        "_property": 4,
        "_price": 134.26,
        "map": property_697
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113179598#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/215k/214025/113179598/214025_ELA210050_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214025/113179598/214025_ELA210050_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214025/113179598/214025_ELA210050_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214025/113179598/214025_ELA210050_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/215k/214025/113179598/214025_ELA210050_IMG_02_0000.jpeg"
        ],
        "price": [
            {
                "value": 699950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Hargham Road, NR17",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.93387,
            "lat": 52.4957
        },
        "_place": 0.25,
        "_people": 35.98,
        "_property": 2,
        "_price": 131,
        "map": property_698
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82889178#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/219k/218141/82889178/218141_3_61_IMG_01_0000.JPG",
            "https://media.rightmove.co.uk/219k/218141/82889178/218141_3_61_IMG_02_0000.JPG",
            "https://media.rightmove.co.uk/219k/218141/82889178/218141_3_61_IMG_03_0000.JPG",
            "https://media.rightmove.co.uk/219k/218141/82889178/218141_3_61_IMG_04_0000.JPG",
            "https://media.rightmove.co.uk/219k/218141/82889178/218141_3_61_IMG_05_0000.JPG",
            "https://media.rightmove.co.uk/219k/218141/82889178/218141_3_61_IMG_06_0000.JPG",
            "https://media.rightmove.co.uk/219k/218141/82889178/218141_3_61_IMG_07_0000.JPG",
            "https://media.rightmove.co.uk/219k/218141/82889178/218141_3_61_IMG_08_0000.JPG",
            "https://media.rightmove.co.uk/219k/218141/82889178/218141_3_61_IMG_09_0000.JPG"
        ],
        "price": [
            {
                "value": 235995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Kingsmark Way, \r\nGoldthorpe,\r\nS63 9JB",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.29687,
            "lat": 53.53536
        },
        "_place": 5.1,
        "_people": 24.23,
        "_property": 5,
        "_price": 195.77,
        "map": property_699
    },
    {
        "url": "https://www.rightmove.co.uk/properties/92877647#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_20_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/89k/88429/92877647/88429_10278799_IMG_19_0000.jpg"
        ],
        "price": [
            {
                "value": 650000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Ferry Road, Fiskerton, LN3",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.418474,
            "lat": 53.235107
        },
        "_place": 9.2,
        "_people": 27.49,
        "_property": 4,
        "_price": 127.36,
        "map": property_700
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82271790#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/78k/77418/82271790/77418_EDI210414_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77418/82271790/77418_EDI210414_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77418/82271790/77418_EDI210414_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77418/82271790/77418_EDI210414_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77418/82271790/77418_EDI210414_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77418/82271790/77418_EDI210414_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77418/82271790/77418_EDI210414_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77418/82271790/77418_EDI210414_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77418/82271790/77418_EDI210414_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77418/82271790/77418_EDI210414_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77418/82271790/77418_EDI210414_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 195000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Plot 28, The Devon, Glenluie Green, Ardler Road, Meigle, Blairgowrie, Perthshire",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.164465,
            "lat": 56.586288
        },
        "_place": 2.14,
        "_people": 35.32,
        "_property": 2,
        "_price": 100.91,
        "map": property_701
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116057306#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/50k/49567/116057306/49567_CHI210443_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/116057306/49567_CHI210443_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/116057306/49567_CHI210443_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/116057306/49567_CHI210443_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/116057306/49567_CHI210443_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/116057306/49567_CHI210443_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/116057306/49567_CHI210443_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/116057306/49567_CHI210443_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/116057306/49567_CHI210443_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/116057306/49567_CHI210443_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/116057306/49567_CHI210443_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/116057306/49567_CHI210443_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49567/116057306/49567_CHI210443_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 735000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Cawley Road, Chichester, West Sussex, PO19",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.777837,
            "lat": 50.832915
        },
        "_place": 9.19,
        "_people": 24.82,
        "_property": 1,
        "_price": 136.4,
        "map": property_702
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81011598#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40100/81011598/40100_OBN210035_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 395000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Calgary Farmhouse, Calgary, Isle of Mull, PA75",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -6.270658,
            "lat": 56.583042
        },
        "_place": 6.8,
        "_people": 26.35,
        "_property": 2,
        "_price": 117.82,
        "map": property_703
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110446427#",
        "title": "4 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/140k/139286/110446427/139286_930_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/140k/139286/110446427/139286_930_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/140k/139286/110446427/139286_930_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/140k/139286/110446427/139286_930_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/140k/139286/110446427/139286_930_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/140k/139286/110446427/139286_930_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/140k/139286/110446427/139286_930_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/140k/139286/110446427/139286_930_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/140k/139286/110446427/139286_930_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/140k/139286/110446427/139286_930_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/140k/139286/110446427/139286_930_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/140k/139286/110446427/139286_930_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/140k/139286/110446427/139286_930_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/140k/139286/110446427/139286_930_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/140k/139286/110446427/139286_930_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 195000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Holderness Road, Hull, Yorkshire, HU9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.299665,
            "lat": 53.7616
        },
        "_place": 11.43,
        "_people": 25.62,
        "_property": 3,
        "_price": 113.74,
        "map": property_704
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115851518#",
        "title": "4 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235532/115851518/235532_1132373-1_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 295000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Ulladale Crescent, Strathpeffer, IV14",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.546289,
            "lat": 57.58562
        },
        "_place": 11.88,
        "_people": 30.96,
        "_property": 1,
        "_price": 195.17,
        "map": property_705
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117130856#",
        "title": "4 bedroom property",
        "images": [
            "https://media.rightmove.co.uk/220k/219014/117130856/219014_4_Plot_44_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/220k/219014/117130856/219014_4_Plot_44_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/220k/219014/117130856/219014_4_Plot_44_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/220k/219014/117130856/219014_4_Plot_44_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/220k/219014/117130856/219014_4_Plot_44_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/220k/219014/117130856/219014_4_Plot_44_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/220k/219014/117130856/219014_4_Plot_44_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/220k/219014/117130856/219014_4_Plot_44_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/220k/219014/117130856/219014_4_Plot_44_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/220k/219014/117130856/219014_4_Plot_44_IMG_10_0000.jpg"
        ],
        "price": [
            {
                "value": 289995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Brimington Road, \r\nChesterfield,\r\nS41",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.42193,
            "lat": 53.2483
        },
        "_place": 9.54,
        "_people": 31.78,
        "_property": 5,
        "_price": 137,
        "map": property_706
    },
    {
        "url": "https://www.rightmove.co.uk/properties/102798776#",
        "title": "4 bedroom barn conversion",
        "images": [
            "https://media.rightmove.co.uk/63k/62680/102798776/62680_100318014955_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62680/102798776/62680_100318014955_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62680/102798776/62680_100318014955_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62680/102798776/62680_100318014955_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62680/102798776/62680_100318014955_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62680/102798776/62680_100318014955_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62680/102798776/62680_100318014955_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62680/102798776/62680_100318014955_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62680/102798776/62680_100318014955_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62680/102798776/62680_100318014955_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62680/102798776/62680_100318014955_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Cooper Lane, Hoylandswaine",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.59401,
            "lat": 53.545
        },
        "_place": 9.82,
        "_people": 27.47,
        "_property": 3,
        "_price": 140.04,
        "map": property_707
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117185147#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76731/117185147/76731_CAM210600_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 900000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Frimley, Camberley, GU16",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.706578,
            "lat": 51.32534
        },
        "_place": 7.59,
        "_people": 28.04,
        "_property": 4,
        "_price": 160.48,
        "map": property_708
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115546214#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7677/115546214/7677_21517763_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 570000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Grovelands Avenue, Hitchin, SG4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.254621,
            "lat": 51.961725
        },
        "_place": 1.37,
        "_people": 31.42,
        "_property": 2,
        "_price": 142.35,
        "map": property_709
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117442079#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52134/117442079/52134_100402005305_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Priory Road, St. Olaves, Great Yarmouth",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.62435,
            "lat": 52.53474
        },
        "_place": 7.15,
        "_people": 20.73,
        "_property": 0,
        "_price": 165.72,
        "map": property_710
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113652953#",
        "title": "3 bedroom link detached house",
        "images": [
            "https://media.rightmove.co.uk/35k/34733/113652953/34733_MOD190097_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/113652953/34733_MOD190097_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/113652953/34733_MOD190097_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/113652953/34733_MOD190097_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/113652953/34733_MOD190097_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/113652953/34733_MOD190097_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/113652953/34733_MOD190097_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/113652953/34733_MOD190097_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/113652953/34733_MOD190097_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/113652953/34733_MOD190097_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/113652953/34733_MOD190097_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/113652953/34733_MOD190097_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34733/113652953/34733_MOD190097_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 410000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Donkey Lane, Ugborough, Ivybridge, Devon, PL21",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.862568,
            "lat": 50.386294
        },
        "_place": 9.96,
        "_people": 29.04,
        "_property": 3,
        "_price": 165.76,
        "map": property_711
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82733310#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/166k/165578/82733310/165578_208-20840-350_4_32_101368_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/166k/165578/82733310/165578_208-20840-350_4_32_101368_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/166k/165578/82733310/165578_208-20840-350_4_32_101368_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/166k/165578/82733310/165578_208-20840-350_4_32_101368_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/166k/165578/82733310/165578_208-20840-350_4_32_101368_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/166k/165578/82733310/165578_208-20840-350_4_32_101368_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/166k/165578/82733310/165578_208-20840-350_4_32_101368_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/166k/165578/82733310/165578_208-20840-350_4_32_101368_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/166k/165578/82733310/165578_208-20840-350_4_32_101368_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/166k/165578/82733310/165578_208-20840-350_4_32_101368_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/166k/165578/82733310/165578_208-20840-350_4_32_101368_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/166k/165578/82733310/165578_208-20840-350_4_32_101368_IMG_12_0000.jpg"
        ],
        "price": [
            {
                "value": 420000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Baker Drive\r\nHethersett\r\nNR9 3GB",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.1843,
            "lat": 52.60488
        },
        "_place": 1.77,
        "_people": 35.63,
        "_property": 1,
        "_price": 103.3,
        "map": property_712
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108189128#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10788/108189128/10788_30704042_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 420000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Sunningdale Avenue, Pakefield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.733413,
            "lat": 52.453868
        },
        "_place": 11.75,
        "_people": 32.86,
        "_property": 0,
        "_price": 92.67,
        "map": property_713
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115726625#",
        "title": "3 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/164k/163850/115726625/163850_11216016_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/164k/163850/115726625/163850_11216016_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/164k/163850/115726625/163850_11216016_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/164k/163850/115726625/163850_11216016_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/164k/163850/115726625/163850_11216016_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/164k/163850/115726625/163850_11216016_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/164k/163850/115726625/163850_11216016_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/164k/163850/115726625/163850_11216016_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/164k/163850/115726625/163850_11216016_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/164k/163850/115726625/163850_11216016_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 325000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Mariners Wharf, Quayside",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.59243,
            "lat": 54.971302
        },
        "_place": 6.16,
        "_people": 24.84,
        "_property": 1,
        "_price": 160.3,
        "map": property_714
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117459863#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/85k/84644/117459863/84644_CHA210461_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84644/117459863/84644_CHA210461_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84644/117459863/84644_CHA210461_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84644/117459863/84644_CHA210461_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84644/117459863/84644_CHA210461_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84644/117459863/84644_CHA210461_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84644/117459863/84644_CHA210461_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84644/117459863/84644_CHA210461_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84644/117459863/84644_CHA210461_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84644/117459863/84644_CHA210461_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84644/117459863/84644_CHA210461_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84644/117459863/84644_CHA210461_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84644/117459863/84644_CHA210461_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 310000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Thorpefield Close, Thorpe Hesley, Rotherham, South Yorkshire, S61",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.43084,
            "lat": 53.460859
        },
        "_place": 6.78,
        "_people": 18.49,
        "_property": 2,
        "_price": 159.66,
        "map": property_715
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113097065#",
        "title": "4 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/12k/11355/113097065/11355_226902_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11355/113097065/11355_226902_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11355/113097065/11355_226902_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11355/113097065/11355_226902_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11355/113097065/11355_226902_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11355/113097065/11355_226902_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11355/113097065/11355_226902_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11355/113097065/11355_226902_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11355/113097065/11355_226902_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11355/113097065/11355_226902_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11355/113097065/11355_226902_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 480000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Cholmeley Road, Reading, RG1",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.945398,
            "lat": 51.454337
        },
        "_place": 1.33,
        "_people": 18.91,
        "_property": 1,
        "_price": 144.12,
        "map": property_716
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117551792#",
        "title": "2 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/11k/10291/117551792/10291_GPS210393_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/117551792/10291_GPS210393_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/117551792/10291_GPS210393_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/117551792/10291_GPS210393_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/117551792/10291_GPS210393_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/117551792/10291_GPS210393_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/117551792/10291_GPS210393_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/117551792/10291_GPS210393_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/117551792/10291_GPS210393_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/117551792/10291_GPS210393_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/117551792/10291_GPS210393_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/117551792/10291_GPS210393_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/117551792/10291_GPS210393_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10291/117551792/10291_GPS210393_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Honiton Road, Romford, RM7",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.173337,
            "lat": 51.573579
        },
        "_place": 5.46,
        "_people": 24.47,
        "_property": 3,
        "_price": 177.69,
        "map": property_717
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117089462#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/232k/231521/117089462/231521_Plot87_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/232k/231521/117089462/231521_Plot87_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/232k/231521/117089462/231521_Plot87_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/232k/231521/117089462/231521_Plot87_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/232k/231521/117089462/231521_Plot87_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/232k/231521/117089462/231521_Plot87_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/232k/231521/117089462/231521_Plot87_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/232k/231521/117089462/231521_Plot87_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 294995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Whalleys Road, Lancashire, \r\nWN8",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.77607,
            "lat": 53.5678
        },
        "_place": 9.41,
        "_people": 26.25,
        "_property": 2,
        "_price": 166.89,
        "map": property_718
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81938580#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4939/81938580/4939_LAS210264_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 149000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Shuma Court, Skelmorlie, PA17",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.889101,
            "lat": 55.857273
        },
        "_place": 5.02,
        "_people": 21.22,
        "_property": 4,
        "_price": 121.69,
        "map": property_719
    },
    {
        "url": "https://www.rightmove.co.uk/properties/97250288#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/47k/46838/97250288/46838_30002465_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/47k/46838/97250288/46838_30002465_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/47k/46838/97250288/46838_30002465_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/47k/46838/97250288/46838_30002465_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/47k/46838/97250288/46838_30002465_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/47k/46838/97250288/46838_30002465_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/47k/46838/97250288/46838_30002465_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/47k/46838/97250288/46838_30002465_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/47k/46838/97250288/46838_30002465_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/47k/46838/97250288/46838_30002465_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/47k/46838/97250288/46838_30002465_IMG_11_0000.jpg"
        ],
        "price": [
            {
                "value": 105000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Bentlass Terrace, Pennar, Pembroke Dock",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.948935,
            "lat": 51.682994
        },
        "_place": 6.8,
        "_people": 22.69,
        "_property": 2,
        "_price": 147.36,
        "map": property_720
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116214692#",
        "title": "Studio apartment",
        "images": [
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116214692/110768_101184001270_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": 1,
        "address": "High Holborn, Holborn",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.116701,
            "lat": 51.518162
        },
        "_place": 3.64,
        "_people": 21.23,
        "_property": 1,
        "_price": 188.09,
        "map": property_721
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117188588#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6233/117188588/6233_11222054_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 180000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Ashwood Road, Parkgate",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.345605,
            "lat": 53.45531
        },
        "_place": 5.91,
        "_people": 19.62,
        "_property": 5,
        "_price": 171.5,
        "map": property_722
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117435434#",
        "title": "3 bedroom semi-detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/77k/76310/117435434/76310_1079_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76310/117435434/76310_1079_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76310/117435434/76310_1079_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76310/117435434/76310_1079_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76310/117435434/76310_1079_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76310/117435434/76310_1079_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76310/117435434/76310_1079_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76310/117435434/76310_1079_IMG_03_0000.jpeg"
        ],
        "price": [
            {
                "value": 290000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Manor Drive, Merriott, Somerset, TA16",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.7925,
            "lat": 50.90783
        },
        "_place": 9.24,
        "_people": 34.23,
        "_property": 1,
        "_price": 140.59,
        "map": property_723
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116091251#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94381/116091251/94381_11188119_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Elmsdale Hall, Wightwick Grove, Wightwick, Wolverhampton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.192288,
            "lat": 52.585758
        },
        "_place": 11.51,
        "_people": 25.19,
        "_property": 4,
        "_price": 188.53,
        "map": property_724
    },
    {
        "url": "https://www.rightmove.co.uk/properties/106116632#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/39k/38834/106116632/38834_100621003682_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38834/106116632/38834_100621003682_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38834/106116632/38834_100621003682_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38834/106116632/38834_100621003682_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38834/106116632/38834_100621003682_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38834/106116632/38834_100621003682_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38834/106116632/38834_100621003682_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38834/106116632/38834_100621003682_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38834/106116632/38834_100621003682_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38834/106116632/38834_100621003682_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38834/106116632/38834_100621003682_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38834/106116632/38834_100621003682_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38834/106116632/38834_100621003682_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38834/106116632/38834_100621003682_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "King Edward Gardens, Tunbridge Wells",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.259072,
            "lat": 51.136766
        },
        "_place": 7.72,
        "_people": 31.86,
        "_property": 5,
        "_price": 142.71,
        "map": property_725
    },
    {
        "url": "https://www.rightmove.co.uk/properties/76049931#",
        "title": "Studio flat",
        "images": [
            "https://media.rightmove.co.uk/217k/216407/76049931/216407_CHG07_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/217k/216407/76049931/216407_CHG07_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/217k/216407/76049931/216407_CHG07_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/217k/216407/76049931/216407_CHG07_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/217k/216407/76049931/216407_CHG07_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/217k/216407/76049931/216407_CHG07_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/217k/216407/76049931/216407_CHG07_IMG_05_0000.jpeg"
        ],
        "price": [
            {
                "value": 995000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": 1,
        "address": "25 Parker Street,\r\nLondon,\r\nWC2B",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.12185,
            "lat": 51.51504
        },
        "_place": 5.87,
        "_people": 22.06,
        "_property": 3,
        "_price": 151.77,
        "map": property_726
    },
    {
        "url": "https://www.rightmove.co.uk/properties/69334193#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/71k/70239/69334193/70239_EDN140296_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/71k/70239/69334193/70239_EDN140296_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/71k/70239/69334193/70239_EDN140296_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/71k/70239/69334193/70239_EDN140296_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/71k/70239/69334193/70239_EDN140296_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 435000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Land At Dreva Road, Broughton, Biggar, Lanarkshire",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.407197,
            "lat": 55.613048
        },
        "_place": 1.69,
        "_people": 32.53,
        "_property": 1,
        "_price": 185.35,
        "map": property_727
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116655035#",
        "title": "6 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/216k/215642/116655035/215642_RX136458_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215642/116655035/215642_RX136458_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215642/116655035/215642_RX136458_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215642/116655035/215642_RX136458_IMG_03_0000.jpeg"
        ],
        "price": [
            {
                "value": 900000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 4,
        "address": "Mount Pleasant, Milton Keynes, MK17",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.621711,
            "lat": 52.013649
        },
        "_place": 1.3,
        "_people": 35.02,
        "_property": 5,
        "_price": 140.35,
        "map": property_728
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110219558#",
        "title": "4 bedroom cottage",
        "images": [
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62411/110219558/62411_2180924_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 585000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Thame, Oxfordshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.966395,
            "lat": 51.741629
        },
        "_place": 1.4,
        "_people": 33.35,
        "_property": 3,
        "_price": 109.66,
        "map": property_729
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117643379#",
        "title": "3 bedroom ground floor flat",
        "images": [
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199427/117643379/199427_442678_IMG_32_0000.jpeg"
        ],
        "price": [
            {
                "value": 120000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "7a Orchard Street, WEST KILBRIDE, KA23 9AF",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.858554,
            "lat": 55.693691
        },
        "_place": 7.78,
        "_people": 34.62,
        "_property": 3,
        "_price": 159.38,
        "map": property_730
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116081420#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15812/116081420/15812_103139016052_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 485000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Hollinscroft Court, Lower Tean, Stoke-on-Trent",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.98299,
            "lat": 52.95144
        },
        "_place": 5.71,
        "_people": 20.46,
        "_property": 3,
        "_price": 166.64,
        "map": property_731
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112858388#",
        "title": "2 bedroom town house",
        "images": [
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40115/112858388/40115_30925049_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 289950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Bank Top, Earsdon, Whitley Bay",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.496058,
            "lat": 55.046318
        },
        "_place": 3.13,
        "_people": 33.41,
        "_property": 3,
        "_price": 171.6,
        "map": property_732
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116896562#",
        "title": "8 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77421/116896562/77421_RUR210393_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 595000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 8,
        "bathrooms": 7,
        "address": "Barley Bree, 6 Willoughby Street, Muthill, Crieff",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.832291,
            "lat": 56.331845
        },
        "_place": 8.43,
        "_people": 20.51,
        "_property": 5,
        "_price": 153.83,
        "map": property_733
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112606577#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/165k/164093/112606577/164093_52324122_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 415000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Aintree Road, Corby, Northants",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.700712,
            "lat": 52.467731
        },
        "_place": 4.88,
        "_people": 20.43,
        "_property": 3,
        "_price": 144.49,
        "map": property_734
    },
    {
        "url": "https://www.rightmove.co.uk/properties/90661684#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/204k/203033/90661684/203033_1164196-1_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203033/90661684/203033_1164196-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/90661684/203033_1164196-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/90661684/203033_1164196-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/90661684/203033_1164196-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/90661684/203033_1164196-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/90661684/203033_1164196-1_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 175000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "3 City Walk, Leeds, LS11",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.54803,
            "lat": 53.789915
        },
        "_place": 3.99,
        "_people": 34.27,
        "_property": 2,
        "_price": 115.43,
        "map": property_735
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111106220#",
        "title": "2 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/33k/32865/111106220/32865_30841516_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32865/111106220/32865_30841516_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32865/111106220/32865_30841516_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32865/111106220/32865_30841516_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32865/111106220/32865_30841516_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32865/111106220/32865_30841516_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32865/111106220/32865_30841516_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32865/111106220/32865_30841516_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32865/111106220/32865_30841516_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32865/111106220/32865_30841516_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32865/111106220/32865_30841516_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32865/111106220/32865_30841516_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32865/111106220/32865_30841516_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32865/111106220/32865_30841516_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 125000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Bryn Llewelyn, Alltwen",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.840459,
            "lat": 51.714366
        },
        "_place": 11.11,
        "_people": 20.81,
        "_property": 5,
        "_price": 175.99,
        "map": property_736
    },
    {
        "url": "https://www.rightmove.co.uk/properties/99683690#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/107k/106696/99683690/106696_AstleyHouse220288_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/107k/106696/99683690/106696_AstleyHouse220288_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/107k/106696/99683690/106696_AstleyHouse220288_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/107k/106696/99683690/106696_AstleyHouse220288_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/107k/106696/99683690/106696_AstleyHouse220288_IMG_03_0000.jpeg"
        ],
        "price": [
            {
                "value": 675000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Back Lane, LE16",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.93005,
            "lat": 52.52401
        },
        "_place": 10.02,
        "_people": 28,
        "_property": 4,
        "_price": 162.2,
        "map": property_737
    },
    {
        "url": "https://www.rightmove.co.uk/properties/83020350#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212471/83020350/212471_5_362_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 489950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "Weir Hill,\r\nPreston Street,\r\nShrewsbury,\r\nSY2",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.71553,
            "lat": 52.70495
        },
        "_place": 11.19,
        "_people": 33.2,
        "_property": 5,
        "_price": 132.39,
        "map": property_738
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114835625#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/77k/76006/114835625/76006_76006_179_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76006/114835625/76006_76006_179_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76006/114835625/76006_76006_179_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76006/114835625/76006_76006_179_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76006/114835625/76006_76006_179_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76006/114835625/76006_76006_179_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76006/114835625/76006_76006_179_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76006/114835625/76006_76006_179_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/77k/76006/114835625/76006_76006_179_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "5 Sykes Close, St. Olave's Road, York, North Yorkshire. YO30 6HZ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.09054,
            "lat": 53.96738
        },
        "_place": 7.65,
        "_people": 21.88,
        "_property": 4,
        "_price": 94.68,
        "map": property_739
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113295674#",
        "title": "2 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/95k/94808/113295674/94808_30945428_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 170000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Grantown on Spey",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.612326,
            "lat": 57.327033
        },
        "_place": 3.84,
        "_people": 31.03,
        "_property": 4,
        "_price": 172.74,
        "map": property_740
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112017290#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/43k/42824/112017290/42824_521848_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 995000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Priory Close, Woodham, GU21",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.524985,
            "lat": 51.338962
        },
        "_place": 9.43,
        "_people": 32.55,
        "_property": 4,
        "_price": 185.79,
        "map": property_741
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114428552#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/23k/22613/114428552/22613_31006408_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22613/114428552/22613_31006408_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22613/114428552/22613_31006408_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22613/114428552/22613_31006408_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22613/114428552/22613_31006408_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22613/114428552/22613_31006408_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22613/114428552/22613_31006408_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22613/114428552/22613_31006408_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22613/114428552/22613_31006408_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22613/114428552/22613_31006408_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 930000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 4,
        "address": "Royal Oak Lane, Pirton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.327413,
            "lat": 51.973419
        },
        "_place": 9.38,
        "_people": 35.85,
        "_property": 4,
        "_price": 120.91,
        "map": property_742
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114366362#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/218k/217232/114366362/217232_Sombourne-277-285_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217232/114366362/217232_Sombourne-277-285_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217232/114366362/217232_Sombourne-277-285_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217232/114366362/217232_Sombourne-277-285_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217232/114366362/217232_Sombourne-277-285_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217232/114366362/217232_Sombourne-277-285_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217232/114366362/217232_Sombourne-277-285_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217232/114366362/217232_Sombourne-277-285_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217232/114366362/217232_Sombourne-277-285_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217232/114366362/217232_Sombourne-277-285_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217232/114366362/217232_Sombourne-277-285_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217232/114366362/217232_Sombourne-277-285_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217232/114366362/217232_Sombourne-277-285_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/218k/217232/114366362/217232_Sombourne-277-285_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Didcot,\r\nOxfordshire,\r\nOX11 7SB",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.2297,
            "lat": 51.61629
        },
        "_place": 11.8,
        "_people": 29.19,
        "_property": 0,
        "_price": 185.94,
        "map": property_743
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113962868#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/45k/44957/113962868/44957_PRA12590_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44957/113962868/44957_PRA12590_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44957/113962868/44957_PRA12590_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44957/113962868/44957_PRA12590_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44957/113962868/44957_PRA12590_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44957/113962868/44957_PRA12590_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44957/113962868/44957_PRA12590_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44957/113962868/44957_PRA12590_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44957/113962868/44957_PRA12590_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44957/113962868/44957_PRA12590_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44957/113962868/44957_PRA12590_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44957/113962868/44957_PRA12590_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44957/113962868/44957_PRA12590_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 235000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Lewis Road, Neath, West Glamorgan. SA11 1DX",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.803527,
            "lat": 51.65569
        },
        "_place": 1.64,
        "_people": 25.42,
        "_property": 5,
        "_price": 190.31,
        "map": property_744
    },
    {
        "url": "https://www.rightmove.co.uk/properties/90620866#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/16k/15013/90620866/15013_4903928_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/90620866/15013_4903928_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/16k/15013/90620866/15013_4903928_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/90620866/15013_4903928_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/90620866/15013_4903928_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/90620866/15013_4903928_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/90620866/15013_4903928_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/90620866/15013_4903928_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/90620866/15013_4903928_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/90620866/15013_4903928_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/90620866/15013_4903928_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/16k/15013/90620866/15013_4903928_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 219999,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Baltic Quay, Mill Road, Gateshead, Tyne and Wear",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.596968,
            "lat": 54.969172
        },
        "_place": 11.02,
        "_people": 19.55,
        "_property": 0,
        "_price": 124.54,
        "map": property_745
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113808920#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191906/113808920/191906_30942186_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 495000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Laugharne, Carmarthen",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.464251,
            "lat": 51.769203
        },
        "_place": 9.16,
        "_people": 19.32,
        "_property": 1,
        "_price": 174.38,
        "map": property_746
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117236996#",
        "title": "5 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/84k/83216/117236996/83216_100662030248_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 389000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Oak Dene Close, Greenfield, Saddleworth",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.01022,
            "lat": 53.53212
        },
        "_place": 2.56,
        "_people": 35.13,
        "_property": 3,
        "_price": 190.44,
        "map": property_747
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108348659#",
        "title": "4 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30209/108348659/30209_DIN210084_IMG_10_0001.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Slade Hooton, Laughton, Sheffield, South Yorkshire, S25",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.214744,
            "lat": 53.396715
        },
        "_place": 0.46,
        "_people": 19.96,
        "_property": 5,
        "_price": 163.75,
        "map": property_748
    },
    {
        "url": "https://www.rightmove.co.uk/properties/78137328#",
        "title": "Character property",
        "images": [
            "https://media.rightmove.co.uk/15k/14009/78137328/14009_30420081_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14009/78137328/14009_30420081_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14009/78137328/14009_30420081_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14009/78137328/14009_30420081_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14009/78137328/14009_30420081_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Manor Road, Farnley Tyas, Huddersfield, HD4 ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.741654,
            "lat": 53.611582
        },
        "_place": 11.01,
        "_people": 34.22,
        "_property": 2,
        "_price": 109.95,
        "map": property_749
    },
    {
        "url": "https://www.rightmove.co.uk/properties/73537257#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/5k/4077/73537257/4077_SLR200452_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/5k/4077/73537257/4077_SLR200452_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/5k/4077/73537257/4077_SLR200452_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/5k/4077/73537257/4077_SLR200452_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/5k/4077/73537257/4077_SLR200452_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/5k/4077/73537257/4077_SLR200452_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/5k/4077/73537257/4077_SLR200452_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/5k/4077/73537257/4077_SLR200452_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/5k/4077/73537257/4077_SLR200452_IMG_09_0000.png",
            "https://media.rightmove.co.uk/5k/4077/73537257/4077_SLR200452_IMG_10_0000.png",
            "https://media.rightmove.co.uk/5k/4077/73537257/4077_SLR200452_IMG_11_0000.jpg"
        ],
        "price": [
            {
                "value": 925000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Rear Of The Railway Inn, Witham Road, LN10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.240613,
            "lat": 53.143053
        },
        "_place": 10.83,
        "_people": 25.67,
        "_property": 3,
        "_price": 137.48,
        "map": property_750
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116488571#",
        "title": "1 bedroom ground floor flat",
        "images": [
            "https://media.rightmove.co.uk/2k/1974/116488571/1974_DSS109061_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/2k/1974/116488571/1974_DSS109061_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/2k/1974/116488571/1974_DSS109061_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/2k/1974/116488571/1974_DSS109061_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/2k/1974/116488571/1974_DSS109061_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/2k/1974/116488571/1974_DSS109061_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/2k/1974/116488571/1974_DSS109061_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/2k/1974/116488571/1974_DSS109061_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/2k/1974/116488571/1974_DSS109061_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/2k/1974/116488571/1974_DSS109061_IMG_01_0001.jpeg"
        ],
        "price": [
            {
                "value": 150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Diss Road, Scole, Diss",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.14672,
            "lat": 52.36684
        },
        "_place": 0.69,
        "_people": 20.09,
        "_property": 3,
        "_price": 118.62,
        "map": property_751
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113348123#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28685/113348123/28685_FieldViewCl_IMG_01_0000.jpeg"
        ],
        "price": [
            {
                "value": 630000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Field View Close, Ampleforth, York, YO62 4EL",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.10493,
            "lat": 54.19818
        },
        "_place": 7.96,
        "_people": 33.68,
        "_property": 0,
        "_price": 200.79,
        "map": property_752
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114979745#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12203/114979745/12203_YAR210293_IMG_30_0000.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Yarm Road, Eaglescliffe",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.346813,
            "lat": 54.530704
        },
        "_place": 6.5,
        "_people": 33.36,
        "_property": 4,
        "_price": 169.62,
        "map": property_753
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115991804#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/190k/189104/115991804/189104_31087135_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189104/115991804/189104_31087135_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189104/115991804/189104_31087135_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189104/115991804/189104_31087135_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189104/115991804/189104_31087135_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189104/115991804/189104_31087135_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189104/115991804/189104_31087135_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189104/115991804/189104_31087135_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189104/115991804/189104_31087135_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/190k/189104/115991804/189104_31087135_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 825000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "St. Thomas's Gardens, Chalk Farm NW5",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.154379,
            "lat": 51.548561
        },
        "_place": 4.66,
        "_people": 33.04,
        "_property": 2,
        "_price": 129.95,
        "map": property_754
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114685187#",
        "title": "3 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/56k/55429/114685187/55429_2256122_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 950000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Ferme Park Road, Crouch End",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.11578,
            "lat": 51.57825
        },
        "_place": 8.98,
        "_people": 18.46,
        "_property": 4,
        "_price": 91.33,
        "map": property_755
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107182943#",
        "title": "4 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/50k/49210/107182943/49210_9418513_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 315000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Leighswood Road, Aldridge, Walsall, WS9 8AW",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.923436,
            "lat": 52.60709
        },
        "_place": 2.83,
        "_people": 29.91,
        "_property": 5,
        "_price": 158.26,
        "map": property_756
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117114788#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200930/117114788/200930_HSS192571_IMG_30_0000.jpeg"
        ],
        "price": [
            {
                "value": 700000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Woodlands Court, Huddersfield, HD3",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.818528,
            "lat": 53.665843
        },
        "_place": 4.75,
        "_people": 26.36,
        "_property": 3,
        "_price": 187.08,
        "map": property_757
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114981146#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/37k/36269/114981146/36269_CLI217366_IMG_00_0002.jpeg",
            "https://media.rightmove.co.uk/37k/36269/114981146/36269_CLI217366_IMG_01_0002.jpeg",
            "https://media.rightmove.co.uk/37k/36269/114981146/36269_CLI217366_IMG_02_0002.jpeg",
            "https://media.rightmove.co.uk/37k/36269/114981146/36269_CLI217366_IMG_03_0002.jpeg",
            "https://media.rightmove.co.uk/37k/36269/114981146/36269_CLI217366_IMG_04_0002.jpeg",
            "https://media.rightmove.co.uk/37k/36269/114981146/36269_CLI217366_IMG_05_0002.jpeg",
            "https://media.rightmove.co.uk/37k/36269/114981146/36269_CLI217366_IMG_06_0002.jpeg",
            "https://media.rightmove.co.uk/37k/36269/114981146/36269_CLI217366_IMG_07_0002.jpeg",
            "https://media.rightmove.co.uk/37k/36269/114981146/36269_CLI217366_IMG_08_0002.jpeg",
            "https://media.rightmove.co.uk/37k/36269/114981146/36269_CLI217366_IMG_09_0002.jpeg"
        ],
        "price": [
            {
                "value": 1100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "The Merchant Building, 38 Wharf Road, London, N1",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.095618,
            "lat": 51.531667
        },
        "_place": 0.93,
        "_people": 18.22,
        "_property": 1,
        "_price": 164.75,
        "map": property_758
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114743972#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/37k/36236/114743972/36236_EXS170180_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36236/114743972/36236_EXS170180_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36236/114743972/36236_EXS170180_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36236/114743972/36236_EXS170180_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36236/114743972/36236_EXS170180_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36236/114743972/36236_EXS170180_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36236/114743972/36236_EXS170180_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36236/114743972/36236_EXS170180_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36236/114743972/36236_EXS170180_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36236/114743972/36236_EXS170180_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36236/114743972/36236_EXS170180_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36236/114743972/36236_EXS170180_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36236/114743972/36236_EXS170180_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/37k/36236/114743972/36236_EXS170180_IMG_13_0001.jpeg"
        ],
        "price": [
            {
                "value": 1000000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Mill Leat Close, Yealmpton, Plymouth, PL8",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.994706,
            "lat": 50.348359
        },
        "_place": 4.32,
        "_people": 19.16,
        "_property": 4,
        "_price": 157.02,
        "map": property_759
    },
    {
        "url": "https://www.rightmove.co.uk/properties/104712926#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/69k/68860/104712926/68860_9583250_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/69k/68860/104712926/68860_9583250_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/69k/68860/104712926/68860_9583250_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/69k/68860/104712926/68860_9583250_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/69k/68860/104712926/68860_9583250_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/69k/68860/104712926/68860_9583250_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/69k/68860/104712926/68860_9583250_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/69k/68860/104712926/68860_9583250_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/69k/68860/104712926/68860_9583250_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/69k/68860/104712926/68860_9583250_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/69k/68860/104712926/68860_9583250_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/69k/68860/104712926/68860_9583250_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/69k/68860/104712926/68860_9583250_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/69k/68860/104712926/68860_9583250_IMG_15_0001.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "7 Wylam Grange, Station Road, Wylam",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.814541,
            "lat": 54.97269
        },
        "_place": 4.25,
        "_people": 28.53,
        "_property": 5,
        "_price": 150.33,
        "map": property_760
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117718100#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/108k/107252/117718100/107252_529038698_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107252/117718100/107252_529038698_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107252/117718100/107252_529038698_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107252/117718100/107252_529038698_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107252/117718100/107252_529038698_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107252/117718100/107252_529038698_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107252/117718100/107252_529038698_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107252/117718100/107252_529038698_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107252/117718100/107252_529038698_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107252/117718100/107252_529038698_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107252/117718100/107252_529038698_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107252/117718100/107252_529038698_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107252/117718100/107252_529038698_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107252/117718100/107252_529038698_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/108k/107252/117718100/107252_529038698_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Kidman Close, Romford, RM2",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": 0.212732,
            "lat": 51.584389
        },
        "_place": 9.76,
        "_people": 33.39,
        "_property": 1,
        "_price": 97.27,
        "map": property_761
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116338871#",
        "title": "24 bedroom block of apartments",
        "images": [
            "https://media.rightmove.co.uk/216k/215642/116338871/215642_RX136074_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215642/116338871/215642_RX136074_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215642/116338871/215642_RX136074_IMG_02_0000.jpeg"
        ],
        "price": [
            {
                "value": 925000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 24,
        "bathrooms": 24,
        "address": "Railway Road, Wigan, WN7",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -2.524074,
            "lat": 53.497289
        },
        "_place": 4.76,
        "_people": 21.42,
        "_property": 1,
        "_price": 182.01,
        "map": property_762
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116919470#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/204k/203042/116919470/203042_1282279-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203042/116919470/203042_1282279-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203042/116919470/203042_1282279-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203042/116919470/203042_1282279-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203042/116919470/203042_1282279-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203042/116919470/203042_1282279-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203042/116919470/203042_1282279-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203042/116919470/203042_1282279-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203042/116919470/203042_1282279-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203042/116919470/203042_1282279-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203042/116919470/203042_1282279-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203042/116919470/203042_1282279-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203042/116919470/203042_1282279-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203042/116919470/203042_1282279-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203042/116919470/203042_1282279-1_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 300000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Northfield Road, Manchester, M40",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.15708,
            "lat": 53.518321
        },
        "_place": 5.03,
        "_people": 32.89,
        "_property": 1,
        "_price": 199.44,
        "map": property_763
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117594734#",
        "title": "2 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/226k/225020/117594734/225020_11247227_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 105000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "16 Helen's Terrace, Kilwinning, KA13 6DB",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.699708,
            "lat": 55.64915
        },
        "_place": 8.87,
        "_people": 25.06,
        "_property": 2,
        "_price": 115.26,
        "map": property_764
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116488100#",
        "title": "1 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110768/116488100/110768_101184001389_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 875000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "John Adam Street, Covent Garden. WC2N",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.12324,
            "lat": 51.50921
        },
        "_place": 8.57,
        "_people": 34.5,
        "_property": 0,
        "_price": 141.12,
        "map": property_765
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117558962#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61866/117558962/61866_6919859_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 485000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Tonkins Drive, Thatcham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.249261,
            "lat": 51.396156
        },
        "_place": 8.99,
        "_people": 18.96,
        "_property": 1,
        "_price": 131.42,
        "map": property_766
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114565493#",
        "title": "1 bedroom terraced bungalow",
        "images": [
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/114565493/154001_100523009035_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 152000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Bembridge , Isle of Wight ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.079039,
            "lat": 50.688213
        },
        "_place": 8.89,
        "_people": 26.77,
        "_property": 0,
        "_price": 169.76,
        "map": property_767
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116341592#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52848/116341592/52848_60902343_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 700000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Solent View Road, Seaview, Isle of Wight",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.1131,
            "lat": 50.71616
        },
        "_place": 3.8,
        "_people": 31.72,
        "_property": 4,
        "_price": 110.85,
        "map": property_768
    },
    {
        "url": "https://www.rightmove.co.uk/properties/64668927#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/95k/94922/64668927/94922_15951910_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/95k/94922/64668927/94922_15951910_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/95k/94922/64668927/94922_15951910_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/95k/94922/64668927/94922_15951910_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/95k/94922/64668927/94922_15951910_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/95k/94922/64668927/94922_15951910_IMG_20_0000.jpg",
            "https://media.rightmove.co.uk/95k/94922/64668927/94922_15951910_IMG_21_0000.jpg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Limetree Court, York, YO30",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.090896,
            "lat": 53.968193
        },
        "_place": 3.96,
        "_people": 35.1,
        "_property": 0,
        "_price": 176.69,
        "map": property_769
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82845300#",
        "title": "3 bedroom barn conversion",
        "images": [
            "https://media.rightmove.co.uk/39k/38693/82845300/38693_BSE210335_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38693/82845300/38693_BSE210335_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38693/82845300/38693_BSE210335_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38693/82845300/38693_BSE210335_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38693/82845300/38693_BSE210335_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38693/82845300/38693_BSE210335_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38693/82845300/38693_BSE210335_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38693/82845300/38693_BSE210335_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38693/82845300/38693_BSE210335_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38693/82845300/38693_BSE210335_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38693/82845300/38693_BSE210335_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38693/82845300/38693_BSE210335_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38693/82845300/38693_BSE210335_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38693/82845300/38693_BSE210335_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38693/82845300/38693_BSE210335_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 535000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Walsham le Willows, Suffolk",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.941099,
            "lat": 52.304833
        },
        "_place": 5.94,
        "_people": 20.39,
        "_property": 4,
        "_price": 135.26,
        "map": property_770
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112755806#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/224k/223841/112755806/223841_214-20560-689_4_34_101368_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/224k/223841/112755806/223841_214-20560-689_4_34_101368_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/224k/223841/112755806/223841_214-20560-689_4_34_101368_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/224k/223841/112755806/223841_214-20560-689_4_34_101368_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/224k/223841/112755806/223841_214-20560-689_4_34_101368_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/224k/223841/112755806/223841_214-20560-689_4_34_101368_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/224k/223841/112755806/223841_214-20560-689_4_34_101368_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/224k/223841/112755806/223841_214-20560-689_4_34_101368_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/224k/223841/112755806/223841_214-20560-689_4_34_101368_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/224k/223841/112755806/223841_214-20560-689_4_34_101368_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/224k/223841/112755806/223841_214-20560-689_4_34_101368_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/224k/223841/112755806/223841_214-20560-689_4_34_101368_IMG_12_0000.jpg"
        ],
        "price": [
            {
                "value": 472000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Radstone Fields,\r\nBrackley,\r\nNN13 5GE",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.14426,
            "lat": 52.04197
        },
        "_place": 11,
        "_people": 33.29,
        "_property": 0,
        "_price": 167.34,
        "map": property_771
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114051461#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4490/114051461/4490_L725684_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 435000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "South Hill Crescent",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.407198,
            "lat": 51.521808
        },
        "_place": 7.71,
        "_people": 25.46,
        "_property": 5,
        "_price": 96.36,
        "map": property_772
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117150053#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/1k/198/117150053/198_MBA210444_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/1k/198/117150053/198_MBA210444_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/1k/198/117150053/198_MBA210444_IMG_02_0000.jpeg"
        ],
        "price": [
            {
                "value": 495000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Chapel Lane, Bodicote, Banbury, Oxfordshire, OX15",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.32876,
            "lat": 52.03777
        },
        "_place": 11.93,
        "_people": 34.41,
        "_property": 5,
        "_price": 99.89,
        "map": property_773
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117215552#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9883/117215552/9883_22237272_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 985000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "New Forest Drive, Brockenhurst, SO42",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.590551,
            "lat": 50.820749
        },
        "_place": 9.36,
        "_people": 28.02,
        "_property": 5,
        "_price": 125.38,
        "map": property_774
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116191409#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78146/116191409/78146_PER210073_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 160000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Spynie Cottage, 56 High Street, Auchtermuchty, Cupar, KY14",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.23319,
            "lat": 56.291927
        },
        "_place": 0.39,
        "_people": 35.83,
        "_property": 1,
        "_price": 168.32,
        "map": property_775
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116695928#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/88k/87041/116695928/87041_TEL210562_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 380000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Lavender Close, Telford, TF3",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.483569,
            "lat": 52.686865
        },
        "_place": 0.53,
        "_people": 27.77,
        "_property": 0,
        "_price": 200.57,
        "map": property_776
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116173397#",
        "title": "3 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/85k/84827/116173397/84827_31097530_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84827/116173397/84827_31097530_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84827/116173397/84827_31097530_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84827/116173397/84827_31097530_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84827/116173397/84827_31097530_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84827/116173397/84827_31097530_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84827/116173397/84827_31097530_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 1150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Palliser Road, West Kensington",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.213079,
            "lat": 51.489509
        },
        "_place": 1.22,
        "_people": 29.28,
        "_property": 3,
        "_price": 181.53,
        "map": property_777
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114433640#",
        "title": "Studio flat",
        "images": [
            "https://media.rightmove.co.uk/80k/79044/114433640/79044_31006820_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79044/114433640/79044_31006820_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79044/114433640/79044_31006820_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79044/114433640/79044_31006820_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79044/114433640/79044_31006820_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79044/114433640/79044_31006820_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79044/114433640/79044_31006820_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79044/114433640/79044_31006820_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79044/114433640/79044_31006820_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79044/114433640/79044_31006820_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79044/114433640/79044_31006820_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79044/114433640/79044_31006820_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 120000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "The Old Bakery General Store, Llanbedr",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.100796,
            "lat": 52.820613
        },
        "_place": 0.42,
        "_people": 19.46,
        "_property": 3,
        "_price": 171.5,
        "map": property_778
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116493575#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48641/116493575/48641_TUS210330_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 1200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "The Glen, Pennington Road, Tunbridge Wells, Kent, TN4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.268147,
            "lat": 51.162906
        },
        "_place": 9.75,
        "_people": 28.95,
        "_property": 5,
        "_price": 110.31,
        "map": property_779
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107401055#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7368/107401055/7368_30669151_IMG_25_0000.jpeg"
        ],
        "price": [
            {
                "value": 315000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Forrest Avenue, Marsh, Huddersfield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.804956,
            "lat": 53.652729
        },
        "_place": 11.45,
        "_people": 23.5,
        "_property": 1,
        "_price": 123.93,
        "map": property_780
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111841832#",
        "title": "5 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/42k/41322/111841832/41322_4163199_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41322/111841832/41322_4163199_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41322/111841832/41322_4163199_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41322/111841832/41322_4163199_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41322/111841832/41322_4163199_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41322/111841832/41322_4163199_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41322/111841832/41322_4163199_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41322/111841832/41322_4163199_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41322/111841832/41322_4163199_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41322/111841832/41322_4163199_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 975000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Balmoral Road, Willesden Green, NW2",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.22911,
            "lat": 51.549618
        },
        "_place": 10.97,
        "_people": 34.95,
        "_property": 0,
        "_price": 101.63,
        "map": property_781
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111909629#",
        "title": "7 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_45_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_46_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_47_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_48_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_49_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_50_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_51_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_52_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_53_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_54_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_55_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234446/111909629/234446_55178_IMG_56_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": 6,
        "address": "The Heathers, Spean Bridge",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.912732,
            "lat": 56.954191
        },
        "_place": 4.84,
        "_people": 35.1,
        "_property": 4,
        "_price": 106.22,
        "map": property_782
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117216149#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/78k/77046/117216149/77046_267326_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/117216149/77046_267326_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/117216149/77046_267326_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/117216149/77046_267326_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/117216149/77046_267326_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/117216149/77046_267326_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/117216149/77046_267326_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/117216149/77046_267326_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/117216149/77046_267326_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/117216149/77046_267326_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/117216149/77046_267326_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 115000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Gordon Place, Tain",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.966173,
            "lat": 57.776347
        },
        "_place": 10.05,
        "_people": 27.52,
        "_property": 0,
        "_price": 167.27,
        "map": property_783
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116400602#",
        "title": "2 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/212k/211355/116400602/211355_SOW210262_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/212k/211355/116400602/211355_SOW210262_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/212k/211355/116400602/211355_SOW210262_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/212k/211355/116400602/211355_SOW210262_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/212k/211355/116400602/211355_SOW210262_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/212k/211355/116400602/211355_SOW210262_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/212k/211355/116400602/211355_SOW210262_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/212k/211355/116400602/211355_SOW210262_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/212k/211355/116400602/211355_SOW210262_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/212k/211355/116400602/211355_SOW210262_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/212k/211355/116400602/211355_SOW210262_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/212k/211355/116400602/211355_SOW210262_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/212k/211355/116400602/211355_SOW210262_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 250000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Wangford Road, Reydon, Southwold, IP18",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.662264,
            "lat": 52.3436
        },
        "_place": 10.51,
        "_people": 35.22,
        "_property": 2,
        "_price": 101.42,
        "map": property_784
    },
    {
        "url": "https://www.rightmove.co.uk/properties/91115638#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203441/91115638/203441_1131141-1_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203441/91115638/203441_1131141-1_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203441/91115638/203441_1131141-1_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203441/91115638/203441_1131141-1_IMG_29_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/91115638/203441_1131141-1_IMG_30_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/91115638/203441_1131141-1_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203441/91115638/203441_1131141-1_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95980/91115638/95980_1131141-1_IMG_28_0000.jpeg"
        ],
        "price": [
            {
                "value": 699950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Stubb Lane, Rye, TN31",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.599341,
            "lat": 50.935837
        },
        "_place": 11.96,
        "_people": 31.52,
        "_property": 1,
        "_price": 97.58,
        "map": property_785
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116345534#",
        "title": "3 bedroom town house",
        "images": [
            "https://media.rightmove.co.uk/145k/144683/116345534/144683_11192729_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144683/116345534/144683_11192729_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144683/116345534/144683_11192729_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144683/116345534/144683_11192729_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144683/116345534/144683_11192729_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144683/116345534/144683_11192729_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144683/116345534/144683_11192729_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144683/116345534/144683_11192729_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144683/116345534/144683_11192729_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144683/116345534/144683_11192729_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144683/116345534/144683_11192729_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144683/116345534/144683_11192729_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/145k/144683/116345534/144683_11192729_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 220000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Deal Avenue, Burntwood",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.924973,
            "lat": 52.68689
        },
        "_place": 3.5,
        "_people": 25.31,
        "_property": 0,
        "_price": 175.75,
        "map": property_786
    },
    {
        "url": "https://www.rightmove.co.uk/properties/100889897#",
        "title": "2 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84391/100889897/84391_10685366_IMG_06_0001.jpeg"
        ],
        "price": [
            {
                "value": 289950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Buildwas, Nr Ironbridge, Shropshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.537149,
            "lat": 52.640289
        },
        "_place": 3,
        "_people": 30.82,
        "_property": 2,
        "_price": 182.59,
        "map": property_787
    },
    {
        "url": "https://www.rightmove.co.uk/properties/73442142#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/135k/134735/73442142/134735_LNH190136_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/135k/134735/73442142/134735_LNH190136_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/135k/134735/73442142/134735_LNH190136_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/135k/134735/73442142/134735_LNH190136_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/135k/134735/73442142/134735_LNH190136_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/135k/134735/73442142/134735_LNH190136_IMG_06_0000.jpg"
        ],
        "price": [
            {
                "value": 932000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Orchard Point, Elephant and Castle, London, SE17",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.098286,
            "lat": 51.492452
        },
        "_place": 6.9,
        "_people": 35.86,
        "_property": 2,
        "_price": 188.19,
        "map": property_788
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108417374#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/78k/77282/108417374/77282_30711942_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77282/108417374/77282_30711942_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77282/108417374/77282_30711942_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77282/108417374/77282_30711942_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77282/108417374/77282_30711942_IMG_05_0000.jpeg"
        ],
        "price": [
            {
                "value": 695000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 4,
        "address": "Hutton Conyers, Ripon",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.500408,
            "lat": 54.155292
        },
        "_place": 10.36,
        "_people": 18.42,
        "_property": 4,
        "_price": 108.27,
        "map": property_789
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116621039#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/53k/52719/116621039/52719_2257629_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/116621039/52719_2257629_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/116621039/52719_2257629_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/116621039/52719_2257629_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/116621039/52719_2257629_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/116621039/52719_2257629_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52719/116621039/52719_2257629_IMG_06_0003.jpeg"
        ],
        "price": [
            {
                "value": 1000000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Gairloch Road, Camberwell",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.08106,
            "lat": 51.4704
        },
        "_place": 11.15,
        "_people": 34.44,
        "_property": 3,
        "_price": 157.99,
        "map": property_790
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117392114#",
        "title": "3 bedroom penthouse",
        "images": [
            "https://media.rightmove.co.uk/45k/44312/117392114/44312_EXE210367_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44312/117392114/44312_EXE210367_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44312/117392114/44312_EXE210367_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44312/117392114/44312_EXE210367_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44312/117392114/44312_EXE210367_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44312/117392114/44312_EXE210367_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44312/117392114/44312_EXE210367_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44312/117392114/44312_EXE210367_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44312/117392114/44312_EXE210367_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44312/117392114/44312_EXE210367_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44312/117392114/44312_EXE210367_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44312/117392114/44312_EXE210367_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44312/117392114/44312_EXE210367_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Crabshell Quay, Embankment Road, Kingsbridge, Devon",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.772881,
            "lat": 50.278217
        },
        "_place": 11.62,
        "_people": 29.42,
        "_property": 0,
        "_price": 187.64,
        "map": property_791
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81607329#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/53k/52921/81607329/52921_10479194_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Llanrug",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.192509,
            "lat": 53.158695
        },
        "_place": 9.23,
        "_people": 32.87,
        "_property": 0,
        "_price": 90.71,
        "map": property_792
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117718424#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/223k/222071/117718424/222071_SNH210047_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222071/117718424/222071_SNH210047_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222071/117718424/222071_SNH210047_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222071/117718424/222071_SNH210047_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222071/117718424/222071_SNH210047_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222071/117718424/222071_SNH210047_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222071/117718424/222071_SNH210047_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222071/117718424/222071_SNH210047_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222071/117718424/222071_SNH210047_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222071/117718424/222071_SNH210047_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Birlinn Brae at Strachur, The Bay, Strachur, Argyll",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.082814,
            "lat": 56.165613
        },
        "_place": 0.32,
        "_people": 26.66,
        "_property": 1,
        "_price": 118.12,
        "map": property_793
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115506416#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30675/115506416/30675_100461001295_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Bugford",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.638533,
            "lat": 50.346423
        },
        "_place": 5.79,
        "_people": 24.99,
        "_property": 1,
        "_price": 183.15,
        "map": property_795
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115776005#",
        "title": "3 bedroom cottage",
        "images": [
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4758/115776005/4758_31077116_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 525000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Higher Tretharrup, Lanner, Redruth",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.205182,
            "lat": 50.207798
        },
        "_place": 7.29,
        "_people": 26.22,
        "_property": 5,
        "_price": 174.26,
        "map": property_796
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114558014#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61544/114558014/61544_4927481_IMG_18_0001.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Kiveton Lane, Todwick, Sheffield, South Yorkshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.257939,
            "lat": 53.355058
        },
        "_place": 11.5,
        "_people": 30.41,
        "_property": 3,
        "_price": 115.25,
        "map": property_797
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113506253#",
        "title": "2 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29429/113506253/29429_30954934_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Sunniside Lane, Cleadon, Sunderland",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.399422,
            "lat": 54.954959
        },
        "_place": 10.69,
        "_people": 34,
        "_property": 5,
        "_price": 106.4,
        "map": property_798
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117610796#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238280/117610796/238280_S51164_IMG_30_0000.jpeg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Rose,Truro,CORNWALL,TR4 9PJ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.12122,
            "lat": 50.351608
        },
        "_place": 9.03,
        "_people": 18.34,
        "_property": 3,
        "_price": 144.5,
        "map": property_800
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116191970#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/216k/215510/116191970/215510_4_11_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/216k/215510/116191970/215510_4_11_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/216k/215510/116191970/215510_4_11_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/216k/215510/116191970/215510_4_11_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/216k/215510/116191970/215510_4_11_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/216k/215510/116191970/215510_4_11_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/216k/215510/116191970/215510_4_11_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/216k/215510/116191970/215510_4_11_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/216k/215510/116191970/215510_4_11_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/216k/215510/116191970/215510_4_11_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/216k/215510/116191970/215510_4_11_IMG_11_0000.jpg"
        ],
        "price": [
            {
                "value": 619995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Mentmore Road,\r\nCheddington,\r\nLU7",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.66564,
            "lat": 51.84906
        },
        "_place": 7.22,
        "_people": 26.58,
        "_property": 0,
        "_price": 97.85,
        "map": property_801
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116277050#",
        "title": "5 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/81k/80375/116277050/80375_31102181_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 675000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 1,
        "address": "Thames Drive, Leigh-on-sea, Essex",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.630531,
            "lat": 51.547883
        },
        "_place": 0.16,
        "_people": 19.63,
        "_property": 2,
        "_price": 106.64,
        "map": property_802
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115636337#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/200k/199958/115636337/199958_41850_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "Trefula Park, Liverpool L12 7LH",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.91331,
            "lat": 53.4292
        },
        "_place": 6.39,
        "_people": 18.67,
        "_property": 4,
        "_price": 199.95,
        "map": property_803
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115004024#",
        "title": "5 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/62k/61336/115004024/61336_31035059_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61336/115004024/61336_31035059_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61336/115004024/61336_31035059_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61336/115004024/61336_31035059_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61336/115004024/61336_31035059_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61336/115004024/61336_31035059_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61336/115004024/61336_31035059_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61336/115004024/61336_31035059_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61336/115004024/61336_31035059_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61336/115004024/61336_31035059_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61336/115004024/61336_31035059_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/62k/61336/115004024/61336_31035059_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 1,
        "address": "Mill Lane, Butterwick, Boston",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.061142,
            "lat": 52.987989
        },
        "_place": 8.37,
        "_people": 18.26,
        "_property": 2,
        "_price": 162.34,
        "map": property_804
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114015485#",
        "title": "5 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2937/114015485/2937_30980064_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 700000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 1,
        "address": "Livesey Street, Teston",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.431261,
            "lat": 51.266361
        },
        "_place": 4.62,
        "_people": 24.62,
        "_property": 0,
        "_price": 109.83,
        "map": property_805
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115120436#",
        "title": "2 bedroom cottage",
        "images": [
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77387/115120436/77387_31041772_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 275000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "New Street, Aby, Alford",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.116163,
            "lat": 53.284397
        },
        "_place": 9.95,
        "_people": 32.37,
        "_property": 5,
        "_price": 186.92,
        "map": property_806
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81606975#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_00_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_01_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_02_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_03_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_04_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_05_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_06_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_07_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_08_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_09_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_10_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_11_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_12_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_13_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_14_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_15_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_16_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_17_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_18_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_19_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_20_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_21_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_22_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_23_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_24_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_25_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_26_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_27_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_28_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_29_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_30_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_31_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_32_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_33_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_34_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_35_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_36_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_37_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_38_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_39_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_40_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_41_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_42_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_43_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_44_0002.jpeg",
            "https://media.rightmove.co.uk/127k/126409/81606975/126409_PFE210233_IMG_45_0002.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Wreath Lane, Chard, Somerset, TA20",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.92552,
            "lat": 50.870998
        },
        "_place": 10.47,
        "_people": 22.19,
        "_property": 5,
        "_price": 109.24,
        "map": property_807
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113780894#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/3k/2128/113780894/2128_SWB106953_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2128/113780894/2128_SWB106953_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2128/113780894/2128_SWB106953_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2128/113780894/2128_SWB106953_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2128/113780894/2128_SWB106953_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2128/113780894/2128_SWB106953_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2128/113780894/2128_SWB106953_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2128/113780894/2128_SWB106953_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2128/113780894/2128_SWB106953_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2128/113780894/2128_SWB106953_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2128/113780894/2128_SWB106953_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2128/113780894/2128_SWB106953_IMG_01_0000.jpeg"
        ],
        "price": [
            {
                "value": 190000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Rochdale Road, Sowerby Bridge",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.90057,
            "lat": 53.71418
        },
        "_place": 6.18,
        "_people": 28.46,
        "_property": 5,
        "_price": 169.66,
        "map": property_808
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113213816#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/92k/91825/113213816/91825_MAC210104_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91825/113213816/91825_MAC210104_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91825/113213816/91825_MAC210104_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91825/113213816/91825_MAC210104_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91825/113213816/91825_MAC210104_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91825/113213816/91825_MAC210104_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91825/113213816/91825_MAC210104_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91825/113213816/91825_MAC210104_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91825/113213816/91825_MAC210104_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91825/113213816/91825_MAC210104_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91825/113213816/91825_MAC210104_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91825/113213816/91825_MAC210104_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91825/113213816/91825_MAC210104_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91825/113213816/91825_MAC210104_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 129000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Maes Y Coed, Aberhosan, Machynlleth, Powys, SY20",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.754207,
            "lat": 52.561258
        },
        "_place": 9.69,
        "_people": 21.38,
        "_property": 0,
        "_price": 194.99,
        "map": property_809
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113142098#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/111k/110776/113142098/110776_30937357_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110776/113142098/110776_30937357_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110776/113142098/110776_30937357_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110776/113142098/110776_30937357_IMG_03_0000.jpeg"
        ],
        "price": [
            {
                "value": 925000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Leicester Road, Uppingham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.73539,
            "lat": 52.593582
        },
        "_place": 7.47,
        "_people": 31.21,
        "_property": 3,
        "_price": 173.41,
        "map": property_810
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82989945#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/200k/199034/82989945/199034_204-20789-533_4_32_101368_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/200k/199034/82989945/199034_204-20789-533_4_32_101368_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/200k/199034/82989945/199034_204-20789-533_4_32_101368_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/200k/199034/82989945/199034_204-20789-533_4_32_101368_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/200k/199034/82989945/199034_204-20789-533_4_32_101368_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/200k/199034/82989945/199034_204-20789-533_4_32_101368_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/200k/199034/82989945/199034_204-20789-533_4_32_101368_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/200k/199034/82989945/199034_204-20789-533_4_32_101368_IMG_08_0000.jpg"
        ],
        "price": [
            {
                "value": 407000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "St. Lucia Crescent\r\nBletchley\r\nMilton Keynes\r\nMK3 5FQ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.73932,
            "lat": 51.97099
        },
        "_place": 3.25,
        "_people": 28.61,
        "_property": 1,
        "_price": 135.87,
        "map": property_811
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116453321#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_06_0002.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/109k/108095/116453321/108095_3130_JENN_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 1200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "NO1 Deansgate, Manchester",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.245081,
            "lat": 53.483929
        },
        "_place": 7.85,
        "_people": 19.94,
        "_property": 5,
        "_price": 192.01,
        "map": property_812
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109787231#",
        "title": "11 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/230k/229118/109787231/229118_10698448_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229118/109787231/229118_10698448_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229118/109787231/229118_10698448_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229118/109787231/229118_10698448_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229118/109787231/229118_10698448_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229118/109787231/229118_10698448_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229118/109787231/229118_10698448_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229118/109787231/229118_10698448_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229118/109787231/229118_10698448_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229118/109787231/229118_10698448_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229118/109787231/229118_10698448_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229118/109787231/229118_10698448_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/230k/229118/109787231/229118_10698448_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 800000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 11,
        "bathrooms": 4,
        "address": "Two Properties on Furness Road, Manchester",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -2.219481,
            "lat": 53.443905
        },
        "_place": 8.65,
        "_people": 20.15,
        "_property": 4,
        "_price": 91.1,
        "map": property_813
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117466799#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/78k/77234/117466799/77234_VAE210436_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/117466799/77234_VAE210436_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/117466799/77234_VAE210436_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/117466799/77234_VAE210436_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/117466799/77234_VAE210436_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/117466799/77234_VAE210436_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/117466799/77234_VAE210436_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/117466799/77234_VAE210436_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/117466799/77234_VAE210436_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/117466799/77234_VAE210436_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/117466799/77234_VAE210436_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77234/117466799/77234_VAE210436_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 325000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Yr Hwylfa, Caernarfon, Gwynedd, LL55",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.26448,
            "lat": 53.146729
        },
        "_place": 6.69,
        "_people": 28.71,
        "_property": 5,
        "_price": 178.76,
        "map": property_814
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112206032#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44160/112206032/44160_21155628_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 845000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Main Road, Sellindge, Ashford, TN25",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.007542,
            "lat": 51.102273
        },
        "_place": 10.24,
        "_people": 19.89,
        "_property": 2,
        "_price": 157.36,
        "map": property_815
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117008498#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/96k/95626/117008498/95626_2238685_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95626/117008498/95626_2238685_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95626/117008498/95626_2238685_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95626/117008498/95626_2238685_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95626/117008498/95626_2238685_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95626/117008498/95626_2238685_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95626/117008498/95626_2238685_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95626/117008498/95626_2238685_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95626/117008498/95626_2238685_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95626/117008498/95626_2238685_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95626/117008498/95626_2238685_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95626/117008498/95626_2238685_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95626/117008498/95626_2238685_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95626/117008498/95626_2238685_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95626/117008498/95626_2238685_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 1025000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Trewince Road, Raynes Park",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.22876,
            "lat": 51.41158
        },
        "_place": 7.4,
        "_people": 33.95,
        "_property": 3,
        "_price": 153.02,
        "map": property_816
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114834542#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/71k/70974/114834542/70974_MCT1000232_IMG_21_0001.jpeg"
        ],
        "price": [
            {
                "value": 220000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Kildalton, Dalintober, Campbeltown",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.600538,
            "lat": 55.429647
        },
        "_place": 0.74,
        "_people": 22.16,
        "_property": 1,
        "_price": 108.8,
        "map": property_817
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114789785#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/49k/48985/114789785/48985_LAD211886_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48985/114789785/48985_LAD211886_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48985/114789785/48985_LAD211886_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48985/114789785/48985_LAD211886_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48985/114789785/48985_LAD211886_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48985/114789785/48985_LAD211886_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48985/114789785/48985_LAD211886_IMG_06_0001.jpeg"
        ],
        "price": [
            {
                "value": 1090000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Westmont Club Residences, White City Living, 54 Wood Lane, London, W12",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.223505,
            "lat": 51.510745
        },
        "_place": 11.05,
        "_people": 31.58,
        "_property": 1,
        "_price": 92.19,
        "map": property_818
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117365654#",
        "title": "9 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/99k/98645/117365654/98645_11231205_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 465000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 9,
        "bathrooms": 4,
        "address": "Church Street, Bridgwater",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -2.998117,
            "lat": 51.130924
        },
        "_place": 2.34,
        "_people": 23.56,
        "_property": 3,
        "_price": 137.2,
        "map": property_819
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111303425#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/3k/2096/111303425/2096_RTF111909_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2096/111303425/2096_RTF111909_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2096/111303425/2096_RTF111909_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2096/111303425/2096_RTF111909_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2096/111303425/2096_RTF111909_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2096/111303425/2096_RTF111909_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2096/111303425/2096_RTF111909_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2096/111303425/2096_RTF111909_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2096/111303425/2096_RTF111909_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2096/111303425/2096_RTF111909_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2096/111303425/2096_RTF111909_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2096/111303425/2096_RTF111909_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2096/111303425/2096_RTF111909_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2096/111303425/2096_RTF111909_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2096/111303425/2096_RTF111909_IMG_14_0001.jpeg"
        ],
        "price": [
            {
                "value": 229000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Park View, Thorpe Hesley, Rotherham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.43865,
            "lat": 53.45916
        },
        "_place": 0.09,
        "_people": 28.22,
        "_property": 3,
        "_price": 108.15,
        "map": property_820
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117194651#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/3k/2104/117194651/2104_CRK104815_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2104/117194651/2104_CRK104815_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2104/117194651/2104_CRK104815_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2104/117194651/2104_CRK104815_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2104/117194651/2104_CRK104815_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2104/117194651/2104_CRK104815_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2104/117194651/2104_CRK104815_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2104/117194651/2104_CRK104815_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2104/117194651/2104_CRK104815_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2104/117194651/2104_CRK104815_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2104/117194651/2104_CRK104815_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2104/117194651/2104_CRK104815_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2104/117194651/2104_CRK104815_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2104/117194651/2104_CRK104815_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2104/117194651/2104_CRK104815_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Packers Way, Misterton, Crewkerne",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.76736,
            "lat": 50.8706
        },
        "_place": 10.71,
        "_people": 19.92,
        "_property": 4,
        "_price": 153.93,
        "map": property_821
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117441350#",
        "title": "4 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/30k/29376/117441350/29376_HSS193093_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/117441350/29376_HSS193093_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/117441350/29376_HSS193093_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/117441350/29376_HSS193093_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/117441350/29376_HSS193093_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/117441350/29376_HSS193093_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/117441350/29376_HSS193093_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/117441350/29376_HSS193093_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/117441350/29376_HSS193093_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/117441350/29376_HSS193093_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/117441350/29376_HSS193093_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/117441350/29376_HSS193093_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/117441350/29376_HSS193093_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "De Havilland Walk, Chatham, ME4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.51065,
            "lat": 51.356691
        },
        "_place": 8.59,
        "_people": 20.46,
        "_property": 4,
        "_price": 185.22,
        "map": property_822
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115548143#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/96k/95011/115548143/95011_11195579_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95011/115548143/95011_11195579_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95011/115548143/95011_11195579_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95011/115548143/95011_11195579_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95011/115548143/95011_11195579_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95011/115548143/95011_11195579_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95011/115548143/95011_11195579_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95011/115548143/95011_11195579_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95011/115548143/95011_11195579_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 120000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Dartmouth Drive, Bootle",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.988477,
            "lat": 53.48815
        },
        "_place": 9.75,
        "_people": 32.54,
        "_property": 2,
        "_price": 143.21,
        "map": property_823
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111540668#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/236k/235448/111540668/235448_30863551_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235448/111540668/235448_30863551_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235448/111540668/235448_30863551_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235448/111540668/235448_30863551_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235448/111540668/235448_30863551_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235448/111540668/235448_30863551_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235448/111540668/235448_30863551_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235448/111540668/235448_30863551_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235448/111540668/235448_30863551_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235448/111540668/235448_30863551_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235448/111540668/235448_30863551_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/236k/235448/111540668/235448_30863551_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 1200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Compayne Gardens, London",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.184347,
            "lat": 51.545253
        },
        "_place": 2.91,
        "_people": 28.3,
        "_property": 4,
        "_price": 124.17,
        "map": property_824
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116127539#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/4k/3282/116127539/3282_100126010611_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 1150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Oxted, Surrey.",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.0095,
            "lat": 51.25167
        },
        "_place": 7.79,
        "_people": 34.23,
        "_property": 0,
        "_price": 187.55,
        "map": property_825
    },
    {
        "url": "https://www.rightmove.co.uk/properties/78715644#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/197k/196289/78715644/196289_AMS190446_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196289/78715644/196289_AMS190446_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196289/78715644/196289_AMS190446_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196289/78715644/196289_AMS190446_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196289/78715644/196289_AMS190446_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196289/78715644/196289_AMS190446_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196289/78715644/196289_AMS190446_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196289/78715644/196289_AMS190446_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196289/78715644/196289_AMS190446_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196289/78715644/196289_AMS190446_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 325000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Cherry Orchard, Amersham, Buckinghamshire, HP6",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.594169,
            "lat": 51.679529
        },
        "_place": 8.84,
        "_people": 26.01,
        "_property": 5,
        "_price": 106.02,
        "map": property_826
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110721524#",
        "title": "2 bedroom retirement property",
        "images": [
            "https://media.rightmove.co.uk/29k/28151/110721524/28151_597959_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28151/110721524/28151_597959_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28151/110721524/28151_597959_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28151/110721524/28151_597959_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28151/110721524/28151_597959_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28151/110721524/28151_597959_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28151/110721524/28151_597959_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28151/110721524/28151_597959_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28151/110721524/28151_597959_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28151/110721524/28151_597959_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28151/110721524/28151_597959_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28151/110721524/28151_597959_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Church Road, Bembridge, Isle of Wight, PO35 5AA",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.090378,
            "lat": 50.690868
        },
        "_place": 2.22,
        "_people": 29.78,
        "_property": 1,
        "_price": 181.74,
        "map": property_827
    },
    {
        "url": "https://www.rightmove.co.uk/properties/79776924#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/196k/195482/79776924/195482_NAR150024_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 300000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Panteg Uchaf, Narberth, Pembrokeshire, SA67",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.73,
            "lat": 51.8
        },
        "_place": 8.82,
        "_people": 32.22,
        "_property": 5,
        "_price": 199.55,
        "map": property_828
    },
    {
        "url": "https://www.rightmove.co.uk/properties/83022738#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/80k/79497/83022738/79497_122-20229-037_3_55_101368_IMG_12_0000.jpg"
        ],
        "price": [
            {
                "value": 284995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Greenhurst Road,\r\nAshton-Under-Lyne,\r\nOL6 8BQ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.07776,
            "lat": 53.50513
        },
        "_place": 5.8,
        "_people": 25.69,
        "_property": 5,
        "_price": 98.05,
        "map": property_829
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116571494#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/91k/90146/116571494/90146_MHI210080_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/91k/90146/116571494/90146_MHI210080_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/91k/90146/116571494/90146_MHI210080_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/91k/90146/116571494/90146_MHI210080_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/91k/90146/116571494/90146_MHI210080_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/91k/90146/116571494/90146_MHI210080_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/91k/90146/116571494/90146_MHI210080_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/91k/90146/116571494/90146_MHI210080_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/91k/90146/116571494/90146_MHI210080_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/91k/90146/116571494/90146_MHI210080_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/91k/90146/116571494/90146_MHI210080_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/91k/90146/116571494/90146_MHI210080_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/91k/90146/116571494/90146_MHI210080_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/91k/90146/116571494/90146_MHI210080_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 585000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Durham Road, London, N2",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.160964,
            "lat": 51.593226
        },
        "_place": 10.58,
        "_people": 23.1,
        "_property": 5,
        "_price": 186.28,
        "map": property_830
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109291352#",
        "title": "3 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/109291352/77231_LLA200015_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Llanbedrgoch, Anglesey, LL76",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.258121,
            "lat": 53.298454
        },
        "_place": 4.07,
        "_people": 25.09,
        "_property": 1,
        "_price": 189.85,
        "map": property_831
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115509806#",
        "title": "3 bedroom semi-detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/125k/124699/115509806/124699_BUNAGLOW9_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124699/115509806/124699_BUNAGLOW9_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/125k/124699/115509806/124699_BUNAGLOW9_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/125k/124699/115509806/124699_BUNAGLOW9_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124699/115509806/124699_BUNAGLOW9_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/125k/124699/115509806/124699_BUNAGLOW9_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124699/115509806/124699_BUNAGLOW9_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124699/115509806/124699_BUNAGLOW9_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124699/115509806/124699_BUNAGLOW9_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124699/115509806/124699_BUNAGLOW9_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/125k/124699/115509806/124699_BUNAGLOW9_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Heyford Park,\r\nCamp Road,\r\nUpper Heyford,\r\nOX25 5HD",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.26216,
            "lat": 51.93874
        },
        "_place": 11.62,
        "_people": 19.98,
        "_property": 5,
        "_price": 175.71,
        "map": property_832
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110375906#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/3k/2220/110375906/2220_11337130_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2220/110375906/2220_11337130_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2220/110375906/2220_11337130_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2220/110375906/2220_11337130_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2220/110375906/2220_11337130_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2220/110375906/2220_11337130_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2220/110375906/2220_11337130_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2220/110375906/2220_11337130_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2220/110375906/2220_11337130_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2220/110375906/2220_11337130_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2220/110375906/2220_11337130_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2220/110375906/2220_11337130_IMG_17_0001.jpeg"
        ],
        "price": [
            {
                "value": 595000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Hempstead Road, The Three Oaks, Hempstead, Gillingham, Kent",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.56988,
            "lat": 51.3465
        },
        "_place": 0.77,
        "_people": 30.87,
        "_property": 1,
        "_price": 175.42,
        "map": property_833
    },
    {
        "url": "https://www.rightmove.co.uk/properties/62101077#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/108k/107816/62101077/107816_9616211_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/108k/107816/62101077/107816_9616211_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/108k/107816/62101077/107816_9616211_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/108k/107816/62101077/107816_9616211_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/108k/107816/62101077/107816_9616211_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/108k/107816/62101077/107816_9616211_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/108k/107816/62101077/107816_9616211_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/108k/107816/62101077/107816_9616211_IMG_02_0000.jpg"
        ],
        "price": [
            {
                "value": 109950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Meadowdale Crescent, Bedlington",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.614167,
            "lat": 55.131765
        },
        "_place": 11.53,
        "_people": 28.49,
        "_property": 1,
        "_price": 176.09,
        "map": property_834
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116528159#",
        "title": "Detached house",
        "images": [
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/3k/2457/116528159/2457_LBD210211_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 1000000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Leighton Road, Stoke Hammond",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.718747,
            "lat": 51.953104
        },
        "_place": 4.39,
        "_people": 26.05,
        "_property": 0,
        "_price": 107.42,
        "map": property_835
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117742571#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/204k/203033/117742571/203033_1280471-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/117742571/203033_1280471-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/117742571/203033_1280471-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/117742571/203033_1280471-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/117742571/203033_1280471-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/117742571/203033_1280471-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/117742571/203033_1280471-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/117742571/203033_1280471-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/117742571/203033_1280471-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/117742571/203033_1280471-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/117742571/203033_1280471-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/117742571/203033_1280471-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/117742571/203033_1280471-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/117742571/203033_1280471-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203033/117742571/203033_1280471-1_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 280000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 3,
        "address": "St. Peters Street, Leeds, LS9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.534527,
            "lat": 53.797584
        },
        "_place": 8.01,
        "_people": 29.65,
        "_property": 0,
        "_price": 96.52,
        "map": property_836
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114879197#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1981/114879197/1981_BOS110439_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Cooden Drive, Bexhill-On-Sea",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.43052,
            "lat": 50.83324
        },
        "_place": 0.05,
        "_people": 33.86,
        "_property": 5,
        "_price": 133.28,
        "map": property_837
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110865992#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/45k/44312/110865992/44312_EXE210093_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44312/110865992/44312_EXE210093_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44312/110865992/44312_EXE210093_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/45k/44312/110865992/44312_EXE210093_IMG_03_0001.jpeg"
        ],
        "price": [
            {
                "value": 175000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "North Huish, Devon",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.823527,
            "lat": 50.403525
        },
        "_place": 5.52,
        "_people": 32.83,
        "_property": 4,
        "_price": 188.84,
        "map": property_838
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111888071#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6069/111888071/6069_100127026723_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 310000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Rakesmoor Lane, Barrow-in-Furness",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.22006,
            "lat": 54.13909
        },
        "_place": 9.67,
        "_people": 26.45,
        "_property": 2,
        "_price": 150.57,
        "map": property_839
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116029307#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/220k/219329/116029307/219329_11186901_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Hunslet Road, Burntwood",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.911976,
            "lat": 52.68417
        },
        "_place": 2.54,
        "_people": 23.51,
        "_property": 2,
        "_price": 170.1,
        "map": property_840
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113854058#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/155k/154001/113854058/154001_100523008473_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/113854058/154001_100523008473_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/113854058/154001_100523008473_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/113854058/154001_100523008473_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/113854058/154001_100523008473_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/113854058/154001_100523008473_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/113854058/154001_100523008473_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/113854058/154001_100523008473_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/113854058/154001_100523008473_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/155k/154001/113854058/154001_100523008473_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 385000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Sandown, Isle of Wight",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.15931,
            "lat": 50.65141
        },
        "_place": 9.5,
        "_people": 18.45,
        "_property": 3,
        "_price": 169.5,
        "map": property_841
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116991632#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/22k/21485/116991632/21485_WDB150658_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 250000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Thomas Lane, Liverpool, Merseyside, L14",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.892008,
            "lat": 53.412855
        },
        "_place": 1.41,
        "_people": 30.25,
        "_property": 2,
        "_price": 176.48,
        "map": property_842
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111698414#",
        "title": "5 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/69k/68149/111698414/68149_30870038_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68149/111698414/68149_30870038_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68149/111698414/68149_30870038_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68149/111698414/68149_30870038_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68149/111698414/68149_30870038_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68149/111698414/68149_30870038_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68149/111698414/68149_30870038_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68149/111698414/68149_30870038_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68149/111698414/68149_30870038_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68149/111698414/68149_30870038_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68149/111698414/68149_30870038_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/69k/68149/111698414/68149_30870038_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 300000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": null,
        "address": "Linden Terrace, Whitley Bay",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.442637,
            "lat": 55.042373
        },
        "_place": 11.84,
        "_people": 19.8,
        "_property": 2,
        "_price": 176.51,
        "map": property_843
    },
    {
        "url": "https://www.rightmove.co.uk/properties/79178352#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/46k/45920/79178352/45920_11550_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/46k/45920/79178352/45920_11550_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/46k/45920/79178352/45920_11550_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/46k/45920/79178352/45920_11550_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/46k/45920/79178352/45920_11550_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/46k/45920/79178352/45920_11550_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/46k/45920/79178352/45920_11550_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/46k/45920/79178352/45920_11550_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/46k/45920/79178352/45920_11550_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 1200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Artillery Mansions, Victoria Street, Westminster, London, SW1H",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.13453,
            "lat": 51.496816
        },
        "_place": 8.7,
        "_people": 33.63,
        "_property": 1,
        "_price": 134.83,
        "map": property_844
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112464971#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/30k/29966/112464971/29966_BBR210353_IMG_00_0002.jpeg",
            "https://media.rightmove.co.uk/30k/29966/112464971/29966_BBR210353_IMG_01_0002.jpeg",
            "https://media.rightmove.co.uk/30k/29966/112464971/29966_BBR210353_IMG_02_0002.jpeg",
            "https://media.rightmove.co.uk/30k/29966/112464971/29966_BBR210353_IMG_03_0002.jpeg",
            "https://media.rightmove.co.uk/30k/29966/112464971/29966_BBR210353_IMG_04_0002.jpeg",
            "https://media.rightmove.co.uk/30k/29966/112464971/29966_BBR210353_IMG_05_0002.jpeg",
            "https://media.rightmove.co.uk/30k/29966/112464971/29966_BBR210353_IMG_06_0002.jpeg",
            "https://media.rightmove.co.uk/30k/29966/112464971/29966_BBR210353_IMG_07_0002.jpeg",
            "https://media.rightmove.co.uk/30k/29966/112464971/29966_BBR210353_IMG_08_0002.jpeg",
            "https://media.rightmove.co.uk/30k/29966/112464971/29966_BBR210353_IMG_09_0002.jpeg",
            "https://media.rightmove.co.uk/30k/29966/112464971/29966_BBR210353_IMG_10_0002.jpeg",
            "https://media.rightmove.co.uk/30k/29966/112464971/29966_BBR210353_IMG_11_0002.jpeg",
            "https://media.rightmove.co.uk/30k/29966/112464971/29966_BBR210353_IMG_12_0001.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Gregson Lane, Hoghton, Preston, PR5",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.63664,
            "lat": 53.737764
        },
        "_place": 10.13,
        "_people": 22.6,
        "_property": 3,
        "_price": 142.39,
        "map": property_845
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110877692#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29126/110877692/29126_11065714_IMG_02_0000.jpeg"
        ],
        "price": [
            {
                "value": 540000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Charing Hill, Charing",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.810401,
            "lat": 51.217327
        },
        "_place": 2.92,
        "_people": 26.78,
        "_property": 1,
        "_price": 155.04,
        "map": property_846
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114480500#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/114480500/77902_11128770_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "The White House, Old Hall Lane, Whitefield, Manchester",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.315463,
            "lat": 53.543751
        },
        "_place": 10.32,
        "_people": 28.88,
        "_property": 4,
        "_price": 113.74,
        "map": property_847
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116901371#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239834/116901371/239834_11222826_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 245000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Barnsley Road, Pontefract",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.318509,
            "lat": 53.594433
        },
        "_place": 0.67,
        "_people": 19.21,
        "_property": 1,
        "_price": 122.32,
        "map": property_848
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109864136#",
        "title": "12 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30038/109864136/30038_11032207_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 895000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 12,
        "bathrooms": 8,
        "address": "Nettleham Road,Uphill, Lincoln",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.520481,
            "lat": 53.248581
        },
        "_place": 8.05,
        "_people": 24.42,
        "_property": 2,
        "_price": 181.81,
        "map": property_849
    },
    {
        "url": "https://www.rightmove.co.uk/properties/83002668#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/11k/10550/83002668/10550_100288005721_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 650000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Mannamead",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.13112,
            "lat": 50.3911
        },
        "_place": 0.9,
        "_people": 28.13,
        "_property": 0,
        "_price": 194.51,
        "map": property_850
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116348141#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17924/116348141/17924_11230935_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 395000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "St. Sulien, Luxulyan",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.743841,
            "lat": 50.389595
        },
        "_place": 7.52,
        "_people": 28.36,
        "_property": 3,
        "_price": 108.69,
        "map": property_851
    },
    {
        "url": "https://www.rightmove.co.uk/properties/72311631#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/37k/36563/72311631/36563_29876428_IMG_16_0000.jpg"
        ],
        "price": [
            {
                "value": 340000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Top Road, Summerhill, Wrexham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.030592,
            "lat": 53.075071
        },
        "_place": 10.56,
        "_people": 28.87,
        "_property": 4,
        "_price": 188,
        "map": property_852
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117068156#",
        "title": "2 bedroom semi-detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/9k/8504/117068156/8504_529036972_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8504/117068156/8504_529036972_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8504/117068156/8504_529036972_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8504/117068156/8504_529036972_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8504/117068156/8504_529036972_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8504/117068156/8504_529036972_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8504/117068156/8504_529036972_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8504/117068156/8504_529036972_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8504/117068156/8504_529036972_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8504/117068156/8504_529036972_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8504/117068156/8504_529036972_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8504/117068156/8504_529036972_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/9k/8504/117068156/8504_529036972_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 260000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Heather Bank, Osbaldwick, York, YO10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.036857,
            "lat": 53.954834
        },
        "_place": 7.15,
        "_people": 25.91,
        "_property": 4,
        "_price": 121.77,
        "map": property_853
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113261690#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/1k/42/113261690/42_WLC210599_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/1k/42/113261690/42_WLC210599_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/1k/42/113261690/42_WLC210599_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/1k/42/113261690/42_WLC210599_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/1k/42/113261690/42_WLC210599_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/1k/42/113261690/42_WLC210599_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/1k/42/113261690/42_WLC210599_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/1k/42/113261690/42_WLC210599_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/1k/42/113261690/42_WLC210599_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/1k/42/113261690/42_WLC210599_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/1k/42/113261690/42_WLC210599_IMG_10_0001.jpeg"
        ],
        "price": [
            {
                "value": 210000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Gipsy Lane, Willenhall, West Midlands, WV13",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.04807,
            "lat": 52.582629
        },
        "_place": 6.85,
        "_people": 18.45,
        "_property": 1,
        "_price": 114.1,
        "map": property_854
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115455578#",
        "title": "3 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4076/115455578/4076_BOR210381_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 399950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Archibald Walk, Boston, PE21",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.053083,
            "lat": 52.975324
        },
        "_place": 3.21,
        "_people": 19.32,
        "_property": 4,
        "_price": 111.75,
        "map": property_855
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117673142#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111223/117673142/111223_11222519_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 449950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "St Cleers Orchard, Somerton ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.741243,
            "lat": 51.04959
        },
        "_place": 6.22,
        "_people": 32.8,
        "_property": 0,
        "_price": 156.02,
        "map": property_856
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110332505#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/44k/43052/110332505/43052_CSD213094_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43052/110332505/43052_CSD213094_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/44k/43052/110332505/43052_CSD213094_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43052/110332505/43052_CSD213094_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43052/110332505/43052_CSD213094_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43052/110332505/43052_CSD213094_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43052/110332505/43052_CSD213094_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43052/110332505/43052_CSD213094_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43052/110332505/43052_CSD213094_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43052/110332505/43052_CSD213094_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43052/110332505/43052_CSD213094_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43052/110332505/43052_CSD213094_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43052/110332505/43052_CSD213094_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Whitewater Court, 20 Station Road, Plymouth, Devon",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.054796,
            "lat": 50.390275
        },
        "_place": 2.09,
        "_people": 31.67,
        "_property": 5,
        "_price": 162.23,
        "map": property_857
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116001320#",
        "title": "7 bedroom link detached house",
        "images": [
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/14k/13516/116001320/13516_100933005047_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 250000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": 3,
        "address": "Chapel Street, Gunnislake",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.21538,
            "lat": 50.52354
        },
        "_place": 4.49,
        "_people": 18.69,
        "_property": 1,
        "_price": 192.47,
        "map": property_858
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110437703#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/47k/46466/110437703/46466_6491_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46466/110437703/46466_6491_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46466/110437703/46466_6491_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46466/110437703/46466_6491_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46466/110437703/46466_6491_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46466/110437703/46466_6491_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/47k/46466/110437703/46466_6491_IMG_05_0000.jpeg"
        ],
        "price": [
            {
                "value": 365000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Vincent Mews, Guildford Road, Great Bookham",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.364686,
            "lat": 51.277988
        },
        "_place": 4.67,
        "_people": 28.58,
        "_property": 1,
        "_price": 188.15,
        "map": property_859
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117693386#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/20k/19299/117693386/19299_31166763_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/117693386/19299_31166763_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/117693386/19299_31166763_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/117693386/19299_31166763_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/117693386/19299_31166763_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19299/117693386/19299_31166763_IMG_05_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Heol Llanelli, Pontyates, Llanelli",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.203634,
            "lat": 51.750305
        },
        "_place": 8.39,
        "_people": 31.59,
        "_property": 4,
        "_price": 198.24,
        "map": property_860
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117235502#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14369/117235502/14369_31146118_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "The Oasis, Glenfield, Leicester",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.209736,
            "lat": 52.645915
        },
        "_place": 4.15,
        "_people": 23.66,
        "_property": 4,
        "_price": 166.45,
        "map": property_861
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116572700#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48113/116572700/48113_0298_HRT029808998_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Redden Court Road, Romford",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.230238,
            "lat": 51.584845
        },
        "_place": 10.82,
        "_people": 20.34,
        "_property": 5,
        "_price": 181.02,
        "map": property_862
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113931425#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/49k/48722/113931425/48722_YOS170347_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48722/113931425/48722_YOS170347_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48722/113931425/48722_YOS170347_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48722/113931425/48722_YOS170347_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48722/113931425/48722_YOS170347_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48722/113931425/48722_YOS170347_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48722/113931425/48722_YOS170347_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48722/113931425/48722_YOS170347_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48722/113931425/48722_YOS170347_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48722/113931425/48722_YOS170347_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48722/113931425/48722_YOS170347_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48722/113931425/48722_YOS170347_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48722/113931425/48722_YOS170347_IMG_12_0001.jpeg"
        ],
        "price": [
            {
                "value": 499950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Main Street, Riccall, York, YO19",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.057919,
            "lat": 53.834794
        },
        "_place": 11.73,
        "_people": 33.44,
        "_property": 4,
        "_price": 136.88,
        "map": property_863
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116760134#",
        "title": "6 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_21_0001.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_22_0001.jpeg",
            "https://media.rightmove.co.uk/29k/28665/116760134/28665_BTH012189195_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 950000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": null,
        "address": "Pickwick, Corsham, Wiltshire, SN13",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.19617,
            "lat": 51.43514
        },
        "_place": 6.36,
        "_people": 34.17,
        "_property": 3,
        "_price": 190.33,
        "map": property_864
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117319202#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/6k/5293/117319202/5293_31151245_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": null,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Brighton Road, Handcross",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.200675,
            "lat": 51.051326
        },
        "_place": 5.83,
        "_people": 29.99,
        "_property": 0,
        "_price": 106.48,
        "map": property_865
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117233894#",
        "title": "3 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/184k/183563/117233894/183563_ILI210293_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/117233894/183563_ILI210293_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/117233894/183563_ILI210293_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/117233894/183563_ILI210293_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/117233894/183563_ILI210293_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/117233894/183563_ILI210293_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/117233894/183563_ILI210293_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/117233894/183563_ILI210293_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/117233894/183563_ILI210293_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/117233894/183563_ILI210293_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/117233894/183563_ILI210293_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/117233894/183563_ILI210293_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/117233894/183563_ILI210293_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/117233894/183563_ILI210293_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/184k/183563/117233894/183563_ILI210293_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Broadway, Merriott",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.799134,
            "lat": 50.908764
        },
        "_place": 2.4,
        "_people": 30.92,
        "_property": 0,
        "_price": 163.58,
        "map": property_866
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116011178#",
        "title": "7 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/216k/215192/116011178/215192_4927947_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 1000000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": 4,
        "address": "London Road, Langley, Berkshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.550837,
            "lat": 51.493788
        },
        "_place": 10.62,
        "_people": 28.85,
        "_property": 2,
        "_price": 179.64,
        "map": property_867
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108641408#",
        "title": "1 bedroom chalet",
        "images": [
            "https://media.rightmove.co.uk/92k/91289/108641408/91289_30723461_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/108641408/91289_30723461_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/108641408/91289_30723461_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/108641408/91289_30723461_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/108641408/91289_30723461_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/108641408/91289_30723461_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/108641408/91289_30723461_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/108641408/91289_30723461_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/108641408/91289_30723461_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/108641408/91289_30723461_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91289/108641408/91289_30723461_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 315000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Anglesey Lakeside Lodges, Llandegfan, Anglesey",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.155463,
            "lat": 53.248886
        },
        "_place": 9.02,
        "_people": 26.98,
        "_property": 3,
        "_price": 183.05,
        "map": property_868
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116132135#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/79k/78149/116132135/78149_INE210115_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Broallan, Beauly, IV4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.52341,
            "lat": 57.475952
        },
        "_place": 3.58,
        "_people": 23.4,
        "_property": 0,
        "_price": 158.76,
        "map": property_869
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82664853#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/176k/175322/82664853/175322_bay99rose_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 361995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Roseberry Manor\r\nGreat West Gardens\r\nNunthorpe\r\nMiddlesbrough\r\nTS7 0GS",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.16561,
            "lat": 54.53362
        },
        "_place": 10.1,
        "_people": 20.26,
        "_property": 5,
        "_price": 106.45,
        "map": property_870
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114833003#",
        "title": "3 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77046/114833003/77046_260929_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 340000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Achavandra Muir, Dornoch",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.071918,
            "lat": 57.919178
        },
        "_place": 10.4,
        "_people": 33.79,
        "_property": 1,
        "_price": 144.53,
        "map": property_871
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116919305#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12195/116919305/12195_31129676_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 485000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Great Linch, Middleton, Milton Keynes, Bucks",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.69669,
            "lat": 52.04048
        },
        "_place": 4.25,
        "_people": 20.27,
        "_property": 5,
        "_price": 114.66,
        "map": property_872
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117326813#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/105k/104930/117326813/104930_MBS210115_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104930/117326813/104930_MBS210115_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104930/117326813/104930_MBS210115_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104930/117326813/104930_MBS210115_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104930/117326813/104930_MBS210115_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104930/117326813/104930_MBS210115_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104930/117326813/104930_MBS210115_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104930/117326813/104930_MBS210115_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104930/117326813/104930_MBS210115_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/105k/104930/117326813/104930_MBS210115_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 825000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Carlton House, 127-129 Cleveland Street, Fitzrovia, London, W1T",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.14152,
            "lat": 51.522574
        },
        "_place": 11.62,
        "_people": 26.3,
        "_property": 2,
        "_price": 160.25,
        "map": property_873
    },
    {
        "url": "https://www.rightmove.co.uk/properties/80404557#",
        "title": "1 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/6k/5102/80404557/5102_KWY103258_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5102/80404557/5102_KWY103258_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5102/80404557/5102_KWY103258_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5102/80404557/5102_KWY103258_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5102/80404557/5102_KWY103258_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5102/80404557/5102_KWY103258_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5102/80404557/5102_KWY103258_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5102/80404557/5102_KWY103258_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5102/80404557/5102_KWY103258_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5102/80404557/5102_KWY103258_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5102/80404557/5102_KWY103258_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5102/80404557/5102_KWY103258_IMG_03_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Newport Court, London",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.12888,
            "lat": 51.51218
        },
        "_place": 4.85,
        "_people": 20.81,
        "_property": 4,
        "_price": 93.08,
        "map": property_874
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114125828#",
        "title": "6 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/86k/85928/114125828/85928_100505002912_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 975000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 4,
        "address": "Camberley, Surrey",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.71088,
            "lat": 51.32561
        },
        "_place": 5.61,
        "_people": 19.9,
        "_property": 5,
        "_price": 154.83,
        "map": property_875
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108567899#",
        "title": "3 bedroom country house",
        "images": [
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74403/108567899/74403_30720082_IMG_27_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Plas y Pinc, Pwllderi, Trefasser, Goodwick",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.072202,
            "lat": 52.006996
        },
        "_place": 0.06,
        "_people": 35.76,
        "_property": 0,
        "_price": 131.86,
        "map": property_876
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117310898#",
        "title": "2 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/5k/4931/117310898/4931_KIN210249_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4931/117310898/4931_KIN210249_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4931/117310898/4931_KIN210249_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4931/117310898/4931_KIN210249_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4931/117310898/4931_KIN210249_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4931/117310898/4931_KIN210249_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4931/117310898/4931_KIN210249_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4931/117310898/4931_KIN210249_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4931/117310898/4931_KIN210249_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4931/117310898/4931_KIN210249_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4931/117310898/4931_KIN210249_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4931/117310898/4931_KIN210249_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4931/117310898/4931_KIN210249_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4931/117310898/4931_KIN210249_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4931/117310898/4931_KIN210249_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 280000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Arundel Drive, King's Lynn, Norfolk, PE30",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.430754,
            "lat": 52.772985
        },
        "_place": 7.72,
        "_people": 33.31,
        "_property": 0,
        "_price": 139.48,
        "map": property_877
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116156729#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/7k/6904/116156729/6904_FHH210229_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6904/116156729/6904_FHH210229_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6904/116156729/6904_FHH210229_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6904/116156729/6904_FHH210229_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6904/116156729/6904_FHH210229_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6904/116156729/6904_FHH210229_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6904/116156729/6904_FHH210229_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6904/116156729/6904_FHH210229_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6904/116156729/6904_FHH210229_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/7k/6904/116156729/6904_FHH210229_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 545000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Plot 3, Wye Mews, Wooburn Green, HP10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.684414,
            "lat": 51.593676
        },
        "_place": 10.91,
        "_people": 24.6,
        "_property": 2,
        "_price": 123.61,
        "map": property_878
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117692921#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103916/117692921/103916_2604_LONG_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 299950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Shepherds Fold, Swaffham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.697776,
            "lat": 52.636655
        },
        "_place": 0.31,
        "_people": 21.79,
        "_property": 2,
        "_price": 127.62,
        "map": property_879
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115721675#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/242k/241499/115721675/241499_RX127158_IMG_30_0000.jpeg"
        ],
        "price": [
            {
                "value": 265000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Viewland Close, Barnsley, S72",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.410726,
            "lat": 53.569794
        },
        "_place": 11.71,
        "_people": 26.57,
        "_property": 1,
        "_price": 161.79,
        "map": property_880
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114739007#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/2k/1678/114739007/1678_ADV307323_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1678/114739007/1678_ADV307323_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1678/114739007/1678_ADV307323_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1678/114739007/1678_ADV307323_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1678/114739007/1678_ADV307323_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1678/114739007/1678_ADV307323_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1678/114739007/1678_ADV307323_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1678/114739007/1678_ADV307323_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1678/114739007/1678_ADV307323_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1678/114739007/1678_ADV307323_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1678/114739007/1678_ADV307323_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1678/114739007/1678_ADV307323_IMG_03_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Bishops Way, Andover",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.48931,
            "lat": 51.21011
        },
        "_place": 0.16,
        "_people": 26.85,
        "_property": 1,
        "_price": 126.37,
        "map": property_881
    },
    {
        "url": "https://www.rightmove.co.uk/properties/90113458#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/111k/110342/90113458/110342_BVSAL99367950_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110342/90113458/110342_BVSAL99367950_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110342/90113458/110342_BVSAL99367950_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110342/90113458/110342_BVSAL99367950_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110342/90113458/110342_BVSAL99367950_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/111k/110342/90113458/110342_BVSAL99367950_IMG_02_0000.jpeg"
        ],
        "price": [
            {
                "value": 650000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Farrow Drive, Walkington, East Yorkshire, HU17 8RX",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.48317,
            "lat": 53.82121
        },
        "_place": 3.31,
        "_people": 31.91,
        "_property": 0,
        "_price": 199.2,
        "map": property_882
    },
    {
        "url": "https://www.rightmove.co.uk/properties/79599549#",
        "title": "6 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_45_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_46_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_47_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_48_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_49_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_50_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_51_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_52_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_53_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_54_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_55_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_56_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_57_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_58_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_59_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_60_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_61_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_62_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_63_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_64_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_65_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_66_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_67_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_68_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_69_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_70_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_71_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_72_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_73_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_74_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_75_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_76_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_77_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_78_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_79_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_80_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_81_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_82_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_83_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_84_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_85_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_86_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_87_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_88_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_89_0000.jpeg",
            "https://media.rightmove.co.uk/132k/131035/79599549/131035_30564108_IMG_90_0000.jpeg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 5,
        "address": "Lansdowne Road, Atherton, Manchester",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.470025,
            "lat": 53.532154
        },
        "_place": 0.41,
        "_people": 33.22,
        "_property": 0,
        "_price": 107.35,
        "map": property_883
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116598911#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/222k/221036/116598911/221036_22143910_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 290000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Railway Walk, Royston, Barnsley, S71",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.473107,
            "lat": 53.599226
        },
        "_place": 0.78,
        "_people": 28.99,
        "_property": 4,
        "_price": 198.64,
        "map": property_884
    },
    {
        "url": "https://www.rightmove.co.uk/properties/77445504#",
        "title": "3 bedroom lodge",
        "images": [
            "https://media.rightmove.co.uk/197k/196718/77445504/196718_GH-Alderney_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196718/77445504/196718_GH-Alderney_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196718/77445504/196718_GH-Alderney_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196718/77445504/196718_GH-Alderney_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196718/77445504/196718_GH-Alderney_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196718/77445504/196718_GH-Alderney_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196718/77445504/196718_GH-Alderney_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196718/77445504/196718_GH-Alderney_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196718/77445504/196718_GH-Alderney_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/197k/196718/77445504/196718_GH-Alderney_IMG_08_0000.jpeg"
        ],
        "price": [
            {
                "value": 250000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Great Hadham Road, Bishop's Stortford, Hertfordshire, SG10",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.10521,
            "lat": 51.85623
        },
        "_place": 7.51,
        "_people": 30.24,
        "_property": 4,
        "_price": 127.46,
        "map": property_885
    },
    {
        "url": "https://www.rightmove.co.uk/properties/77401791#",
        "title": "3 bedroom penthouse",
        "images": [
            "https://media.rightmove.co.uk/47k/46965/77401791/46965_48872_IMG_00_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/77401791/46965_48872_IMG_01_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/77401791/46965_48872_IMG_02_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/77401791/46965_48872_IMG_03_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/77401791/46965_48872_IMG_04_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/77401791/46965_48872_IMG_05_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/77401791/46965_48872_IMG_06_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/77401791/46965_48872_IMG_07_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/77401791/46965_48872_IMG_08_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/77401791/46965_48872_IMG_09_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/77401791/46965_48872_IMG_10_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/77401791/46965_48872_IMG_11_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/77401791/46965_48872_IMG_12_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/77401791/46965_48872_IMG_13_0002.jpeg",
            "https://media.rightmove.co.uk/47k/46965/77401791/46965_48872_IMG_14_0002.jpeg"
        ],
        "price": [
            {
                "value": 1200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Tenby Mansions,186 Brent Street, Brent Street, Hendon, NW4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.221447,
            "lat": 51.589541
        },
        "_place": 2.45,
        "_people": 28.34,
        "_property": 1,
        "_price": 135.55,
        "map": property_886
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114191405#",
        "title": "6 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/15k/14330/114191405/14330_21513018_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 3,
        "address": "Abbey Road, Pontrhydfendigaid, SY25",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.863278,
            "lat": 52.279693
        },
        "_place": 0.97,
        "_people": 18.41,
        "_property": 3,
        "_price": 176.83,
        "map": property_887
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114064985#",
        "title": "4 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41258/114064985/41258_YEO210353_IMG_30_0000.jpeg"
        ],
        "price": [
            {
                "value": 950000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Gawbridge Mill, Hawthorn Hill, Kingsbury Episcopi, Martock, Somerset",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -2.794363,
            "lat": 50.973921
        },
        "_place": 4.9,
        "_people": 31.01,
        "_property": 4,
        "_price": 118.61,
        "map": property_888
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112288661#",
        "title": "7 bedroom farm house",
        "images": [
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_49_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_48_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_47_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_46_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_45_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203447/112288661/203447_957472-1_IMG_34_0000.jpeg"
        ],
        "price": [
            {
                "value": 1100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 7,
        "bathrooms": 4,
        "address": "Wheal Hope, Goonhavern, TR4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.110879,
            "lat": 50.349424
        },
        "_place": 0.44,
        "_people": 22.55,
        "_property": 2,
        "_price": 91.66,
        "map": property_889
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115827449#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75383/115827449/75383_11214543_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 440000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Cross Park, Brixham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.522166,
            "lat": 50.38816
        },
        "_place": 10.72,
        "_people": 24.92,
        "_property": 4,
        "_price": 117.59,
        "map": property_890
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107383733#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/1k/943/107383733/943_15710056_IMG_27_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Helleborine, Badgers Dene, Grays, Essex, RM17",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.31325,
            "lat": 51.48019
        },
        "_place": 8.78,
        "_people": 23.03,
        "_property": 4,
        "_price": 145.9,
        "map": property_891
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116011604#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69351/116011604/69351_103252003077_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 199950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Dunkirk Street, Droylsden, Manchester",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.141642,
            "lat": 53.481998
        },
        "_place": 3.03,
        "_people": 21.89,
        "_property": 2,
        "_price": 107.11,
        "map": property_892
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114090407#",
        "title": "1 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/27k/26241/114090407/26241_INV210122_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/114090407/26241_INV210122_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/114090407/26241_INV210122_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/114090407/26241_INV210122_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/114090407/26241_INV210122_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/114090407/26241_INV210122_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/114090407/26241_INV210122_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/114090407/26241_INV210122_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/114090407/26241_INV210122_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/114090407/26241_INV210122_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/114090407/26241_INV210122_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/114090407/26241_INV210122_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/114090407/26241_INV210122_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/114090407/26241_INV210122_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 250000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Fearnmore Church, Fearnmore, Applecross, IV54",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -5.805018,
            "lat": 57.5762
        },
        "_place": 9.45,
        "_people": 34.01,
        "_property": 3,
        "_price": 170.44,
        "map": property_893
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114799127#",
        "title": "Terraced house",
        "images": [
            "https://media.rightmove.co.uk/31k/30429/114799127/30429_ERD210055_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30429/114799127/30429_ERD210055_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30429/114799127/30429_ERD210055_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30429/114799127/30429_ERD210055_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30429/114799127/30429_ERD210055_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 650000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": 2,
        "address": "Ashley Road, Epsom, Surrey, KT18",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.267489,
            "lat": 51.324267
        },
        "_place": 6.59,
        "_people": 29.94,
        "_property": 1,
        "_price": 137.1,
        "map": property_894
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113428112#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34214/113428112/34214_21316907_IMG_31_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Aviemore Drive, Fearnhead, Warrington, WA2",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.552223,
            "lat": 53.416216
        },
        "_place": 11.1,
        "_people": 20.54,
        "_property": 2,
        "_price": 159.27,
        "map": property_895
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116908457#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/124k/123562/116908457/123562_CSM102392_IMG_03_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Nawab Court, Chesham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.61012,
            "lat": 51.71418
        },
        "_place": 11.15,
        "_people": 31.16,
        "_property": 2,
        "_price": 140.51,
        "map": property_896
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116068574#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/175k/174137/116068574/174137_31091686_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 440000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Dry Leys, Orton Longueville, Peterborough",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.274258,
            "lat": 52.556579
        },
        "_place": 1.17,
        "_people": 27.41,
        "_property": 4,
        "_price": 144.52,
        "map": property_897
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115677671#",
        "title": "3 bedroom character property",
        "images": [
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/92k/91994/115677671/91994_31071559_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 700000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Totley Hall",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.540344,
            "lat": 53.312816
        },
        "_place": 10.06,
        "_people": 27.4,
        "_property": 2,
        "_price": 183.14,
        "map": property_898
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117036701#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/211k/210650/117036701/210650_ac8859ce-30c0-47af-b373-c9ed18451fc7-106_4_34_101368_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/211k/210650/117036701/210650_ac8859ce-30c0-47af-b373-c9ed18451fc7-106_4_34_101368_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/211k/210650/117036701/210650_ac8859ce-30c0-47af-b373-c9ed18451fc7-106_4_34_101368_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/211k/210650/117036701/210650_ac8859ce-30c0-47af-b373-c9ed18451fc7-106_4_34_101368_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/211k/210650/117036701/210650_ac8859ce-30c0-47af-b373-c9ed18451fc7-106_4_34_101368_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/211k/210650/117036701/210650_ac8859ce-30c0-47af-b373-c9ed18451fc7-106_4_34_101368_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/211k/210650/117036701/210650_ac8859ce-30c0-47af-b373-c9ed18451fc7-106_4_34_101368_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/211k/210650/117036701/210650_ac8859ce-30c0-47af-b373-c9ed18451fc7-106_4_34_101368_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/211k/210650/117036701/210650_ac8859ce-30c0-47af-b373-c9ed18451fc7-106_4_34_101368_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/211k/210650/117036701/210650_ac8859ce-30c0-47af-b373-c9ed18451fc7-106_4_34_101368_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/211k/210650/117036701/210650_ac8859ce-30c0-47af-b373-c9ed18451fc7-106_4_34_101368_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/211k/210650/117036701/210650_ac8859ce-30c0-47af-b373-c9ed18451fc7-106_4_34_101368_IMG_12_0000.jpg"
        ],
        "price": [
            {
                "value": 459950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Off William Larkin Way,\r\nColton,\r\nLeeds, \r\nLS15 8AD",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.42098,
            "lat": 53.8054
        },
        "_place": 4.65,
        "_people": 23.79,
        "_property": 3,
        "_price": 200.74,
        "map": property_899
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116464787#",
        "title": "4 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/1k/909/116464787/909_100390006092_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/1k/909/116464787/909_100390006092_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/1k/909/116464787/909_100390006092_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/1k/909/116464787/909_100390006092_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/1k/909/116464787/909_100390006092_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/1k/909/116464787/909_100390006092_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/1k/909/116464787/909_100390006092_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/1k/909/116464787/909_100390006092_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/1k/909/116464787/909_100390006092_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/1k/909/116464787/909_100390006092_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/1k/909/116464787/909_100390006092_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 399950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Michaels Mews, Fairford Leys, Aylesbury",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.83986,
            "lat": 51.81513
        },
        "_place": 2.19,
        "_people": 24.61,
        "_property": 4,
        "_price": 151.47,
        "map": property_900
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115585595#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/201k/200294/115585595/200294_31067886_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/115585595/200294_31067886_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/115585595/200294_31067886_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/115585595/200294_31067886_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/115585595/200294_31067886_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/115585595/200294_31067886_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/115585595/200294_31067886_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/201k/200294/115585595/200294_31067886_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 795000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Bishop Norton Road, Glentham, Market Rasen",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.495398,
            "lat": 53.40287
        },
        "_place": 7.64,
        "_people": 24.54,
        "_property": 1,
        "_price": 167.53,
        "map": property_901
    },
    {
        "url": "https://www.rightmove.co.uk/properties/88128853#",
        "title": "3 bedroom town house",
        "images": [
            "https://media.rightmove.co.uk/80k/79370/88128853/79370_30304806_IMG_00_0002.jpeg",
            "https://media.rightmove.co.uk/80k/79370/88128853/79370_30304806_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79370/88128853/79370_30304806_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79370/88128853/79370_30304806_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/80k/79370/88128853/79370_30304806_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79370/88128853/79370_30304806_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79370/88128853/79370_30304806_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79370/88128853/79370_30304806_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79370/88128853/79370_30304806_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79370/88128853/79370_30304806_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79370/88128853/79370_30304806_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79370/88128853/79370_30304806_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79370/88128853/79370_30304806_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79370/88128853/79370_30304806_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/80k/79370/88128853/79370_30304806_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 190000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Archer Street, Wallsend",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.504443,
            "lat": 54.997962
        },
        "_place": 2.27,
        "_people": 18.03,
        "_property": 4,
        "_price": 113.78,
        "map": property_902
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116422037#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_02_0000.png",
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_03_0000.png",
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_04_0000.png",
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/116422037/75717_1262744-1_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 325000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Ashton Hall Drive, Boston, PE21",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.053863,
            "lat": 52.975054
        },
        "_place": 10.89,
        "_people": 25.1,
        "_property": 0,
        "_price": 130.61,
        "map": property_903
    },
    {
        "url": "https://www.rightmove.co.uk/properties/77389095#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_01_0000.JPG",
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_04_0000.JPG",
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_02_0000.JPG",
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_03_0000.JPG",
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_05_0000.JPG",
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_06_0000.JPG",
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_07_0000.JPG",
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_08_0000.JPG",
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_09_0000.JPG",
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_10_0000.JPG",
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_11_0000.JPG",
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_12_0000.JPG",
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_13_0000.JPG",
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_14_0000.JPG",
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_15_0000.JPG",
            "https://media.rightmove.co.uk/56k/55534/77389095/55534_2243099_IMG_16_0000.JPG"
        ],
        "price": [
            {
                "value": 1150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Abbey Road, St. John's Wood",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.17847,
            "lat": 51.53365
        },
        "_place": 9.8,
        "_people": 25.9,
        "_property": 1,
        "_price": 183.87,
        "map": property_904
    },
    {
        "url": "https://www.rightmove.co.uk/properties/77087167#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/167k/166469/77087167/166469_29433569_IMG_17_0000.jpg"
        ],
        "price": [
            {
                "value": 230000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Presthope Grange, Stretton Road, Presthope, Much Wenlock",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.601269,
            "lat": 52.58044
        },
        "_place": 0.73,
        "_people": 27.15,
        "_property": 2,
        "_price": 149.04,
        "map": property_905
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81995919#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/208k/207248/81995919/207248_4_Plot 90 - The Windsor_IMG_21_0000.jpg"
        ],
        "price": [
            {
                "value": 299995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Brand Lane,\r\nStanton Hill,\r\nSutton-In-Ashfield,\r\nNG17",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.27704,
            "lat": 53.13928
        },
        "_place": 7.01,
        "_people": 35.52,
        "_property": 4,
        "_price": 117.07,
        "map": property_906
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107540474#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/76k/75847/107540474/75847_WES200194_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75847/107540474/75847_WES200194_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75847/107540474/75847_WES200194_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75847/107540474/75847_WES200194_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75847/107540474/75847_WES200194_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75847/107540474/75847_WES200194_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75847/107540474/75847_WES200194_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75847/107540474/75847_WES200194_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75847/107540474/75847_WES200194_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75847/107540474/75847_WES200194_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75847/107540474/75847_WES200194_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Shaftesbury Avenue, London, W1D",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.130923,
            "lat": 51.512278
        },
        "_place": 4.91,
        "_people": 33.26,
        "_property": 0,
        "_price": 92.58,
        "map": property_907
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116632295#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/27k/26241/116632295/26241_INV200060_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 310000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Insh Cottage, Spean Bridge, Highland, PH34",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.864491,
            "lat": 56.882781
        },
        "_place": 10.64,
        "_people": 32,
        "_property": 5,
        "_price": 136.47,
        "map": property_908
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114483356#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/23k/22868/114483356/22868_31008956_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22868/114483356/22868_31008956_IMG_01_0000.png"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Cressex, High Wycombe",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.768996,
            "lat": 51.619266
        },
        "_place": 9.03,
        "_people": 23.77,
        "_property": 4,
        "_price": 124.9,
        "map": property_909
    },
    {
        "url": "https://www.rightmove.co.uk/properties/90006880#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/42k/41855/90006880/41855_LUC1001671_IMG_18_0000.jpeg"
        ],
        "price": [
            {
                "value": 270000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Y Fron, Cemaes",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.45398,
            "lat": 53.409666
        },
        "_place": 7.8,
        "_people": 25.29,
        "_property": 2,
        "_price": 189.75,
        "map": property_910
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110856668#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_20_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_21_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_22_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_23_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_24_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_25_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_26_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_27_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_28_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_29_0001.jpeg",
            "https://media.rightmove.co.uk/1k/838/110856668/838_PWL190177_IMG_30_0001.jpeg"
        ],
        "price": [
            {
                "value": 680000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Rhodfa'r Mor, Nefyn, Pwllheli, Gwynedd, LL53",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.524589,
            "lat": 52.937099
        },
        "_place": 2.81,
        "_people": 27.31,
        "_property": 2,
        "_price": 138.85,
        "map": property_911
    },
    {
        "url": "https://www.rightmove.co.uk/properties/97314350#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_00_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/169k/168242/97314350/168242_Cha04_IMG_17_0000.jpg"
        ],
        "price": [
            {
                "value": 389000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Apartment N09-12, Chatham Waters, Chatham Docks, ME4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.55043,
            "lat": 51.39902
        },
        "_place": 6.64,
        "_people": 18.25,
        "_property": 3,
        "_price": 177.53,
        "map": property_912
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109951019#",
        "title": "6 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/202k/201389/109951019/201389_4101437_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/202k/201389/109951019/201389_4101437_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/202k/201389/109951019/201389_4101437_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/202k/201389/109951019/201389_4101437_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/202k/201389/109951019/201389_4101437_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/202k/201389/109951019/201389_4101437_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/202k/201389/109951019/201389_4101437_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/202k/201389/109951019/201389_4101437_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/202k/201389/109951019/201389_4101437_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/202k/201389/109951019/201389_4101437_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/202k/201389/109951019/201389_4101437_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 800000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 2,
        "address": "Staines-Upon-Thames, Surrey, TW18",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.519897,
            "lat": 51.43011
        },
        "_place": 10.79,
        "_people": 29.23,
        "_property": 3,
        "_price": 187.66,
        "map": property_913
    },
    {
        "url": "https://www.rightmove.co.uk/properties/79039854#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/33k/32552/79039854/32552_10827676_IMG_37_0000.jpeg"
        ],
        "price": [
            {
                "value": 575000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Llaneilian, Amlwch",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.322777,
            "lat": 53.40925
        },
        "_place": 10.41,
        "_people": 35.04,
        "_property": 2,
        "_price": 93.49,
        "map": property_914
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114237326#",
        "title": "5 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/170k/169901/114237326/169901_WLL210216_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 800000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Onslow Gardens, Wallington, SM6",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.146952,
            "lat": 51.352697
        },
        "_place": 8.44,
        "_people": 23.41,
        "_property": 0,
        "_price": 181.01,
        "map": property_915
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117366143#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/18k/17616/117366143/17616_SHE210331_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17616/117366143/17616_SHE210331_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17616/117366143/17616_SHE210331_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17616/117366143/17616_SHE210331_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17616/117366143/17616_SHE210331_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17616/117366143/17616_SHE210331_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17616/117366143/17616_SHE210331_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17616/117366143/17616_SHE210331_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17616/117366143/17616_SHE210331_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17616/117366143/17616_SHE210331_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17616/117366143/17616_SHE210331_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17616/117366143/17616_SHE210331_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17616/117366143/17616_SHE210331_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17616/117366143/17616_SHE210331_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17616/117366143/17616_SHE210331_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 250000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Springpool, St. Helens, Merseyside, WA9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.710558,
            "lat": 53.434782
        },
        "_place": 5.97,
        "_people": 22.06,
        "_property": 5,
        "_price": 121.48,
        "map": property_916
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109395284#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/6k/5280/109395284/5280_30754970_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5280/109395284/5280_30754970_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5280/109395284/5280_30754970_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5280/109395284/5280_30754970_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5280/109395284/5280_30754970_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5280/109395284/5280_30754970_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5280/109395284/5280_30754970_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5280/109395284/5280_30754970_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5280/109395284/5280_30754970_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5280/109395284/5280_30754970_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 370000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Bracken Road, Tadcaster Road",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.101643,
            "lat": 53.933883
        },
        "_place": 7.59,
        "_people": 35.72,
        "_property": 2,
        "_price": 117.66,
        "map": property_917
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113311733#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/10k/9407/113311733/9407_30946515_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 250000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Church Street, Bradwell, Hope Valley",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.739753,
            "lat": 53.326835
        },
        "_place": 1.32,
        "_people": 28.76,
        "_property": 2,
        "_price": 174.27,
        "map": property_918
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113901440#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/17k/16544/113901440/16544_GLO210165_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Wesley Street, Old Glossop, SK13",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.937449,
            "lat": 53.450116
        },
        "_place": 5.63,
        "_people": 35.18,
        "_property": 3,
        "_price": 166.97,
        "map": property_919
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117197546#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_00_0000.png",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53089/117197546/53089_22239001_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 490000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Eastcotts Green, Shortstown, Bedford, MK42",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.426103,
            "lat": 52.114248
        },
        "_place": 3.84,
        "_people": 33.66,
        "_property": 0,
        "_price": 145.31,
        "map": property_920
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114541268#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/204k/203438/114541268/203438_1261821-1_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203438/114541268/203438_1261821-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203438/114541268/203438_1261821-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203438/114541268/203438_1261821-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203438/114541268/203438_1261821-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203438/114541268/203438_1261821-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203438/114541268/203438_1261821-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203438/114541268/203438_1261821-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203438/114541268/203438_1261821-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203438/114541268/203438_1261821-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203438/114541268/203438_1261821-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203438/114541268/203438_1261821-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203438/114541268/203438_1261821-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203438/114541268/203438_1261821-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/204k/203438/114541268/203438_1261821-1_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 600000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Hanstubbin Road, Nottingham, NG16",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.302144,
            "lat": 53.068852
        },
        "_place": 11.45,
        "_people": 19.6,
        "_property": 1,
        "_price": 92.39,
        "map": property_921
    },
    {
        "url": "https://www.rightmove.co.uk/properties/106190135#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/16k/15578/106190135/15578_797898_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/16k/15578/106190135/15578_797898_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/16k/15578/106190135/15578_797898_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/16k/15578/106190135/15578_797898_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/16k/15578/106190135/15578_797898_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/16k/15578/106190135/15578_797898_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/16k/15578/106190135/15578_797898_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/16k/15578/106190135/15578_797898_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/16k/15578/106190135/15578_797898_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/16k/15578/106190135/15578_797898_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/16k/15578/106190135/15578_797898_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/16k/15578/106190135/15578_797898_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/16k/15578/106190135/15578_797898_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/16k/15578/106190135/15578_797898_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/16k/15578/106190135/15578_797898_IMG_14_0001.jpeg"
        ],
        "price": [
            {
                "value": 700000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Victoria Road, Grappenhall",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.55745,
            "lat": 53.377192
        },
        "_place": 5.96,
        "_people": 34.46,
        "_property": 2,
        "_price": 115.24,
        "map": property_922
    },
    {
        "url": "https://www.rightmove.co.uk/properties/78689239#",
        "title": "2 bedroom lodge",
        "images": [
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_01_0001.png",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/195k/194636/78689239/194636_1382_IMG_17_0001.jpeg"
        ],
        "price": [
            {
                "value": 335000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Conwy, Conwy, LL32 8HZ",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.83669,
            "lat": 53.27043
        },
        "_place": 0.08,
        "_people": 22.47,
        "_property": 3,
        "_price": 190.95,
        "map": property_923
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115838771#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57880/115838771/57880_31080679_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 239950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Parsley Close, Easington, Peterlee",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.355606,
            "lat": 54.786811
        },
        "_place": 7.7,
        "_people": 19.62,
        "_property": 4,
        "_price": 186.68,
        "map": property_924
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109369940#",
        "title": "4 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/109369940/191918_30747232_IMG_32_0000.jpeg"
        ],
        "price": [
            {
                "value": 370000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Goat Street, Haverfordwest",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.96906,
            "lat": 51.800849
        },
        "_place": 10,
        "_people": 28.41,
        "_property": 1,
        "_price": 121.56,
        "map": property_925
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114944276#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/143k/142502/114944276/142502_SOU210177_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142502/114944276/142502_SOU210177_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142502/114944276/142502_SOU210177_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142502/114944276/142502_SOU210177_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142502/114944276/142502_SOU210177_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142502/114944276/142502_SOU210177_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142502/114944276/142502_SOU210177_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142502/114944276/142502_SOU210177_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142502/114944276/142502_SOU210177_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142502/114944276/142502_SOU210177_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142502/114944276/142502_SOU210177_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142502/114944276/142502_SOU210177_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142502/114944276/142502_SOU210177_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142502/114944276/142502_SOU210177_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/143k/142502/114944276/142502_SOU210177_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 499950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Newitt Place, Southampton, Hampshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.406118,
            "lat": 50.947804
        },
        "_place": 3.51,
        "_people": 34.41,
        "_property": 2,
        "_price": 114.03,
        "map": property_926
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114263321#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/30k/29376/114263321/29376_HSS185106_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 489000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Compstall Road, Stockport, SK6",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.054356,
            "lat": 53.411698
        },
        "_place": 10.84,
        "_people": 35.61,
        "_property": 3,
        "_price": 98.9,
        "map": property_927
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116747948#",
        "title": "1 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/35k/34197/116747948/34197_HEA131788_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34197/116747948/34197_HEA131788_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34197/116747948/34197_HEA131788_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34197/116747948/34197_HEA131788_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34197/116747948/34197_HEA131788_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34197/116747948/34197_HEA131788_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34197/116747948/34197_HEA131788_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34197/116747948/34197_HEA131788_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34197/116747948/34197_HEA131788_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34197/116747948/34197_HEA131788_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34197/116747948/34197_HEA131788_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34197/116747948/34197_HEA131788_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34197/116747948/34197_HEA131788_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/35k/34197/116747948/34197_HEA131788_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 675000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": null,
        "address": "Aria House, 5-15 Newton Street, London, WC2B",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.12119,
            "lat": 51.51701
        },
        "_place": 3.65,
        "_people": 34.68,
        "_property": 5,
        "_price": 178.76,
        "map": property_928
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113545355#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/2k/1999/113545355/1999_HOL104983_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1999/113545355/1999_HOL104983_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1999/113545355/1999_HOL104983_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1999/113545355/1999_HOL104983_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1999/113545355/1999_HOL104983_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1999/113545355/1999_HOL104983_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1999/113545355/1999_HOL104983_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1999/113545355/1999_HOL104983_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1999/113545355/1999_HOL104983_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1999/113545355/1999_HOL104983_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1999/113545355/1999_HOL104983_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1999/113545355/1999_HOL104983_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 395000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Hawthorn Walk, Holt",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.09284,
            "lat": 52.90983
        },
        "_place": 4.48,
        "_people": 33.12,
        "_property": 2,
        "_price": 196.57,
        "map": property_929
    },
    {
        "url": "https://www.rightmove.co.uk/properties/83666291#",
        "title": "2 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/50k/49180/83666291/49180_WW1291_IMG_00_0000.jpg",
            "https://media.rightmove.co.uk/50k/49180/83666291/49180_WW1291_IMG_01_0000.JPG",
            "https://media.rightmove.co.uk/50k/49180/83666291/49180_WW1291_IMG_02_0000.JPG",
            "https://media.rightmove.co.uk/50k/49180/83666291/49180_WW1291_IMG_03_0000.JPG",
            "https://media.rightmove.co.uk/50k/49180/83666291/49180_WW1291_IMG_04_0000.JPG",
            "https://media.rightmove.co.uk/50k/49180/83666291/49180_WW1291_IMG_05_0000.JPG",
            "https://media.rightmove.co.uk/50k/49180/83666291/49180_WW1291_IMG_06_0000.JPG",
            "https://media.rightmove.co.uk/50k/49180/83666291/49180_WW1291_IMG_07_0000.JPG",
            "https://media.rightmove.co.uk/50k/49180/83666291/49180_WW1291_IMG_08_0000.JPG",
            "https://media.rightmove.co.uk/50k/49180/83666291/49180_WW1291_IMG_09_0000.JPG"
        ],
        "price": [
            {
                "value": 100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Warren Bay Holiday Village, TA23",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -3.34993,
            "lat": 51.17773
        },
        "_place": 8.73,
        "_people": 35.27,
        "_property": 3,
        "_price": 112.51,
        "map": property_930
    },
    {
        "url": "https://www.rightmove.co.uk/properties/74770169#",
        "title": "Block of apartments",
        "images": [
            "https://media.rightmove.co.uk/61k/60602/74770169/60602_28081161_IMG_03_0000.jpg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Crammavill Street, Stifford Clays, Essex",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": 0.329148,
            "lat": 51.497701
        },
        "_place": 9.06,
        "_people": 32.3,
        "_property": 2,
        "_price": 91.12,
        "map": property_931
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117234410#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/146k/145619/117234410/145619_31146023_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 385000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Lynchens Close, Bembridge",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.091366,
            "lat": 50.686374
        },
        "_place": 8.53,
        "_people": 23.61,
        "_property": 4,
        "_price": 160.61,
        "map": property_932
    },
    {
        "url": "https://www.rightmove.co.uk/properties/85583680#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/9k/8504/85583680/8504_528985197_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/9k/8504/85583680/8504_528985197_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/9k/8504/85583680/8504_528985197_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/9k/8504/85583680/8504_528985197_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/9k/8504/85583680/8504_528985197_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/9k/8504/85583680/8504_528985197_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/9k/8504/85583680/8504_528985197_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/9k/8504/85583680/8504_528985197_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/9k/8504/85583680/8504_528985197_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/9k/8504/85583680/8504_528985197_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/9k/8504/85583680/8504_528985197_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/9k/8504/85583680/8504_528985197_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/9k/8504/85583680/8504_528985197_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/9k/8504/85583680/8504_528985197_IMG_14_0000.jpg"
        ],
        "price": [
            {
                "value": 535000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "St. Andrew Place, York, YO1",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.079421,
            "lat": 53.959663
        },
        "_place": 3.76,
        "_people": 29.58,
        "_property": 4,
        "_price": 191.32,
        "map": property_933
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82844061#",
        "title": "4 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/12k/11464/82844061/11464_0106_FJL010607123_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 899950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Grove Park Road, London",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.035872,
            "lat": 51.433449
        },
        "_place": 4.51,
        "_people": 20.01,
        "_property": 2,
        "_price": 152.04,
        "map": property_934
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81954474#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/13k/12250/81954474/12250_RS0010_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/81954474/12250_RS0010_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/81954474/12250_RS0010_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12250/81954474/12250_RS0010_IMG_03_0000.jpeg"
        ],
        "price": [
            {
                "value": 195000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Ael y Bryn",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.089099,
            "lat": 52.894265
        },
        "_place": 0.36,
        "_people": 35.55,
        "_property": 2,
        "_price": 114.1,
        "map": property_935
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117644714#",
        "title": "2 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/239k/238187/117644714/238187_10_IMG_23_0000.jpeg"
        ],
        "price": [
            {
                "value": 270000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Erw Fawr, Henryd, Conwy, LL32",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.845261,
            "lat": 53.255896
        },
        "_place": 2.55,
        "_people": 30.62,
        "_property": 2,
        "_price": 109.58,
        "map": property_936
    },
    {
        "url": "https://www.rightmove.co.uk/properties/79569990#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/66k/65710/79569990/65710_30560508_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/66k/65710/79569990/65710_30560508_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65710/79569990/65710_30560508_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65710/79569990/65710_30560508_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65710/79569990/65710_30560508_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65710/79569990/65710_30560508_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65710/79569990/65710_30560508_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65710/79569990/65710_30560508_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65710/79569990/65710_30560508_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65710/79569990/65710_30560508_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65710/79569990/65710_30560508_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/66k/65710/79569990/65710_30560508_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 200000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Lochgoilhead, Argyll",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.900366,
            "lat": 56.170641
        },
        "_place": 8.96,
        "_people": 24.58,
        "_property": 3,
        "_price": 132.51,
        "map": property_937
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114447902#",
        "title": "2 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/51k/50283/114447902/50283_31007647_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50283/114447902/50283_31007647_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50283/114447902/50283_31007647_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50283/114447902/50283_31007647_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50283/114447902/50283_31007647_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50283/114447902/50283_31007647_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50283/114447902/50283_31007647_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50283/114447902/50283_31007647_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50283/114447902/50283_31007647_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50283/114447902/50283_31007647_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50283/114447902/50283_31007647_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/51k/50283/114447902/50283_31007647_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 265000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Llanbedr",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.102405,
            "lat": 52.822261
        },
        "_place": 3.68,
        "_people": 22.99,
        "_property": 0,
        "_price": 185.57,
        "map": property_938
    },
    {
        "url": "https://www.rightmove.co.uk/properties/88772818#",
        "title": "2 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_22_0001.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77231/88772818/77231_LLA200341_IMG_41_0000.jpeg"
        ],
        "price": [
            {
                "value": 295000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Bodffordd, Llangefni, Sir Ynys Mon, LL77",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.354764,
            "lat": 53.245312
        },
        "_place": 1.52,
        "_people": 24.27,
        "_property": 2,
        "_price": 136.72,
        "map": property_939
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115582376#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/34k/33296/115582376/33296_0013660_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33296/115582376/33296_0013660_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33296/115582376/33296_0013660_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33296/115582376/33296_0013660_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33296/115582376/33296_0013660_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33296/115582376/33296_0013660_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33296/115582376/33296_0013660_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/34k/33296/115582376/33296_0013660_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 195000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Strange Road, Ashton-in-Makerfield, Wigan, WN4 0RX",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.670627,
            "lat": 53.48943
        },
        "_place": 7.07,
        "_people": 35.84,
        "_property": 1,
        "_price": 196.95,
        "map": property_940
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117646142#",
        "title": "3 bedroom link detached house",
        "images": [
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4276/117646142/4276_101091013274_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Hollingbourne Crescent, Tollgate Hill",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.199828,
            "lat": 51.09023
        },
        "_place": 6.79,
        "_people": 35.9,
        "_property": 1,
        "_price": 154.66,
        "map": property_941
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117005945#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234392/117005945/234392_PLOT43BIDWELLMEWS_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 589950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Houghton Regis,\r\nLU5 6JP",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.52816,
            "lat": 51.911
        },
        "_place": 11.28,
        "_people": 30.26,
        "_property": 4,
        "_price": 148.84,
        "map": property_942
    },
    {
        "url": "https://www.rightmove.co.uk/properties/109759286#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/49k/48623/109759286/48623_PER210005_IMG_08_0001.jpeg"
        ],
        "price": [
            {
                "value": 655000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Skiff Wood, Howwood, Johnstone, Renfrewshire, PA9",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -4.544087,
            "lat": 55.805795
        },
        "_place": 11.22,
        "_people": 24.91,
        "_property": 0,
        "_price": 140.33,
        "map": property_943
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115644794#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/103k/102578/115644794/102578_102593001513_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/115644794/102578_102593001513_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/115644794/102578_102593001513_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/115644794/102578_102593001513_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/115644794/102578_102593001513_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/115644794/102578_102593001513_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/115644794/102578_102593001513_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/115644794/102578_102593001513_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/115644794/102578_102593001513_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/103k/102578/115644794/102578_102593001513_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 260000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Rock Cottage, Dervaig, PA75 6QJ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -6.188693,
            "lat": 56.590226
        },
        "_place": 6.38,
        "_people": 28.5,
        "_property": 0,
        "_price": 121.07,
        "map": property_944
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110995103#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/201k/200828/110995103/200828_44D3C601-40B6-4D95-9959-3545B8BBD4DE_IMG_00_0002.jpeg"
        ],
        "price": [
            {
                "value": null,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "West Farm Avenue, Newcastle Upon Tyne",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.591024,
            "lat": 55.011296
        },
        "_place": 6.45,
        "_people": 32.88,
        "_property": 3,
        "_price": 109.79,
        "map": property_945
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116049062#",
        "title": "1 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/75k/74982/116049062/74982_appt37_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74982/116049062/74982_appt37_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74982/116049062/74982_appt37_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74982/116049062/74982_appt37_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74982/116049062/74982_appt37_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74982/116049062/74982_appt37_IMG_05_0000.jpeg"
        ],
        "price": [
            {
                "value": 1005000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Market Suite at 12\r\nMount Pleasant\r\nLondon\r\nWC1X 0BU",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.113,
            "lat": 51.52335
        },
        "_place": 2.4,
        "_people": 25.03,
        "_property": 2,
        "_price": 133.84,
        "map": property_946
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117341978#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75717/117341978/75717_1272393-1_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 1000000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Cage Lane, Great Staughton, St. Neots, PE19",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.339953,
            "lat": 52.268487
        },
        "_place": 0.81,
        "_people": 33.78,
        "_property": 4,
        "_price": 172.43,
        "map": property_947
    },
    {
        "url": "https://www.rightmove.co.uk/properties/87736723#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/213k/212903/87736723/212903_100439034782_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212903/87736723/212903_100439034782_IMG_20_0001.jpeg",
            "https://media.rightmove.co.uk/213k/212903/87736723/212903_100439034782_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212903/87736723/212903_100439034782_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212903/87736723/212903_100439034782_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212903/87736723/212903_100439034782_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/213k/212903/87736723/212903_100439034782_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212903/87736723/212903_100439034782_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/213k/212903/87736723/212903_100439034782_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212903/87736723/212903_100439034782_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/213k/212903/87736723/212903_100439034782_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Bridewell Street, Walsingham, Norfolk",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.87395,
            "lat": 52.89501
        },
        "_place": 7.08,
        "_people": 28.12,
        "_property": 4,
        "_price": 93.37,
        "map": property_948
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117686057#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/40k/39326/117686057/39326_31166549_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39326/117686057/39326_31166549_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39326/117686057/39326_31166549_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39326/117686057/39326_31166549_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39326/117686057/39326_31166549_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39326/117686057/39326_31166549_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39326/117686057/39326_31166549_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39326/117686057/39326_31166549_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39326/117686057/39326_31166549_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39326/117686057/39326_31166549_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39326/117686057/39326_31166549_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39326/117686057/39326_31166549_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39326/117686057/39326_31166549_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 279950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Craig Y Don, Benllech, Isle Of Anglesey",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.226905,
            "lat": 53.323642
        },
        "_place": 10.76,
        "_people": 33.2,
        "_property": 3,
        "_price": 134.07,
        "map": property_949
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115407329#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_29_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_30_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_31_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_32_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_33_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_34_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_35_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_36_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_37_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_38_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_39_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_40_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_41_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_42_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_43_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_44_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_45_0001.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_46_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_47_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_48_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_49_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4425/115407329/4425_31056902_IMG_50_0000.jpeg"
        ],
        "price": [
            {
                "value": 650000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Edale Grove, Sale",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.340828,
            "lat": 53.413927
        },
        "_place": 4.39,
        "_people": 34.49,
        "_property": 2,
        "_price": 195.82,
        "map": property_950
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113769011#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37035/113769011/37035_HOR180064_IMG_22_0000.jpeg"
        ],
        "price": [
            {
                "value": 635000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Holly Park, Horsforth, Leeds, West Yorkshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.656908,
            "lat": 53.837861
        },
        "_place": 7.38,
        "_people": 18.54,
        "_property": 2,
        "_price": 200.79,
        "map": property_951
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115046168#",
        "title": "2 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/5k/4307/115046168/4307_4928583_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4307/115046168/4307_4928583_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4307/115046168/4307_4928583_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4307/115046168/4307_4928583_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4307/115046168/4307_4928583_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4307/115046168/4307_4928583_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/5k/4307/115046168/4307_4928583_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 425000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "The Orchard, VIRGINIA WATER, Surrey",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.557986,
            "lat": 51.400307
        },
        "_place": 11.33,
        "_people": 25.33,
        "_property": 5,
        "_price": 180.83,
        "map": property_952
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113680286#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/6k/5942/113680286/5942_3685_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5942/113680286/5942_3685_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5942/113680286/5942_3685_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5942/113680286/5942_3685_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5942/113680286/5942_3685_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5942/113680286/5942_3685_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5942/113680286/5942_3685_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5942/113680286/5942_3685_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5942/113680286/5942_3685_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5942/113680286/5942_3685_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5942/113680286/5942_3685_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 395000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "The Common, Little Blakenham, Ipswich,IP8",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.09454,
            "lat": 52.09706
        },
        "_place": 11,
        "_people": 24.66,
        "_property": 5,
        "_price": 197.27,
        "map": property_953
    },
    {
        "url": "https://www.rightmove.co.uk/properties/81572448#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/38k/37799/81572448/37799_3166_IMG_08_0001.jpeg"
        ],
        "price": [
            {
                "value": 625000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Lowertown, TR13",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.27706,
            "lat": 50.11494
        },
        "_place": 3.9,
        "_people": 21.85,
        "_property": 1,
        "_price": 182.73,
        "map": property_954
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114538853#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/3k/2226/114538853/2226_13821152_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2226/114538853/2226_13821152_IMG_10_0002.jpeg",
            "https://media.rightmove.co.uk/3k/2226/114538853/2226_13821152_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2226/114538853/2226_13821152_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2226/114538853/2226_13821152_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2226/114538853/2226_13821152_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2226/114538853/2226_13821152_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2226/114538853/2226_13821152_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2226/114538853/2226_13821152_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2226/114538853/2226_13821152_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/3k/2226/114538853/2226_13821152_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Busbridge Road, Snodland, Kent",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.429519,
            "lat": 51.325295
        },
        "_place": 5.05,
        "_people": 26.85,
        "_property": 0,
        "_price": 146.18,
        "map": property_955
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115416272#",
        "title": "4 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/54k/53226/115416272/53226_WNO190043_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53226/115416272/53226_WNO190043_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53226/115416272/53226_WNO190043_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53226/115416272/53226_WNO190043_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53226/115416272/53226_WNO190043_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53226/115416272/53226_WNO190043_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53226/115416272/53226_WNO190043_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53226/115416272/53226_WNO190043_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53226/115416272/53226_WNO190043_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53226/115416272/53226_WNO190043_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/54k/53226/115416272/53226_WNO190043_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 1150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Casewick Road, West Norwood, London, SE27",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.10827,
            "lat": 51.428077
        },
        "_place": 5.73,
        "_people": 29.49,
        "_property": 0,
        "_price": 180.33,
        "map": property_956
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114807005#",
        "title": "3 bedroom town house",
        "images": [
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75069/114807005/75069_KenilworthHouse_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 325000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Fletcher Road, Gateshead, Tyne And Wear, NE8",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.60794,
            "lat": 54.96505
        },
        "_place": 2.78,
        "_people": 21.26,
        "_property": 3,
        "_price": 139.69,
        "map": property_957
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110885252#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/29k/28187/110885252/28187_11052086_IMG_25_0000.jpeg"
        ],
        "price": [
            {
                "value": 399995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "En-Gedi, Thornholme Road, Thornhill, Sunderland",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.39355,
            "lat": 54.89798
        },
        "_place": 11.55,
        "_people": 33.21,
        "_property": 0,
        "_price": 158.26,
        "map": property_958
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112715993#",
        "title": "3 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/20k/19323/112715993/19323_30918200_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19323/112715993/19323_30918200_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19323/112715993/19323_30918200_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19323/112715993/19323_30918200_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19323/112715993/19323_30918200_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19323/112715993/19323_30918200_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19323/112715993/19323_30918200_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19323/112715993/19323_30918200_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19323/112715993/19323_30918200_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19323/112715993/19323_30918200_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19323/112715993/19323_30918200_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19323/112715993/19323_30918200_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19323/112715993/19323_30918200_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/20k/19323/112715993/19323_30918200_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 399950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Manselfield Road, Murton",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.035456,
            "lat": 51.580534
        },
        "_place": 4.33,
        "_people": 24.84,
        "_property": 4,
        "_price": 93.85,
        "map": property_959
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112552652#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/44k/43955/112552652/43955_30913717_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43955/112552652/43955_30913717_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43955/112552652/43955_30913717_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43955/112552652/43955_30913717_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43955/112552652/43955_30913717_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43955/112552652/43955_30913717_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43955/112552652/43955_30913717_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43955/112552652/43955_30913717_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43955/112552652/43955_30913717_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43955/112552652/43955_30913717_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43955/112552652/43955_30913717_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43955/112552652/43955_30913717_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43955/112552652/43955_30913717_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43955/112552652/43955_30913717_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/44k/43955/112552652/43955_30913717_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Willow Drive, Brough",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.564556,
            "lat": 53.727345
        },
        "_place": 7.54,
        "_people": 19.65,
        "_property": 5,
        "_price": 148.54,
        "map": property_960
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117029549#",
        "title": "4 bedroom town house",
        "images": [
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_45_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_46_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_47_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_48_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_49_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_50_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_51_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_52_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_53_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_54_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_55_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_56_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_57_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_58_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_59_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_60_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77088/117029549/77088_254533_IMG_61_0000.jpeg"
        ],
        "price": [
            {
                "value": 210000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Locke Drive, Sheffield",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.419482,
            "lat": 53.386863
        },
        "_place": 9.25,
        "_people": 29.24,
        "_property": 5,
        "_price": 143.13,
        "map": property_961
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117338741#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/83k/82694/117338741/82694_P599_IMG_26_0000.jpeg"
        ],
        "price": [
            {
                "value": 100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Cowal Terrace, Tighnabruaich, Argyll and Bute, PA21",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.24656,
            "lat": 55.89438
        },
        "_place": 4.53,
        "_people": 31.32,
        "_property": 4,
        "_price": 122.19,
        "map": property_962
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115222013#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37029/115222013/37029_2291829_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 580000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Bramley House, Main Street, West Markham, NG22 0GT",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.918902,
            "lat": 53.24529
        },
        "_place": 4.46,
        "_people": 19.5,
        "_property": 5,
        "_price": 119.6,
        "map": property_963
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116469584#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/70k/69998/116469584/69998_ZK1103555_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/116469584/69998_ZK1103555_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/116469584/69998_ZK1103555_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/116469584/69998_ZK1103555_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/116469584/69998_ZK1103555_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/116469584/69998_ZK1103555_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/116469584/69998_ZK1103555_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/116469584/69998_ZK1103555_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/116469584/69998_ZK1103555_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/116469584/69998_ZK1103555_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/116469584/69998_ZK1103555_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/116469584/69998_ZK1103555_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/116469584/69998_ZK1103555_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/116469584/69998_ZK1103555_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/70k/69998/116469584/69998_ZK1103555_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 525000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Station Road, Reepham, Norwich",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.11229,
            "lat": 52.76597
        },
        "_place": 9.18,
        "_people": 34.12,
        "_property": 0,
        "_price": 177.89,
        "map": property_964
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113481407#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/23k/22895/113481407/22895_LRO210055_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22895/113481407/22895_LRO210055_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22895/113481407/22895_LRO210055_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22895/113481407/22895_LRO210055_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22895/113481407/22895_LRO210055_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22895/113481407/22895_LRO210055_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22895/113481407/22895_LRO210055_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22895/113481407/22895_LRO210055_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22895/113481407/22895_LRO210055_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22895/113481407/22895_LRO210055_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22895/113481407/22895_LRO210055_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22895/113481407/22895_LRO210055_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22895/113481407/22895_LRO210055_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/23k/22895/113481407/22895_LRO210055_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 650000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Blackthorn Drive, Lightwater, Surrey, GU18",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.671896,
            "lat": 51.344994
        },
        "_place": 7.65,
        "_people": 27.3,
        "_property": 1,
        "_price": 190.47,
        "map": property_965
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114684314#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/223k/222917/114684314/222917_36e41241-ac44-40a6-a469-9992dd21458b_IMG_31_0000.jpeg"
        ],
        "price": [
            {
                "value": 375000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Penperlleni, Pontypool, NP4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.98293,
            "lat": 51.73564
        },
        "_place": 3.87,
        "_people": 35.42,
        "_property": 0,
        "_price": 165.07,
        "map": property_966
    },
    {
        "url": "https://www.rightmove.co.uk/properties/79227852#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/233k/232709/79227852/232709_LNH210249_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/233k/232709/79227852/232709_LNH210249_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/233k/232709/79227852/232709_LNH210249_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/233k/232709/79227852/232709_LNH210249_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/233k/232709/79227852/232709_LNH210249_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/233k/232709/79227852/232709_LNH210249_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/233k/232709/79227852/232709_LNH210249_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/233k/232709/79227852/232709_LNH210249_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/233k/232709/79227852/232709_LNH210249_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/233k/232709/79227852/232709_LNH210249_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/233k/232709/79227852/232709_LNH210249_IMG_11_0001.jpeg"
        ],
        "price": [
            {
                "value": 397950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Spitfire Lodge, Belmont Road, Southampton, Hampshire, SO17",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.38926,
            "lat": 50.924496
        },
        "_place": 2.86,
        "_people": 35.42,
        "_property": 2,
        "_price": 199.75,
        "map": property_967
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117349097#",
        "title": "4 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/242k/241205/117349097/241205_31152579_IMG_00_0000.png",
            "https://media.rightmove.co.uk/242k/241205/117349097/241205_31152579_IMG_01_0000.png"
        ],
        "price": [
            {
                "value": 875000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Richings Way, Iver SL0",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.509443,
            "lat": 51.503044
        },
        "_place": 2.07,
        "_people": 28.61,
        "_property": 4,
        "_price": 181.61,
        "map": property_968
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115196909#",
        "title": "2 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/240k/239918/115196909/239918_606528_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239918/115196909/239918_606528_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239918/115196909/239918_606528_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239918/115196909/239918_606528_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239918/115196909/239918_606528_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239918/115196909/239918_606528_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239918/115196909/239918_606528_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239918/115196909/239918_606528_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239918/115196909/239918_606528_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239918/115196909/239918_606528_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239918/115196909/239918_606528_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239918/115196909/239918_606528_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/240k/239918/115196909/239918_606528_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 475000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "Ledicott, Shobdon, Herefordshire, HR6 9NX",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.858041,
            "lat": 52.253048
        },
        "_place": 10.45,
        "_people": 25.4,
        "_property": 2,
        "_price": 90.18,
        "map": property_969
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116242634#",
        "title": "3 bedroom cottage",
        "images": [
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/158k/157103/116242634/157103_21997122_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 675000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": " Rushall, Ledbury, HR8",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.522865,
            "lat": 52.012653
        },
        "_place": 9.87,
        "_people": 35.43,
        "_property": 2,
        "_price": 169.13,
        "map": property_970
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108488840#",
        "title": "2 bedroom retirement property",
        "images": [
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_50_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_52_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_51_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_45_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_56_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_46_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_53_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_47_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_48_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_55_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_54_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_49_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/126k/125116/108488840/125116_10648052_IMG_41_0000.jpeg"
        ],
        "price": [
            {
                "value": 325000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Wheatley House, St. Pauls Lock, Mirfield",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -1.695804,
            "lat": 53.673157
        },
        "_place": 2.5,
        "_people": 22.94,
        "_property": 5,
        "_price": 198.71,
        "map": property_971
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117357059#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/96k/95647/117357059/95647_101412010153_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95647/117357059/95647_101412010153_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95647/117357059/95647_101412010153_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95647/117357059/95647_101412010153_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95647/117357059/95647_101412010153_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95647/117357059/95647_101412010153_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95647/117357059/95647_101412010153_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95647/117357059/95647_101412010153_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95647/117357059/95647_101412010153_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95647/117357059/95647_101412010153_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95647/117357059/95647_101412010153_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/96k/95647/117357059/95647_101412010153_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 345000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Bolster Close, St. Agnes",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -5.20589,
            "lat": 50.30885
        },
        "_place": 3.62,
        "_people": 22.01,
        "_property": 5,
        "_price": 173.67,
        "map": property_972
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116363885#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/39k/38088/116363885/38088_31105212_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38088/116363885/38088_31105212_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38088/116363885/38088_31105212_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38088/116363885/38088_31105212_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38088/116363885/38088_31105212_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38088/116363885/38088_31105212_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38088/116363885/38088_31105212_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38088/116363885/38088_31105212_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38088/116363885/38088_31105212_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38088/116363885/38088_31105212_IMG_09_0000.jpeg"
        ],
        "price": [
            {
                "value": 429950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "The Paddocks, North Duffield, Selby",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.964041,
            "lat": 53.827995
        },
        "_place": 4.41,
        "_people": 33.09,
        "_property": 0,
        "_price": 186.74,
        "map": property_973
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113801912#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/39k/38957/113801912/38957_KLLIP_640853_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/113801912/38957_KLLIP_640853_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/113801912/38957_KLLIP_640853_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/113801912/38957_KLLIP_640853_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/113801912/38957_KLLIP_640853_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/113801912/38957_KLLIP_640853_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/113801912/38957_KLLIP_640853_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/113801912/38957_KLLIP_640853_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/113801912/38957_KLLIP_640853_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/113801912/38957_KLLIP_640853_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/39k/38957/113801912/38957_KLLIP_640853_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 725000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Childerstone Close, Liphook",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.802347,
            "lat": 51.078836
        },
        "_place": 6.3,
        "_people": 18.17,
        "_property": 0,
        "_price": 107.12,
        "map": property_974
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113245394#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/85k/84824/113245394/84824_30942854_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84824/113245394/84824_30942854_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84824/113245394/84824_30942854_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84824/113245394/84824_30942854_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84824/113245394/84824_30942854_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84824/113245394/84824_30942854_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/85k/84824/113245394/84824_30942854_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 730000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Chestnut Walk, Shepperton",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.42878,
            "lat": 51.400469
        },
        "_place": 4.86,
        "_people": 24.36,
        "_property": 2,
        "_price": 153.49,
        "map": property_975
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114180299#",
        "title": "3 bedroom semi-detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/58k/57859/114180299/57859_30990642_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 290000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Seaton Grove, Seaham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.385251,
            "lat": 54.842887
        },
        "_place": 1.99,
        "_people": 27.54,
        "_property": 2,
        "_price": 143.96,
        "map": property_976
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117680624#",
        "title": "2 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/8k/7959/117680624/7959_102476009632_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Athelstan Gardens, Wickford, Wickford, Essex",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.52544,
            "lat": 51.61899
        },
        "_place": 5.2,
        "_people": 21.01,
        "_property": 0,
        "_price": 193.85,
        "map": property_977
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112810862#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/237k/236756/112810862/236756_F1Plot3_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/237k/236756/112810862/236756_F1Plot3_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/237k/236756/112810862/236756_F1Plot3_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/237k/236756/112810862/236756_F1Plot3_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/237k/236756/112810862/236756_F1Plot3_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/237k/236756/112810862/236756_F1Plot3_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/237k/236756/112810862/236756_F1Plot3_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/237k/236756/112810862/236756_F1Plot3_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/237k/236756/112810862/236756_F1Plot3_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/237k/236756/112810862/236756_F1Plot3_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/237k/236756/112810862/236756_F1Plot3_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 645000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Hitchin Road,\r\nStotfold,\r\nSG5",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.24346,
            "lat": 52.00114
        },
        "_place": 4.57,
        "_people": 27,
        "_property": 3,
        "_price": 134.81,
        "map": property_978
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82733463#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/235k/234215/82733463/234215_213-20318-042_4_32_101368_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/235k/234215/82733463/234215_213-20318-042_4_32_101368_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/235k/234215/82733463/234215_213-20318-042_4_32_101368_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/235k/234215/82733463/234215_213-20318-042_4_32_101368_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/235k/234215/82733463/234215_213-20318-042_4_32_101368_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/235k/234215/82733463/234215_213-20318-042_4_32_101368_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/235k/234215/82733463/234215_213-20318-042_4_32_101368_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/235k/234215/82733463/234215_213-20318-042_4_32_101368_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/235k/234215/82733463/234215_213-20318-042_4_32_101368_IMG_09_0000.jpg"
        ],
        "price": [
            {
                "value": 409995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Leeming Lane,\r\nBoroughbridge,\r\nYO51",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.40232,
            "lat": 54.10254
        },
        "_place": 0.81,
        "_people": 32.75,
        "_property": 3,
        "_price": 121.61,
        "map": property_979
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115332119#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/57k/56521/115332119/56521_31052244_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 685000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 4,
        "address": "17, Peartree Drive, Wombourne, Wolverhampton, South Staffordshire, WV5 ",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.180522,
            "lat": 52.529827
        },
        "_place": 5.74,
        "_people": 30.78,
        "_property": 5,
        "_price": 100.63,
        "map": property_980
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116591216#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/235k/234626/116591216/234626_31117377_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 100000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Guildford Street, Millport, Isle Of Cumbrae",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.930314,
            "lat": 55.752433
        },
        "_place": 1.2,
        "_people": 18.93,
        "_property": 5,
        "_price": 108.4,
        "map": property_981
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114420725#",
        "title": "House",
        "images": [
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/154k/153122/114420725/153122_586869_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 159950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "House, Llanidloes, Wales",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -3.5401,
            "lat": 52.4495
        },
        "_place": 5.39,
        "_people": 27.19,
        "_property": 0,
        "_price": 163.8,
        "map": property_982
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82660275#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/41k/40460/82660275/40460_BRI210314_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 319950,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Lippetts Way, Catcott, Bridgwater, Somerset, TA7",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.86,
            "lat": 51.15
        },
        "_place": 11.6,
        "_people": 32.61,
        "_property": 4,
        "_price": 177.52,
        "map": property_983
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114150302#",
        "title": "4 bedroom detached bungalow",
        "images": [
            "https://media.rightmove.co.uk/31k/30026/114150302/30026_201206200_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30026/114150302/30026_201206200_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30026/114150302/30026_201206200_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30026/114150302/30026_201206200_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30026/114150302/30026_201206200_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30026/114150302/30026_201206200_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30026/114150302/30026_201206200_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30026/114150302/30026_201206200_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30026/114150302/30026_201206200_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30026/114150302/30026_201206200_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30026/114150302/30026_201206200_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30026/114150302/30026_201206200_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/31k/30026/114150302/30026_201206200_IMG_12_0000.jpeg"
        ],
        "price": [
            {
                "value": 450000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Meaford, Stone, ST15",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.172931,
            "lat": 52.918098
        },
        "_place": 11.11,
        "_people": 34.91,
        "_property": 2,
        "_price": 104.28,
        "map": property_984
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115328534#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/76k/75462/115328534/75462_ROM210806_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75462/115328534/75462_ROM210806_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75462/115328534/75462_ROM210806_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75462/115328534/75462_ROM210806_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75462/115328534/75462_ROM210806_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75462/115328534/75462_ROM210806_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75462/115328534/75462_ROM210806_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75462/115328534/75462_ROM210806_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75462/115328534/75462_ROM210806_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75462/115328534/75462_ROM210806_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/76k/75462/115328534/75462_ROM210806_IMG_10_0000.jpeg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Lukin Drive, Nursling, Southampton, SO16",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.468692,
            "lat": 50.946844
        },
        "_place": 3.56,
        "_people": 31.66,
        "_property": 0,
        "_price": 195.42,
        "map": property_985
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117643667#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/110k/109382/117643667/109382_GRC210569_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 765000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Deanacre Close, Chalfont St. Peter, Gerrards Cross, Buckinghamshire, SL9",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.556483,
            "lat": 51.616321
        },
        "_place": 8.28,
        "_people": 25.22,
        "_property": 0,
        "_price": 187.98,
        "map": property_986
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114809354#",
        "title": "3 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/1k/307/114809354/307_LNH210042_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 260000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": null,
        "address": "Sky Heights, Gregory Street, Nottingham, NG7",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.177053,
            "lat": 52.944037
        },
        "_place": 8.99,
        "_people": 21.39,
        "_property": 4,
        "_price": 124.87,
        "map": property_987
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117221393#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/104k/103145/117221393/103145_RS0259_IMG_16_0000.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Wenlock Road, Shrewsbury",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.730072,
            "lat": 52.701136
        },
        "_place": 9.21,
        "_people": 25.49,
        "_property": 1,
        "_price": 125.31,
        "map": property_988
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116091014#",
        "title": "4 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/6k/5073/116091014/5073_WBF200176_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5073/116091014/5073_WBF200176_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5073/116091014/5073_WBF200176_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5073/116091014/5073_WBF200176_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5073/116091014/5073_WBF200176_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5073/116091014/5073_WBF200176_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5073/116091014/5073_WBF200176_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5073/116091014/5073_WBF200176_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5073/116091014/5073_WBF200176_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5073/116091014/5073_WBF200176_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5073/116091014/5073_WBF200176_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5073/116091014/5073_WBF200176_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5073/116091014/5073_WBF200176_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5073/116091014/5073_WBF200176_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/6k/5073/116091014/5073_WBF200176_IMG_14_0000.jpeg"
        ],
        "price": [
            {
                "value": 525000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Broomfield Road, New Haw, Addlestone, Surrey, KT15",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.49478,
            "lat": 51.34615
        },
        "_place": 4.48,
        "_people": 20.89,
        "_property": 0,
        "_price": 121.23,
        "map": property_989
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113771681#",
        "title": "4 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/238k/237434/113771681/237434_4_9_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237434/113771681/237434_4_9_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237434/113771681/237434_4_9_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 460000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Loughborough Road,\r\nRuddington,\r\nNottingham,\r\nNG11",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.14222,
            "lat": 52.8901
        },
        "_place": 0.01,
        "_people": 31.36,
        "_property": 5,
        "_price": 104.14,
        "map": property_990
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116063756#",
        "title": "5 bedroom detached house",
        "images": [],
        "price": [
            {
                "value": 800000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "Newton-Le-Willows, Merseyside, WA12",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.61651,
            "lat": 53.45664
        },
        "_place": 0.58,
        "_people": 33.5,
        "_property": 1,
        "_price": 132.96,
        "map": property_991
    },
    {
        "url": "https://www.rightmove.co.uk/properties/111067472#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77902/111067472/77902_11060677_IMG_32_0000.jpeg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Radcliffe New Road, Whitefield, Manchester",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.309206,
            "lat": 53.555264
        },
        "_place": 5.58,
        "_people": 18.21,
        "_property": 0,
        "_price": 124.94,
        "map": property_992
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116791871#",
        "title": "4 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/31k/30065/116791871/30065_HGR210515_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30065/116791871/30065_HGR210515_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30065/116791871/30065_HGR210515_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30065/116791871/30065_HGR210515_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30065/116791871/30065_HGR210515_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30065/116791871/30065_HGR210515_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30065/116791871/30065_HGR210515_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30065/116791871/30065_HGR210515_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30065/116791871/30065_HGR210515_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30065/116791871/30065_HGR210515_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30065/116791871/30065_HGR210515_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/31k/30065/116791871/30065_HGR210515_IMG_11_0001.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Moorland Road, Woodsmoor, Stockport, Cheshire, SK2",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.141246,
            "lat": 53.387433
        },
        "_place": 2.04,
        "_people": 30.19,
        "_property": 2,
        "_price": 178.9,
        "map": property_993
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115783709#",
        "title": "3 bedroom penthouse",
        "images": [
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/194k/193397/115783709/193397_31077639_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 1000000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Cribbar, Headland Road",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -5.099833,
            "lat": 50.412166
        },
        "_place": 7.6,
        "_people": 28.43,
        "_property": 3,
        "_price": 104.73,
        "map": property_994
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115123166#",
        "title": "5 bedroom house",
        "images": [
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/229k/228344/115123166/228344_31042007_IMG_34_0000.jpeg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Claymires Lane, Newstead, Melrose",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.696129,
            "lat": 55.599327
        },
        "_place": 11.6,
        "_people": 32.19,
        "_property": 4,
        "_price": 121.58,
        "map": property_995
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117133070#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/24k/23264/117133070/23264_FBM210545_IMG_20_0000.jpeg"
        ],
        "price": [
            {
                "value": 675000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Undley Common, Lakenheath, Brandon, Suffolk, IP27",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.501318,
            "lat": 52.401757
        },
        "_place": 2.04,
        "_people": 35.11,
        "_property": 0,
        "_price": 171.46,
        "map": property_996
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82835859#",
        "title": "6 bedroom country house",
        "images": [
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/40k/39890/82835859/39890_31144311_IMG_35_0000.jpeg"
        ],
        "price": [
            {
                "value": 950000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 4,
        "address": "Ladyrig House & Lodge, Kelso",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.442747,
            "lat": 55.569076
        },
        "_place": 4.78,
        "_people": 23.78,
        "_property": 0,
        "_price": 132.06,
        "map": property_997
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112243241#",
        "title": "3 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74409/112243241/74409_30897464_IMG_43_0000.jpeg"
        ],
        "price": [
            {
                "value": 310000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Rock Terrace, Clynderwen",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.729005,
            "lat": 51.839035
        },
        "_place": 1.6,
        "_people": 23.53,
        "_property": 3,
        "_price": 111.78,
        "map": property_998
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107434403#",
        "title": "2 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_27_0002.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_26_0002.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_21_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_32_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_28_0002.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_30_0002.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_20_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_21_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_31_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_22_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_29_0002.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_23_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_33_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_25_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_26_0002.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_27_0002.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_20_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_22_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_23_0001.jpeg",
            "https://media.rightmove.co.uk/204k/203441/107434403/203441_863711-1_IMG_25_0001.jpeg"
        ],
        "price": [
            {
                "value": 400000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 3,
        "address": "De La Warr Court, Bexhill-on-sea, TN40",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": 0.47751,
            "lat": 50.838256
        },
        "_place": 4.63,
        "_people": 22.48,
        "_property": 0,
        "_price": 185.32,
        "map": property_999
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117742778#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/238k/237692/117742778/237692_78trillium_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237692/117742778/237692_78trillium_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237692/117742778/237692_78trillium_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237692/117742778/237692_78trillium_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237692/117742778/237692_78trillium_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237692/117742778/237692_78trillium_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237692/117742778/237692_78trillium_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237692/117742778/237692_78trillium_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237692/117742778/237692_78trillium_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237692/117742778/237692_78trillium_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237692/117742778/237692_78trillium_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237692/117742778/237692_78trillium_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237692/117742778/237692_78trillium_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/238k/237692/117742778/237692_78trillium_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 414995,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Church Fenton,\r\nLS24",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.20709,
            "lat": 53.83393
        },
        "_place": 1.66,
        "_people": 22.25,
        "_property": 4,
        "_price": 94.1,
        "map": property_1000
    },
    {
        "url": "https://www.rightmove.co.uk/properties/115447052#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/112k/111235/115447052/111235_jncihdechchcd_IMG_15_0000.jpeg"
        ],
        "price": [
            {
                "value": 350000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Luxembourg Way, Dereham, Norfolk, NR19",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 0.93213,
            "lat": 52.66634
        },
        "_place": 0.16,
        "_people": 27.76,
        "_property": 2,
        "_price": 162.66,
        "map": property_1001
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116706911#",
        "title": "6 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/63k/62280/116706911/62280_31083832_IMG_32_0000.jpeg"
        ],
        "price": [
            {
                "value": 700000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 6,
        "bathrooms": 2,
        "address": "Stone Road, Bramshall, Uttoxeter, Staffordshire, ST14 8SH",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.92489,
            "lat": 52.895706
        },
        "_place": 9.98,
        "_people": 34.85,
        "_property": 2,
        "_price": 145.78,
        "map": property_1002
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113768417#",
        "title": "4 bedroom link detached house",
        "images": [
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/38k/37982/113768417/37982_ALT100207_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 885000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 3,
        "address": "Brislands Lane, Four Marks, Alton, Hampshire, GU34",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.050137,
            "lat": 51.104762
        },
        "_place": 3.56,
        "_people": 31.82,
        "_property": 3,
        "_price": 92.43,
        "map": property_1003
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110701097#",
        "title": "3 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/32k/31079/110701097/31079_PRE210233_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/32k/31079/110701097/31079_PRE210233_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/32k/31079/110701097/31079_PRE210233_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/32k/31079/110701097/31079_PRE210233_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/32k/31079/110701097/31079_PRE210233_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/32k/31079/110701097/31079_PRE210233_IMG_05_0001.jpeg",
            "https://media.rightmove.co.uk/32k/31079/110701097/31079_PRE210233_IMG_06_0001.jpeg",
            "https://media.rightmove.co.uk/32k/31079/110701097/31079_PRE210233_IMG_07_0001.jpeg",
            "https://media.rightmove.co.uk/32k/31079/110701097/31079_PRE210233_IMG_08_0001.jpeg",
            "https://media.rightmove.co.uk/32k/31079/110701097/31079_PRE210233_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/32k/31079/110701097/31079_PRE210233_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/32k/31079/110701097/31079_PRE210233_IMG_11_0001.jpeg"
        ],
        "price": [
            {
                "value": 470000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Teynham Avenue, Knowsley, Prescot, Merseyside, L34",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.850416,
            "lat": 53.456998
        },
        "_place": 5.52,
        "_people": 20.82,
        "_property": 1,
        "_price": 160.85,
        "map": property_1004
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114717290#",
        "title": "3 bedroom apartment",
        "images": [
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/162k/161522/114717290/161522_30703476_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 1150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 3,
        "address": "Albury Park, Albury, Guildford",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.479025,
            "lat": 51.218887
        },
        "_place": 1.31,
        "_people": 27.63,
        "_property": 1,
        "_price": 106.24,
        "map": property_1005
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116068199#",
        "title": "1 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/18k/17624/116068199/17624_31091656_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17624/116068199/17624_31091656_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17624/116068199/17624_31091656_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17624/116068199/17624_31091656_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17624/116068199/17624_31091656_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17624/116068199/17624_31091656_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/18k/17624/116068199/17624_31091656_IMG_06_0000.jpeg"
        ],
        "price": [
            {
                "value": 140000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 1,
        "bathrooms": 1,
        "address": "Porter Close, West Thurrock",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": 0.291533,
            "lat": 51.474967
        },
        "_place": 11.91,
        "_people": 34.4,
        "_property": 1,
        "_price": 157.7,
        "map": property_1006
    },
    {
        "url": "https://www.rightmove.co.uk/properties/107870981#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_84_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_29_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_30_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_31_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_32_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_33_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_34_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_35_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_36_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_37_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_38_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_39_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_40_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_41_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_42_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_43_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_44_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_45_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_46_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_47_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_48_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_49_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_50_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_51_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_52_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_53_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_54_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_55_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_56_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_57_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_58_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_59_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_60_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_61_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_62_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_63_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_64_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_65_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_66_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_67_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_68_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_69_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_70_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_71_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_72_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_73_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_74_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_75_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_76_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_77_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_78_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_79_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_80_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_81_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_82_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_83_0000.jpeg",
            "https://media.rightmove.co.uk/192k/191918/107870981/191918_30684808_IMG_01_0000.jpeg"
        ],
        "price": [
            {
                "value": 850000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 8,
        "bathrooms": 3,
        "address": "Clarbeston Road",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -4.827209,
            "lat": 51.865318
        },
        "_place": 2.08,
        "_people": 31.93,
        "_property": 2,
        "_price": 171.33,
        "map": property_1007
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117059246#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/78k/77776/117059246/77776_OXP104163_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/117059246/77776_OXP104163_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/117059246/77776_OXP104163_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/117059246/77776_OXP104163_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/117059246/77776_OXP104163_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/117059246/77776_OXP104163_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/117059246/77776_OXP104163_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/117059246/77776_OXP104163_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/117059246/77776_OXP104163_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/117059246/77776_OXP104163_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/117059246/77776_OXP104163_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/117059246/77776_OXP104163_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/117059246/77776_OXP104163_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/78k/77776/117059246/77776_OXP104163_IMG_13_0000.jpeg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 3,
        "address": "Tenby Grove, Kingsmead, Milton Keynes",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.80685,
            "lat": 51.99903
        },
        "_place": 4.21,
        "_people": 18.59,
        "_property": 4,
        "_price": 125.46,
        "map": property_1008
    },
    {
        "url": "https://www.rightmove.co.uk/properties/108630977#",
        "title": "4 bedroom bungalow",
        "images": [
            "https://media.rightmove.co.uk/47k/46299/108630977/46299_BAR210254_IMG_00_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/108630977/46299_BAR210254_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/108630977/46299_BAR210254_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/47k/46299/108630977/46299_BAR210254_IMG_03_0001.jpeg"
        ],
        "price": [
            {
                "value": 360000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Royston Road, Cudworth, Barnsley, South Yorkshire, S72",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.422181,
            "lat": 53.588524
        },
        "_place": 8.07,
        "_people": 23.59,
        "_property": 3,
        "_price": 107.09,
        "map": property_1009
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114665897#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/65k/64658/114665897/64658_P398_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64658/114665897/64658_P398_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64658/114665897/64658_P398_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64658/114665897/64658_P398_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64658/114665897/64658_P398_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64658/114665897/64658_P398_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64658/114665897/64658_P398_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/65k/64658/114665897/64658_P398_IMG_07_0000.jpeg"
        ],
        "price": [
            {
                "value": 750000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 2,
        "address": "Ivanhoe Road, East Dulwich, London, SE5",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.07913,
            "lat": 51.46498
        },
        "_place": 4.03,
        "_people": 32.14,
        "_property": 3,
        "_price": 180.1,
        "map": property_1010
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116505392#",
        "title": "2 bedroom terraced house",
        "images": [
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/75k/74669/116505392/74669_388793_IMG_19_0000.jpeg"
        ],
        "price": [
            {
                "value": 125000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": null,
        "address": "The Cloisters, Wingate, Durham, TS28 5PT",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.37758,
            "lat": 54.7361
        },
        "_place": 7.28,
        "_people": 33.76,
        "_property": 2,
        "_price": 193.21,
        "map": property_1011
    },
    {
        "url": "https://www.rightmove.co.uk/properties/104733905#",
        "title": "Land",
        "images": [
            "https://media.rightmove.co.uk/153k/152222/104733905/152222_10862880_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/153k/152222/104733905/152222_10862880_IMG_01_0001.jpeg",
            "https://media.rightmove.co.uk/153k/152222/104733905/152222_10862880_IMG_02_0001.jpeg",
            "https://media.rightmove.co.uk/153k/152222/104733905/152222_10862880_IMG_03_0001.jpeg",
            "https://media.rightmove.co.uk/153k/152222/104733905/152222_10862880_IMG_04_0001.jpeg",
            "https://media.rightmove.co.uk/153k/152222/104733905/152222_10862880_IMG_05_0001.jpeg"
        ],
        "price": [
            {
                "value": 150000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": null,
        "bathrooms": null,
        "address": "Building Plot, Pilots Retreat, St. Mary's Isles Of Scilly",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -6.307618,
            "lat": 49.914165
        },
        "_place": 7.89,
        "_people": 18.62,
        "_property": 1,
        "_price": 100.61,
        "map": property_1012
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116994524#",
        "title": "4 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/49k/48916/116994524/48916_WAS180091_IMG_17_0000.jpeg"
        ],
        "price": [
            {
                "value": 1185000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 1,
        "address": "Chetwode Road, London, SW17",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.159454,
            "lat": 51.436958
        },
        "_place": 0.4,
        "_people": 31.61,
        "_property": 4,
        "_price": 186.97,
        "map": property_1013
    },
    {
        "url": "https://www.rightmove.co.uk/properties/82669818#",
        "title": "4 bedroom link detached house",
        "images": [
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_09_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_10_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_11_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_12_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_13_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_14_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_15_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_16_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_17_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_18_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_19_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_20_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_21_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_22_0001.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12195/82669818/12195_31125746_IMG_25_0000.jpeg"
        ],
        "price": [
            {
                "value": 525000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Lagonda Close, Newport Pagnell, Milton Keynes",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.713128,
            "lat": 52.085652
        },
        "_place": 11.27,
        "_people": 29.39,
        "_property": 2,
        "_price": 98.4,
        "map": property_1014
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112191878#",
        "title": "2 bedroom end of terrace house",
        "images": [
            "https://media.rightmove.co.uk/224k/223541/112191878/223541_102623003530_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223541/112191878/223541_102623003530_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223541/112191878/223541_102623003530_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223541/112191878/223541_102623003530_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223541/112191878/223541_102623003530_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223541/112191878/223541_102623003530_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223541/112191878/223541_102623003530_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223541/112191878/223541_102623003530_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223541/112191878/223541_102623003530_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223541/112191878/223541_102623003530_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223541/112191878/223541_102623003530_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/224k/223541/112191878/223541_102623003530_IMG_11_0000.jpeg"
        ],
        "price": [
            {
                "value": 170000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 1,
        "address": "Lower Olland Street, Bungay",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": 1.43937,
            "lat": 52.45338
        },
        "_place": 1.22,
        "_people": 27.59,
        "_property": 2,
        "_price": 183.67,
        "map": property_1015
    },
    {
        "url": "https://www.rightmove.co.uk/properties/117110384#",
        "title": "3 bedroom semi-detached house",
        "images": [
            "https://media.rightmove.co.uk/13k/12854/117110384/12854_31140169_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12854/117110384/12854_31140169_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12854/117110384/12854_31140169_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12854/117110384/12854_31140169_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12854/117110384/12854_31140169_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12854/117110384/12854_31140169_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12854/117110384/12854_31140169_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12854/117110384/12854_31140169_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12854/117110384/12854_31140169_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12854/117110384/12854_31140169_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12854/117110384/12854_31140169_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12854/117110384/12854_31140169_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12854/117110384/12854_31140169_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12854/117110384/12854_31140169_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12854/117110384/12854_31140169_IMG_00_0000.jpeg"
        ],
        "price": [
            {
                "value": 300000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 3,
        "bathrooms": 1,
        "address": "Freshwater",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.51757,
            "lat": 50.682399
        },
        "_place": 0.01,
        "_people": 34.34,
        "_property": 3,
        "_price": 191.83,
        "map": property_1016
    },
    {
        "url": "https://www.rightmove.co.uk/properties/83860606#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_01_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_02_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_03_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_04_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_05_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_06_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_07_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_08_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_09_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_10_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_11_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_12_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_13_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_14_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_15_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_16_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_17_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_18_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_19_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_20_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_21_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_22_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_23_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_24_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_25_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_26_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_27_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_28_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_29_0000.jpg",
            "https://media.rightmove.co.uk/62k/61544/83860606/61544_4866400_IMG_30_0000.jpg"
        ],
        "price": [
            {
                "value": 500000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": null,
        "address": "Chapel Lane, Oxton, Southwell, Nottinghamshire",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.061484,
            "lat": 53.059306
        },
        "_place": 1.87,
        "_people": 20.43,
        "_property": 4,
        "_price": 190.86,
        "map": property_1017
    },
    {
        "url": "https://www.rightmove.co.uk/properties/112082729#",
        "title": "5 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/13k/12228/112082729/12228_103066009809_IMG_29_0000.jpeg"
        ],
        "price": [
            {
                "value": 695000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 5,
        "bathrooms": 2,
        "address": "The Green, Ulrome",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -0.23047,
            "lat": 53.993301
        },
        "_place": 9.05,
        "_people": 20.28,
        "_property": 0,
        "_price": 176.26,
        "map": property_1018
    },
    {
        "url": "https://www.rightmove.co.uk/properties/110000603#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_24_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_25_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_26_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_27_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_28_0000.jpeg",
            "https://media.rightmove.co.uk/141k/140027/110000603/140027_30785167_IMG_29_0000.jpeg"
        ],
        "price": [
            {
                "value": 550000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Colwell, Hexham",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -2.074267,
            "lat": 55.070285
        },
        "_place": 5.91,
        "_people": 26.32,
        "_property": 4,
        "_price": 185.02,
        "map": property_1019
    },
    {
        "url": "https://www.rightmove.co.uk/properties/116993723#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/98k/97841/116993723/97841_AIC210226_IMG_21_0000.jpeg"
        ],
        "price": [
            {
                "value": 615000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 4,
        "address": "Myers Croft, Horbury, Wakefield, West Yorkshire, WF4",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.55527,
            "lat": 53.65614
        },
        "_place": 4.18,
        "_people": 21.56,
        "_property": 5,
        "_price": 125.25,
        "map": property_1020
    },
    {
        "url": "https://www.rightmove.co.uk/properties/114747320#",
        "title": "4 bedroom detached house",
        "images": [
            "https://media.rightmove.co.uk/2k/1673/114747320/1673_EGH307253_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1673/114747320/1673_EGH307253_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1673/114747320/1673_EGH307253_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1673/114747320/1673_EGH307253_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/2k/1673/114747320/1673_EGH307253_IMG_04_0000.jpeg"
        ],
        "price": [
            {
                "value": 625000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 4,
        "bathrooms": 2,
        "address": "Ash Copse, Bishopstoke, Eastleigh",
        "garden": true,
        "comments": [],
        "coordinates": {
            "long": -1.33032,
            "lat": 50.97911
        },
        "_place": 7.18,
        "_people": 20.95,
        "_property": 4,
        "_price": 141.46,
        "map": property_1021
    },
    {
        "url": "https://www.rightmove.co.uk/properties/113961785#",
        "title": "2 bedroom flat",
        "images": [
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_00_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_01_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_06_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_03_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_10_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_04_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_02_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_08_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_09_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_21_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_11_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_17_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_05_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_12_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_07_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_13_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_14_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_15_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_16_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_18_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_19_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_20_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_22_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_23_0000.jpeg",
            "https://media.rightmove.co.uk/52k/51770/113961785/51770_30977821_IMG_24_0000.jpeg"
        ],
        "price": [
            {
                "value": 260000,
                "date": "Sat Dec 18 2021"
            }
        ],
        "bedrooms": 2,
        "bathrooms": 2,
        "address": "Rickfords Hill, Aylesbury",
        "garden": false,
        "comments": [],
        "coordinates": {
            "long": -0.814921,
            "lat": 51.81623
        },
        "_place": 2.5,
        "_people": 32.34,
        "_property": 5,
        "_price": 162.32,
        "map": property_1022
    }
]

export default data