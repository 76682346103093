import React from 'react';
import Footer from '../footer/footer';
import logo from "../../assets/img/logo.png"
import history from '../../history';

export default function Thanks(props) {
    return (
        <div className="thanks">
            <div className="contain">
                <div className="heading">
                    <img className="logo" src={logo} alt="Sprung" />
                </div>

                <div className="content">
                    Thank you so much for applying for our product, we will reach out to you as soon as it becomes available.
                </div>

                <button onClick={() => history.push("/")}>
                    Back to home
                </button>
            </div>

            <Footer />
        </div>
    );
}