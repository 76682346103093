import React, { useEffect, useState } from 'react';
import superagent from 'superagent';
import Chart from 'chart.js/auto'
import Age from './age';
import Adverse from './adverse';
import UkNationals from './uk_nationals';
import Income from './income';
import DateJoined from './date_joined';
import NumberApplicants from './number_applicants';
import EmploymentType from './employment_type';
import Dependants from './dependants';
import AdversType from './adverse_type';
import Deposit from './deposit';
import DepositVLTV from './deposit_v_ltv';
import MaxVLTV from './max_v_ltv';
import MaxVDeposit from './max_v_deposit';

export default function Charts(props) {
    const [data, setData] = useState([])
    const [appData, setAppData] = useState([])
    const [incomeBucket, setIncomeBucket] = useState(10000)
    const [depositBucket, setDepositBucket] = useState(10000)
    const [userBucket, setUserBucket] = useState("week")

    useEffect(() => {
        superagent.get(process.env.REACT_APP_FREE_API + "/mortgage/all")
            .then(res => {
                if (res.body.success) {
                    setData(res.body.data.filter(e => e.data.referrer === "sprung"))
                }
            })
            .catch(err => {
                console.log(err)
            })

        superagent.post(process.env.REACT_APP_FREE_API + "/application/all")
            .then(res => {
                if (res.body.success) setAppData(res.body.data.filter(e => {
                    return e.data.lender === "Sprung"
                }))
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <div className="charts" style={{ paddingBottom: "150px" }}>
            LTV against deposit of applications
            <DepositVLTV data={appData} />

            <br /><br />
            LTV against max loan amount of applications
            <MaxVLTV data={appData} />

            <br /><br />
            Deposit against max loan amount of applications
            <MaxVDeposit data={appData} />

            <br /><br />
            Age
            <Age data={data} />

            <br /><br />
            Adverse Credit
            <Adverse data={data} />

            <br /><br />
            Type of Adverse Credit
            <AdversType data={data} />

            <br /><br />
            Nationality
            <UkNationals data={data} />

            <br /><br />
            Income
            <br />
            <select value={incomeBucket} onChange={e => {
                setIncomeBucket(e.currentTarget.value)
            }}>
                <option value={10000}>10000</option>
                <option value={20000}>20000</option>
                <option value={30000}>30000</option>
                <option value={40000}>40000</option>
                <option value={50000}>50000</option>
                <option value={100000}>100000</option>
            </select>
            <Income data={data} bucket={incomeBucket} />

            <br /><br />
            New Users
            <br />
            <select value={userBucket} onChange={e => {
                setUserBucket(e.currentTarget.value)
            }}>
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
            </select>
            <DateJoined data={data} bucket={userBucket} />

            <br /><br />
            Number of applicants
            <NumberApplicants data={data} />

            <br /><br />
            Employment type
            <EmploymentType data={data} />

            <br /><br />
            Number of dependants
            <Dependants data={data} />

            <br /><br />
            Deposit Amount
            <br />
            <select value={depositBucket} onChange={e => {
                setDepositBucket(e.currentTarget.value)
            }}>
                <option value={10000}>10000</option>
                <option value={20000}>20000</option>
                <option value={30000}>30000</option>
                <option value={40000}>40000</option>
                <option value={50000}>50000</option>
                <option value={100000}>100000</option>
            </select>
            <Deposit data={data} bucket={depositBucket} />
        </div>
    );
}