import React, { useEffect, useState } from 'react';
import Property from './property';

export default function Properties(props) {
    const [scroll, setScroll] = useState(10)
    let count = 0
    const controller = new AbortController();

    function elementInViewport2(el) {
        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top < (window.pageYOffset + window.innerHeight) &&
            left < (window.pageXOffset + window.innerWidth) &&
            (top + height) > window.pageYOffset &&
            (left + width) > window.pageXOffset
        );
    }

    useEffect(() => {
        window.addEventListener('scroll', e => {
            handleScroll()
        }, { signal: controller.signal })

        return () => {
            window.removeEventListener("scroll", () => { })
        }
    }, [scroll])

    function handleScroll() {
        console.log(scroll)
        let bottom = elementInViewport2(document.getElementById("property_" + scroll))
        if (bottom) {
            controller.abort()
            setScroll(scroll + 10)
        }
    }

    return (
        <div className='properties'>
            <div className='head'>
                <div className='first'>

                </div>
                <div className='second'>
                    People
                </div>
                <div className='third'>
                    Place
                </div>
                <div className='fourth'>
                    Property
                </div>
                <div className='fifth'>
                    Price
                </div>
            </div>

            {props.data.sort((a, b) => {
                return b._price - a._price
            }).slice(0, scroll).map((e, n) => {
                count += e.price[0].value
                return <div id={"property_" + (n + 1)} key={n}><Property n={n} e={e} disabled={count > props.input * 1000000} /></div>
            })}

            <div className='spinner' style={{display: scroll >= props.data.length ? "none" : ""}}></div>
        </div>
    );
}