import React from 'react';
import Footer from '../footer/footer';
import Mailing from '../mailing/mailing';
import CTA from './cta/cta';
import Reviews from './reviews/reviews';
import Story from './story/story';

import climbers from "../../assets/img/climbers-2.png"
import watch from "../../assets/img/watch.png"
import chain from "../../assets/img/watch_chain.png"

export default function Landing(props) {
    return (
        <div className="landing">
            <CTA />

            <img className="climbers" src={climbers} alt="Climbing" />

            <Reviews />

            <img className="chain" src={chain} alt="Watch Chain" />

            <Story />

            <img className="watch" src={watch} alt="Watch" />

            <Mailing />
            <Footer />
        </div>
    );
}