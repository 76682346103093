import superagent from "superagent"

let url = process.env.REACT_APP_API_URL

const api = {
    get: (path, params) => {
        return superagent.get(url + path).query(params).then(res => { return res }).catch(err => console.log(err))
    },
    post: (path, body) => {
        return superagent.post(url + path).send(body).then(res => { return res }).catch(err => console.log(err))
    },
    delete: (path, body) => {
        return superagent.delete(url + path).send(body).then(res => { return res }).catch(err => console.log(err))
    },
    put: (path, body) => {
        return superagent.put(url + path).send(body).then(res => { return res }).catch(err => console.log(err))
    },
    patch: (path, body) => {
        return superagent.patch(url + path).send(body).then(res => { return res }).catch(err => console.log(err))
    }
}

let user = {
    current: (token) => { return api.post("/user/current", { token }) },
    create: (first_name, last_name, phone_number) => { return api.post("/user/create", { first_name, last_name, phone_number }) },
    delete: (id) => { return api.delete("/user/delete", { id }) }
}

let verify = {
    send: (phone_number) => { return api.post("/verify/send", { phone_number }) },
    verify: (id, token) => { return api.post("/verify/verify", { id, token }) }
}

let mailing = {
    add: (first_name, last_name, email) => { return api.post("/mailing/add", { first_name, last_name, email }) }
}

let agent = {
    user,
    verify,
    mailing
}

export default agent