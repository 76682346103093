import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import create_set from './create_set';
import getAge from './get_age';

export default function Age(props) {
    const [data, setData] = useState(props.data)
    const [dataset, setDataset] = useState([])
    const [min, setMin] = useState(18)
    const [max, setMax] = useState(80)

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        let obj = {}
        data.map((e, n) => {
            return getAge(e.data.date_of_birth)
        }).forEach(e => {
            if (!obj[e]) obj[e] = 1
            else obj[e] += 1
        })

        let arr = []
        let canSet = true
        for (let i = 18; i < 80; i++) {
            if (obj[i]) {
                if(canSet) setMin(i - 2)
                canSet = false
                setMax(i + 2)
                arr.push({ x: i, y: obj[i] })
            } else {
                arr.push({ x: i, y: 0 })
            }
        }

        setDataset(arr.sort((a, b) => {
            if (a.x > b.x) return 1
            else if (b.x > a.x) return -1
            else return 0
        }))
    }, [data])

    return (
        <Bar data={{
            labels: create_set(min, max),
            datasets: [
                {
                    label: 'Number of people in age group',
                    data: [...dataset],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }} />
    );
}