import history from "./history";
import { Router, Switch, Route, Redirect } from "react-router";

import Landing from "./components/landing";

import store from './store/store'
import { Provider } from 'react-redux'

import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import Mortgage from "./components/mortgage/mortgage";
import Thanks from "./components/thanks/thanks";
import Charts from "./components/charts";
import Pipeline from "./components/pipeline/pipeline";

let persistor = persistStore(store);

export default function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/apply" component={Mortgage} />
              <Route exact path="/thanks" component={Thanks} />
              <Route exact path="/charts" component={Charts} />
              <Route exact path="/pipeline" component={Pipeline} />
              <Redirect from="*" to="/" />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </div>
  );
}