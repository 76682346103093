import React, { useEffect, useState } from 'react';
import { Scatter } from 'react-chartjs-2';

export default function MaxVLTV(props) {
    const [data, setData] = useState(props.data)
    const [dataset, setDataset] = useState([])

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        let arr = []
        data.map((e, n) => {
            return [e.data.max, e.data.ltv]
        }).forEach(e => {
            arr.push({ x: parseInt(e[0].replace(/,/g, "")), y: e[1] })
        })

        setDataset(arr.sort((a, b) => {
            if (a.x > b.x) return 1
            else if (b.x > a.x) return -1
            else return 0
        }))
    }, [data])

    return (
        <Scatter data={{
            datasets: [
                {
                    label: 'Deposit against LTV',
                    data: [...dataset],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ]
        }} />
    );
}