import commaNumber from 'comma-number';
import React from 'react';
import Modal from '../tools/modal/modal';
import toggle_modal from '../tools/modal/toggle_modal';
import person from "../../assets/img/person.png"
import { Bar } from 'react-chartjs-2';

export default function Property(props) {
    return (
        <>
            <div className='property' style={{ filter: props.disabled ? "grayscale(1)" : "", backgroundColor: props.disabled ? "rgb(238, 238, 238)" : "" }}>
                <div className='first'>
                    <img src={props.e.images[0]} alt="House" />
                    <div className='div'>
                        <div className='link' onClick={() => toggle_modal(props.e.url)}>{props.e.address.split(",")[0]}</div>
                        <div><b>£{commaNumber(props.e.price[0].value)}</b></div>
                        <div className='title'>{props.e.title} in {props.e.address.split(",")[props.e.address.split(",").length - 1]}</div>
                    </div>
                </div>
                <div className='second'>
                    {props.e._people}%
                    <div className='small_text'>Of income</div>
                </div>
                <div className='third'>
                    {props.e._place}%
                    <div className='small_text'>Growth p.a.</div>
                </div>
                <div className='fourth'>
                    {props.e._property}/5
                    <div className='small_text'>Desireability</div>
                </div>
                <div className='fifth'>
                    {props.e._price}%
                    <div className='small_text'>Relative yield</div>
                </div>
            </div>
            <Modal id={props.e.url} x backgroundClose>
                <div className='block'>
                    <div className="block_title">
                        Property
                    </div>
                    <div className='column1'>
                        <img src={props.e.images[0]} alt="House" />
                        <div className='details'>
                            <div className='detail extra'>
                                <b>{props.e.bedrooms || 0}</b> bedrooms
                            </div>
                            <div className='detail'>
                                <b>67 m²</b>
                            </div>
                            <div className='detail'>
                                Leasehold
                            </div>
                        </div>
                        <div className='details'>
                            <div className='detail extra'>
                                <b>{props.e.bathrooms || 0}</b> bathrooms
                            </div>
                            <div className='detail'>
                                <b>54 m³/p</b>
                            </div>
                            <div className='detail'>
                                £100 pcm
                            </div>
                        </div>
                        <hr size="1" />
                        <div className='things'>
                            No Majors
                        </div>
                        <hr size="1" />
                        <div className='things'>
                            No Minors
                        </div>
                    </div>
                    <div className='column2'>
                        <div className='title_bar'>
                            <div className='title'>
                                {props.e.address.split(",")[0]}
                            </div>
                            <div className='sub_title'>
                                {props.e.title}
                            </div>
                            <div className='score'>
                                <div className='value'>{props.e._property}/5</div>
                                Desirability
                            </div>
                        </div>

                        <div className='columns'>
                            <div className='flex1'>
                                <div className='list'>
                                    <div className='list_title'>
                                        Structural checks
                                    </div>
                                    <ul>
                                        <li>Chimney stack and flashing check</li>
                                        <li>Roofs and valleys check</li>
                                        <li>Roof void check</li>
                                        <li>Rainwater goods check</li>
                                        <li>External walls, elevation check</li>
                                        <li>Damp proof coursing check</li>
                                        <li>Sub floor ventilation check</li>
                                        <li>External joinery check</li>
                                        <li>External decorations check</li>
                                    </ul>
                                </div>
                                <div className='list'>
                                    <div className='list_title'>
                                        Property risk
                                    </div>
                                    <ul>
                                        <li>Listed building status</li>
                                        <li>Property tenure risk</li>
                                        <li>Year built risk</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='flex1'>
                                <div className='list'>
                                    <div className='list_title'>
                                        Internal checks
                                    </div>
                                    <ul>
                                        <li>Ceilings and floors check</li>
                                        <li>Internal walls and partitions check</li>
                                        <li>Fireplaces chimney check</li>
                                        <li>Dampness, condensation check</li>
                                        <li>Timber decay, infestation check</li>
                                        <li>Thermal insulation check</li>
                                    </ul>
                                </div>
                                <div className='list'>
                                    <div className='list_title'>
                                        Valuation risk
                                    </div>
                                    <ul>
                                        <li>Valuation model risk</li>
                                        <li>House price index risk</li>
                                        <li>Financial worthiness</li>
                                        <li>Price outlier risk</li>
                                        <li>Floor area risk</li>
                                        <li>Discount percentage</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='flex1'>
                                <div className='list'>
                                    <div className='list_title'>
                                        Services risk
                                    </div>
                                    <ul>
                                        <li>Gas supply risk</li>
                                        <li>Electricity supply risk</li>
                                        <li>Cold water supply risk</li>
                                        <li>Hot water supply risk</li>
                                        <li>Space heating supply risk</li>
                                        <li>Sanitary fittings supply risk</li>
                                        <li>Drainage &amp; sewage risk</li>
                                    </ul>
                                </div>
                                <div className='list'>
                                    <div className='list_title'>
                                        Environment risk
                                    </div>
                                    <ul>
                                        <li>Property flooding risk</li>
                                        <li>Property subsidence risk</li>
                                        <li>Property fracking risk</li>
                                        <li>Property HS2 risk</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='block'>
                    <div className="block_title">
                        Place
                    </div>
                    <div className='column1'>
                        <img src={props.e.map} alt="map" />
                        <div className='details'>
                            <div className='detail wide'>
                                Number of properties
                            </div>
                            <div className='detail right'>
                                363
                            </div>
                        </div>
                        <hr />
                        <div className='details'>
                            <div className='detail wide'>
                                1yr market volume
                            </div>
                            <div className='detail right'>
                                £225,473,904
                            </div>
                        </div>
                        <hr />
                        <div className='details'>
                            <div className='detail wide'>
                                Average rental yield
                            </div>
                            <div className='detail right'>
                                4.18%
                            </div>
                        </div>
                        <hr />
                        <div className='details'>
                            <div className='detail wide'>
                                5yr capital appreciation
                            </div>
                            <div className='detail right'>
                                6.50%*
                            </div>
                        </div>
                        <hr />
                        <div className='note'>
                            * Based on land registry data, not predictive of future results
                        </div>
                    </div>
                    <div className='column2'>
                        <div className='title_bar'>
                            <div className='title'>
                                {props.e.address.split(",")[1]}, {props.e.address.split(",")[props.e.address.split(",").length - 1]}
                            </div>
                            <div className='sub_title'>
                                {props.e.address.split(",")[props.e.address.split(",").length - 2]}
                            </div>
                            <div className='score'>
                                <div className='value'>{props.e._place}%</div>
                                Growth p.a.
                            </div>
                        </div>

                        <div className='columns'>
                            <div className='flex2'>
                                <Bar data={{
                                    labels: ["270000", "360000", "450000", "540000", "630000", "720000", "810000", "900000", "990000", "1080000"],
                                    datasets: [
                                        {
                                            label: `Land Registry Price Distribution in 2016 for ${props.e.address.split(",")[props.e.address.split(",").length - 1]}`,
                                            data: [
                                                { x: "270000", y: 5 },
                                                { x: "360000", y: 10 },
                                                { x: "450000", y: 32 },
                                                { x: "540000", y: 26 },
                                                { x: "630000", y: 49 },
                                                { x: "720000", y: 35 },
                                                { x: "810000", y: 54 },
                                                { x: "900000", y: 37 },
                                                { x: "990000", y: 57 },
                                                { x: "1080000", y: 38 }
                                            ],
                                            backgroundColor: [
                                                'rgb(218, 218, 218)',
                                                'rgb(252, 55, 105)',
                                                'rgb(218, 218, 218)',
                                                'rgb(218, 218, 218)',
                                                'rgb(218, 218, 218)',
                                                'rgb(218, 218, 218)',
                                                'rgb(218, 218, 218)',
                                                'rgb(218, 218, 218)',
                                                'rgb(218, 218, 218)'
                                            ]
                                        },
                                    ],
                                }} />
                            </div>
                            <div className='flex1'>
                                <div className='list'>
                                    <div className='list_title'>
                                        Local area
                                    </div>
                                    <ul>
                                        <li>Number of schools</li>
                                        <li>School quality comparison</li>
                                        <li>Crime rate comparison</li>
                                        <li>Access to motorways</li>
                                        <li>Access to train network</li>
                                        <li>Access to airports</li>
                                    </ul>
                                </div>
                                <div className='list'>
                                    <div className='list_title'>
                                        Population
                                    </div>
                                    <ul>
                                        <li>Education level</li>
                                        <li>Economic activity</li>
                                        <li>Skilled professionals</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='block'>
                    <div className="block_title">
                        People
                    </div>
                    <div className='column1 flex1' style={{ overflow: "hidden" }}>
                        <img src={person} alt="Person" style={{ height: "100%", width: "auto", borderBottomLeftRadius: "5px" }} />
                    </div>
                    <div className='column2 flex4'>
                        <div className='title_bar'>
                            <div className='title'>
                                Potential homeowner
                            </div>
                            <div className='sub_title'>
                                28% salary is rent - | Deposit - £23,443 (6.7%)
                            </div>
                            <div className='score'>
                                <div className='value'>{props.e._people}%</div>
                                Of income
                            </div>
                        </div>

                        <div className='columns'>
                            <div className="flex1">
                                <div className='list'>
                                    <div className='list_title'>
                                        Reliable tenant
                                    </div>
                                    <ul>
                                        <li>Landlord reference</li>
                                        <li>Rental payment history</li>
                                        <li>Right to rent check</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex1">
                                <div className='list'>
                                    <div className='list_title'>
                                        Background checks
                                    </div>
                                    <ul>
                                        <li>Employee reference</li>
                                        <li>Employment history</li>
                                        <li>Income verification</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex1">
                                <div className='list'>
                                    <div className='list_title'>
                                        Financially stable
                                    </div>
                                    <ul>
                                        <li>Full credit check</li>
                                        <li>Deposit verification</li>
                                        <li>Affordability check</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex1">
                                <div className='list'>
                                    <div className='list_title'>
                                        Suitability
                                    </div>
                                    <ul>
                                        <li>Had mortgage advice</li>
                                        <li>Onboarding phone call</li>
                                        <li>Sourcing accuracy</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='block'>
                    <div className="block_title">
                        Price
                    </div>
                    <div className='column1 flex1 row'>
                        <div className='bar'>
                            <div style={{ flex: "5.8" }}></div>
                            <div style={{ flex: "4.2", backgroundColor: "rgb(218, 218, 218)", display: "flex", justifyContent: "space-between", flexDirection: "column", padding: "10px 0px", borderBottomLeftRadius: "5px" }}>
                                <div className='top'>
                                    4.2%
                                </div>
                                <div className='bottom'>
                                    Market yield
                                </div>
                            </div>
                        </div>
                        <div className='bar'>
                            <div style={{ flex: "2.1" }}></div>
                            <div style={{ flex: "7.9", backgroundColor: "rgb(252, 55, 105)", display: "flex", justifyContent: "space-between", flexDirection: "column", padding: "10px 0px", color: "white" }}>
                                <div className='top'>
                                    7.9%
                                </div>
                                <div className='bottom'>
                                    Property yield
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='column2 flex4'>
                        <div className='title_bar'>
                            <div className='title'>
                                Asking price £{commaNumber(props.e.price[0].value)}
                            </div>
                            <div className='sub_title'>
                                Annual rent £27,600 | £2,300 pcm
                            </div>
                            <div className='score'>
                                <div className='value'>{Math.round(props.e._price)}%</div>
                                Relative yield
                            </div>
                        </div>

                        <div className='columns'>
                            <div className='flex1'>
                                <div className='top'>
                                    Fair sales price
                                </div>
                                <div className='middle'>
                                    <div>Current price</div>
                                    <div>£{commaNumber(props.e.price[0].value)}</div>
                                </div>
                                <div className='bottom'>
                                    <div>RICS valuation</div>
                                    <div>£{commaNumber(props.e.price[0].value * 1.12)}</div>
                                </div>
                            </div>
                            <div className='flex1'>
                                <div className='top'>
                                    Strong rental income
                                </div>
                                <div className='middle'>
                                    <div>Market rent (comps)</div>
                                    <div>£2,300</div>
                                </div>
                                <div className='bottom'>
                                    <div>Rightmove AVM</div>
                                    <div>£2,415</div>
                                </div>
                            </div>
                            <div className='flex1'>
                                <div className='top'>
                                    Negotiation potential
                                </div>
                                <div className='middle'>
                                    <div>Vendor position</div>
                                    <div>weak</div>
                                </div>
                                <div className='bottom'>
                                    <div>Time on market</div>
                                    <div>50 days</div>
                                </div>
                            </div>
                            <div className='flex1'>
                                <div className='top'>
                                    Relative yield
                                </div>
                                <div className='middle'>
                                    <div>Property yield</div>
                                    <div>£7.89%</div>
                                </div>
                                <div className='bottom'>
                                    <div>Local market yield</div>
                                    <div>4.18%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}