import React from 'react';
import Review from './review';
import john from "../../../assets/img/john.png"
import james from "../../../assets/img/james.png"
import jane from "../../../assets/img/jane.png"
import judy from "../../../assets/img/judy.png"
import nishanddimple from "../../../assets/img/nishanddimple-2.png"

export default function Reviews(props) {
    return (
        <div className="reviews">
            <div className="contain">
                <div className="heading">
                    How We <span className="underline">Help</span><br />First Time Buyers
                </div>

                <Review img={jane} name="Emily" issue="Single Mum" comment="Emily pays more in rent than what a mortgage would cost. But saving for a bigger deposit just isn’t possible. Sprung helps Emily escape renting and higher housing costs. Sooner." />
                <Review img={john} name="Eden" issue="Self Employed" comment="Eden earns more as a contractor. But because the bank’s computer says no, he’s frustrated by a mortgage process that sends him around the bend. Sprung recognises Eden’s income using open banking, making the process of buying a home smooth, simple and safe." />
                {/* <Review img={james} name="James" issue="Low Income" comment="James has a stable income but can’t afford to buy what he wants, and doesn’t want to buy a new build. Sprung can help James buy the property he wants today." /> */}
                <Review img={nishanddimple} name="Nish and Dimple" issue="Young Family" comment="It’s hard to save enough when you have to raise young children. There’s an endless list of things that need paying for. Sprung helps reduce the housing costs for young families by getting them into ownership much sooner. One thing less to worry about." />
            </div>
        </div>
    );
}