import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import create_date_set from './create_date_set';
require("datejs")

export default function DateJoined(props) {
    const [data, setData] = useState(props.data)
    const [dataset, setDataset] = useState([])
    const [min, setMin] = useState(null)
    const [max, setMax] = useState(null)
    const [bucket, setBucket] = useState(props.bucket)

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        setBucket(props.bucket)
    }, [props.bucket])

    useEffect(() => {
        let obj = create_date_set("Fri Jul 09 2021", new Date().toDateString(), bucket, "object")

        if (bucket === "day") {
            for (let i = 0, size = data.length; i < size; i++) {
                let date = new Date(data[i].dateCached).toDateString()
                if (obj[date]) obj[date] += 1
                else obj[date] = 1
            }
        } else if (bucket === "week") {
            for (let i = 0, size = data.length; i < size; i++) {
                let date = new Date(data[i].dateCached)
                date.setDate(date.getDate() - (date.getDay() + 6) % 7);
                date = date.toDateString()
                if (obj[date]) obj[date] += 1
                else obj[date] = 1
            }
        } else if(bucket === "month") {
            for (let i = 0, size = data.length; i < size; i++) {
                let date = new Date(data[i].dateCached)
                date.setDate(1)
                date = date.toString("MMMM yyyy")
                if (obj[date] && date) obj[date] += 1
                else if(date) obj[date] = 1
            }
        } else if(bucket === "year") {
            for (let i = 0, size = data.length; i < size; i++) {
                let date = new Date(data[i].dateCached)
                date = date.toString("yyyy")
                if (obj[date] && date) obj[date] += 1
                else if(date) obj[date] = 1
            }
        }

        let arr = Object.entries(obj).map(e => {
            return { x: e[0], y: e[1] }
        }).sort((a, b) => {
            if (a.x > b.y) return 1
            else if (a.x < b.y) return -1
            else return 0
        })

        setMin(arr[0].x)
        setMax(arr[arr.length - 1].x)
        setDataset(arr)
    }, [data, bucket])

    return (
        <Line data={{
            labels: create_date_set(min, max, bucket, "label"),
            datasets: [
                {
                    label: `Date joined by ${bucket}`,
                    data: dataset,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }} />
    );
}