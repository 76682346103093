import moment from 'moment'
require("datejs")
moment().format()

function format_data(data) {
    let total_income = 0

    let first = {
        first_name: data.first_name,
        last_name: data.last_name,
        date_of_birth: data.date_of_birth,
        dependants: data.dependants,
        employment: data.employment,
        additional_income: data.additional_income,
        credit_card_balance: data.credit_card_balance,
        adverse_credit: data.adverse_credit,
    }

    total_income += parseInt(data.additional_income) || 0

    if (data.employment === "EMPLOYED") { first.employment_details = { type: data.employment_contract, income: data.annual_income, deductions: data.deductions }; total_income += data.annual_income }
    if (data.employment === "SELF_EMPLOYED") { first.employment_details = { type: data.self_employment, years: data.self_employed_years_in_business, net_profit_19_20: data.self_employed_net_profit_19_20, net_profit_20_21: data.self_employed_net_profit_20_21 }; total_income += ((data.self_employed_net_profit_19_20 + data.self_employed_net_profit_20_21) / 2) }
    if (data.employment === "COMPANY_DIRECTOR") { first.employment_details = { type: data.shareholding_of_company, years: data.company_director_years_in_business, net_profit_19_20: data.company_director_net_profit_19_20, net_profit_20_21: data.company_director_net_profit_20_21, operating_profit_19_20: data.company_director_operating_profit_19_20, operating_profit_20_21: data.company_director_operating_profit_20_21 }; total_income += ((data.company_director_net_profit_19_20 + data.company_director_net_profit_20_21) / 2) }
    if (data.employment === "CONTRACTOR") { first.daily_rate = data.contractor_rate; total_income += data.contractor_rate * 5 * 47 }

    if (data.adverse_credit === "YES") first.adverse_credit_details = { had_a_ccj: data.had_a_ccj, had_a_default: data.had_a_default, had_a_missed_secured_payment: data.had_a_missed_secured_payment, had_a_missed_unsecured_payment: data.had_a_missed_unsecured_payment, had_a_payday_loan: data.had_a_payday_loan, been_bankrupt: data.been_bankrupt, had_a_iva: data.had_a_iva, had_a_dmp: data.had_a_dmp, had_a_repossession: data.had_a_repossession }
    if (data.adverse_credit === "YES" && data.had_a_ccj === "YES") {
        first.adverse_credit_details.ccjs = {}
        first.adverse_credit_details.ccjs.raw = data.ccjs
        let last_6 = { number: 0, total_amount: 0 }, last_12 = { number: 0, total_amount: 0 }, last_24 = { number: 0, total_amount: 0 }, last_36 = { number: 0, total_amount: 0 }, gt_36 = { number: 0, total_amount: 0 }, ongoing = { number: 0, total_amount: 0 }
        for (let i = 0; i < data.ccjs.length; i++) {
            if (data.ccjs[i].ccj_settled !== "ONGOING") {
                let months_since = moment().diff(moment(data.ccjs[i].ccj_settled), "months")
                if (months_since >= 0 && months_since < 6) { last_6.number++; last_6.total_amount += data.ccjs[i].ccj_amount }
                else if (months_since >= 6 && months_since < 12) { last_12.number++; last_12.total_amount += data.ccjs[i].ccj_amount }
                else if (months_since >= 12 && months_since < 24) { last_24.number++; last_24.total_amount += data.ccjs[i].ccj_amount }
                else if (months_since >= 24 && months_since < 36) { last_36.number++; last_36.total_amount += data.ccjs[i].ccj_amount }
                else if (months_since >= 36) { gt_36.number++; gt_36.total_amount += data.ccjs[i].ccj_amount }
                first.adverse_credit_details.ccjs.last_6 = last_6
                first.adverse_credit_details.ccjs.last_12 = last_12
                first.adverse_credit_details.ccjs.last_24 = last_24
                first.adverse_credit_details.ccjs.last_36 = last_36
                first.adverse_credit_details.ccjs.gt_36 = gt_36
            } else {
                ongoing.number++
                ongoing.total_amount += data.ccjs[i].ccj_amount
            }
        }
    }
    if (data.adverse_credit === "YES" && data.had_a_default === "YES") {
        first.adverse_credit_details.defaults = {}
        first.adverse_credit_details.defaults.raw = data.defaults
        let last_6 = { number: 0, total_amount: 0, mobile_defaults: 0 }, last_12 = { number: 0, total_amount: 0, mobile_defaults: 0 }, last_24 = { number: 0, total_amount: 0, mobile_defaults: 0 }, last_36 = { number: 0, total_amount: 0, mobile_defaults: 0 }, gt_36 = { number: 0, total_amount: 0, mobile_defaults: 0 }, ongoing = { number: 0, total_amount: 0, mobile_defaults: 0 }
        for (let i = 0; i < data.defaults.length; i++) {
            if (data.defaults[i].default_settled !== "ONGOING") {
                let months_since = moment().diff(moment(data.defaults[i].default_settled), "months")
                if (months_since >= 0 && months_since < 6) { last_6.number++; last_6.total_amount += data.defaults[i].default_amount; if (data.defaults[i].default_for_mobile === "YES") last_6.mobile_defaults++ }
                else if (months_since >= 6 && months_since < 12) { last_12.number++; last_12.total_amount += data.defaults[i].default_amount; if (data.defaults[i].default_for_mobile === "YES") last_12.mobile_defaults++ }
                else if (months_since >= 12 && months_since < 24) { last_24.number++; last_24.total_amount += data.defaults[i].default_amount; if (data.defaults[i].default_for_mobile === "YES") last_24.mobile_defaults++ }
                else if (months_since >= 24 && months_since < 36) { last_36.number++; last_36.total_amount += data.defaults[i].default_amount; if (data.defaults[i].default_for_mobile === "YES") last_36.mobile_defaults++ }
                else if (months_since >= 36) { gt_36.number++; gt_36.total_amount += data.defaults[i].default_amount; if (data.defaults[i].default_for_mobile === "YES") gt_36.mobile_defaults++ }
                first.adverse_credit_details.defaults.last_6 = last_6
                first.adverse_credit_details.defaults.last_12 = last_12
                first.adverse_credit_details.defaults.last_24 = last_24
                first.adverse_credit_details.defaults.last_36 = last_36
                first.adverse_credit_details.defaults.gt_36 = gt_36
            } else {
                ongoing.number++
                ongoing.total_amount += data.defaults[i].default_amount
                if (data.defaults[i].default_for_mobile === "YES") ongoing.mobile_defaults++
            }
        }
    }
    if (data.adverse_credit === "YES" && data.had_a_missed_secured_payment === "YES") {
        first.adverse_credit_details.missed_secured_payments = {}
        first.adverse_credit_details.missed_secured_payments.raw = data.missed_secured_payments
        let last_6 = { number: 0, total_amount: 0 }, last_12 = { number: 0, total_amount: 0 }, last_24 = { number: 0, total_amount: 0 }, last_36 = { number: 0, total_amount: 0 }, gt_36 = { number: 0, total_amount: 0 }, ongoing = { number: 0, total_amount: 0 }
        for (let i = 0; i < data.missed_secured_payments.length; i++) {
            if (data.missed_secured_payments[i].missed_secured_payment_date !== "ONGOING") {
                let months_since = moment().diff(moment(data.missed_secured_payments[i].missed_secured_payment_date), "months")
                if (months_since >= 0 && months_since < 6) { last_6.number++; last_6.total_amount += data.missed_secured_payments[i].missed_secured_payment_amount }
                else if (months_since >= 6 && months_since < 12) { last_12.number++; last_12.total_amount += data.missed_secured_payments[i].missed_secured_payment_amount }
                else if (months_since >= 12 && months_since < 24) { last_24.number++; last_24.total_amount += data.missed_secured_payments[i].missed_secured_payment_amount }
                else if (months_since >= 24 && months_since < 36) { last_36.number++; last_36.total_amount += data.missed_secured_payments[i].missed_secured_payment_amount }
                else if (months_since >= 36) { gt_36.number++; gt_36.total_amount += data.missed_secured_payments[i].missed_secured_payment_amount }
                first.adverse_credit_details.missed_secured_payments.last_6 = last_6
                first.adverse_credit_details.missed_secured_payments.last_12 = last_12
                first.adverse_credit_details.missed_secured_payments.last_24 = last_24
                first.adverse_credit_details.missed_secured_payments.last_36 = last_36
                first.adverse_credit_details.missed_secured_payments.gt_36 = gt_36
            } else {
                ongoing.number++
                ongoing.total_amount += data.missed_secured_payments[i].missed_secured_payment_amount
                if (data.missed_secured_payments[i].missed_secured_payment_for_mobile === "YES") ongoing.mobile_missed_secured_payments++
            }
        }
    }
    if (data.adverse_credit === "YES" && data.had_a_missed_unsecured_payment === "YES") {
        first.adverse_credit_details.missed_unsecured_payments = {}
        first.adverse_credit_details.missed_unsecured_payments.raw = data.missed_unsecured_payments
        let last_6 = { number: 0, total_amount: 0 }, last_12 = { number: 0, total_amount: 0 }, last_24 = { number: 0, total_amount: 0 }, last_36 = { number: 0, total_amount: 0 }, gt_36 = { number: 0, total_amount: 0 }, ongoing = { number: 0, total_amount: 0 }
        for (let i = 0; i < data.missed_unsecured_payments.length; i++) {
            if (data.missed_unsecured_payments[i].missed_unsecured_payment_date !== "ONGOING") {
                let months_since = moment().diff(moment(data.missed_unsecured_payments[i].missed_unsecured_payment_date), "months")
                if (months_since >= 0 && months_since < 6) { last_6.number++; last_6.total_amount += data.missed_unsecured_payments[i].missed_unsecured_payment_amount }
                else if (months_since >= 6 && months_since < 12) { last_12.number++; last_12.total_amount += data.missed_unsecured_payments[i].missed_unsecured_payment_amount }
                else if (months_since >= 12 && months_since < 24) { last_24.number++; last_24.total_amount += data.missed_unsecured_payments[i].missed_unsecured_payment_amount }
                else if (months_since >= 24 && months_since < 36) { last_36.number++; last_36.total_amount += data.missed_unsecured_payments[i].missed_unsecured_payment_amount }
                else if (months_since >= 36) { gt_36.number++; gt_36.total_amount += data.missed_unsecured_payments[i].missed_unsecured_payment_amount }
                first.adverse_credit_details.missed_unsecured_payments.last_6 = last_6
                first.adverse_credit_details.missed_unsecured_payments.last_12 = last_12
                first.adverse_credit_details.missed_unsecured_payments.last_24 = last_24
                first.adverse_credit_details.missed_unsecured_payments.last_36 = last_36
                first.adverse_credit_details.missed_unsecured_payments.gt_36 = gt_36
            } else {
                ongoing.number++
                ongoing.total_amount += data.unmissed_secured_payments[i].missed_unsecured_payment_amount
                if (data.missed_unsecured_payments[i].missed_unsecured_payment_for_mobile === "YES") ongoing.mobile_missed_unsecured_payments++
            }
        }
    }
    // if (data.adverse_credit === "YES" && data.had_a_missed_unsecured_payment === "YES") {
    //     first.adverse_credit_details.missed_unsecured_payments = {}
    //     first.adverse_credit_details.missed_unsecured_payments.raw = data.missed_unsecured_payments
    //     let last_6 = { number: 0, total_amount: 0 }, last_12 = { number: 0, total_amount: 0 }, last_24 = { number: 0, total_amount: 0 }, last_36 = { number: 0, total_amount: 0 }, gt_36 = { number: 0, total_amount: 0 }, ongoing = { number: 0, total_amount: 0 }
    //     for (let i = 0; i < data.missed_unsecured_payments.length; i++) {
    //         if (data.missed_unsecured_payments[i].missed_unsecured_payment_date !== "ONGOING") {
    //             let months_since = moment().diff(moment(data.missed_unsecured_payments[i].missed_unsecured_payment_date), "months")
    //             if (months_since >= 0 && months_since < 6) { last_6.number++; last_6.total_amount += data.missed_unsecured_payments[i].missed_unsecured_payment_amount }
    //             else if (months_since >= 6 && months_since < 12) { last_12.number++; last_12.total_amount += data.missed_unsecured_payments[i].missed_unsecured_payment_amount }
    //             else if (months_since >= 12 && months_since < 24) { last_24.number++; last_24.total_amount += data.missed_unsecured_payments[i].missed_unsecured_payment_amount }
    //             else if (months_since >= 24 && months_since < 36) { last_36.number++; last_36.total_amount += data.missed_unsecured_payments[i].missed_unsecured_payment_amount }
    //             else if (months_since >= 36) { gt_36.number++; gt_36.total_amount += data.missed_unsecured_payments[i].missed_unsecured_payment_amount }
    //             first.adverse_credit_details.missed_unsecured_payments.last_6 = last_6
    //             first.adverse_credit_details.missed_unsecured_payments.last_12 = last_12
    //             first.adverse_credit_details.missed_unsecured_payments.last_24 = last_24
    //             first.adverse_credit_details.missed_unsecured_payments.last_36 = last_36
    //             first.adverse_credit_details.missed_unsecured_payments.gt_36 = gt_36
    //         } else {
    //             ongoing.number++
    //             ongoing.total_amount += data.unmissed_secured_payments[i].missed_unsecured_payment_amount
    //             if (data.missed_unsecured_payments[i].missed_unsecured_payment_for_mobile === "YES") ongoing.mobile_missed_unsecured_payments++
    //         }
    //     }
    // }
    if (data.adverse_credit === "YES" && data.been_bankrupt === "YES") {
        if (data.bankruptcy_discharged !== "ONGOING") {
            let years_since = moment().diff(moment(data.bankruptcy_discharged), "years")
            first.adverse_credit_details.bankruptcy_discharged = data.bankruptcy_discharged
            if (years_since < 3) first.adverse_credit_details.bankruptcy_since = "last 3 years"
            else if (years_since >= 3 && years_since < 6) first.adverse_credit_details.bankruptcy_since = "last 6 years"
            else if (years_since >= 6) first.adverse_credit_details.bankruptcy_since = "> 6 years"
        } else {
            first.adverse_credit_details.bankruptcy_discharged = "ONGOING"
            first.adverse_credit_details.bankruptcy_since = "ONGOING"
        }
    }
    if (data.adverse_credit === "YES" && data.had_a_iva === "YES") {
        if (data.iva_discharged !== "ONGOING") {
            let years_since = moment().diff(moment(data.iva_discharged), "years")
            first.adverse_credit_details.iva_discharged = data.iva_discharged
            if (years_since < 3) first.adverse_credit_details.iva_since = "last 3 years"
            else if (years_since >= 3 && years_since < 6) first.adverse_credit_details.iva_since = "last 6 years"
            else if (years_since >= 6) first.adverse_credit_details.iva_since = "> 6 years"
        } else {
            first.adverse_credit_details.iva_discharged = "ONGOING"
            first.adverse_credit_details.iva_since = "ONGOING"
        }
    }
    if (data.adverse_credit === "YES" && data.had_a_dmp === "YES") {
        if (data.dmp_discharged !== "ONGOING") {
            let years_since = moment().diff(moment(data.dmp_discharged), "years")
            first.adverse_credit_details.dmp_discharged = data.dmp_discharged
            if (years_since < 3) first.adverse_credit_details.dmp_since = "last 3 years"
            else if (years_since >= 3 && years_since < 6) first.adverse_credit_details.dmp_since = "last 6 years"
            else if (years_since >= 6) first.adverse_credit_details.dmp_since = "> 6 years"
        } else {
            first.adverse_credit_details.dmp_discharged = "ONGOING"
            first.adverse_credit_details.dmp_since = "ONGOING"
        }
    }
    if (data.adverse_credit === "YES" && data.had_a_payday_loan === "YES") {
        let years_since = moment().diff(moment(data.payday_loan_discharged), "months")
        first.adverse_credit_details.payday_loan_settled = data.payday_loan_settled
        if (years_since < 6) first.adverse_credit_details.payday_loan_since = "last 6 months"
        else if (years_since >= 6 && years_since < 12) first.adverse_credit_details.payday_loan_since = "last 12 months"
        else if (years_since >= 24) first.adverse_credit_details.payday_loan_since = "last 24 months"
    }
    if (data.adverse_credit === "YES" && data.had_a_repossession === "YES") {
        let years_since = moment().diff(moment(data.repossession_settled), "years")
        first.adverse_credit_details.repossession_settled = data.repossession_settled
        first.adverse_credit_details.payday_loan_since = "last 3 years"
    }

    let final = {
        first_applicant: { ...first },
        uk_nationals: data.uk_nationals,
        deposit: data.deposit
    }

    if (data.uk_nationals === "NO") {
        final.residency_status = data.residency_status
        final.eu_nationals = data.eu_nationals
        final.time_in_uk = data.time_in_uk
    }

    if (data.eu_nationals === "NO") final.residency_status = data.residency_status

    if (data.number_of_applicants > 1) {
        let second = {
            first_name: data.first_name_2,
            last_name: data.last_name_2,
            date_of_birth: data.date_of_birth_2,
            dependants: data.dependants_2,
            employment: data.employment_2,
            additional_income: data.additional_income_2,
            credit_card_balance: data.credit_card_balance_2,
            adverse_credit: data.adverse_credit_2
        }

        total_income += parseInt(data.additional_income_2) || 0

        if (data.employment_2 === "EMPLOYED") {
            second.employment_details = { type: data.employment_contract_2, income: data.annual_income_2, deductions: data.deductions_2 }
            total_income += data.annual_income_2
        }
        if (data.employment_2 === "SELF_EMPLOYED") {
            second.employment_details = { type: data.self_employment_2, years: data.self_emplyed_years_in_business_2, net_profit_19_20: data.self_employed_net_profit_19_20_2, net_profit_20_21: data.self_employed_net_profit_20_21_2 }
            total_income += ((data.self_employed_net_profit_19_20_2 + data.self_employed_net_profit_20_21_2) / 2)
        }
        if (data.employment_2 === "COMPANY_DIRECTOR") {
            second.employment_details = { type: data.shareholding_of_company_2, years: data.company_director_years_in_business_2, net_profit_19_20: data.company_director_net_profit_19_20_2, net_profit_20_21: data.company_director_net_profit_20_21_2, operating_profit_19_20: data.company_director_operating_profit_19_20_2, operating_profit_20_21: data.company_director_operating_profit_20_21_2 }
            total_income += ((data.company_director_net_profit_19_20_2 + data.company_director_net_profit_20_21_2) / 2)
        }
        if (data.employment_2 === "CONTRACTOR") {
            second.daily_rate = data.contractor_rate_2
            total_income += data.contractor_rate_2 * 5 * 47
        }

        if (data.adverse_credit_2 === "YES") second.adverse_credit_details = { had_a_ccj: data.had_a_ccj_2, had_a_default: data.had_a_default_2, had_a_missed_secured_payment: data.had_a_missed_secured_payment_2, had_a_missed_unsecured_payment: data.had_a_missed_unsecured_payment_2, had_a_payday_loan: data.had_a_payday_loan_2, been_bankrupt: data.been_bankrupt_2, had_a_iva: data.had_a_iva_2, had_a_dmp: data.had_a_dmp_2 }
        if (data.adverse_credit_2 === "YES" && data.had_a_ccj_2 === "YES") {
            second.adverse_credit_details.ccjs = {}
            second.adverse_credit_details.ccjs.raw = data.ccjs_2
            let last_6 = { number: 0, total_amount: 0 }, last_12 = { number: 0, total_amount: 0 }, last_24 = { number: 0, total_amount: 0 }, last_36 = { number: 0, total_amount: 0 }, gt_36 = { number: 0, total_amount: 0 }, ongoing = { number: 0, total_amount: 0 }
            for (let i = 0; i < data.ccjs_2.length; i++) {
                if (data.ccjs_2[i].ccj_settled_2 !== "ONGOING") {
                    let months_since = moment().diff(moment(data.ccjs_2[i].ccj_settled_2), "months")
                    if (months_since >= 0 && months_since < 6) { last_6.number++; last_6.total_amount += data.ccjs_2[i].ccj_amount_2 }
                    else if (months_since >= 6 && months_since < 12) { last_12.number++; last_12.total_amount += data.ccjs_2[i].ccj_amount_2 }
                    else if (months_since >= 12 && months_since < 24) { last_24.number++; last_24.total_amount += data.ccjs_2[i].ccj_amount_2 }
                    else if (months_since >= 24 && months_since < 36) { last_36.number++; last_36.total_amount += data.ccjs_2[i].ccj_amount_2 }
                    else if (months_since >= 36) { gt_36.number++; gt_36.total_amount += data.ccjs_2[i].ccj_amount_2 }
                    second.adverse_credit_details.ccjs.last_6 = last_6
                    second.adverse_credit_details.ccjs.last_12 = last_12
                    second.adverse_credit_details.ccjs.last_24 = last_24
                    second.adverse_credit_details.ccjs.last_36 = last_36
                    second.adverse_credit_details.ccjs.gt_36 = gt_36
                } else {
                    ongoing.number++
                    ongoing.total_amount += data.ccjs_2[i].ccj_amount_2
                }
            }
        }
        if (data.adverse_credit_2 === "YES" && data.had_a_default_2 === "YES") {
            second.adverse_credit_details.defaults = {}
            second.adverse_credit_details.defaults.raw = data.defaults_2
            let last_6 = { number: 0, total_amount: 0, mobile_defaults: 0 }, last_12 = { number: 0, total_amount: 0, mobile_defaults: 0 }, last_24 = { number: 0, total_amount: 0, mobile_defaults: 0 }, last_36 = { number: 0, total_amount: 0, mobile_defaults: 0 }, gt_36 = { number: 0, total_amount: 0, mobile_defaults: 0 }, ongoing = { number: 0, total_amount: 0, mobile_defaults: 0 }
            for (let i = 0; i < data.defaults_2.length; i++) {
                if (data.defaults_2[i].default_settled_2 !== "ONGOING") {
                    let months_since = moment().diff(moment(data.defaults_2[i].default_settled_2), "months")
                    if (months_since >= 0 && months_since < 6) { last_6.number++; last_6.total_amount += data.defaults_2[i].default_amount_2; if (data.defaults_2[i].default_for_mobile_2 === "YES") last_6.mobile_defaults++ }
                    else if (months_since >= 6 && months_since < 12) { last_12.number++; last_12.total_amount += data.defaults_2[i].default_amount_2; if (data.defaults_2[i].default_for_mobile_2 === "YES") last_12.mobile_defaults++ }
                    else if (months_since >= 12 && months_since < 24) { last_24.number++; last_24.total_amount += data.defaults_2[i].default_amount_2; if (data.defaults_2[i].default_for_mobile_2 === "YES") last_24.mobile_defaults++ }
                    else if (months_since >= 24 && months_since < 36) { last_36.number++; last_36.total_amount += data.defaults_2[i].default_amount_2; if (data.defaults_2[i].default_for_mobile_2 === "YES") last_36.mobile_defaults++ }
                    else if (months_since >= 36) { gt_36.number++; gt_36.total_amount += data.defaults_2[i].default_amount_2; if (data.defaults_2[i].default_for_mobile_2 === "YES") gt_36.mobile_defaults++ }
                    second.adverse_credit_details.defaults.last_6 = last_6
                    second.adverse_credit_details.defaults.last_12 = last_12
                    second.adverse_credit_details.defaults.last_24 = last_24
                    second.adverse_credit_details.defaults.last_36 = last_36
                    second.adverse_credit_details.defaults.gt_36 = gt_36
                } else {
                    ongoing.number++
                    ongoing.total_amount += data.defaults_2[i].default_amount_2
                    if (data.defaults_2[i].default_for_mobile_2 === "YES") ongoing.mobile_defaults++
                }
            }
        }
        if (data.adverse_credit_2 === "YES" && data.had_a_missed_secured_payment_2 === "YES") {
            second.adverse_credit_details.missed_secured_payments = {}
            second.adverse_credit_details.missed_secured_payments.raw = data.missed_secured_payments_2
            let last_6 = { number: 0, total_amount: 0 }, last_12 = { number: 0, total_amount: 0 }, last_24 = { number: 0, total_amount: 0 }, last_36 = { number: 0, total_amount: 0 }, gt_36 = { number: 0, total_amount: 0 }, ongoing = { number: 0, total_amount: 0 }
            for (let i = 0; i < data.missed_secured_payments_2.length; i++) {
                if (data.missed_secured_payments_2[i].missed_secured_payment_date_2 !== "ONGOING") {
                    let months_since = moment().diff(moment(data.missed_secured_payments_2[i].missed_secured_payment_date_2), "months")
                    if (months_since >= 0 && months_since < 6) { last_6.number++; last_6.total_amount += data.missed_secured_payments_2[i].missed_secured_payment_amount_2 }
                    else if (months_since >= 6 && months_since < 12) { last_12.number++; last_12.total_amount += data.missed_secured_payments_2[i].missed_secured_payment_amount_2 }
                    else if (months_since >= 12 && months_since < 24) { last_24.number++; last_24.total_amount += data.missed_secured_payments_2[i].missed_secured_payment_amount_2 }
                    else if (months_since >= 24 && months_since < 36) { last_36.number++; last_36.total_amount += data.missed_secured_payments_2[i].missed_secured_payment_amount_2 }
                    else if (months_since >= 36) { gt_36.number++; gt_36.total_amount += data.missed_secured_payments_2[i].missed_secured_payment_amount_2 }
                    second.adverse_credit_details.missed_secured_payments.last_6 = last_6
                    second.adverse_credit_details.missed_secured_payments.last_12 = last_12
                    second.adverse_credit_details.missed_secured_payments.last_24 = last_24
                    second.adverse_credit_details.missed_secured_payments.last_36 = last_36
                    second.adverse_credit_details.missed_secured_payments.gt_36 = gt_36
                } else {
                    ongoing.number++
                    ongoing.total_amount += data.missed_secured_payments_2[i].missed_secured_payment_amount_2
                    if (data.missed_secured_payments_2[i].missed_secured_payment_for_mobile_2 === "YES") ongoing.mobile_missed_secured_payments++
                }
            }
        }
        if (data.adverse_credit_2 === "YES" && data.had_a_missed_unsecured_payment_2 === "YES") {
            second.adverse_credit_details.missed_unsecured_payments = {}
            second.adverse_credit_details.missed_unsecured_payments.raw = data.missed_unsecured_payments_2
            let last_6 = { number: 0, total_amount: 0 }, last_12 = { number: 0, total_amount: 0 }, last_24 = { number: 0, total_amount: 0 }, last_36 = { number: 0, total_amount: 0 }, gt_36 = { number: 0, total_amount: 0 }, ongoing = { number: 0, total_amount: 0 }
            for (let i = 0; i < data.missed_unsecured_payments_2.length; i++) {
                if (data.missed_unsecured_payments_2[i].missed_unsecured_payment_date_2 !== "ONGOING") {
                    let months_since = moment().diff(moment(data.missed_unsecured_payments_2[i].missed_unsecured_payment_date_2), "months")
                    if (months_since >= 0 && months_since < 6) { last_6.number++; last_6.total_amount += data.missed_unsecured_payments_2[i].missed_unsecured_payment_amount_2 }
                    else if (months_since >= 6 && months_since < 12) { last_12.number++; last_12.total_amount += data.missed_unsecured_payments_2[i].missed_unsecured_payment_amount_2 }
                    else if (months_since >= 12 && months_since < 24) { last_24.number++; last_24.total_amount += data.missed_unsecured_payments_2[i].missed_unsecured_payment_amount_2 }
                    else if (months_since >= 24 && months_since < 36) { last_36.number++; last_36.total_amount += data.missed_unsecured_payments_2[i].missed_unsecured_payment_amount_2 }
                    else if (months_since >= 36) { gt_36.number++; gt_36.total_amount += data.missed_unsecured_payments_2[i].missed_unsecured_payment_amount_2 }
                    second.adverse_credit_details.missed_unsecured_payments.last_6 = last_6
                    second.adverse_credit_details.missed_unsecured_payments.last_12 = last_12
                    second.adverse_credit_details.missed_unsecured_payments.last_24 = last_24
                    second.adverse_credit_details.missed_unsecured_payments.last_36 = last_36
                    second.adverse_credit_details.missed_unsecured_payments.gt_36 = gt_36
                } else {
                    ongoing.number++
                    ongoing.total_amount += data.unmissed_secured_payments_2[i].missed_unsecured_payment_amount_2
                    if (data.missed_unsecured_payments_2[i].missed_unsecured_payment_for_mobile_2 === "YES") ongoing.mobile_missed_unsecured_payments++
                }
            }
        }
        if (data.adverse_credit_2 === "YES" && data.been_bankrupt_2 === "YES") {
            if (data.bankruptcy_discharged_2 !== "ONGOING") {
                let years_since = moment().diff(moment(data.bankruptcy_discharged_2), "years")
                second.adverse_credit_details.bankruptcy_discharged = data.bankruptcy_discharged_2
                if (years_since < 3) second.adverse_credit_details.bankruptcy_since = "last 3 years"
                else if (years_since >= 3 && years_since < 6) second.adverse_credit_details.bankruptcy_since = "last 6 years"
                else if (years_since >= 6) second.adverse_credit_details.bankruptcy_since = "> 6 years"
            } else {
                second.adverse_credit_details.bankruptcy_discharged = "ONGOING"
                second.adverse_credit_details.bankruptcy_since = "ONGOING"
            }
        }
        if (data.adverse_credit_2 === "YES" && data.had_a_iva_2 === "YES") {
            if (data.iva_discharged_2 !== "ONGOING") {
                let years_since = moment().diff(moment(data.iva_discharged_2), "years")
                second.adverse_credit_details.iva_discharged = data.iva_discharged_2
                if (years_since < 3) second.adverse_credit_details.iva_since = "last 3 years"
                else if (years_since >= 3 && years_since < 6) second.adverse_credit_details.iva_since = "last 6 years"
                else if (years_since >= 6) second.adverse_credit_details.iva_since = "> 6 years"
            } else {
                second.adverse_credit_details.iva_discharged = "ONGOING"
                second.adverse_credit_details.iva_since = "ONGOING"
            }
        }
        if (data.adverse_credit_2 === "YES" && data.had_a_dmp_2 === "YES") {
            if (data.dmp_discharged_2 !== "ONGOING") {
                let years_since = moment().diff(moment(data.dmp_discharged_2), "years")
                second.adverse_credit_details.dmp_discharged = data.dmp_discharged_2
                if (years_since < 3) second.adverse_credit_details.dmp_since = "last 3 years"
                else if (years_since >= 3 && years_since < 6) second.adverse_credit_details.dmp_since = "last 6 years"
                else if (years_since >= 6) second.adverse_credit_details.dmp_since = "> 6 years"
            } else {
                second.adverse_credit_details.dmp_discharged = "ONGOING"
                second.adverse_credit_details.dmp_since = "ONGOING"
            }
        }
        if (data.adverse_credit === "YES" && data.had_a_payday_loan_2 === "YES") {
            let years_since = moment().diff(moment(data.payday_loan_discharged_2), "months")
            second.adverse_credit_details.payday_loan_settled = data.payday_loan_settled_2
            if (years_since < 6) second.adverse_credit_details.payday_loan_since = "last 6 months"
            else if (years_since >= 6 && years_since < 12) second.adverse_credit_details.payday_loan_since = "last 12 months"
            else if (years_since >= 24) second.adverse_credit_details.payday_loan_since = "last 24 months"
        }
        if (data.adverse_credit === "YES" && data.had_a_repossession_2 === "YES") {
            let years_since = moment().diff(moment(data.repossession_settled_2), "years")
            second.adverse_credit_details.repossession_settled = data.repossession_settled_2
            second.adverse_credit_details.payday_loan_since = "last 3 years"
        }

        final = { ...final, second_applicant: { ...second } }
    }

    final = { ...final, total_income: total_income, number: data.phone_number }

    return final
}

export default format_data