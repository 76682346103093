import React, { useState } from 'react';
import agent from '../../agent/agent';
import * as EmailValidator from 'email-validator';

export default function Mailing(props) {
    const [first, setFirst] = useState("")
    const [last, setLast] = useState("")
    const [email, setEmail] = useState("")
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")

    async function send_mailing() {
        if (EmailValidator.validate(email)) {
            let res = await agent.mailing.add(first, last, email)
            if (res.body.success) {
                setSuccess(res.body.message)
            } else {
                setError(res.body.message)
            }
        } else {
            setError("Email address invalid")
        }
    }

    return (
        <div className="mailing">
            <div className="contain">
                <i className="heading">
                    Sign Me Up!
                </i>
                <br />
                <div className="small_text">
                    Sign up with your email address to receive news and register for our products.
                </div>

                <div className="form">
                    <input className="input" type="text" placeholder="First Name" value={first} onChange={e => {
                        setError("")
                        setSuccess("")
                        setFirst(e.currentTarget.value)
                    }} />
                    <input className="input" type="text" placeholder="Last Name" value={last} onChange={e => {
                        setError("")
                        setSuccess("")
                        setLast(e.currentTarget.value)
                    }} />
                    <input className="input" type="text" placeholder="Email" value={email} onChange={e => {
                        setError("")
                        setSuccess("")
                        setEmail(e.currentTarget.value)
                    }} />
                    <br />
                    <button className="submit" onClick={send_mailing}>
                        Submit
                    </button>
                    <div className="success" style={{ display: success ? "block" : "none" }}>{success}</div>
                    <div className="error" style={{ display: error ? "block" : "none" }}>{error}</div>
                </div>
            </div>
        </div>
    );
}