export default function toggle_modal(id) {
    let modal = document.getElementById(id);
    let modalContent = document.getElementById(id + "_content");
    if (modal) {
        if (modal.style.visibility === "visible") {
            modal.style.visibility = "hidden"
            if (modalContent) {
                modalContent.style.transform = "translate(-50%, 100%)";
            }
        } else {
            modal.style.visibility = "visible";
            if (modalContent) {
                modalContent.style.transform = "translate(-50%, -50%)";
            }
        }
    }
}