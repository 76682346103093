import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

export default function AdversType(props) {
    const [data, setData] = useState(props.data)
    const [dataset, setDataset] = useState([])

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        let obj = {
            "CCJ": 0,
            "Default": 0,
            "Bankruptcy": 0,
            "IVA": 0,
            "DMP": 0,
            "Missed Secured Payment": 0,
            "Missed Unsecured Payment": 0,
            "Payday Loan": 0,
            "Repossession": 0
        }
        for (let i = 0, size = data.length; i < size; i++) {
            let d = data[i].data
            if (d.adverse_credit === "YES") {
                if (d.had_a_ccj === "YES") obj["CCJ"] += 1
                if (d.had_a_default === "YES") obj["Default"] += 1
                if (d.been_bankrupt === "YES") obj["Bankruptcy"] += 1
                if (d.had_a_iva === "YES") obj["IVA"] += 1
                if (d.had_a_dmp === "YES") obj["DMP"] += 1
                if (d.had_a_missed_secured_payment === "YES") obj["Missed Secured Payment"] += 1
                if (d.had_a_missed_unsecured_payment === "YES") obj["Missed Unsecured Payment"] += 1
                if (d.had_a_payday_loan === "YES") obj["Payday Loan"] += 1
                if (d.had_a_repossession === "YES") obj["Repossession"] += 1
            }
        }

        let arr = []
        Object.entries(obj).map(e => {
            arr.push({ x: e[0], y: e[1] })
        })

        setDataset(arr)
    }, [data])

    return (
        <Bar data={{
            labels: ["CCJ", "Default", "Bankruptcy", "IVA", "DMP", "Missed Secured Payment", "Missed Unsecured Payment", "Payday Loan", "Repossession"],
            datasets: [
                {
                    label: 'Type of adverse credit',
                    data: [...dataset],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }} />
    );
}