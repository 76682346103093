import React from 'react';

export default function Review(props) {
    return (
        <div className="review">
            <div className="review_contain">
                <div className="image">
                    <img src={props.img} alt="Profile" />
                </div>
                <div className="content">
                    <div className="heading">
                        {props.name}. <span className="red">{props.issue}.</span>
                    </div>
                    <div className="text">
                        {props.comment}
                    </div>
                </div>
            </div>
        </div>
    );
}