import React from 'react';
import mountain from "../../assets/img/mountain-2.png"
import logo from "../../assets/img/logo_pink.png"

export default function Footer(props) {
    return (
        <div className="footer">
            <img className="mountain" src={mountain} alt="Mountain" />
            <div className="contain">
                <div className="logo_section">
                    <div className="logo">
                        <img src={logo} alt="Sprung" />
                    </div>
                </div>
                <div className="links">
                    <div className="links_heading">
                        INFO
                    </div>
                    <div className="link">
                        Jobs
                    </div>
                    <div className="link">
                        Copyright
                    </div>
                    <div className="link">
                        Privacy Policy
                    </div>
                    <div className="link">
                        More Info
                    </div>
                </div>
                <div className="links">
                    <div className="links_heading">
                        SOCIAL
                    </div>
                    <div className="link">
                        Twitter
                    </div>
                    <div className="link">
                        Facebook
                    </div>
                    <div className="link">
                        Instagram
                    </div>
                </div>
            </div>
        </div>
    );
}